import { Storage } from '@capacitor/storage';
const consola = function(){ void(0) }; //consola

const schema = 'ubiku.ges.digital'

        const delUbicacionStorage = async function(clave, pdv ) {
            await Storage.remove({ key: `${schema}:ubicaciones:${pdv}:${clave}` });
        }
        /** ------------------------------------------------------------------------------------------ */

        const saveUbicacionStorage = async function(clave,pdv, valor) {

            await Storage.set({ key: `${schema}:ubicaciones:${pdv}:${clave}` , value: JSON.stringify(valor)})
        }
        
        /** ------------------------------------------------------------------------------------------ */
        const loadUbicacionStorage = async function(clave, pdv) {
            window.fake_console(clave, pdv )
            try {
                const k = pdv == undefined && clave.indexOf(`${schema}:ubicaciones`)!= -1 
                    ? clave 
                    : `${schema}:ubicaciones:${pdv}:${clave}`
                consola(k)
                const {value} = await Storage.get({ key: k})
                return value == undefined ? undefined: JSON.parse(value )
            }
            catch(ey) {
                return undefined
            }            
        }

        const clavesUbicacionStorage = async function(pdv) {
                try {
                    const {keys}  = await Storage.keys()
                    consola(keys ,`${schema}:ubicaciones:${pdv}`)
                    return keys.filter(v => v.indexOf( `${schema}:ubicaciones:${pdv}`)!=-1)
                }
                catch {
                    return undefined
                }
        }



export { saveUbicacionStorage, loadUbicacionStorage, delUbicacionStorage, clavesUbicacionStorage}

