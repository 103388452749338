
import { myURL } from '@/main';
import { IPS } from '@/utilities/global-constants'
const consola = function () { void (0) } //consola


const dNumber = (n) => {
  return n === undefined ? 0 : n
}

const isOnline = () => {
  const src = `${myURL}/online`
  let img = new Image()
  img.src = src

  img.onload = function () {
    consola('entro en onload')
    //alert('estamos online')
    return true
  }

  img.onerror = function () {
    consola('entro en onerror')
    alert(' no hay conexion')
    return false
  }
}

const convertSku = function (sku) {
  try {
    return { 'codmar': sku.slice(0, 3), 'artpro': sku.slice(4) }
  }
  catch {
    return { 'codmar': undefined, 'artpro': undefined }
  }

}
const UUIDGeneratorBrowser = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  )

const ValidarDNI = (value) => {
  if (!value || value.length < 9) { return false }

  var validChars = 'TRWAGMYFPDXBNJZSQVHLCKET'
  var nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i
  var nieRexp = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i
  var str = value.toString().toUpperCase()

  if (!nifRexp.test(str) && !nieRexp.test(str)) return false

  var nie = str
    .replace(/^[X]/, '0')
    .replace(/^[Y]/, '1')
    .replace(/^[Z]/, '2')

  var letter = str.substr(-1)
  var charIndex = parseInt(nie.substr(0, 8)) % 23

  if (validChars.charAt(charIndex) === letter) return true

  return false
}


const fetchWithTimeout = async function (resource, options = {}) {
  const { timeout = 5000 } = options

  const controller = new AbortController()
  const id = setTimeout(() => controller.abort(), timeout)

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal
  })
  clearTimeout(id)
  return response
}


const checkIP = async () => {
  try {
    const response = await fetchWithTimeout('https://api.ipify.org/?format=text')
    const cur_ip = await response.text()
    console.log(cur_ip, IPS.indexOf(cur_ip) != -1, IPS.indexOf(cur_ip) != -1 ? 'En GES' : 'Fuera de GES')
    return IPS.indexOf(cur_ip) != -1 ? true : undefined
  }
  catch {
    console.log('Fuera de linea ')
    return undefined
  }
}

export {
  dNumber, UUIDGeneratorBrowser, isOnline, convertSku, ValidarDNI, checkIP
}

