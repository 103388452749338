
import {pluck, mapObject , groupBy, chain,  pick, flatten, unique, pairs, extend} from 'underscore';
import { loadEntradafromStorage  } from '@/utilities/storage/storage-entradas'
const metros_aceptados_merma = 5 
const tolerancia_merma = 0.05

const comparador = function(a,b) {
        if (a[0] > b[0]) { return 1; }
        if (a[0] < b[0]) { return -1; }
        return 0;
}

const filtroPedido = function (peds, unidades) {
    const pares = pairs(peds).sort(comparador)
    const elegibles =  pares.filter ( w=>w[1]['pedido_origen'] ==unidades)
    return (elegibles && elegibles.length>0) ? elegibles[0][0]: undefined 
}

const getDetallePedidosSku = async function (pdv, codpro,codalb, http_con){
    let recepcion_data
    try {        
        const all_recepcion_data = await loadEntradafromStorage(pdv, codpro,codalb, 'recepcion')
        recepcion_data = all_recepcion_data.filter(x => x.recepcionable === true)
        window.fake_console(recepcion_data)
        /** Montamos la lista de skus  */
        const skus = JSON.stringify(unique(pluck(recepcion_data,'sku')));
        window.fake_console(skus)

        /** montamos los niveles posibles de coincidencia de mejor a peor pedido.sucursal.linea, pedido.sucursal...  */
        const grupos_recepcion = mapObject(groupBy(recepcion_data, 'sku'), (v)=>{
                return v.reduce((m,x)=> { 
                        m[0].push(`${x.pedcli||'_'}.${x.codsuc||'_'}.${x.pedlin}`)
                        m[1].push(`${x.pedcli||'_'}.${x.codsuc||'_'}`)
                        m[2].push(`${x.pedcli||'_'}`)
                        m[3].push(`${x.codsuc}`)
                        return [unique(m[0]), unique(m[1]), unique(m[2]), unique(m[3]) ] 
                }, [[],[],[],[]])
            });
        window.fake_console(grupos_recepcion)
        /** Montamos los totales por referencia  */
        const limites_unidad =  recepcion_data.reduce((ml,vl)=>{
            const ped_ref = `${vl.numped.toString().padStart(10,0)}:${vl.pedlin.toString().padStart(3,0)}`;
            const articulo = vl['sku']
            if (ml[articulo]=== undefined) {
                if (vl['numlot'] && vl['geslot']== 'S') {
                    ml[articulo] = {};
                    window.fake_console(JSON.stringify(ml ))
                    
                    const sk = articulo;
                    ml[sk] =  { [ ped_ref ] : {'pedido_origen' : vl.unidades_originales_pedido, 'pedido': vl.uds_ped , 'lotes' : [{'numlot' : vl.numlot, 'uds_rec' : vl.uds_rec }] , 'recibidas' : vl.uds_rec , 'sobrante' : vl.uds_rec - vl.uds_ped }}
                }   
                else {
                    ml[articulo] = { 'all' : vl.uds_rec }
                    window.fake_console(JSON.stringify(ml ))
                }
           }
           
            else if (vl['numlot'] && vl['geslot']== 'S') {
                
                ml[articulo][ ped_ref ].lotes.push({'numlot' : vl.numlot, 'uds_rec' : vl.uds_rec });
                ml[articulo][ ped_ref ].recibidas+=vl.uds_rec;
                ml[articulo][ ped_ref ].sobrante+=vl.uds_rec;
            }
            else {                
                ml[articulo]['all'] +=   vl.uds_rec  
            }
            return ml 
        } ,{})

        window.fake_console(limites_unidad)
        window.fake_console(JSON.stringify(limites_unidad))
        const payload = {codpro, codalb, skus }
        window.fake_console(payload)
        const respuesta = await http_con.post(`/get_skus_pending_orders/${pdv}`, payload)
        window.fake_console(respuesta.data)
        
        if (respuesta)
            {
                window.fake_console(respuesta.data )
                const backlog = chain(respuesta.data).reduce((m,v) =>{
                    v.sku = `${v.codmar}.${v.artpro}`;
                    const base = grupos_recepcion[v.sku];
                    window.fake_console(base)
                    const elementos = [`${v.numped}.${v.codsuc}.${v.numlin}`, `${v.numped}.${v.codsuc}`, `${v.numped}`, `${v.codsuc}`]
                    window.fake_console(elementos)
                    const setUnicos = new Set([ ...flatten(base),...elementos ]);
                    window.fake_console(setUnicos)
                    const unicos = [...setUnicos].length
                    window.fake_console(unicos)
                    const indice = unicos - base.length
                    window.fake_console(indice, unicos, base.length)
                    if (indice <=base.length) /* al menos coincidir en codsuc*/
                        {
                            v.rnum =  parseInt(('0'+(indice).toString(2)).slice(-2)+v.mi_binario , 2);
                            v.grupo = indice;
                            m.push(v);
                        }
                    return m;
                    },[]).sortBy('rk').map( x => {
                        let sku =  x['sku'];
                        window.fake_console(limites_unidad[x['sku']] )  
                        /** si hemos sumarizado la referencia all, sino el pedido que coincida en unidades */
                        const variante = limites_unidad[x['sku']]['all'] !=undefined 
                            ? 'all'
                            : filtroPedido (limites_unidad[x['sku']], x['unidad'] )
                        window.fake_console(x['sku'], x['unidad'], variante)
                        /** tratamos de forma diferente en función de si hay o no lotes */

                        if (variante ) {
                            window.fake_console('dentro de la variante')
                            let objeto = limites_unidad[x['sku']][variante] ;
                            x['resto'] = variante === 'all' ? objeto : objeto['pedido']  ;
                            x['pdtes'] = 0+( variante === 'all' ? objeto : objeto['pedido'] )-x['unipen']
                            limites_unidad[x['sku']][variante] = x['pdtes'];
                            /*objeto['remanente'] = x['pdtes'];*/
                            if (variante != 'all') {
                                window.fake_console('entro en el detalle ')
                                /** ajusto los sobrantes  */
                                const lote_medio = objeto.pedido_origen / (objeto.lotes.length||1)
                                objeto.lotes = objeto.lotes.map(ol => {
                                    if ( (ol.uds_rec - lote_medio)> Math.max(metros_aceptados_merma, lote_medio*0.001) ||  ((ol.uds_rec - lote_medio)>(lote_medio*tolerancia_merma)) ) {
                                        ol.sobrante = ol.uds_rec - lote_medio;
                                    }
                                    return ol  
                                })
                                const sobrantes = pluck(objeto.lotes, 'sobrante').reduce( (mo, so) =>  {return mo+=(so||0)} ,0)
                                objeto.sobrante = sobrantes||0; 
                                x['sobrante_total']= (x['sobrante_total']||0)+(objeto.sobrante||0);
                                if ( x['detalle']=== undefined) {
                                    x['detalle']=[extend({'sku':sku}, objeto)]
                                }
                                else {
                                    x['detalle'].push(extend({'sku':sku}, objeto))
                                }
                            }
                        }
                        
                        window.fake_console(x)
                        return x;
                    })
                        /* 
                        Devolvemos los que podemos completar linea - si son del grupo general - 
                        y los que podemos completar total o parcial si hay identificación por pedido
                        */
                    .filter(x =>{
                        window.fake_console(x)
                        return ( (x.pdtes>=  0 )|| (x.grupo<4 && x.resto>0))}
                        )
                    .groupBy('numped')
                    .value()
                
                window.fake_console( backlog)
                /* agrupamos para presentacion a nivel de pedido*/
                let solucion =  groupBy(mapObject(backlog,  (bv)=> {
                    const {numped, fecped, feceni, fecent, pedcli, cliente, priori, enttot, nomenv, locenv ,impres_ped, imppen_ped, impbru_ped, sobrante_total, lintot_ped, linpen_ped, grupo,detalle} = bv[0]
                    let impbru_ent = 0 
                    const lineas = bv.map( xl =>  {
                        xl['asignadas'] = Math.min(xl['unipen'],xl['resto'])
                        xl['impbru_lin']= Math.min(xl['asignadas'],xl['resto'])*xl['impbru_lin']/xl['unidad']
                        impbru_ent+=xl['impbru_lin']
                        return pick(xl,['numped','codsuc','numlin','codmar','artpro','estado','unidad','asignadas', 'unipen','pdtes', 'resto', 'impbru_lin'] )
                    })
                    impbru_ent = parseInt(Math.round(impbru_ent*100))/100	
                    return {numped, fecped, feceni, fecent, pedcli, cliente, priori, enttot, nomenv, locenv ,impres_ped, imppen_ped, sobrante_total, impbru_ped, lintot_ped, linpen_ped,  grupo, impbru_ent, lineas, detalle, xdocking:true}
                }), zz => zz.grupo===4 ? 'generico' : 'especifico') 

                return solucion 
            }
        
    }
    catch (ex){
       console.log(ex)
        return []
    }
}
    
    
    
export { getDetallePedidosSku   }