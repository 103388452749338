/**
    Seleccion expecidiones 
    Listado de las envios generadas y aun no expedidas a cliente 
 */

<template>
    <ion-page>
        <CabeceraComponent :pdv="$route.meta.pdv" :isIOS="$route.meta.isIOS" titulo="Carga pendiente de entrega"
            color="primary" :at_ges="EnPdv || false" />
        <ion-content class="ion-padding" key="`content:${qt}`">

            <ion-fab vertical="top" horizontal="end" slot="fixed">
                <ion-fab-button :key="`scan-activo:${blinker}`" :class="blinker ? 'activo' : 'inactivo'">
                    <i class="fas fa-solid fa-barcode-read muy-grande" :class="blinker ? 'blink' : 'not-blink'"
                        :key="`ion-scan:${blinker}`"></i>
                </ion-fab-button>
            </ion-fab>

            <ion-fab vertical="bottom" horizontal="start" edge="true" slot="fixed" v-if="EnPdv">
                <ion-fab-button color="danger" @click="cambiarVehiculo()">
                    <i class="fak fa-regular-truck-circle-question crossdock blanco"></i>
                </ion-fab-button>
            </ion-fab>
            <ion-fab vertical="bottom" horizontal="end" edge="true" slot="fixed" v-if="EnPdv || ubiku_rol == 5">
                <ion-fab-button color="danger" routerLink="/expediciones-carga" routerDirection="none"
                    :disabled="!vehiculo_libre">
                    <i class="fas fa-sharp fa-dolly  crossdock blanco" />
                </ion-fab-button>
            </ion-fab>

            <!-- situaciones de bloqueo-->
            <ion-row v-if="!vehiculo_libre && lock_usu !== $current_user">
                <ion-col size="48">
                    <ion-button expand="block" fill="outline" size="large" class="trigger_scan">
                        <ion-title>
                            <ion-text class="ion-text-wrap estrecho">
                                El vehiculo <b>{{ vehiculo_actual }} está bloqueado por {{ nomusu }}.</b><br />
                                No es posible añadir carga hasta que él lo libere.
                                Esta acción solo puede hacerla estando en el pdv.
                            </ion-text>
                        </ion-title>
                    </ion-button>
                </ion-col>
            </ion-row>

            <ion-row v-else-if="!vehiculo_libre && lock_usu === $current_user">
                <ion-col size="38">
                    <ion-button expand="block" fill="outline" size="large" class="trigger_scan">
                        <ion-title>
                            <ion-text class="ion-text-wrap estrecho">
                                El vehiculo <b>{{ vehiculo_actual }} está bloqueado por ti.</b><br />
                                Para poder cargar antes debes cerrar el reparto actual.
                            </ion-text>
                        </ion-title>
                    </ion-button>
                </ion-col>
                <ion-col size="10">
                    <ion-button class="alternativa impresion" fill="solid" expand="block" color="darkdanger"
                        @click="cerrarRepartos()">
                        <i class="fas fa-plus blanca">
                            <ion-text class="ion-text-wrap en-botton">
                                Cerrar<br />Reparto
                            </ion-text>
                        </i>
                    </ion-button>
                </ion-col>
            </ion-row>
            <!-- DATOS DE LA CARGA-->
            <ion-row>
                <ion-list v-if="showme && largo > 0" class="ion-no-padding" ref="ion_item_options_slider">
                    <ion-list-header class="head" style="width:100% !important;">
                        <ion-row>
                            <ion-col size="48">
                                <ion-text class="fake-titulo"> Carga Actual ({{ vehiculo_actual }})</ion-text>
                            </ion-col>
                        </ion-row>
                    </ion-list-header>

                    <ion-item-sliding v-for="(envio, index) in datos" v-bind:key="`item-${index}`">
                        <ion-item class="ion-no-padding ion-no-margin">
                            <ion-row>
                                <ion-col size-offset="1" size="43" class="ion-no-margin  ion-no-padding">
                                    <h3 class="nombre_cortado" text-wrap>
                                        {{ String(envio.codemp) }}.{{ String(envio.codcli) }} - {{ envio.nomcli }}
                                    </h3>
                                </ion-col>
                                <ion-col size="4" class="ion-no-margin  ion-no-padding">
                                    <i :class="`${icono_estado(envio)}   ${colores_situacion[envio['situa']]} tiempo`" />
                                </ion-col>
                                <ion-col size-offset="1" size="47" class="ion-no-margin  ion-no-padding">
                                    <h5 class="full-h5 intermedio">
                                        <span class="lote oscuro"><b><small>Nomenv : </small>
                                                {{ envio.nomenv }}
                                            </b>
                                        </span>
                                        <span class="lote peque forzar_derecha">Fecha: {{ $filters.num2Date(envio.fecenv) }}
                                        </span>
                                    </h5>
                                </ion-col>

                                <ion-col size-offset="1" size="36" class="ion-no-margin  ion-no-padding">
                                    <h5 class="nombre_cortado intermedio" text-wrap>
                                        <span class="lote oscuro">
                                            <small>
                                                <b>{{ envio.cpenv || '' }} - {{ envio.pobenv }}</b>
                                            </small>
                                        </span>
                                    </h5>
                                </ion-col>
                                <ion-col size="11" class="ion-no-margin  ion-no-padding ion-text-end">
                                    <ion-text class="ion-text-wrap">
                                        <small>Bultos: </small>{{ envio.bultos }}
                                    </ion-text>
                                </ion-col>

                                <ion-col size-offset="1" size="47" class="ion-no-margin  ion-no-padding">
                                    <h5 class="full-h5 intermedio" text-wrap>
                                        <span class="lote oscuro"><b><small>Albaran. :
                                                </small>{{ envio.albaranes }}</b></span>
                                    </h5>
                                </ion-col>

                                <ion-col size-offset="1" size="32" class="ion-no-margin  ion-no-padding">
                                    <h5 class="full-h5 intermedio" text-wrap>
                                        <span class="lote oscuro"><b><small>Cargado por :
                                                </small>{{ envio.carga_uname }}</b></span>
                                    </h5>
                                </ion-col>
                                <ion-col size="15" class="ion-no-margin  ion-no-padding">
                                    <h3 class="full-h5 sin-margen">
                                        <span class="lote forzar_derecha" :class="css_entrega[envio['envio_tipo']]">
                                            <b>{{ envio.ubicacion }}</b>
                                        </span>
                                    </h3>
                                </ion-col>
                                <ion-col size-offset="1" size="47" v-if="envio.incidencia_ent"
                                    class="ion-no-margin  ion-no-padding">
                                    <h5 class="nombre_cortado" text-wrap>
                                        <span class="lote rojo forzar_derecha">{{ envio.incidencia_ent }}</span>
                                    </h5>
                                </ion-col>
                            </ion-row>
                        </ion-item>

                        <ion-item-options side="start">
                            <ion-item-option color="danger" @click="RutaGoogleMaps(envio)" v-if="envio.lat_env">
                                <i class="fas  fa-location-dot  muy-grande" slot="bottom"></i>
                                <span class="boton-opciones">Indicaciones</span>
                            </ion-item-option>
                            <ion-item-option color="warning" @click="forzarPointer(envio)" v-if="envio.incidencia_ent">
                                <i class="fas fa-sharp fa-location-pen muy-grande" slot="bottom"></i>
                                <span class="boton-opciones">Forzar<br />Pointer</span>
                            </ion-item-option>
                            <ion-item-option color="darksuccess" @click="imprimir_etiqueta_envio(envio)" :disabled="!EnPdv"
                                v-if="envio.situa == 1">
                                <i class="fas fa-sharp fa-print muy-grande" slot="bottom"></i>
                                <span class="boton-opciones">Print</span>
                            </ion-item-option>
                        </ion-item-options>

                        <ion-item-options side="end">
                            <ion-item-option color="danger" @click="DescargarEnvio(envio)" :disabled="!EnPdv">
                                <i class="fak fa-solid-truck-circle-arrow-down muy-grande" slot="bottom"></i>
                                <span class="boton-opciones">Descarga</span>
                            </ion-item-option>
                        </ion-item-options>
                    </ion-item-sliding>
                </ion-list>

                <ion-list v-else class="ion-no-padding" ref="ion_item_options_slider">
                    <ion-list-header class="head" style="width:100% !important;">
                        <ion-row>
                            <ion-col size="48">
                                <ion-text class="fake-titulo"> Carga Actual ({{ vehiculo_actual }})</ion-text>
                            </ion-col>
                        </ion-row>
                    </ion-list-header>
                    <ion-item>
                        <ion-row no-padding no-margin no-lines>
                            <ion-col class="centro">
                                <ion-text>No hay envios en reparto</ion-text>
                            </ion-col>
                        </ion-row>
                    </ion-item>
                </ion-list>
            </ion-row>
        </ion-content>

        <ion-tab-bar slot="bottom" color="primary">
            <ion-tab-button :selected="EnPdv" :disabled="!EnPdv">
                <i class="fak fa-regular-truck-circle-question tiempo" v-if="!EnPdv && vehiculo_libre" />
                <ion-label :class="EnPdv ? 'fab-in-tab' : ''">
                    Cambiar<br />Vehiculo
                </ion-label>
            </ion-tab-button>

            <ion-tab-button @click="void (0)" selected :disabled="ubiku_rol == 5 && !EnPdv">
                <i class="fas fa-sharp fa-truck-ramp-box tiempo" />
                <ion-label>Carga<br />Actual</ion-label>
            </ion-tab-button>

            <ion-tab-button @click="irA('/expediciones-reparto')" :disabled="ubiku_rol != 5">
                <i class="fas fa-route tiempo" />
                <ion-label>Reparto</ion-label>
            </ion-tab-button>

            <ion-tab-button @click="irA('/expediciones-entregado')" :disabled="ubiku_rol != 5">
                <i class="fak fa-sharp-regular-house-circle-check tiempo" />
                <ion-label>Entregado</ion-label>
            </ion-tab-button>

            <ion-tab-button :selected="EnPdv && vehiculo_libre" :disabled="!EnPdv && ubiku_rol != 5">
                <i class="fas fa-sharp fa-dolly tiempo" v-if="!EnPdv" />
                <ion-label :class="EnPdv ? 'fab-in-tab' : ''">
                    Cargar<br />camion
                </ion-label>
            </ion-tab-button>
        </ion-tab-bar>
    </ion-page>
</template>

<script>
import CabeceraComponent from '@/components/CabeceraComponent'
import { defineComponent } from 'vue';
import { scanGenericStart, scanGenericStop } from '@/utilities/scanner/scan-generic'
import { esEnvio } from '@/utilities/global-constants';
import { sortBy, pluck } from 'underscore';
import { saveStorage, loadStorage } from '@/utilities/storage/storage-utilities-main'
import { openToast } from '@/utilities/services/alert-utilities';
import { openAlertaVehiculo, openAlertaDescargaCamion } from '@/pages/enviar/components/expedir-utilities.js';
import { openAlertaV2 } from '@/utilities/services/alert-utilities';
import { css_entrega, ubicaciones_entrega, estados_visuales, colores_situacion } from '@/utilities/global-constants';
import { pdvDistance, RutaGoogleMaps } from '@/utilities/location'
import { recuperarGoogleGeoLocEntrega } from '@/pages/enviar/components/expedir-utilities.js';

import eventBus from "@/event-bus";

import { useRoute } from 'vue-router';

export default defineComponent({
    name: "SeleccionExpedicionesPage",
    components: { CabeceraComponent },
    data() {
        return {
            css_entrega, ubicaciones_entrega, estados_visuales, colores_situacion,
            RutaGoogleMaps,
            pdv: this.$route.meta.pdv,
            ubiku_rol: this.$route.meta.ubiku_rol,
            qt: false,
            nompos: this.$route.meta.nompos,
            element_id: 'seleccion_expediciones',
            clave: undefined,
            showme: false,
            datos: [],
            situacion_envio: 1,
            mis_vehiculos: undefined,
            vehiculo_actual: undefined,
            datos_filtrados: [],
            EnPdv: undefined,
            pdv_geoloc: this.$route.meta.pdv_geoloc,
            vehiculo_libre: undefined,
            nomusu: undefined,
            lock_usu: undefined,
            blinker: false
        }
    },
    async created() {
        this.pdv = this.$route.meta.pdv;
        this.nompos = this.$route.meta.nompos;
        this.pdv_geoloc = this.$route.meta.pdv_geoloc;
        this.ubiku_rol = this.$route.meta.ubiku_rol
    },
    async mounted() {
        this.$NoBackNavigator()
        window.fake_console('seleccion ubicaciones: entro en mounted  ', this.pdv)
        const route = useRoute();
        this.qt = route.query.q || Date.now()
        this.EnPdv = await pdvDistance(this.$geofence, this.pdv_geoloc, true) === true || undefined
        await this.recuperar_vehiculo()
        await this.estadoVehiculo()
        await this.recuperar_envios()
        await this.escaner_generico()
    },
    async unmounted() {
        await this.pararScanner()
    },
    methods: {
        async estadoVehiculo() {
            const status_camion = await this.$http.post(`/status_transport/${this.pdv}`, { vehiculo: this.vehiculo_actual })
            const { email, nomusu, estado } = status_camion.data
            this.vehiculo_libre = estado === 0 || undefined
            this.nomusu = nomusu;
            this.lock_usu = email
        },
        async forzarPointer(envio) {
            let slider = this.$refs.ion_item_options_slider
            window.fake_console('L290 :', envio)
            const coordenadas = await recuperarGoogleGeoLocEntrega(this.$http, envio, true)
            if (coordenadas && coordenadas != false) {
                const { lat_env, lon_env } = coordenadas
                envio.lat_env = lat_env;
                envio.lon_env = lon_env;
            }
            else if (coordenadas === false) {
                await openAlertaV2(
                    'Error coordenadas',
                    `Error al intentar obtener las coordenadas forzadas ' `
                )
                await slider.$el.closeSlidingItems()
                return
            }
            const { vehiculo, ms_uuid, lat_env, lon_env } = envio;
            const envios = { ms_uuid, lat_env, lon_env }
            const payload = { vehiculo, envios };
            window.fake_console(payload)
            const actualizacion = await this.$http.post(`/set_pointer_expedicion/${this.pdv}`, payload)
            if (actualizacion.data.status === 0) {
                await openAlertaV2(
                    'Error al forzar el pointer del envio',
                    `No hemos podido forzar el pointer del envio ', error:\n${actualizacion.data.alert} `
                )
                await slider.$el.closeSlidingItems()
            }
            else {
                await slider.$el.closeSlidingItems()
                await this.recuperar_envios()
                this.qt = Date.now()
            }
        },
        async cerrarRepartos() {
            await this.pararScanner()
            try {
                const payload = { vehiculo: this.vehiculo_actual };
                const actualizacion = await this.$http.post(`/unset_expedicion/${this.pdv}`, payload)
                window.fake_console(actualizacion.data)
                if (actualizacion.data.status === 0) {
                    await openAlertaV2(
                        'Error en el cierre del reparto ',
                        `No hemos podido realizar la operacion, error:\n${actualizacion.data.alert} `
                    )
                }
                else {
                    const desbloqueo_camion = await this.desbloquearCamion()
                    if (desbloqueo_camion) {
                        await openAlertaV2(
                            'Error en el desbloqueo del camion ',
                            `Hemos anulado el reparto pero ha habido un error al desbloquear el camion `
                        )
                    }
                    await openAlertaV2(
                        `Reparto ${this.vehiculo_actual} cerrado`,
                        `${actualizacion.data.alert}`
                    )
                }
                await this.recuperar_vehiculo()
                await this.estadoVehiculo()
                await this.recuperar_envios()
                await this.escaner_generico()
                this.qt = Date.now()
            }
            catch (ex) {
                await this.escaner_generico()
                window.fake_console(ex)
            }
        },
        async desbloquearCamion() {
            try {
                const payload = { vehiculo: this.vehiculo_actual, nomusu: this.$current_user_name }
                await this.$http.post(`/unlock_transport/${this.pdv}`, payload)
                return undefined
            }
            catch (ex) {
                return ex
            }
        },
        async DescargarEnvio(envio) {
            let slider = this.$refs.ion_item_options_slider
            await this.pararScanner()
            const xx = await openAlertaDescargaCamion(envio)
            if (xx === true) {
                const { ms_uuid } = envio;
                const payload = { codexp: ms_uuid, nuevo_estado: 0 };
                const actualizacion = await this.$http.post(`/update_envio_status/${this.pdv}`, payload)
                window.fake_console(actualizacion.data[0])
                if (actualizacion.data[0].status === 0) {
                    await openAlertaV2(
                        'Error actualizacion envio',
                        `No hemos podido realizar la operacion, error:\n${actualizacion.data[0].alert} `
                    )
                }
                else {
                    await openAlertaV2(
                        'Envio actualizado',
                        `${actualizacion.data[0].alert} `
                    )
                }
                this.showme = false
            }
            await slider.$el.closeSlidingItems()
            await this.escaner_generico()
            await this.recuperar_envios()
        },
        async irA(a) {
            this.$router.replace(a)
        },
        async cambiarVehiculo() {
            const seleccionado = await openAlertaVehiculo(this.mis_vehiculos, this.vehiculo_actual)
            if (seleccionado) {
                if (this.vehiculo_actual != seleccionado) {
                    this.vehiculo_actual = seleccionado
                    await this.estadoVehiculo()
                    await this.recuperar_envios()
                }
            }
            else if (!this.vehiculo_actual) {
                await openToast('Vehiculo no elegido', `No has elegido vehiculo, te redirigimos a la home`, 2000)
                setTimeout(() => this.$router.replace('/home'), 750)
            }
        },
        async recuperar_vehiculo() {
            try {
                /** recuperaos los vehiculos y vemos  */
                this.vehiculo_actual = await loadStorage('vehiculo')
                const rec = await this.$http.post(`/get_transportes_pdv/${this.pdv}`)
                const recursos = rec.data.vehiculos || []
                this.mis_vehiculos = recursos.filter(x => {
                    return x.activo === 1
                })
                const matriculas = pluck(this.mis_vehiculos, 'matricula')
                const tiene_vehiculo_principal = this.mis_vehiculos.filter(x => x.email == this.$current_user)
                if (!this.vehiculo_actual || matriculas.indexOf(this.vehiculo_actual) == -1) {

                    if (tiene_vehiculo_principal.length == 1) {
                        await saveStorage('vehiculo', tiene_vehiculo_principal[0].matricula)
                        this.vehiculo_actual = tiene_vehiculo_principal[0].matricula
                    }
                    else if (matriculas.length == 1) {
                        await saveStorage('vehiculo', matriculas[0])
                        this.vehiculo_actual = matriculas[0]
                    }

                    else {
                        const seleccionado = await openAlertaVehiculo(this.mis_vehiculos, undefined)
                        if (seleccionado) {
                            this.vehiculo_actual = seleccionado
                        }
                        else {
                            await openToast('Vehiculo no elegido', `No has elegido vehiculo, te redirigimos a la home`, 2000)
                            setTimeout(() => this.$router.replace('/home'), 750)
                        }
                    }
                }
            }
            catch (ex) {
                alert(JSON.stringify(ex))
            }
        },
        async recuperar_envios() {
            try {
                const recep = await this.$http.post(`/get_expediciones/${this.pdv}`, { vehiculo: this.vehiculo_actual })
                /** solo puede ver los del vehiculo, que además chequearemos que sean suyos */
                window.fake_console(recep.data)
                const datos = recep.data.filter(y => y.envio_tipo == 'REPARTO' && y.vehiculo == this.vehiculo_actual && y.situa === 1)
                if (datos.length > 0) {
                    window.fake_console(datos)
                    const dat = datos.map(x => {
                        x.albaranes = JSON.parse(x.albaranes).join(' , ');
                        x.incidencia_ent = x.incidencia_ent ? JSON.parse(x.incidencia_ent) : undefined
                        return x
                    })
                    this.datos = sortBy(dat, x => { return x.codcli })
                    window.fake_console(this.datos)
                }
                else { throw 'No hay envios' }
            }
            catch (ex) {
                console.log(ex)
                window.fake_console(ex)
                this.datos = []
            }
            this.showme = true;
        },
        /** Función para devoler el icono a mostrar  */
        icono_estado(x) {
            return this.estados_visuales[x['envio_tipo']] || this.estados_visuales[x['situa']] || 'question-circle'
        },
        /** Funcion que devuelve la etiqueta de 'ubicación' del envio  */
        ubi_text(x) {
            return (['REPARTO', 'ESPERA'].indexOf(x.envio_tipo) != -1) ? x.ubicacion : this.ubicaciones_entrega[x['envio_tipo']]
        },

        async refrescar() {
            this.showme = false;
            this.showme = true;
        },

        async pararScanner() {
            await scanGenericStop(this.element_id)
            this.blinker = false;
        },
        async lectorEventosGenericos() {
            let self = this;
            const d = new Date();
            d.setDate(d.getDate() - 90);
            eventBus().emitter.off(`lectura_generica:${this.element_id}`)
            eventBus().emitter.once(`lectura_generica:${this.element_id}`, async (input_scaner_in) => {
                /** es de mi pdv  */
                try {
                    const { ms_uuid, bultos, bl } = JSON.parse(input_scaner_in)
                    const dat = ms_uuid.match(esEnvio).groups
                    void ({ dat, bultos, bl })
                }
                catch {
                    await openAlertaV2('Envio erroneo', `
                                La lectura no corresponde a un envio.<br/>
                                Recuerda que en esta pantalla cargamos el camión, para ello es necesario tener envios y sus etiquetas.`
                    )
                    await self.escaner_generico()
                    return
                }
                this.$router.replace({ 'name': 'expediciones-carga', params: { envio_inicial: input_scaner_in } })

            })
        },
        async escaner_generico() {
            window.fake_console('activado escaner')
            /** Hacemos reset del scanner cada vez que entramos en el campo  */
            await scanGenericStop(this.element_id)
            this.blinker = true
            await scanGenericStart(this.element_id, 'desde escaner_generico')
            this.blinker = true
            this.refrescar()
            await this.lectorEventosGenericos()
        },
        async imprimir_etiqueta_envio(envio) {
            const { nompos } = this;
            const payload = Object.assign({ nompos, ...envio })
            let bls = [...Array(payload.bultos).keys()]
            payload['albaranes'] = payload.albaranes.split(' , ')
            try {
                await bls.reduce(async (mx, vx) => {
                    await this.$http.post(`/etiqueta/envio/${this.$pdv}`, { ...payload, bl: vx + 1 })
                    return mx
                }, 0)
            }
            catch (ex) {
                console.log(ex)
                window.miconsola('Error : ', envio)
            }
        }
    },
    computed: {
        largo: function () {
            return Object.keys(this.datos).length
        }
    }
})
</script>
 

<style scoped  lang="scss">
@import "@/theme/seleccion-pedido-styles.scss";

ion-label.fab-in-tab {
    padding-top: 1.5rem !important;
    font-weight: 900 !important;
}

ion-tab-bar {
    height: 5rem !important;
}

ion-segment {
    width: 100% !important;
}

ion-segment-button.todas {
    width: 20% ª !important;
}

span.boton-opciones {
    font-size: 1.05rem !important;
    padding-bottom: 0.3rem !important;
    font-weight: bold !important;
    text-align: center !important;
}

ion-badge {
    width: 100% !important;
}

ion-row.altos {
    height: 3rem !important;

}

ion-text.en-botton {
    font-family: 'Roboto';
    font-size: 0.9rem !important;
    color: var(--ion-color-blanco) !important;
    text-transform: none !important;
    line-height: 0.4rem !important;
}

ion-row {
    max-width: 600px !important;
    padding: 0.3rem 0.2rem !important;
}

ion-item {
    --inner-padding-end: 0px !important;
    --padding-end: 0px !important;
}

ion-text.fake-titulo {
    color: var(--ion-color-primary) !important;
    text-align: center !important;
}

span.oscuro {
    color: var(--ion-color-medium) !important;
    padding-left: 0.3rem !important;
}

span.primario {
    color: var(--ion-color-primary) !important;
    padding-left: 0.3rem !important;
}

span.peque {
    font-size: smaller !important;
    padding-right: 0.3rem !important;
}

i.fas.tiempo,
i.fak.tiempo,
i.far.tiempo {
    color: dark;
    font-weight: 600 important;
    ;
    font-size: 1.8rem !important;
    padding-right: 0.4rem !important
}

.sin-margen {
    margin-top: 0px !important;
}

i.crossdock {
    position: absolute;
    top: 50%;
    left: 50%;
}

i.blanco {
    color: var(--ion-color-blanco) !important;
    font-size: xx-large !important;
    font-weight: 300 !important;
    transform: translate(-50%, -50%)
}
</style>