<template>
    <ion-page :key="qt">
        <!-- <CabeceraComponentBack  
            :pdv = "pdv"
            :isIOS = "$route.meta.isIOS"
            :titulo="`Form Entrega`"
            color="primary"
            previo="envios"
        />-->


    <ion-header>
        <ion-toolbar  color="primary" class="ion-justify-content-start ion-align-items-start"> 
            <ion-buttons slot="start" >
                <ion-button size="default" color="light"  routerLink="/home" routerDirection="none">
                    <ion-icon :icon="homeSharp" color="light" class="muy-grande"> </ion-icon>
                </ion-button>
            </ion-buttons>
           <ion-title>
                <ion-row  class="ion-justify-content-start ion-align-items-start">    
                    <ion-col size="40"  class="ion-float-left izquierda"  
                        :style="isIOS ? 'padding-left: 4rem !important;': ''">
                        Form de Entrega ({{pdv}})
                    </ion-col>
                </ion-row>                
            </ion-title>
            <ion-buttons slot="end" >
                <ion-button size="default" fill="solid" color="warning"  @click="processSignature()" class="boton-firma">
                    <i class="fas fa-signature muy-grande"></i>
                    <span>Grabar Firma</span>
                </ion-button>
            </ion-buttons>


        </ion-toolbar>
    </ion-header>



        <ion-content class="ion-padding"  >     
            <div id="barcode" :hidden="hideQR"></div>
            <!--<ion-button @click="doPdf">do pdf</ion-button>
            <embed id="recibo-entrega" :src="pdfContent" :key="qt" >-->
            <canvas id="canvas-entrega" width="250" height="450" ></canvas>
            <canvas id="canvas-firma" width="10" height="10"></canvas>
        </ion-content>

    </ion-page>

</template>
    

<script>

    /**
     * 
     * https://www.section.io/engineering-education/generate-pdfs-using-jspdf-signature-pad/

* https://rawgit.com/MrRio/jsPDF/master/docs/index.html

     */
    import { defineComponent } from 'vue';
    import { homeSharp} from 'ionicons/icons';

    import {  estado_envio , ubicaciones_entrega , colores_situacion } from '@/utilities/global-constants';
    import { DistanciaLinealEntrePuntos}  from '@/utilities/location';
    import SignaturePad from 'signature_pad';
    import { QrCode } from '@grapecity/wijmo.barcode.common';
    import { jsPDF } from "jspdf";
    import { PDFDocument } from 'pdf-lib';
    /*
    //const doc = new jsPDF('p', 'mm', [55, 95]);
    const doc = new jsPDF();
    */
    export default defineComponent({
            name : "FormEntregaPdfPage",
            //components: {  CabeceraComponentBack  } , 
            props: ['envio_id_intro'],  
            data() { 
                return { 
                    ubicaciones_entrega, estado_envio, homeSharp, 
                    element_id: 'revision-envio-entregados',
                    pdv: this.$route.meta.pdv, 
                    nompos: this.$route.meta.nompos, 
                    isIOS: undefined, 
                    scanner_listener: undefined, 
                    blinker : false ,
                    datos_envio :  undefined , 
                    isGrupo: undefined,      
                    showme :false, 
                    qt :false , 
                    pdfContent: undefined, 
                    ancho : 250, 
                    alto : 450 , 
                    hideQR: false, 
                    hideCanvas: false   , 
                    showFirma: undefined
                    
                    }
            },
            created() {
                this.pdv =  this.$route.meta.pdv 
                this.nompos =  this.$route.meta.nompos 
                this.isIOS = this.$route.meta.isIOS 
            },
            watch: {
                '$route.query.q'() { 
                    this.pdv =  this.$route.meta.pdv ;
                    this.nompos =  this.$route.meta.nompos 
                }  
            },
            /*async updated() {
                this.pdv =  this.$route.meta.pdv ;
                this.nompos =  this.$route.meta.nompos ;
               await this.signature()
               await this.doPdf()
               this.qt = Date.now()
            },*/
            async mounted() {
                this.showme = false; 
                this.envio_id = this.envio_id_intro||'ENV:008:20230509:063519:407'
                window.fake_console(this.envio_id)
                await this.fetchEnvio()
                await this.doCanvasPrimary()
               this.showme = true; 
            }, 

            methods: {
                async doCanvasPrimary(){
                    this.showme = false;
                    const {codemp, nompos, ms_uuid, codcli, nomenv, domenv, cpenv, pobenv, albaranes,has_nif, fecenv, bultos, bl = 1, entrega_id }	= this.datos_envio
                    const {nif, nombre } = entrega_id 
                    const canvas = document.getElementById("canvas-entrega");
                    const dt = JSON.stringify({ms_uuid,bultos, bl})
                    let qrCode = new QrCode('#barcode', {value:dt , version:5  });
                    const Qr =  new Image()
                    Qr.src = qrCode.getDataUrl()
                    await Qr.decode()
                    console.log({codemp, nompos, ms_uuid, codcli, nomenv, domenv, cpenv, pobenv, albaranes, fecenv, bultos, bl}	)                    
                    const ctx = canvas.getContext('2d'); 
                    ctx.drawImage(Qr,173, 5 )
                    const img = new Image() 
                    img.src = '/assets/logo_ges.png';
                    await img.decode()
                    ctx.fillStyle = "#ffffff";
                    ctx.fillRect(0, 0, ctx.width, ctx.height);
                    ctx.fillStyle = "black";
                    ctx.font = "normal bold 12px Roboto ";
                    ctx.fillText(`${nompos}` , 8, 60);
                    ctx.font = "normal bold 10px Roboto ";
                    ctx.fillText(`${ms_uuid}` , 8, 75);
                    ctx.beginPath(); 
                    ctx.moveTo(5,83);
                    ctx.lineTo(245,83);
                    ctx.lineWidth = 1;
                    ctx.stroke();
                    ctx.font = "normal bold 12px Roboto ";
                    ctx.fillText('Cliente:' , 12, 100);
                    ctx.fillText('Albaranes Entregados:' , 12, 183);
                    ctx.fillText(`Firmado:` , 12, 268);
                    ctx.fillText('Nif:' , 12, 283);
                    ctx.font = "normal 13px Roboto ";
                    ctx.fillText( this.$filters.Initcap2(nombre) , 70, 268)
                    ctx.fillText( has_nif === 1 ? nif : 'No facilita' , 40, 283)
                    ctx.fillText(nomenv , 25, 118)
                    ctx.fillText(nomenv , 25, 118)
                    ctx.fillText(domenv , 25, 135)
                    ctx.fillText(`${cpenv} - ${pobenv}` , 25, 153)
                        /* Dibujamos linea */                
                    ctx.beginPath(); 
                    ctx.moveTo(1,425);
                    ctx.lineTo(259,425);
                    ctx.moveTo(125,425);
                    ctx.lineTo(125,450);
                    ctx.lineWidth = 1;
                    ctx.stroke();
                    /*
                    const g = 1000000
                    const iterador  = [1,2,3,4,5,6,7,8,9,10,11]
                    iterador.map(i => {
                        albaranes.push(g+i)
                    })
                    */
                    ctx.font = "normal bold 12px Roboto ";
                    let albs = albaranes.map( (x,i) => {
                        return [x, 203+(Math.floor(i/3)*15) , 12+((i%3)*75 )]
                    })    
                    albs.forEach(y => {ctx.fillText(y[0],y[2],y[1]) })

                    ctx.font = "normal 12px Roboto ";
                    ctx.fillText('Fecha:' , 6, 442);
                    ctx.fillText('Bultos:' , 131, 442);

                    ctx.textAlign = 'right';
                    ctx.font = "normal bold 14px Roboto ";
                    ctx.fillText(`${codemp} ${codcli}`,241,100) 
                    ctx.fillText(this.$filters.num2Date(fecenv) , 120, 442)
                    ctx.fillText(`${bultos}` , 240, 442)
                    console.log('llego hasta aqui')
                    ctx.drawImage(img,5,10 )
                    /** 
                     *  Rectangulo firma 
                     * Solo para ver si encana bien
                    
                    ctx.beginPath(); 
                    ctx.rect(6,252, 238,168);
                    ctx.lineWidth = 0.4;
                    ctx.stroke();
                    */
                    const png = new Image()
                    png.src = canvas.toDataURL()    
                    await png.decode()
                    ctx.clearRect(0, 0, canvas.width, canvas.height)
                    const fuente =parseFloat(getComputedStyle(document.documentElement).fontSize) 
                    const w = window.innerWidth-(3*fuente)
                    const h = Math.ceil(w*450/250)
                    canvas.width = w ; 
                    canvas.height = h 
                    await ctx.drawImage(png,0,0,canvas.width,canvas.height)
                    setTimeout(function() {
                        const canvas_firma= document.getElementById("canvas-firma")
                        canvas_firma.width= Math.ceil(238/250*w)
                        canvas_firma.height= Math.ceil(168/450*h)
                        canvas_firma.style.left= 16+(6/250*w)+'px'
                        canvas_firma.style.top= 16+(252/450*h)+'px'
                        new SignaturePad(canvas_firma);
                        const xt = canvas_firma.getContext('2d');
                        xt.beginPath(); 
                        xt.rect(2,2, canvas_firma.width-2,canvas_firma.height-2);
                        xt.lineWidth = 1;
                        xt.stroke();
                    }, 500)
                }, 
                async getAlbaran() {
                    const {codemp, codcli, codsuc,albaranes} = this.datos_envio
                    const ruta = `/albaran_ubiku/${codemp}.${codsuc||"00"}.${albaranes[0]}/${codcli}`
                    console.log({codemp, codcli, codsuc,albaranes ,ruta} )
                    const pdfAlb = await this.$http.get(ruta)
                    
                    console.log(pdfAlb)
                    const dt = Buffer.from(pdfAlb.data).toString('base64'); 
                    //const dt = pdfAlb.data.toString('base64'); 
                    const dataurl = `data:application/pdf;base64,${dt}`

                    const myPdf = new jsPDF()
                    myPdf.loadFile(dataurl, false, responsePDF());
                    function responsePDF() {
                           console.log("in callback")
                            myPdf.save("pdfjsX.pdf");
                        }
                    

                    //console.log(pdfAlb)
                    return dt
                },
                /*async addSignature() {
                    const canvas = document.getElementById("canvas-firma");
                    const img = canvas.toDataURL("image/png")
                    doc.addImage(img, 'png', 20, 20, 100, 40)
                    console.log('image added')
                    this.qt = Date.now()

                },*/ 
                async processSignature(){
                    const albaran = await this.getAlbaran()
                    
                    const pdfDoc = await PDFDocument.load(albaran)
                    console.log(pdfDoc.getPages())
                    const saved = await pdfDoc.save()
                    console.log(saved)
                    const canvas_firma = document.getElementById("canvas-firma");
                    const canvas = document.getElementById("canvas-entrega");
                    const {width, height} = canvas
                    console.log(canvas_firma.width, canvas_firma.height)
                    const doc = new jsPDF('p', 'px', [width, height]);
                    var img1 = canvas.toDataURL("image/png");
                    var img2 = canvas_firma.toDataURL("image/png");
                    doc.addImage(img1, 'JPEG', 0, 0, width, height);
                    doc.addImage(img2, 'JPEG', (6/250*width), (252/450*height),  Math.ceil(238/250*width), Math.ceil(168/450*height));
                    doc.save('test.pdf');

                }, /*
                async doPdf() {
                    const pdf = document.getElementById("recibo-entrega");
                    console.log(doc)
                    pdf.height = pdf.width
                    const kk = ([10, 30, 50, 70,90,110,120,130,150]).reduce((m,y)=> {
                        doc.text("Hello world!", y, y);
                        return m
                        },0);
                    console.log(kk)

                    var string = doc.output('datauristring');
                    this.pdfContent = string; 
                    this.qt = Date.now()
                    console.log('pdf ready')
                },
                */
                /*
                async  processImage() {
                    //creating a blob and displaying the image in the image element
                        canvas.toBlob(function (blob) {
                        var targetImg = document.querySelector("img"),
                        url = URL.createObjectURL(blob);
                        targetImg.src = url;
                    });
                },
                */
                async fetchEnvio() {
                    this.showme = false
                    const env = await this.$http.post(`/check_envio/${this.pdv}`, {ms_uuid: this.envio_id})
                    if (!env.data || env.data.length ==0) {
                            throw `No hemos podido encontrar el envio ${this.envio_id}`
                        }
                    else {
                        const x = env.data[0]        
                        x.est_env = estado_envio[x.situa]||'Desconocido'     
                        x.colores = colores_situacion[x.situa]||'rojo'           
                        x.albaranes = JSON.parse(x.albaranes)
                        x.obsent = !x.obsent ||x.obsent==='null' ? undefined : JSON.parse(x.obsent) 
                        x.incidencia_ent = x.incidencia_ent === null ? undefined : JSON.parse(x.incidencia_ent)
                        x.entrega_id = x.entrega_id === null ? undefined : JSON.parse(x.entrega_id)
                        const {lat_ent, lon_ent, lat_env, lon_env } = x
                        x.dist_ent = DistanciaLinealEntrePuntos({lat_ent, lon_ent, lat_env, lon_env })
                        this.datos_envio = x
                        window.fake_console(this.datos_envio)
                        this.showme = true                        
                    }
                }, 

                goBack() { this.$router.replace('/envios')},


                

            },    
        })
</script>

<style scoped lang="scss">
    @import "@/theme/footer-styles.scss";
    @import "@/theme/recepciones-styles.scss";
    #barcode {
        width : 75px !important;
        height : 75px !important;
        margin: 2px !important; 
        position: absolute !important; 
        z-index: 1 !important; 
        top: 1rem !important; 
    }

    canvas#canvas-entrega, embed {
        /*border: 1px solid var(--ion-color-primary);*/
        background: #ffffff !important; 
        margin: auto !important;
        position: absolute !important; 
        z-index: 9999 !important; 
        top: 1rem !important; 
        /*width: 90% !important; */
    }

    canvas#canvas-firma {
        //border: 1px solid var(--ion-color-medium);
        background:transparent !important;
        margin: auto !important;
        z-index: 9999 !important; 
        position:absolute !important;
    }
    .fas {
        padding-right: 0.5rem !important;
    }
    .boton-firma >* {
        color: var(--ion-color-primary) !important;
    }


</style>