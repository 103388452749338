<template>
    <ion-page :key="qt">
        <ion-header>
            <ion-toolbar   color="primary" class="ion-justify-content-start ion-align-items-start"> 
                <ion-buttons slot="start" >
                    <ion-button size="default" color="light"  routerLink="/home" routerDirection="none">
                        <ion-icon :icon="homeSharp" color="light" class="muy-grande"> </ion-icon>
                    </ion-button>
                </ion-buttons>
                
                <ion-title>
                    <ion-row  class="ion-justify-content-start ion-align-items-start">
                        <ion-col size="40"  class="ion-float-left izquierda"                     
                            :style="isIOS ? 'padding-left: 4rem !important;': ''">
                           Detalles de producto ({{pdv}})
                        </ion-col>
                    </ion-row>                
                </ion-title>
                <ion-buttons slot="end" >
                    <ion-button size="default" color="light"  @click="goBuscar" >
                        <i class="fa-sharp fa-solid fa-circle-left muy-grande" color="light" ></i>

                    </ion-button>
                </ion-buttons>
            </ion-toolbar>

        </ion-header>

        <ion-content  key="`content:${qt}`">
            <ion-row  class="spin ion-justify-content-center "  v-if="!showme">
                <ion-col  v-if="!showme" class="spin ion-align-self-center" size="48" > 
                    <ion-spinner  v-if="!showme"></ion-spinner>            
                </ion-col>
            </ion-row>


            <ion-row v-if="showme && ficha_producto" class="ion-padding-horizontal">
                <ion-col size="48" class="ion-padding-top titulo_sku">
                    <ion-title class="titulo_sku">
                        {{ficha_producto.sku}}
                    </ion-title><br/>
                        <h3 class="nombre_cortado titulin" text-wrap>
                        {{ficha_producto.shortDescription}}
                    </h3>
                </ion-col>

                <ion-col size="18" class="ion-align-self-center" v-if="ficha_producto['thumb']">
                    <img v-if="ficha_producto['thumb']"
                        :src="`${$ruta_imagen}${ficha_producto['thumb']}`"  
                        onerror="this.src='https://storage.googleapis.com/mobigescom/product_images/no_image.jpg';"     
                        class="producto"/>
                </ion-col>
                <ion-col  :size="!ficha_producto['thumb'] ? 48 : 30"  class="ion-no-margin  ion-no-padding" v-if="ficha_producto.longDescription">
                    <ion-text class="descripcion_cortada" >
                        {{ficha_producto.longDescription}}
                    </ion-text>
                </ion-col>
            </ion-row>  

            
            <!-- productos sin gestion de lote  -->
            <ion-row v-if="showme && elementos_sku && elementos_sku.length>0 && !ficha_producto.geslot">
                <ion-list class="ion-no-padding inicio-lista" >       
                    <ion-item 
                        v-for="(product,index) in elementos_sku"
                        v-bind:key="'item-'+index+'-'+product.ubi+'-'+qt"
                        class="ion-no-padding ion-no-margin">        
                        <ion-row >    
                            <ion-col size-offset="1" size="36"  class="ion-no-margin  ion-no-padding">
                                <h5  class="full-h5 bolded"> 
                                    <span class="lote" v-if="product.codsuc">Sucursal: {{ product.codsuc   }}</span>
                                </h5>
                                <h5 class=" bolded" >Ubicacion principal : </h5>
                                <ion-badge mode="md" class="principal" color="default">
                                    <div class="agrupado">
                                        <ion-icon :icon="printSharp" 
                                            class="badge impresion" 
                                            @click="imprimir(product, product.ubi)"
                                            v-if="product.ubi">
                                        </ion-icon>
                                        <ion-text class="principal" :style="!product.ubi ? 'color:var(--ion-color-darkdanger) !important;' :''">
                                            {{product.ubi||'NO UBICADO'}}
                                        </ion-text>
                                        <ion-icon :icon="create" @click="activarLectura(product)" class="badge edita">
                                        </ion-icon>
                                    </div>
                                </ion-badge>

                            </ion-col>

                            <ion-col size="11"  class="ion-no-margin  ion-no-padding ion-text-end">
                                <ion-text  class="stock" text-wrap>
                                    <span class="stock"><b>{{$filters.Entero(product.stoalm)}}</b></span>
                                </ion-text><br/>
                                <ion-text class="mini-lista" text-wrap>
                                   {{product.unimed}}
                                   
                                </ion-text>
                           
                            </ion-col>    


                            <!-- Display temporal cuando estamos esperando una lectura -->
                            <ion-row  v-if="leyendo">
                                <ion-col push="2" size="32">
                                    <ion-text> 
                                            <b>Esperando lectura</b><br/>
                                            <small v-if="product.ubi && product.ubi2 && product.ubi2.length>1">
                                                Escanea una ubicacion o clicka en una de las ya existentes para convertirla en principal
                                            </small> 
                                            <small v-else>
                                                Escanea una nueva ubicacion
                                            </small> 
                                    </ion-text>    
                                    <ion-progress-bar type="indeterminate"></ion-progress-bar>
                                </ion-col>
                                <ion-col push="2" size="10">
                                    <ion-button color="danger" @click="cancelar">
                                        Cancelar
                                    </ion-button>
                                </ion-col>  
                            </ion-row>

                            <ion-col class="ion-no-margin  ion-no-padding" size="48" key="`secundarias-${qt}`">
                                <h5  class="full-h5 bolded" v-if="product.ubi2 && product.ubi2.length>1">
                                    Ubicaciones secundarias : <br/>
                                    <ion-badge mode="md" class="agrupado"
                                        v-for="(ubisec,index) in  product.ubi2" color="default"
                                            v-bind:key="'ubisec-'+index"
                                            @click="leyendo ?   favorito(ubisec)  : eliminar(product,ubisec)"
                                            :hidden="ubisec==product.ubi">
                                        <div class="agrupado">
                                            <ion-icon :icon="closeCircle" class="badge elimina"
                                                v-if="!leyendo"
                                                    @click="eliminar(product,ubisec)">
                                            </ion-icon>
                                            <ion-text class="agrupado">{{ubisec}}</ion-text>
                                            <ion-icon :icon="star" class="badge favorito"
                                                v-if="leyendo"
                                                    @click="favorito(ubisec)">
                                            </ion-icon>
                                            <ion-icon :icon="printSharp" class="badge edita" v-else
                                                @click="imprimir(product, ubisec)"
                                            >
                                            </ion-icon>

                                        </div>
                                    </ion-badge>
                                </h5>
                            </ion-col>
                        </ion-row>
                    </ion-item>
                </ion-list>
            </ion-row>   

            <!-- 
                
                productos con gestion de lote 
                mostramos las ubicaciones dado que solo puede haber una siempre
            -->

            <ion-row v-else>
                <ion-list class="ion-no-padding">       
                    <ion-item 
                        v-for="(product,index) in elementos_sku"
                        v-bind:key="`item-${index}-${qt}`"
                        class="ion-no-padding ion-no-margin">        
                        <ion-row class="ion-no-padding ion-no-margin">
                            <ion-col size-offset="1" size="36"  class="ion-no-margin  ion-no-padding">
                                <h5  class="full-h5 bolded"> 
                                    <span class="lote" v-if="product.codsuc" >Sucursal: {{ product.codsuc   }}</span>
                                </h5>
                                <h5 class="full-h5 bolded nombre_cortado" text-wrap v-if="product.lote!=null">
                                    <b><span class="lote">Lote : {{ product.lote  }}</span></b>
                                </h5>

                                <h5 class=" bolded" >Ubicacion principal : </h5>
                                <ion-badge mode="md" class="principal" color="default"
                                    key="`badge-no-lot-${qt}`">
                                    <div class="agrupado">
                                        <ion-icon :icon="printSharp" 
                                            class="badge impresion" 
                                            @click="imprimir(product, product.ubi)"
                                            v-if="product.ubi">
                                        </ion-icon>
                                        <ion-text class="principal" :style="!product.ubi ? 'color:var(--ion-color-darkdanger) !important;' :''">
                                            {{product.ubi||'NO UBICADO'}}
                                        </ion-text>
                                        <ion-icon :icon="create" @click="activarLectura(product)" class="badge edita">
                                        </ion-icon>
                                    </div>
                                </ion-badge>
                            
                            <!-- Display temporal cuando estamos esperando una lectura -->
                            <ion-row  v-if="leyendo">
                                <ion-col push="2" size="32">
                                    <ion-text> 
                                            <b>Esperando lectura</b><br/>
                                            <small> Escanea una nueva ubicacion</small> 
                                    </ion-text>    
                                    <ion-progress-bar type="indeterminate"></ion-progress-bar>
                                </ion-col>
                                <ion-col push="2" size="10">
                                    <ion-button color="danger" @click="cancelar">
                                        Cancelar
                                    </ion-button>
                                </ion-col>  
                            </ion-row>      

                                
                            </ion-col>
                            <ion-col size="11"  class="ion-no-margin  ion-no-padding ion-text-end">
                                <ion-text  class="stock" text-wrap>
                                    <span class="stock"><b>{{$filters.Entero(product.stoalm)}}</b></span>
                                </ion-text>
                                <ion-text class="mini-lista" text-wrap>
                                   {{product.unimed}}
                                </ion-text>
                            </ion-col>    
                        </ion-row>
                    </ion-item>
                </ion-list>
            </ion-row>
        </ion-content>





    </ion-page>

</template>
    

<script>
    import { defineComponent } from 'vue';
    import { homeSharp,  printSharp, closeCircle, star, create} from 'ionicons/icons';
    import { without, pick , uniq, union } from 'underscore';
    import { DetallesReferencia, ActualizarUbicacionesLocales} from '@/utilities/scanner/scan-sku';
    import eventBus from "@/event-bus"; 
    import { scanUbiStart, scanUbiStop} from '@/utilities/scanner/scan-ubi'
    import { useRoute } from 'vue-router';

    export default defineComponent({
            name : "ProductPageComponent",
            data() { 
                return { 
                    element_id: 'busqueda-manual-generica',
                    pdv: undefined,
                    homeSharp, printSharp, closeCircle, star, create,
                    showme :false, 
                    ruta: undefined, 
                    qt :false , 
                    elementos_sku: undefined, 
                    ficha_producto: undefined ,
                    resultado : undefined, 
                    leyendo: false
                    }
            },
            props : ['sku', 'origen'] , 
            created() {
                this.pdv =  this.$route.meta.pdv ;
                this.isIOS = this.$route.meta.isIOS ;
            },
            watch: {
                resultado () {
                    setTimeout(()=> this.qt = Date.now() , 200 )
                    this.$nextTick()
                }
            },
            updated() {
                this.pdv =  this.$route.meta.pdv 
            },
            async mounted() {
                this.pdv =  this.$route.meta.pdv 
                const route = useRoute();
                this.ruta = route.fullPath
                this.qt = route.query.q||Date.now()
                window.fake_console(DetallesReferencia(this.sku ))
                window.fake_console(this.sku )
                this.elementos_sku = DetallesReferencia(this.sku )
                window.fake_console(this.elementos_sku)
                this.ficha_producto = this.elementos_sku[0]
                if (this.ficha_producto)
                {
                    this.ficha_producto.geslot = !this.ficha_producto.lote ? false : true
                }
                else {
                    window.fake_console(`/producto_ubiku/${this.sku}`)
                    const prod = await this.$http.post(`/producto_ubiku?sku=${this.sku}`)
                    window.fake_console(prod.data)
                    this.ficha_producto= prod.data||undefined 
                    
                    this.ficha_producto.geslot = prod.data.ges_lot && prod.data.ges_lot != 'N'
                    const inicializacion = {'stoalm' : 0, 'ubi': undefined, 'lote':undefined, 'codsuc': undefined  }
                    this.ficha_producto = Object.assign(this.ficha_producto, inicializacion)
                    this.elementos_sku = [this.ficha_producto]

                }
                window.fake_console(this.ficha_producto)
                this.showme = true
            },
           
            methods: {
                async imprimir(product, ubi) {
                    const payload = pick (product ,[ 'sku','lote','refpro'] )
                    payload.ubi = ubi 
                    if (payload.lote && /^rollo\d/i.test(payload.lote)) {
                        payload.unimed = (product.unimed||'').toLowerCase()
                        payload.stoalm = product.stoalm
                    }
                    window.fake_console(payload)
                    await  this.$http.post(`/etiqueta/producto/${this.$pdv}`, payload)
                    //await  this.$http.post(`/etiqueta/producto/851`, payload)
                },
                async cancelar () {
                    this.leyendo = false; 
                    await scanUbiStop(this.element_id) 
                }, 
                async activarLectura(product){
                    window.fake_console('activo lectura', product)
                    let self = this ;
                    this.leyendo = true; 
                    await scanUbiStart(this.element_id) 
                    eventBus().emitter.once(`ubicacion:${this.element_id}`,async (newUbi)=>{
                        window.fake_console(product, newUbi)
                        await self.saveFavorito(product, newUbi)
                        window.fake_console('recupero lectura ',newUbi)
                        window.miconsola(' Nos dirigimos a la newUbi ')
                        self.leyendo = false; 
                        await scanUbiStop(self.element_id) 
                        self.resultado = newUbi; 
                    }) 
                },
                /** convierte la ubicacion en primaria  */
                async favorito(ubisec) {
                    eventBus().emitter.emit(`ubicacion:${this.element_id}`,ubisec)
                },
                async eliminar(product, ubisec) {
                    window.fake_console(product, ubisec)
                    const payload = pick (product ,[ 'sku','codsuc','principal','lote'] )
                    payload['ubi'] = ubisec;
                    window.fake_console(payload)
                    const feedback = await  this.$http.post(`/eliminar_ubicacion/${this.$pdv}`, payload)
                        window.fake_console(feedback.data)
                        if (feedback.data.status == 1) {
                            /*ActualizarUbicacionesLocales= function(codsuc, sku, lote, ubi, ubi2){*/
                            product.ubi2 = without(product.ubi2, ubisec)
                            window.fake_console(product.ubi2)
                            ActualizarUbicacionesLocales(payload.codsuc, payload.sku, payload.lote, product.ubi, product.ubi2)
                        }
                    this.qt = Date.now()
                }, 
                async saveFavorito(product, newUbi) {
                    const payload = pick (product ,[ 'sku','codsuc','principal','lote'] )
                    payload['ubi'] = newUbi;
                    payload['principal'] = payload['principal']||'1'
                    window.fake_console(payload)
                    const feedback = await  this.$http.post(`/actualizar_ubicaciones/${this.$pdv}`, payload)
                    window.fake_console(feedback)
                    if (feedback.data.status == 1) {
                            const ubi2 = uniq(union(product.ubi2,[product.ubi,newUbi]))
                            product.ubi2 = ubi2;
                            ActualizarUbicacionesLocales(payload.codsuc, payload.sku, payload.lote, newUbi, ubi2)
                    }
                },
                goBuscar() {
                    const destino = this.origen ||'/buscar'
                    window.fake_console(this.origen, destino)
                    this.$router.replace(destino)
                }

            },    
        })
</script>

<style scoped lang="scss">
    @import "@/theme/footer-styles.scss";
    
    ion-icon.badge {
        font-size: 1.8rem !important;
        padding: 0.1rem 0.1rem !important;
    }
    ion-icon.elimina {
        color: var(--ion-color-danger) !important; 
    }
    ion-icon.impresion  {
        color: var(--ion-color-darksuccess) !important; 
        padding-right: 0.4rem !important; 
    }
    ion-icon.edita {
        color: var(--ion-color-primary) !important; 
        padding-left: 0.4rem !important; 
    }
    ion-icon.favorito {
        color: var(--ion-color-success) !important; 
    }
    div.agrupado {
        display: flex;
        align-items: center !important;
    }
    h5.agrupa {
        display: flex;
        align-items: center !important;

    }
    ion-badge.agrupado {
        border: 2px solid var(--ion-color-dark-tint) !important; 
        padding: 0px !important;
        border-radius : 4px !important;
    }
    ion-badge.principal {
        border: 2px solid var(--ion-color-primary) !important; 
        padding: 0px 6px !important;
        margin:  0px 0  !important;
        border-radius : 4px !important;
    }

    ion-text.principal {
        color: var(--ion-color-primary) !important; 
        font-size: 1.1rem !important; 
        padding: 0 12px !important; 
    }
    span.ubi-principal {
        color: var(--ion-color-primary) !important; 
    }

    ion-text.agrupado {
        font-size: 1rem !important; 
        padding: 0.2rem 0.6rem !important; 
        letter-spacing: 0.01rem !important;
    }


    img.producto{
        height: 130px;
        width: 130px;
        max-width: 130px;
        max-height: 130px;
        object-fit: contain;
        margin:auto;
    }
    p > span.p-alert {
        color: var(--ion-color-darkdanger) !important; 
        font-size: larger !important;
        font-style: italic !important ;
        font-weight: 450 !important;
    }
    ion-buttons.completo {
        width : 50% !important; 
    }

    h3 {
        font-size:smaller !important; 
        margin-top: 3px !important; 
        margin-bottom: 3px !important; 
        font-weight: bold !important;
        
    }
    h3, h4, h5, p {
        font-size:smaller !important; 
        margin-top: 3px !important; 
        margin-bottom: 3px !important; 
    }
    h3 {
        font-weight: bold;
    }
    h3.titulin {
        font-size: 1.1rem  !important; 
        padding : 6px !important; 
        padding-inline: 20px !important;
    }
    h5,ion-text,p  {
        color: var(--ion-color-dark) !important; 
    }



    .nombre_cortado {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
        -webkit-box-orient: vertical;
    }


    .descripcion_cortada {
        /*padding-top: 1rem !important; */
        padding-inline: 12px !important;
        font-size: 1rem !important; 
        overflow: hidden;
        color: var(--ion-color-medium) !important;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 8; /* number of lines to show */
        -webkit-box-orient: vertical;
    }


    ion-searchbar {
        width: 100% !important;
        margin-left: 6px !important;
    }


    ion-title {
        width: 100% !important;
        padding-right: 4px;
        text-align: left !important;
    }
    .izquierda {
        text-align:left; 
    }


    ion-row {
        max-width: 600px !important; 
        padding: 0.5rem 1rem !important; 
    }
 
    ion-item, ion-list {
        width: 100% !important; 
        margin: auto !important; 
    }

    .mini-lista{
            padding: 4px 4px !important;
            font-size: small !important; 
        }
    .stock {
        font-size: large !important; 
        width: 100% !important;
        float: right !important; 
        /*color : var(--ion-color-medium) !important; */
        font-weight: 400 !important;
    }
    
    h5.full-h5 {
        width: 100% !important;  
    }
    h5.bolded {
        font-size: 1.1rem !important; 
        font-weight: bold !important;
    }

    ion-row.resaltado {
        border: 2px dashed var(--ion-color-darksuccess) !important; 
        background-color: var(--color-resaltado-positivo) !important; 

    }

    titulo_sku {
        color: var(--ion-color-primary) !important;
    }
    ion-title.titulo_sku {
         position: relative !important;
        }
        ion-list.inicio-lista {
        border-top: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))) !important; 
    }
    ion-badge {
        margin-right: 12px !important; 
        margin-top: 4px !important; 
    }

</style>