<template>
    <ion-header>
        <ion-toolbar  :color="color===undefined ? 'primary' : color " class="ion-justify-content-start ion-align-items-start"> 
            <ion-buttons slot="start" >
                <ion-button size="default" color="light"  routerLink="/home" routerDirection="none">
                    <ion-icon :icon="homeSharp" color="light" class="muy-grande"> </ion-icon>
                </ion-button>
            </ion-buttons>

            <ion-title>
                <ion-row  class="ion-justify-content-start ion-align-items-start" >
                       
                    <ion-col size="40"  class="ion-float-left izquierda"
                        :style="isIOS ? 'padding-left: 4rem !important;': ''">
                        {{titulo}}  ({{pdv}})
                    </ion-col>
                </ion-row>                
            </ion-title>
            <!-- <div v-if="at_ges">-->
                <i class="fas fa-solid fa-warehouse-full fa-fade en-header" v-if="at_ges && at_ges===true" ></i>
                <i class="fas fa-solid fa-truck-fast fa-fade en-header" v-else-if="at_ges &&at_ges===false" ></i>
            <!--</div>-->
        </ion-toolbar>
    </ion-header>
</template>

<script>
    import { defineComponent } from 'vue';
    import { homeSharp} from 'ionicons/icons';
    export default defineComponent({
        name : "CabeceraComponent",
        data() { return {  homeSharp }},
        props : ['titulo', 'color', 'pdv','isIOS','at_ges'] 

    })
</script>

<style scoped>

    ion-title {
        width: 100% !important;
        padding-right: 1rem !important;
    }
    .izquierda {
        text-align:left; 
    }


  i.fas.en-header , i.fak.en-header , i.far.en-header {
      z-index : 9999!important; 
      position: absolute !important; 
      top: 1rem !important; 
      right: 1rem !important; 
      display: flex !important; 
      color: dark !important; 
      font-weight: 550 !important; ;
      font-size: 2rem !important;
      float: right !important; 
      
  }

</style>