<template>
    <ion-page :key="qt">
        <ion-header>
            <ion-toolbar   color="primary" class="ion-justify-content-start ion-align-items-start"> 
                <ion-title>
                    <ion-row  class="ion-justify-content-start ion-align-items-start">
                        <ion-col size="40"  class="ion-float-left izquierda" 
                            :style="isIOS ? 'padding-left: 4rem !important;': ''">
                            Realizar Entrada ({{pdv}})
                        </ion-col>
                    </ion-row>                
                </ion-title>
                <ion-buttons slot="end" >
                    <ion-button size="default" color="light"  routerLink="/recepciones" routerDirection="none">
                        <i class="fa-sharp fa-solid fa-circle-left muy-grande" color="light" ></i>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
            <ion-toolbar color="primary"  class="top">
                    <ion-searchbar  v-model="search_manually"
                        id="busqueda_filtrada_proveedor"
                        ref="search_busqueda_filtrada_proveedor"
                        :debounce="debounce_milis" 
                        @ionClear="onCancel($event)"
                        autocomplete="off" autocorrect="off" 
                        autocapitalize="off" spellcheck="false"
                        show-clear-button="always"
                        placeholder="Filtrar lineas de recepcion por articulo" 
                        inputmode ="search"
                        type="search"
                        @ionChange="filtroInputSearch"
                        @ionBlur="getFoco($event, false)"
                        @ionFocus="getFoco($event,true)"
                        slot="start"
                        mode = "md">
                    </ion-searchbar>
                </ion-toolbar>


        </ion-header>

        <ion-content>
            
            <ion-row  class="spin ion-justify-content-center "  v-if="!showme">
                <ion-col  v-if="!showme" class="spin ion-align-self-center" size="48" > 
                    <ion-spinner  v-if="!showme"></ion-spinner>            
                </ion-col>
            </ion-row>
                 
                 
            <ion-row v-if="showme">
                    <ion-col class="breadcrumb" size="48" text-right  v-if="showme && recepcion_filtrados.length >0">
                         {{recepcion_todos.length }} lineas totales  /  {{datos_tot}} filtradas 
                              /  {{Math.min(limite, recepcion_filtrados.length)}} mostradas
                        
                    </ion-col>

                <!-- BUSQUEDA SIN RESULTADOS-->
                <ion-row class="ion-padding ion-margin" v-else>
                    <ion-title class="ion-padding-top ion-margin-top texto-mas-grande" >Busqueda sin resultados </ion-title>
                        <div class="ion-padding-top ion-margin-top ion-text-justify texto-mas-grande">
                            <ion-text >
                            No hemos encontrado ningun resultado para  tu busqueda "<strong><i>'{{search_manually}}'</i></strong>"
                            entre las lineas de la recpecion. <br/><br/>

                            Puedes editar manualmente la busqueda, o escanear para seguir buscando. 
                            <br/>
                            </ion-text>
                        </div>
                </ion-row>
                
                <ion-list v-if="showme && datos_tot >0 " class="ion-no-padding">    
                    <ion-list-header class="mini">
                        <ion-item class="ion-no-padding ion-no-margin con-fondo">        
                            <ion-row class="linea-recepcion">    
                                <ion-col size-offset="1" size="36"  class="ion-no-margin  ion-no-padding">
                                    <h3 > Referencia / Sucursal / Lote / Linea </h3>
                                    <h5 class="nombre_cortado" text-wrap>
                                        <b><span class="lote">Unidades Pendientes (pedido) </span></b>
                                    </h5>
                                </ion-col>
                                <ion-col size="11"  class="ion-no-margin  ion-no-padding ion-text-end">
                                    <h3 > Recibidas </h3>
                                    <h5 class="nombre_cortado" text-wrap >
                                        <b><span class="lote">Albaran</span></b>
                                    </h5>
                                </ion-col>
        
                            </ion-row>
                        </ion-item>
                    </ion-list-header>   

                    <ion-item 
                        v-for="(linea,index) in recepcion_filtrados"
                        v-bind:key="`item-${linea.numlin}`"
                        class="ion-no-padding ion-no-margin">        
                        <ion-row class="linea-recepcion" :class="linea.done ? (linea.accion ? 'resaltado negativo' : !linea.recepcionable ? 'resaltado neutro' : 'resaltado' ): ''">    
                            <ion-col size-offset="1" size="36"  class="ion-no-margin  ion-no-padding">
                                <h3 > {{ $filters.showSku(linea.sku)   }}</h3>
                                <h4 class="nombre_cortado" text-wrap
                                    v-html="$filters.Initcap(linea.desart)"
                                >
                                </h4>
                                <h5 class="full-h5"> 
                                    <span class="lote" >
                                        Suc: {{ linea.codsuc   }}  ({{linea.geslot||(linea.numlot ? 'S' : 'N')||'N' }})</span>
                                    <span class="lote separata">Linea : {{ linea.pedlin   }}</span>
                                </h5>
                                <h5 class="full-h5"> 
                                    <span class="lote ">Pedido Pendiente ({{linea.unimed}}) :</span>
                                    <span class="lote forzar_derecha sin-limites"> {{$filters.sDecimal(linea.uds_ped)}} </span>
                                </h5>

                                <h5 class="nombre_cortado" text-wrap v-if="linea.numlot!=null">
                                    <b><span class="lote">Lote : {{ linea.numlot  }}</span></b>
                                </h5>
                            </ion-col>
                            <ion-col push="1" size="10"  class="ion-no-margin  ion-no-padding ion-text-end  ion-align-items-end">
                                <ion-col size="48" class="unidades borde-bajo "  >
                                    <ion-text  class="titulo-uds" ion-text-end  text-wrap>
                                        <small>Albaran</small>
                                    </ion-text>
                                    <ion-text  class="stock" ion-text-end  text-wrap>
                                        <span ><b>{{$filters.sDecimal(linea.uds_alb)}}</b></span>
                                    </ion-text>
                                </ion-col>
                                <ion-col size="48" class="unidades negrita" :class="linea.uds_rec ? 'primary' : 'darkdanger' " >
                                    <ion-text  class="titulo-uds" ion-text-end  text-wrap>
                                        <small>RECIBIDAS</small>
                                    </ion-text>
                                    <ion-text  class="stock" ion-text-end 
                                        text-wrap v-if="linea.uds_rec">
                                        <span ><b>{{$filters.sDecimal(linea.uds_rec)}}</b></span>
                                    </ion-text>
                                </ion-col>
                                
                            </ion-col>


                        <ion-col :size="linea.accion ? 20 : 34">
                            <ion-buttons >
                                <ion-button  slot="start" fill="clear"  expand ="block" color="danger" 
                                    class="solo ion-text-capitalize incidencia"
                                    v-if="!linea.recepcionable">
                                        Linea no recepcionada
                                </ion-button>
                            </ion-buttons>
                        </ion-col>
                        <ion-col size="14"  v-if="linea.done && linea.accion">
                            <ion-buttons >
                                <ion-button  slot="start" fill="clear"  expand ="block" color="danger" 
                                    class="solo ion-text-capitalize incidencia">
                                        {{{'R': 'Reponer' , 'A' : 'Abonar' , 'C': 'Cargo'}[linea.accion]}}
                                </ion-button>
                            </ion-buttons>
                        </ion-col>
                        <ion-col size="14"  v-if=" linea.done">
                            <ion-buttons >
                                <ion-button slot="end"  fill="solid"  expand ="block"  class="solo"
                                    color="secondary" @click = "reactivar(linea)" v-if="linea.original === true"
                                    >Revisar
                                </ion-button>
                                <ion-button slot="end"  fill="solid"  expand ="block"  class="solo"
                                    color="danger" @click = "reactivar(linea)" v-else
                                    >Eliminar
                                </ion-button>

                            </ion-buttons>
                        </ion-col>

                        <ion-col push="8" size="40"  v-if="!linea.done">
                            
                            <!-- 
                                Boton de creacion de lote solo si no hay info de lotes y tiene gestion de lote 
                                Boton de confirmacion solo si no hay gestion de lote o el lote viene informado 
                                No recibida, solo disponible cuando viene de un pedido, para eliminar lineas que no hay que hacer nada con ellas. 
                                Editar: solo en los casos en que es osible confirmar 
                            --> 
                            <ion-buttons >
                                    <ion-button  fill="solid"   expand ="block" class="confirmaciones"
                                        color="danger" @click = "LineaNoRecibida(linea)"
                                        v-if="linea.origen === 'pedido'||linea.pedlin == -99"
                                        >Cerrar
                                    </ion-button>

                                    <ion-button  fill="solid"   expand ="block" class="confirmaciones"
                                        color="primary" @click = "LineaEntradaSeleccionada(linea)"
                                        :disabled="!(linea.numlot || linea.geslot==='N'|| linea.geslot === undefined)"
                                        >Editar 
                                    </ion-button>

                                    <ion-button fill="solid" class="confirmaciones"
                                        v-if="linea.numlot || linea.geslot==='N'|| linea.geslot === undefined" 
                                        color="success" @click="confirmar(linea)">Confirmar
                                    </ion-button>
                                    <ion-button fill="solid"   expand ="block"  color="darksuccess"  class="confirmaciones"
                                        slot="start" v-else
                                        @click="addLote(linea, index )"  
                                        > 
                                            <ion-icon :icon="addCircleSharp" > </ion-icon>
                                            <span class="texto-boton-grande-con-icono">Lotes</span>
                                    </ion-button>

                                
                            </ion-buttons>
                        </ion-col>
                        </ion-row>
                    </ion-item>
                </ion-list>
            </ion-row>
        </ion-content>


        <ion-footer v-if="!incompleto">
            <ion-toolbar color="primary" >    
                <ion-buttons slot="end" class="dos-tercios" color="primary" >    
                    <ion-button class ="completo"  expand ="block" fill="outline" 
                        id="crear_entrada"
                        size="normal" @click="CrearRecepcion()"
                        :disabled="incompleto"
                        > 
                        Revisar CrossDocking
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-footer>

    </ion-page>

</template>
    

<script>
    import { defineComponent } from 'vue';
    import {   addCircleSharp} from 'ionicons/icons';
    import { RegexBobinasPdv , transformSchneiderBrand} from '@/utilities/scanner/scan-sku'
    import { useRoute } from 'vue-router';
    import { partition, every , pluck, sortBy, max, find } from 'underscore';
    import NuevoLoteComponent from '@/pages/recepcionar/components/NuevoLoteComponent.vue' ;
    import NuevaEntradaComponent from '@/pages/recepcionar/components/NuevaEntradaComponent.vue' ;
    import {  modalController } from '@ionic/vue';
    import { scanGenericStart, scanGenericStop  } from '@/utilities/scanner/scan-generic'    
    import { saveEntradaToStorage, loadEntradafromStorage , delEntradaFromStorage } from '@/utilities/storage/storage-entradas'

    import { openAlertaV2} from  '@/utilities/services/alert-utilities';
    import eventBus from "@/event-bus"; 

    export default defineComponent({
            name : "CrearEntradaFromRecepcionPage",
            data() { 
                return { 
                    element_id: 'crear-entrada-from-recepcion',
                    pdv: undefined,
                    isIOS: undefined, 
                    search_manually : undefined, 
                     addCircleSharp, 
                    limite:  100 , 
                    datos_tot: undefined , 
                    recepcion_todos : undefined, 
                    recepcion_filtrados: undefined , 
                    showme :false, 
                    ruta: undefined, 
                    qt :false , 
                    input_activo: false,
                    hasLote: false , 
                    debounce_milis: 300   
                    }
            },
            props : ['codpro', 'codalb','tiptra'] , 
            created() {
                this.pdv =  this.$route.meta.pdv 
                this.isIOS = this.$route.meta.isIOS 
            },
            watch: {
                '$route.query.q'() { this.onCancel();}, 
                async 'num_completos'  (newVal, oldVal) {
                    window.fake_console(newVal, oldVal)
                    if ((newVal === 1 && oldVal === 0) ||(newVal === 0 && oldVal == 1))   {
                        const payload = { codpro: this.codpro, codalb: this.codalb};
                        payload.nuevo_estado = newVal; 
                        const actualizacion = await this.$http.post(`/update_recepcion_status/${this.pdv}`, payload)
                        window.fake_console(actualizacion.data[0])
                        if(actualizacion.data[0].status === 0  ){
                            await openAlertaV2(
                                'Error actualizacion recepcion', 
                                `No hemos podido crear realizar la operacion, error:\n${actualizacion.data[0].alert} `  
                                )     
                            }
                        if (newVal=== 0 ) {
                                await delEntradaFromStorage (this.pdv, this.codpro, this.codalb)
                        }
                    }
                    if (newVal >0) {
                        await saveEntradaToStorage (this.pdv, this.codpro, this.codalb, 'recepcion', this.recepcion_todos)
                    }
                }
            },
            computed :{
                num_completos : function() {
                    return this.recepcion_todos=== undefined ? undefined : this.recepcion_todos.filter(x=>x.done==1).length
                },
                incompleto :function () {
                    return this.recepcion_todos && this.recepcion_todos.filter(x=>x.done ==0).length!= 0 
                }, 
                maximo: function () {
                    return max(this.recepcion_todos, x=> x.orden)['orden']+1
                    }, 
                maxLin: function () {
                    return max(this.recepcion_todos, x=> x.numlin)['numlin']+1
                    }, 
                
            },
            updated() {
                this.pdv =  this.$route.meta.pdv 
            },
            async mounted() {
                this.pdv =  this.$route.meta.pdv;
                const route = useRoute();
                this.ruta = route.fullPath
                this.qt = route.query.q
                await this.fetchData()
                this.onCancel()
                await this.escaner_generico()
                
            },
            async unmounted() {
                await scanGenericStop(this.element_id)
            }, 
            methods: {
                async CrearRecepcion() {
                    this.$router.replace({'name' :'revisar_cross_docking', 
                        params : {codpro :this.codpro, codalb : this.codalb}})

                },
                async fetchData () { 
                    const grabados = await loadEntradafromStorage(this.pdv, this.codpro, this.codalb, 'recepcion')
                    if (grabados != undefined )
                        {
                            /** recuperamos del storage  */
                            this.recepcion_todos = grabados 
                        }
                    else 
                   { 
                        window.fake_console(this.recepcion_input)
                        const payload = {codpro : this.codpro, codalb : this.codalb}
                        const recepcion_res = await this.$http.post(`/get_recepcion/${this.pdv}`, payload)
                        window.fake_console(`/get_recepcion/${this.pdv}`, payload)
                        window.fake_console(`http://localhost:3000/get_recepcion/raul.sanchez/${this.pdv}?codpro=${this.codpro}&codalb=${this.codalb}`)
                        this.recepcion_todos = recepcion_res.data.map((x,i) =>{
                            x['numlin']= (i*10)+(i==0 ? 1 : 0 ); 
                            x['orden']= i ;
                            x['id_original'] = Math.random().toString(36).slice(-8);
                            x['original'] = true
                            x['done'] = false;  
                            x['recepcionable'] = true; 
                            x['unidades_originales_pedido'] = x['uds_ped']
                            return x } )
                    }
                    this.hasLote = pluck(this.recepcion_todos.data,'numlot').length>0
                    window.fake_console(this.recepcion_todos)
                    return 
                }, 
                getFoco (ev, si) {
                    window.fake_console('evento escuchado')
                    if(si===true) {
                        this.input_activo = true; 
                        window.fake_console('activado')
                    }
                    else {
                        this.input_activo=false; 
                        window.fake_console('desactivado')
                    }
                },
                goBack() {
                    this.$router.go(-1)
                },


                async lectorEventosGenericos() {
                    let self = this; 
                    eventBus().emitter.off(`lectura_generica:${this.element_id}`)
                    eventBus().emitter.once(`lectura_generica:${this.element_id}`,async (input_scaner)=>{
                       self.input_activo = false;
                       let buscar = input_scaner
                       /** miramos si el termino tiene includo un codigo de bobina , en cuyo caso lo eliminamos */
                       if ( RegexBobinasPdv.test(input_scaner) && input_scaner.replace(RegexBobinasPdv,'').length > 2)
                            { 
                                buscar = input_scaner.replace(RegexBobinasPdv,'')
                                window.fake_console(input_scaner, buscar)
                            }
                       self.search_manually = buscar
                       self.filtrar('Via lector Generico' , 'exacta') 
                       self.lectorEventosGenericos()
                    })
                },
                async escaner_generico() {
                    await scanGenericStart(this.element_id, 'desde escaner_generico')
                    await this.lectorEventosGenericos()
                },
                async filtroInputSearch() {
                    window.fake_console(this.input_activo)
                    if (this.input_activo) {
                        this.filtrar('Caja de busqueda via filtroInputSearch')
                    }
                }, 
                onCancel(){
                    this.showme = false
                    this.pdv =  this.$route.meta.pdv 
                    window.fake_console('en el cancel ')
                    this.search_manually = undefined; 
                    const d = this.recepcion_todos
                    this.datos_tot = d.length||0 
                    window.fake_console(this.datos_tot,d.slice(0,5))
                    this.recepcion_filtrados = d.slice(0,this.limite)
                    this.showme = true 
                    },

                async filtrar (comentario) {
                    window.fake_console('COMENTARIO :', comentario, this.input_activo)
                    window.fake_console(this.search_manually)
                    const terminos = transformSchneiderBrand(this.search_manually)
                    window.fake_console(terminos)
                    if (terminos === false|| !terminos ) {
                        this.recepcion_filtrados= this.recepcion_todos
                        }
                    else {

                        const primarySearch = new RegExp(`(${terminos.toLowerCase().replace(/ +$/,'').replace(/ +/g,'.*')})`, 'i')
                        const secondarySearch = terminos.toLowerCase().replace(/ +$/,'').split(' ').map(v => new RegExp(`${v}`,'i'))
                        const [primario,resto] = partition(this.recepcion_todos, v=>primarySearch.test(JSON.stringify(v.tags).toLocaleLowerCase()))
                        const secundario =  resto.filter(v=>every(secondarySearch, t => t.test(JSON.stringify(v.tags).toLowerCase())))                    
                        this.recepcion_filtrados= primario.concat(secundario)||[]
                    }
                    
                    this.datos_tot = this.recepcion_filtrados.length 
                    this.recepcion_filtrados = sortBy(this.recepcion_filtrados,'orden').slice(0,this.limite)
                    this.showme = true
                    
                }, 
                async LineaEntradaSeleccionada(item) {
                    await scanGenericStop(this.element_id)
                    var self = this; 
                    const modal = await modalController
                        .create({
                            component: NuevaEntradaComponent ,
                            cssClass: 'custom-nuevo-lote-modal',
                            componentProps: {codpro: self.codpro, sku: item.sku, uds_ped: item.uds_ped , albaran: item.uds_alb, recepcion: item.uds_rec, origen: item.origen},
                            })
                        modal.onDidDismiss().then(async ()=>{
                            await this.escaner_generico()
                        })
                        eventBus().emitter.once('creacion-entrada-nueva',async (entrada)=>{
                            if (entrada) 
                            {   
                                item.done = true;
                                item.uds_alb = parseInt(entrada.uds_alb*100)/100
                                item.uds_rec = parseInt(entrada.uds_rec*100)/100
                                item.accion = entrada.accion 
                                item['orden']= this.maximo
                                window.fake_console(item)
                            }
                            this.filtrar()
                            modal.dismiss();
                            await this.escaner_generico()
                        })
                        modal.present()
                 }, 
                async confirmar(item) {
                    if (item.uds_alb) { item.uds_rec = item.uds_alb  }
                    else 
                        {
                            item.uds_rec = item.uds_ped; 
                            item.uds_alb = item.uds_ped;    
                        }  
                    item.done = true;
                    item.orden= this.maximo; 
                    this.filtrar()
                 }, 
                async reactivar(item){
                    window.fake_console(this.recepcion_todos) 
                    if (item.original === false) {
                        window.fake_console(item)
                        /** Eliminamos tanto el registro como su bobfina si existe  */
                        const nuevos = this.recepcion_todos.filter( x=> x.numlot!=item.numlot)
                        /** si es un lote generado desde otra linea activamos tb. esa  */
                        let original = find(nuevos, x => x.original === true && x.id_original == item.id_original)
                        original.done = false; 
                        original.uds_ped= (original.uds_ped||0)+item.uds_ped
                        window.fake_console(nuevos)
                        this.recepcion_todos =  nuevos
                    }
                    else {
                        item.done = false; 
                        item.orden = item.numlin; 
                    }
                    this.filtrar()
                },
                async LineaNoRecibida(item) {
                    item.uds_rec = 0 ;
                    item.done = true;
                    item.recepcionable = false; 
                    item.orden = this.maximo; 
                    this.filtrar()

                }, 
                async addLote(item){
                    await scanGenericStop(this.element_id)
                    var self = this; 
                    const modal = await modalController
                        .create({
                            component: NuevoLoteComponent ,
                            cssClass: 'custom-nuevo-lote-modal',
                            componentProps: {codpro: self.codpro, sku: item.sku, uds_ped: item.uds_ped , albaran: item.uds_alb, recepcion: item.uds_rec},
                            })
                        modal.onDidDismiss().then(async ()=>{
                            await this.escaner_generico()
                        })
                        eventBus().emitter.once('creacion-lote-nuevo',async (lote_nuevo)=>{
                            if (lote_nuevo) 
                            {   
                                const x = {...item, ...lote_nuevo}
                                x['uds_ped'] = parseInt(lote_nuevo.uds_alb*100)/100
                                x.done = true;
                                x['orden']= this.maximo
                                x['original'] = false; 
                                item.uds_ped-=parseInt(lote_nuevo.uds_alb*100)/100
                                item.numlin = this.maxLin;
                                window.fake_console(x)
                                this.recepcion_todos.push( x);
                            }
                            if (lote_nuevo && lote_nuevo.tipo === 'bobina') {
                                const x = {...item, ...lote_nuevo}
                                x['uds_ped'] = 1
                                x['uds_alb'] = 1
                                x['uds_rec'] = 1
                                x['desart'] = x.nombre 
                                x['original'] = false; 
                                x['pmp_ges'] = x['pmp_ges_bobina']
                                x['sku'] = x.bobina
                                x.done = true;
                                x['orden']= this.maximo
                                item.numlin=  this.maxLin;
                                delete x.pmp_ges_bobina
                                window.fake_console(x)
                                this.recepcion_todos.push( x);
                            }
                            this.filtrar()
                            modal.dismiss();
                            await this.escaner_generico()
                        })
                        modal.present()
                        
                }, 



            },    
        })
</script>

<style scoped lang="scss">
    @import "@/theme/footer-styles.scss";
    @import "@/theme/seleccion-producto-stock.scss";
    ion-list-header.mini {
        margin: 0px !important; 
        padding: 0px !important; 
        
    } 
    ion-list-header.mini >ion-row >ion-col  {
        font-size: 1.2rem  !important; 
        color: var(--ion-color-dark) !important;
        margin: 0px !important; 
        padding: 0px !important; 
        text-align: left !important;

    }
    .separata { padding-left : 1rem !important; }
    ion-item {
        --inner-padding-end: 0px !important; 
        --padding-end: 0px !important; 
    }
    ion-button.confirmaciones {
        width: 50% !important; 
        margin-left: 0.3rem !important; 
    }
    ion-button.solo {
        width: 100% !important; 
        margin-left: 0.3rem !important; 
    }
    ion-button.dos {
        width: 50% !important; 
        margin-left: 0.3rem !important; 
    }

    ion-button.incidencia {
        text-transform: capitalize !important;
        font-size: 110% !important; 
        font-weight: 700 !important; 
        color: var(--ion-color-danger) !important; 
    }

    .sin-limites {
        font-size: x-large !important; 
        overflow: visible !important;
        position: absolute !important; 
        bottom: 0px !important; 
        right: 0px !important; 
    }

</style>