<template>
    <ion-page :key="qt">
        <CabeceraComponentBack  
            :pdv = "pdv"
            :isIOS = "$route.meta.isIOS"
            :titulo="`Datos entrega envio`"
            color="primary"
            previo="envios"
        />
        <ion-content class="ion-padding" v-cloak>     
            <ion-fab vertical="top" horizontal="end" slot="fixed" >
                    <ion-fab-button                          
                        :key="`scan-activo:${blinker}`"
                        :class="blinker ? 'activo' : 'inactivo'"
                        >
                        <ion-icon :icon="barcodeSharp" :class="blinker ? 'blink' : 'not-blink'" :key="`ion-scan:${blinker}`"></ion-icon>
                    </ion-fab-button>
            </ion-fab>

            <ion-row  class="spin ion-justify-content-center "  v-if="!showme">
                <ion-col  v-if="!showme" class="spin ion-align-self-center" size="48" > 
                    <ion-spinner  v-if="!showme"></ion-spinner>            
                </ion-col>
            </ion-row>
        
            <ion-row class="recepcion ion-no-margin con-borde" v-if="showme">
                <ion-col   size="48" class="ion-no-padding ion-no-margin">

                    <ion-list class="ion-no-padding ion-no-margin" >

                        <!-- Cliente-->
                        <ion-item lines="none"> 
                            <ion-label class="negrita ">Cliente :  
                                {{datos_envio.codemp}}.{{datos_envio.codcli}}
                            </ion-label>
                        </ion-item>      
                        <ion-row class="elementos">
                            <ion-input class="detalle medio " :value="datos_envio.nomcli"   readonly />
                        </ion-row>

                        <!-- Direccion de entrega  -->
                        <ion-item lines="none"> 
                            <ion-label class="negrita" >
                                    Direccion de entrega
                            </ion-label>                            
                        </ion-item>   
                        <ion-row class="elementos">
                            <ion-input class="detalle medio" :value="datos_envio.nomenv"   readonly />
                            <ion-input class="detalle medio" :value="datos_envio.domenv"   readonly />
                            <ion-input class="detalle medio" :value="`${datos_envio.cpenv} ${datos_envio.pobenv}`"   readonly />
                        </ion-row>

                        <!-- Tipo de Envio -->
                        <ion-item lines="none"> 
                            <ion-label class="negrita">Datos de situacion del envio</ion-label>
                        </ion-item>    
                        <ion-row class="elementos">
                            <ion-input class="detalle medio" readonly >
                                <span class="izquierda">Situacion :</span>
                                <span class="derecha" :class="datos_envio.colores">{{datos_envio.est_env}}</span>
                            </ion-input>

                            <ion-input class="detalle medio" readonly >
                                <span class="izquierda">Forma de entrega :</span>
                                <span class="derecha">{{ubicaciones_entrega[datos_envio.envio_tipo]}}</span>                            
                            </ion-input>

                            <ion-input class="detalle medio" readonly v-if="datos_envio.envio_tipo === 'AGENCIA_GES'">
                                <span class="izquierda">Agencia :</span>
                                <span class="derecha">{{datos_envio.entrega_id.agencia}}</span>                            
                            </ion-input>

                            <ion-input class="detalle medio" readonly v-if="datos_envio.envio_tipo === 'AGENCIA_GES'">
                                <span class="izquierda">Código expedición :</span>
                                <span class="derecha">{{datos_envio.entrega_id.codexp_agencia}}</span>                            
                            </ion-input>

                            <ion-input class="detalle medio" readonly >
                                <span class="izquierda">Bultos : </span>
                                <span class="derecha">{{datos_envio.bultos}}</span>
                            </ion-input>

                            <ion-input class="detalle medio" readonly v-if="datos_envio.situa === 3" >
                                <span class="izquierda">Entregado por : </span>
                                <span class="derecha">{{$filters.Initcap2(datos_envio.entrega_usr)}}</span>
                            </ion-input>

                            <ion-input class="detalle medio" readonly v-if="datos_envio.situa === 2" >
                                <span class="izquierda">Conductor : </span>
                                <span class="derecha">{{$filters.Initcap2(datos_envio.chofer)}}</span>
                            </ion-input>

                            <ion-input class="detalle medio" readonly v-if="datos_envio.situa === 1" >
                                <span class="izquierda">Cargado por : </span>
                                <span class="derecha">{{$filters.Initcap2(datos_envio.carga_usr)}}</span>
                            </ion-input>
                            <ion-input class="detalle medio" readonly v-if="datos_envio.situa === 0" >
                                <span class="izquierda">Preparado por : </span>
                                <span class="derecha">{{$filters.Initcap2(datos_envio.envio_usr)}}</span>
                            </ion-input>

                            <ion-input class="detalle medio" readonly v-if="datos_envio.situa>0" >
                                <span class="izquierda">Vehiculo : </span>
                                <span class="derecha"  v-if="datos_envio.vehiculo" >{{datos_envio.vehiculo}}</span>
                            </ion-input>

                            <ion-input class="detalle medio" readonly v-if="datos_envio.situa==0" >
                                <span class="izquierda"> {{datos_envio.envio_tipo === 'ESPERA' ? 'Ubicacion': 'Ruta' }} : </span>
                                <span class="derecha rojo"  v-if="datos_envio.envio_tipo ==='ESPERA'" >
                                        {{datos_envio.ubicacion}}
                                </span>
                                <span class="derecha"  v-else >
                                        {{datos_envio.ubicacion}}
                                </span>
                            </ion-input>

                            <ion-input class="detalle medio" readonly  v-if="datos_envio.situa>2">
                                <span class="izquierda">Fecha : </span>
                                <span class="derecha" v-if="datos_envio.entrega_fec">{{$filters.num2DateTime(datos_envio.entrega_fec)}}</span>
                                <span class="derecha" v-else>Pendiente entrega</span>
                            </ion-input>

                            <ion-input class="detalle medio" readonly v-if='datos_envio.entrega_id && datos_envio.situa >2' >
                                <span class="izquierda">Entregado a : </span>
                                <span class="derecha" v-if="datos_envio.has_nif === 1 ">{{datos_envio.entrega_id.nombre}} ({{datos_envio.entrega_id.nif.toUpperCase()}})</span>
                                <span class="derecha" v-else>{{datos_envio.entrega_id.nombre}} 
                                    <span class="rojo">(NO FACILITA)</span>
                                    </span>
                            </ion-input>

                            <ion-input class="detalle medio" readonly v-if='datos_envio.entrega_id && datos_envio.situa >2' >
                                <span class="izquierda">Distancia entrega teorica : </span>
                                <span class="derecha" v-if="datos_envio.dist_ent">{{$filters.Entero(datos_envio.dist_ent)}} m</span>
                                <span class="derecha" v-else>No disponible</span>
                            </ion-input>

                            <ion-input class="detalle medio" readonly v-if="datos_envio.incidencia_ent">
                                <span class="izquierda">Incidencias entrega: </span>
                            </ion-input>

                            <ion-row class="elementos ultima" v-if="datos_envio.incidencia_ent  && datos_envio.incidencia_ent.length>0">
                                <ion-input v-for="(inci,index) in datos_envio.incidencia_ent" 
                                    class="detalle medio incident" 
                                    :key="`cmt-${index}`"
                                    :value="`- ${inci.k} : ${inci.v}`"
                                    />
                            </ion-row>                        
                        </ion-row>

                        <!-- albaranes  class="borde-arriba" -->
                        <ion-item lines="none" > 
                            <ion-label class="negrita">Albaranes</ion-label>
                        </ion-item>      
                        <ion-item  class="sin-borde bajo">
                                <ion-row class="sin-nada">
                                    <ion-col size="16" v-for="(alb ,index) in datos_envio.albaranes " :key="index" 
                                        class="ion-no-padding ion-no-margin">
                                        <ion-badge mode="md" class="agrupado" >
                                                <ion-text class="principal">
                                                {{alb}}
                                                </ion-text>
                                        </ion-badge>
                                    </ion-col>
                                </ion-row>
                        </ion-item>
                        <!-- Observaciones -->
                        <ion-row class="elementos ultima" v-if="datos_envio.obsent  && datos_envio.obsent.length>0">
                            <ion-input v-for="(obs,index) in datos_envio.obsent" 
                                class="detalle medio mediano" 
                                :key="`cmt-${index}`"
                                :value="`- ${obs.alb} : ${obs.obsent}`"
                                />
                        </ion-row>

                        <ion-item lines="none"  v-if="datos_envio.graphs"> 
                            <ion-label class="negrita">Evidencia digital de entrega </ion-label>
                        </ion-item>
                                <ion-item  class="sin-borde bajo">
                                
                                <ion-row class="sin-nada">
                                    <ion-col size="24" v-for="(g ,index) in datos_envio.graphs " :key="index" 
                                        class="ion-no-padding ion-no-margin">
                                        <ion-button mode="md" class="link"
                                            :color="g.o === 0 ? 'darksuccess' : 'medium'" 
                                            :href="g.url" target="_blank">
                                                <ion-text class="links">
                                                {{g.k}}
                                                </ion-text>
                                        </ion-button>

                                    </ion-col>
                                </ion-row>
                        </ion-item>


                    </ion-list>
                </ion-col>
            </ion-row>        


        </ion-content>

   

    </ion-page>

</template>
    

<script>

    /**
     * NOTAS: 
     * El escaner solo dirige salidas al campo albaranes, 
     * todos los demas son solo de introducción manual 
     * Si leemos algo o vienen de la inicialización de la pagina 
     */
    import { defineComponent } from 'vue';
    import CabeceraComponentBack from '@/components/CabeceraComponentBack';
    import { barcodeSharp} from 'ionicons/icons';
    import { openAlertaV2 , openAlertaBinaria } from  '@/utilities/services/alert-utilities';
    import { useRoute } from 'vue-router';
    import { esAlbaran , esEnvio , estado_envio , ubicaciones_entrega , colores_situacion } from '@/utilities/global-constants';
    import { DistanciaLinealEntrePuntos}  from '@/utilities/location'

    import { scanGenericStart, scanGenericStop  } from '@/utilities/scanner/scan-generic'
    import eventBus from "@/event-bus"; 
    
    export default defineComponent({
            name : "RevisionEnvioEntregadoPage",
            components: {  CabeceraComponentBack  } , 
            props: ['envio_id_intro'],  
            data() { 
                return { 
                    barcodeSharp ,   ubicaciones_entrega, estado_envio, 
                    element_id: 'revision-envio-entregados',
                    pdv: this.$route.meta.pdv, 
                    nompos: this.$route.meta.nompos, 
                    isIOS: undefined, 
                    scanner_listener: undefined, 
                    blinker : false ,
                    datos_envio :  undefined , 
                    isGrupo: undefined,      
                    showme :false, 
                    qt :false , 
                    show_image:undefined
                    }
            },
            created() {
                this.pdv =  this.$route.meta.pdv 
                this.nompos =  this.$route.meta.nompos 
                this.isIOS = this.$route.meta.isIOS 
            },
            watch: {
                '$route.query.q'() { 
                    this.pdv =  this.$route.meta.pdv ;
                    this.nompos =  this.$route.meta.nompos 
                }  
            },
            updated() {
                this.pdv =  this.$route.meta.pdv ;
                this.nompos =  this.$route.meta.nompos ;

            },
            async mounted() {
                this.showme = false; 
                this.envio_id = this.envio_id_intro||'ENV:034:20230420:072133:067'
                //navigator.share
                //'ENV:021:20230417:102837:490'||'ENV:129:20230404:060406:089'
                window.fake_console(this.envio_id)
                const route = useRoute();
                this.qt = route.query.q
                
                if (this.envio_id && esEnvio.test(this.envio_id) === true)
                    {   
                        await this.fetchEnvio()    
                        this.showme = true; 
                        await this.escaner_generico() 
                    }
                else {
                    await openAlertaV2( 'Error envio', 
                        `No hay ningun envio que chequear `) 
                    this.$router.go(-1)
                    }
            }, 
            async unmounted() {
                await this.pararScanner()
            }, 

            methods: {
                async fetchEnvio() {
                    this.showme = false
                    const env = await this.$http.post(`/check_envio/${this.pdv}`, {ms_uuid: this.envio_id})
                    if (!env.data || env.data.length ==0) {
                            throw `No hemos podido encontrar el envio ${this.envio_id}`
                        }
                    else {
                        const x = env.data[0]        
                        x.est_env = estado_envio[x.situa]||'Desconocido'     
                        x.colores = colores_situacion[x.situa]||'rojo'           
                        x.albaranes = JSON.parse(x.albaranes)
                        x.obsent = !x.obsent ||x.obsent==='null' ? undefined : JSON.parse(x.obsent) 
                        x.incidencia_ent = x.incidencia_ent === null ? undefined : JSON.parse(x.incidencia_ent)
                        x.entrega_id = x.entrega_id === null ? undefined : JSON.parse(x.entrega_id)
                        const {lat_ent, lon_ent, lat_env, lon_env } = x
                        x.dist_ent = DistanciaLinealEntrePuntos({lat_ent, lon_ent, lat_env, lon_env })
                        x.digital_assets = x.imagen === 1 ||x.firma === 1
                        if (x.digital_assets === true ) {
                            const anyo = this.envio_id.split(':')[2].slice(0,4)
                            const mes = this.envio_id.split(':')[2].slice(4,6)
                            const graphs = await this.$http.post(`/list_delivery_images`,  {fileId : `${x.codemp}/${x.codcli}/${anyo}/${mes}/${this.envio_id}`})
                            window.fake_console(graphs.data)
                            const dt = graphs.data
                            if (dt && dt.length >0) {
                                x.graphs = dt.reduce((m,v)=> {
                                    if (v.name.indexOf('IMG')!=-1){
                                        m.push( {"k" : `Imagen entrega` , "v" : v.name , "url": v.url , "o" :1})
                                    } 
                                    else {
                                        m.push( {"k" : `Firma digitalizada` , "v" : v.name , "url": v.url ,  "o" : 0 })
                                    }
                                    return m 
                                } ,[])
                            }
                            window.fake_console(dt)
                        }
                        this.datos_envio = x
                        window.fake_console(this.datos_envio)
                        console.log(this.datos_envio);
                        this.showme = true                        
                    }
                }, 

                goBack() { this.$router.replace('/envios')},

                /** Parada de escaneo */
                async pararScanner () {
                    await scanGenericStop(this.element_id)
                    this.blinker = false;
                }, 

                async chequeoAlbaranes(input_scaner){ 
                    const alb = esAlbaran.test(input_scaner);
                       if ( alb ) {
                            const alb = input_scaner.match(esAlbaran).groups
                            const { codemp, codsuc, numalb, codcli} = alb 
                            const payload = {codsuc, numalb, codcli }
                            const albaran = await this.$http.post(`/get_delivery_note/${codemp}`, payload)
                            if (albaran.data[0].status === 0 && albaran.data[0].ms_uuid ) {
                                const confirmacion = await openAlertaBinaria('Albaran ya enviado ' , 
                                    `El albarán se expidió anteriormente en el envio ${albaran.data[0].ms_uuid}.<br/> 
                                     Si quieres ver los detalles del mismo confirma, sino cancela.<br/>
                                    `, 'Cancelar','Confirmar')
                                if ( confirmacion === true ){
                                    this.envio_id = albaran.data[0].ms_uuid; 
                                    await this.fetchEnvio()
                                    return true
                                } 
                            }
                            else {
                                await openAlertaV2('Error recuperacion', `Albarán inexistente o no enviado a través de Ubiku `)
                            }
                            return true

                       }
                       else {
                            return  undefined
                       }
                }, 
                /** Funcion de control del listener de los escaneos  */
                async lectorEventosGenericos() {
                    let self = this; 
                    eventBus().emitter.off(`lectura_generica:${this.element_id}`)
                    eventBus().emitter.once(`lectura_generica:${this.element_id}`,async (input_scaner_in)=>{
                       await self.pararScanner()
                       const input_scaner = input_scaner_in.replace(/\?/g,'_')
                       /** Verifico si es un albarán y fue enviado anteriormente, en cuyo caso te propongo verlo si quieres */
                       const es_albaran = await this.chequeoAlbaranes(input_scaner)
                       if (es_albaran) {
                            await self.escaner_generico()
                            return 
                       }
                       try { 
                           const {ms_uuid  } = JSON.parse(input_scaner)
                           if (ms_uuid && esEnvio.test(ms_uuid) === true )
                               {
                                    this.envio_id = ms_uuid; 
                                    await this.fetchEnvio()
                                }
                                    else {
                                        window.fake_console('salgo por el error ')
                                        await openAlertaV2('Envio erroneo' , `
                                            La lectura facilitada no corresponde con un envio valido`
                                            )
                                }
                        }
                        catch(ex) {
                            console.log(ex)
                             await openAlertaV2('Error lectura', `La lectura ${input_scaner} no se corresponde con ningun formato aceptado `)
                        }
                        await self.escaner_generico()
                    })
                },

                async escaner_generico() {                    
                    window.fake_console('activado escaner')
                    /** Hacemos reset del scanner cada vez que entramos en el campo  */
                    await scanGenericStop(this.element_id)
                    this.blinker = true
                    await scanGenericStart(this.element_id, 'desde escaner_generico')
                    this.blinker = true
                    await this.lectorEventosGenericos()
                },


            },    
        })
</script>

<style scoped lang="scss">
    @import "@/theme/footer-styles.scss";
    @import "@/theme/recepciones-styles.scss";
    ion-row.recepcion {
        padding: 7px !important; 
    }
    ion-content.ion-padding{
        padding: 0.5rem !important; 
    }
    ion-input.detalle {
        padding-left: 1rem !important; 
        --padding-top:  0.1rem !important; 
        --padding-bottom:  0.2rem !important; 
    }
    ion-item.detalle {
        --min-height: 2rem !important; 
        --padding-top:  0.1rem !important; 
        --padding-bottom:  0.1rem !important; 
    }

    .negro-det, .negro-det > *  {
        --min-height: 1rem !important; 
        --padding-top:  0.1rem !important; 
        --padding-bottom:  0.1rem !important; 
        font-weight: 550 !important; 
        font-size: 100% !important; 
    }
    ion-label.auxiliar {
        --padding-top: 0px !important;
        --padding-bottom: 0px !important;
        
    }
    .negrita {
        font-weight: 550 !important; 
        font-size: 105% !important; 
    }
    ion-input.mediano {
        font-size: 0.95rem !important; 
        padding-left: 2rem !important; 


    }

    ion-item.borde-arriba {
        border-top: 1px solid var(--border-color) !important; 
    }
    ion-input.bultos {
        margin-right: 0px !important; 
        margin-top: 0.3rem !important; 
        min-width: 25% !important;
        max-width: 25% !important;
    }
    ion-label.tres_cuartos {
        min-width: 75% !important;
        max-width: 75% !important;
        width: 75% !important; 
    }
    ion-fab.fijo {
        position: relative !important; 
        top: 0.5rem !important; 
        right : 1rem !important; 
        z-index : 99999 !important; 
    }
    ion-row.elementos {
        display:block !important; 
        border-bottom: 1px solid var(--ion-color-light) !important; 
    }
    ion-row.elementos.ultima {
        padding-bottom: 0.5rem !important; 
    }

    ion-input {
        font-size : 1.1rem !important; 
    }

    div.agrupado {
        display: flex;  
        align-items: center !important;
    }
    ion-badge.agrupado {
        display: block !important; 
        border: 2px solid var(--ion-color-medium) !important; 
        background: var(--ion-color-blanco) !important;
        padding: 0.2rem 0px  !important;
        margin: 0.3rem !important; 
        border-radius : 3px !important;
    }

    ion-button.link {
        width: 90% !important; 
        min-height: 2.5rem !important;
        margin: 0.3rem auto !important; 
        text-transform: none !important;
    }

    ion-badge.links {
        display: block !important; 
        padding: 0.5rem 0px  !important;
        margin: 0.3rem !important; 
        border-radius : 3px !important;

    }
    ion-text.principal {
        color: var(--ion-color-medium) !important; 
        font-size: 1.1rem !important; 
        padding: 0.3rem auto !important; 
        margin: auto !important; 
    }
    ion-text.links {
        font-size: 1.1rem !important; 
        padding: 0.3rem auto !important; 
        margin: auto !important; 
    }
    ion-input.incident {
        font-size: 0.9rem !important; 
        color: var(--ion-color-darkdanger) !important; 
          overflow-wrap: break-word;


    }
    ion-item.bajo {
        --min-height: 2rem !important; 
    }
    img , object {
          width: 90% !important;
          margin: auto !important;
          height: auto !important
    }

</style>