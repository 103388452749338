<template>
    <ion-page>
        <ion-header>
            <ion-toolbar color="primary">
                <ion-buttons slot="start">
                    <ion-button size="default" color="light" routerLink="/home" routerDirection="none">
                        <ion-icon :icon="homeSharp" color="light" class="muy-grande"> </ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title class="derecha">
                    Ubiku<br />
                    <ion-note>
                        <small>by GES</small>
                    </ion-note>
                </ion-title>
                <div v-cloak>
                    <i class="fas fa-solid fa-person-shelter fa-fade en-home" v-if="EnPdv === true"></i>
                    <i class="fas fa-solid fa-house-person-leave fa-fade en-home" v-else></i>
                </div>
            </ion-toolbar>
        </ion-header>

        <ion-content v-if="!showme">

            <ion-row class="spin ion-justify-content-center " v-if="!showme">
                <ion-col v-if="!showme" class="spin ion-align-self-center" size="48">
                    <ion-spinner v-if="!showme"></ion-spinner>
                </ion-col>
            </ion-row>
        </ion-content>
        <ion-content v-else>

            <ion-grid>
                <!-- CASO CHOFER -->
                <ion-row padding-top
                    v-if="showme && (superUsers.indexOf($current_user) != -1 || pdvsSalidas.indexOf(pdv) != -1) && ubiku_rol == 5">
                    <TarjetaHome link="login" titulo="Configuracion" detalle="Login y configuracion" />
                    <TarjetaHome link="buscar" titulo="Buscar" detalle="Busqueda de articulos del pdv" :inactivo="!EnPdv" />
                </ion-row>

                <ion-row padding-top
                    v-if="showme && (superUsers.indexOf($current_user) != -1 || pdvsSalidas.indexOf(pdv) != -1) && ubiku_rol == 5">
                    <TarjetaHome :link="destino_reparto()" titulo="Repartos"
                        detalle="Carga camion y creación expediciones de reparto" />
                    <TarjetaHome link="envios" titulo="Envios" detalle="Realizar un envio" :inactivo="!EnPdv" />
                </ion-row>


                <!-- CASO GENERAL -->
                <ion-row padding-top v-if="showme && ubiku_rol != 5">
                    <TarjetaHome link="ubicar" titulo="Ubicar" detalle="Ubicar articulos de forma masiva"
                        :inactivo="!EnPdv" />
                    <TarjetaHome link="login" titulo="Configuracion" detalle="Login y configuracion" />
                </ion-row>
                <ion-row padding-top v-if="showme && ubiku_rol != 5">
                    <TarjetaHome link="etiqueta-ubicacion" titulo="Etiqueta" detalle="Imprir etiquetas ubicacion"
                        :inactivo="!EnPdv" />
                    <TarjetaHome link="buscar" titulo="Buscar" detalle="Busqueda de articulos del pdv" />
                </ion-row>
                <ion-row padding-top v-if="showme && ubiku_recepcion == 1">
                    <TarjetaHome link="recepciones" titulo="Recepcionar" detalle="Recepcionar pedido" :inactivo="!EnPdv" />
                    <TarjetaHome link="test-velocidad" titulo="Test" detalle="Test de velocidad movil" />
                </ion-row>

                <ion-row padding-top v-if="showme && (superUsers.indexOf($current_user) != -1 || pdvsMostrador.indexOf(pdv) != -1)">
                    <TarjetaHome link="clientes-preparacion-pedidos" titulo="Picking pedido"  detalle="Preparar pedido de cliente" />
                    <TarjetaHome link="clientes-carrito-mostrador" titulo="Mostrador" detalle="Carrito de mostrador" />
                </ion-row>

                <ion-row padding-top
                    v-if="showme && (superUsers.indexOf($current_user) != -1 || pdvsSalidas.indexOf(pdv) != -1) && ubiku_rol != 5">
                    <TarjetaHome link="envios" titulo="Envios" detalle="Realizar un envio" :inactivo="!EnPdv" />
                    <TarjetaHome link="expediciones" titulo="Cargar Camion" detalle="Carga camion para expedir"
                        :inactivo="!EnPdv" />
                </ion-row>
                <!-- 
                <ion-row padding-top v-if="showme  && superUsers.indexOf($current_user)!=-1">
                    <TarjetaHome link="form-entrega" titulo="Entrega" detalle="Formulario Entrega"  />
                    <TarjetaHome link="" titulo="" detalle="" :inactivo="true" />                    
                </ion-row>
                -->

            </ion-grid>
        </ion-content>
    </ion-page>
</template>

<script>

import TarjetaHome from '@/components/TarjetaHome'
import axios from 'axios';
import { defineComponent } from 'vue';
import eventBus from "@/event-bus";
import { homeSharp } from 'ionicons/icons';
import { pdvDistance } from '@/utilities/location'
export default defineComponent({
    name: "HomePage",
    components: { TarjetaHome },
    created() {
        this.actualiza_vars()
    },
    data() {
        return {
            pdvsMostrador: undefined,
            pdvsSalidas: undefined,
            superUsers: ['raul.sanchez', 'javier.padilla'],
            homeSharp,
            pdv: this.$route.meta.pdv,
            ubiku_rol: this.$route.meta.ubiku_rol, 
            ubiku_recepcion: this.$route.meta.ubiku_recepcion,
            pdv_geoloc: this.$route.meta.pdv_geoloc,
            EnPdv: undefined,
            showme: false
        }
    },
    async updated() {
        this.$NoBackNavigator()
        this.showme = false
        window.fake_console('cambiando...')
        eventBus().emitter.once('transicion', () => this.showme = false)
        await this.actualiza_vars()
        window.fake_console('cambiado ', this.showme)
        this.EnPdv = await pdvDistance(this.$geofence, this.pdv_geoloc, true) || undefined
        this.showme = true
    },
    async mounted() {
        this.$NoBackNavigator()
        this.showme = false;
        const especiales = await axios.get('/pdvs_test')
        const pdvs_especiales = especiales.data
        this.pdvsMostrador = pdvs_especiales.mostrador
        this.pdvsSalidas = pdvs_especiales.salidas
        window.fake_console('cambiando...')
        eventBus().emitter.once('transicion', () => this.showme = false)
        await this.actualiza_vars()
        this.EnPdv = await pdvDistance(this.$geofence, this.pdv_geoloc, true) || undefined
        window.fake_console('cambiado ', this.showme)
        this.showme = true
    },
    methods: {
        destino_reparto() {
            const url = this.EnPdv ? 'expediciones' : 'expediciones-reparto'
            return url
        },
        async actualiza_vars() {
            this.pdv = this.$route.meta.pdv
            this.ubiku_rol = this.$route.meta.ubiku_rol
            this.ubiku_recepcion = this.$route.meta.ubiku_recepcion
            this.pdv_geoloc = this.$route.meta.pdv_geoloc


        }
    }
})
</script>

<style scoped>
ion-title {
    padding-right: 4.5rem !important;
}

ion-card {
    font-family: var(--ion-font-family, inherit);

    margin: 10px 4px !important;
    height: 7rem !important;
    font-size: x-large !important;
}

ion-card-title {
    text-align: center;
}

ion-card-header {
    padding-top: 10px;
    padding-bottom: 10px;
}

ion-note {
    color: whitesmoke;
}

i.fas.en-home,
i.fak.en-home,
i.far.en-home {
    z-index: 9999 !important;
    position: absolute !important;
    top: 0.7rem !important;
    right: 0.7rem !important;
    display: flex !important;
    color: dark !important;
    font-weight: 550 !important;
    ;
    font-size: 2rem !important;
    float: right !important;

}</style>