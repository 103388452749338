<template >
    <ion-radio-group value="undefined" mode="md" v-if="alb!=undefined && rec!=undefined" @ionChange="procesaCambio($event)">
        <ion-row class="incidencia-entrada" v-if="negativo">        
            <ion-col size="24">
                <ion-item class="incidencia-entrada danger"  lines="none" >
                    <ion-label color="darkdanger" >Abonar</ion-label>
                    <ion-radio slot="end" value="A"  color="danger" ></ion-radio>
                </ion-item>

            </ion-col>

            <ion-col size="24">
                <ion-item class="incidencia-entrada danger"  lines="none">
                    <ion-label color="darkdanger" >Reponer</ion-label>
                    <ion-radio slot="end" value="R"  color="danger" ></ion-radio>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row class="incidencia-entrada success" v-if="positivo">

            <ion-col size="24" >
                <ion-item class="incidencia-entrada success" lines="none">
                    <ion-label color="darksuccess" >Cargo</ion-label>
                    <ion-radio slot="start" value="C"></ion-radio>
                </ion-item>
            </ion-col>

            <ion-col size="24">
                <ion-item class="incidencia-entrada success" lines="none">
                    <ion-label color="darksuccess" :value="null">Igualar</ion-label>
                    <ion-radio slot="end" value="I" ></ion-radio>
                </ion-item>
            </ion-col>
        </ion-row>
  </ion-radio-group>


</template>

<script>
    import { defineComponent } from 'vue';
    export default defineComponent({
        name : "AccionIncidenciaEntradaComponent",
        props : {uds_rec : String, uds_alb : String}, 
        computed :{
            alb : function ()  { 
                window.fake_console('alb', this.uds_alb, parseFloat(this.uds_alb) )
                return parseFloat(this.uds_alb) 
                }, 
            rec : function() { 
                window.fake_console('rec', this.uds_rec, parseFloat(this.uds_rec) )
                return parseFloat(this.uds_rec) 
                },
            positivo: function () {
                try { return  this.rec!=undefined && this.alb!=undefined && this.rec > this.alb }
                catch { return false  }
            } , 
            negativo: function () {
                try { return  this.rec!=undefined && this.alb!=undefined && this.rec < this.alb }
                catch { return false  }
            } 
        },
        methods :{
            procesaCambio(e) {
                const valor = e.target.value 
                this.$emit('update:radio-button-accion', valor)
                window.fake_console('emitido....' , valor)
            }
        }

    })
</script>

<style scoped lang="scss">
    @import "@/theme/recepciones-styles.scss";
    ion-radio-group {
        padding-left: 1rem !important; 
    }

    ion-item.incidencia-entrada {
    --padding-start: 1rem !important; 
    --padding-end: 1rem !important; 

}
ion-item.incidencia-entrada.danger {
    --border-width: 2px solid red !important;
}
ion-item.incidencia-entrada.success {
    --border-width: 2px solid var(--ion-color-success) !important;
}

ion-row.incidencia-entrada {
    padding-right : 1rem !important; 
    padding-left : 1rem !important; 
}

</style>