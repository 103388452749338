<template>
    <ion-col>

            <ion-card >
                <ion-card-header class="head">
                    <ion-title class="super"> Lotes / Sucursales </ion-title>
                    <ion-card-subtitle size="large">
                         <ion-row class=" titulin">
                            <ion-col size="29" offset ="1" >Referencia</ion-col>
                            <ion-col size="6" >Suc</ion-col>
                            <ion-col size="12" >Unid.</ion-col>
                        </ion-row>
                    </ion-card-subtitle>
                </ion-card-header>

                <ion-card-content  v-if="datos">
                    <ion-row v-for="(item,index) in datos"  v-bind:key="index"
                        class="pares-articulo-lote-ubicacion"
                        @click="SeleccionarOpcion(item)" >
                        <!-- primera linea--> 
                        <ion-col size="31" 
                            offset="1"
                            class="ion-padding-left ion-text-nowrap  sku nombre_cortado ion-text-start">
                            {{ $filters.showSku(item.sku)}}
                        </ion-col>
                        <ion-col  size="4"  class=" medios  ion-text-center">
                            {{ item.codsuc }}
                        </ion-col>
                        <ion-col size="12"  class="ion-padding-left ion-text-nowrap  medios ion-text-right">
                            <span class="pad-1-derecha">{{ $filters.Entero(item['stoalm'])}}</span>
                        </ion-col>

                        <!-- Segunda linea --> 
                        <ion-col size="43" offset="1"  class=" ion-padding-left ion-text-nowrap    medios ion-text-left " v-if="item.lote" > 
                            <span class="lote">Lote : {{ item.lote  }}</span>
                            
                        </ion-col>
                        
                        <!-- Tercera linea --> 
                        <ion-col size = "43"  offset="1" class="ion-padding-left  nombre_cortado mini-lista ion-text-start"> 
                           {{item.shortDescription}}
                        </ion-col>
                        <ion-col size="4"  class="ion-margin-right centro " @click="borrar(item)"> 
                            <i class="fas fa-sharp fa-trash-can"></i>
                        </ion-col>
                    </ion-row>
                </ion-card-content>
            </ion-card>
        </ion-col>
</template>
    

<script>
    import { defineComponent } from 'vue';
    import { countBy, pluck, uniq } from 'underscore';
    import { trashOutline } from 'ionicons/icons';
    import eventBus from '../event-bus';
    export default defineComponent({
            name : "LotesSeleccionados",
            props: ['datos'], 
            data() {
                return { 
                    trashOutline, 
                    }
            }, 
            mounted() {
                    window.fake_console('LotesSeleccionados ',this.datos)
            },
            computed :{
                largo: function () {
                    return Object.keys(this.datos).length
                    }, 
                lotes: function () {
                    return (countBy(this.datos, (x)=> {return !x.lote ? 'suc': 'lot'} )['lot']||0) > 0 
                    }, 
                skus: function()
                    {
                        window.fake_console(pluck(this.datos,'sku'))
                        window.fake_console( uniq(pluck(this.datos,'sku')))
                        return uniq(pluck(this.datos,'sku')).length>1                    
                    }, 
                
            },
            methods: {
                async SeleccionarOpcion(item) {
                    window.fake_console(item)
                 },
                async borrar(item) {
                    window.fake_console(`Borrando.... ${item.sku}`)
                    eventBus().emitter.emit('deselect-sku', item)
                }
            },    
        })
</script>

<style scoped lang="scss" >
  @import "@/theme/selectores-lotes-styles.scss";

</style>