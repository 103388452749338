import { Storage } from '@capacitor/storage';
    const schema = 'ubiku.ges.digital'
    const variables_limpieza = ["pdv" ,"nompos", "zonas_crossdocking" , "uname" , "ml" ,"geofence","ubiku_rol","mis_pdvs", "sales-plus", "printer", "ubiku_recepcion", "geoloc"]

        /** ------------------------------------------------------------------------------------------ */
        const saveStorage = async function(clave, valor) {
            await Storage.set({ key: `${schema}:${clave}` , value: valor})
        }

        /** ------------------------------------------------------------------------------------------ */
        const saveMainStorage = async function(uid, ml, no, prt, geofence, ubiku_rol, mis_pdvs) {
            await Storage.set({ key: `${schema}:sales-plus` , value: uid})
            await Storage.set({ key: `${schema}:ml` , value: ml})
            await Storage.set({ key: `${schema}:uname` , value: no})
            await Storage.set({ key: `${schema}:timestamp` , value: Date.now()})
            await Storage.set({ key: `${schema}:printer` , value: JSON.stringify(prt)})
            await Storage.set({ key: `${schema}:geofence` , value: JSON.stringify(geofence)})
            await Storage.set({ key: `${schema}:ubiku_rol` , value: ubiku_rol})
            await Storage.set({ key: `${schema}:mis_pdvs` , value: mis_pdvs})
            window.fake_console(`${schema}:printer` ,  prt)
        }
    
        /** ------------------------------------------------------------------------------------------ */
        const loadStorage = async function(clave) {
            try {
                const {value} = await Storage.get({ key: `${schema}:${clave}` })
                return (clave === 'geofence' || clave == 'pdv_geoloc') ? JSON.parse(value) : value 
            }
            catch(ey) {
                return undefined
            }            
        }

        /** ------------------------------------------------------------------------------------------ */
        const limpiarMemoria = async function(pdv, caso) {
            window.fake_console('voy a borrar las claves principales ....')
            try {
                const string = `^${schema}:(ubicaciones:${pdv}|${variables_limpieza.join('|')})`
                const tester = new RegExp(string)
                const  {keys} = await Storage.keys()
                window.fake_console(keys)
                const borrables = caso == undefined ? keys.filter(v => !tester.test(v)) : keys.filter(v => tester.test(v))
                window.fake_console(borrables)
                await borrables.reduce(async (m,v)=> { 
                    const memo = await m ;
                    await Storage.remove({ key: v });
                    return memo
                } , 0)
                return true 
            }
            catch{
                return false 
            }
        }

        /** ------------------------------------------------------------------------------------------ */

        const LimpiaTodo = async function() {
            window.fake_console('entro en limpia todo.....')
            try {
                const  {keys} = await Storage.keys()
                window.fake_console('antes :', keys)
                await Storage.clear()
                window.fake_console('borradas....')
                return true
            }
            catch {
                return false
            }
        }        

export { 
        loadStorage, saveStorage , saveMainStorage, 
        limpiarMemoria , LimpiaTodo
    }

