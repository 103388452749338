<template>
    <ion-page :key="qt">
        <CabeceraComponentBack :pdv="pdv" :isIOS="$route.meta.isIOS" :titulo="`Crear recepción`" color="primary"
            previo="recepciones" />
        <ion-content class="ion-padding" v-cloak>
            <!--  <p v-if="datos_entrada" v-html="JSON.stringify(omit(datos_entrada,'fecalb', 'numero_pedidos_proveedor')).replace(/,/g,',<br/>')"></p>-->

            <ion-row class="spin ion-justify-content-center " v-if="!showme">
                <ion-col v-if="!showme" class="spin ion-align-self-center" size="48">
                    <ion-spinner v-if="!showme"></ion-spinner>
                </ion-col>
            </ion-row>

            <ion-row class="recepcion ion-no-margin con-borde" v-if="showme">
                <ion-col size="48" class="ion-no-padding ion-no-margin">

                    <!-- Campo de escucha del escanner -->
                    <ion-list class="ion-no-padding ion-no-margin">
                        <!-- NUMERO DE ALBARAN -->
                        <RecepcionInputComponent titulo="Albaran" nombre="codalb" :pagina_origen="element_id"
                            placeholder="Codigo de albaran"
                            v-on:update:codalb="datos_entrada.codalb = $event.slice(-10); setAlbaran($event);" descripcion="Escanea o introduce el albaran.<br/>
                                Debes tener seleccionado el campo del formulario." modo="text" />
                    </ion-list>

                    <!-- SI ES PTL NO DEJAMOS EDITAR NADA, SOLO RECEPCIONAR -->
                    <AlbaranPtlComponent :valores="datos_entrada" v-if="isPtl === true" />

                    <!-- SI NO ES PTL INFORMAMOS CAMPO A CAMPO -->
                    <ion-list class="ion-no-padding ion-no-margin" v-else>
                        <!-- Fecha Recepcion-->
                        <ion-item v-if="!completo">
                            <ion-label position="fixed" class="mitad">Fecha Recepcion</ion-label>
                            <ion-datetime-button datetime="datetime" :value="datos_entrada.fecrec" disabled></ion-datetime-button>

                            <ion-modal :keep-contents-mounted="true">
                                <ion-datetime presentation="date" id="datetime"></ion-datetime>
                            </ion-modal>
                        </ion-item>

                        <RecepcionInputComponent titulo="Bultos" nombre="numbul" placeholder="Número de bultos"
                            v-on:update:numbul="datos_entrada.numbul = $event"
                            descripcion="Introduce el numero de bultos que vienen con el albaran " modo="numeric" />

                        <ion-item class="sin-borde pure-form">
                            <ion-button expand="block" fill="solid" color="darksuccess" class="recepcion" size="normal"
                                slot="start" @click="SelectPedidoProveedor(undefined)">
                                <span class="texto-boton-grande">Proveedor</span>
                            </ion-button>

                            <ion-input inputmode="numeric" type="number" v-model="datos_entrada.codpro" class="recepcion"
                                placeholder="Codigo proveedor" readonly>
                            </ion-input>
                        </ion-item>

                        <ion-item v-if="datos_entrada.nompro" lines="none">
                            <ion-label position="fixed" class="mitad">Proveedor</ion-label>
                            <ion-input :value="datos_entrada.nompro" disabled />
                        </ion-item>

                    </ion-list>

                    <ion-list class="ion-no-padding ion-no-margin">
                        <!-- UBICACION -->
                        <RecepcionInputComponent titulo="Ubicacion" nombre="ubicacion" autocap="words"
                            placeholder="Ubicacion recepcion" v-on:update:ubicacion="datos_entrada.ubicacion = $event"
                            descripcion="Escanea o introduce la ubicación donde has dejado el albaran recibido antes de darle entrada"
                            modo="text" />

                        <div>
                            <ion-item class="sin-borde pure-form">
                                <ion-button expand="block" fill="solid" color="darksuccess" class="recepcion" size="normal"
                                    slot="start" @click="SelectPedidoProveedor(datos_entrada.codpro)">
                                    <ion-icon :icon="addCircleSharp" class="muy-grande"> </ion-icon>
                                    <span class="texto-boton-grande-con-icono">Pedidos</span>
                                </ion-button>

                                <ion-input inputmode="text" class="recepcion" :placeholder="datos_entrada.numero_pedidos_proveedor > 0 ?
                                    `${datos_entrada.numero_pedidos_proveedor} pendientes`
                                    : 'No constan pedidos pendientes'" readonly>
                                </ion-input>
                            </ion-item>


                            <ion-item class="sin-borde"
                                v-if="errorPedidos && (!datos_entrada.pedidos || datos_entrada.pedidos.length == 0)">
                                <ion-text class="pedcli">
                                    Los datos de PTL no coinciden con ningun pedido pendiente de entrada.
                                </ion-text>
                            </ion-item>

                            <ion-item>
                                <ion-row class="sin-nada">
                                    <ion-col :size="peds.pedcli ? 32 : 16" v-for="(peds, index) in datos_entrada.pedidos "
                                        :key="index" class="ion-no-padding ion-no-margin">
                                        <ion-badge mode="md" class="agrupado" color="default">
                                            <div class="agrupado">
                                                <ion-text class="principal">
                                                    {{ peds.numped }}
                                                </ion-text>
                                                <ion-text class="pedcli" v-if="peds.pedcli">
                                                    (pedcli: {{ peds.pedcli }})
                                                </ion-text>
                                                <ion-icon :icon="closeCircle" class="badge elimina"
                                                    @click="eliminarPedido(peds.numped)">
                                                </ion-icon>
                                            </div>
                                        </ion-badge>
                                    </ion-col>
                                </ion-row>

                            </ion-item>

                            <!-- notas pedidos-->
                            <ion-item>
                                <ion-row>
                                    <ion-col size="48">
                                        <ion-text class="notas">
                                            Notas pedidos
                                        </ion-text>
                                    </ion-col>
                                    <ion-col size="48" v-for="(nota, index) in notas" :key="`nota:${index}`">
                                        <ion-text class="pednot">Pedido {{ nota[0] }} : <br /></ion-text>
                                        <ion-text class="detnot">
                                            * {{ nota[1] }}
                                        </ion-text>
                                    </ion-col>
                                    <ion-col size="48"></ion-col>
                                </ion-row>
                            </ion-item>

                        </div>

                    </ion-list>
                </ion-col>
            </ion-row>
        </ion-content>
        <ion-footer>
            <ion-toolbar>
                <ion-buttons slot="start" class="completo">
                    <ion-button class="completo" expand="block" fill="solid" id="recepcion_boton_reset_field"
                        color="darkdanger" size="normal" @click="descartar()">
                        Descartar
                    </ion-button>
                </ion-buttons>
                <ion-buttons slot="end" class="completo">
                    <ion-button class="completo" expand="block" fill="solid" id="recepcion_boton_creacion_field"
                        color="primary" size="normal" @click="CrearRecepcion()" :disabled="completo">
                        Crear
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-footer>
    </ion-page>
</template>
    

<script>

/**
 * NOTAS: 
 * El escaner solo dirige salidas al campo codalb, 
 * todos los demas son solo de introducción manual 
 * Si leemos algo 
 */
import { defineComponent } from 'vue';
import AlbaranPtlComponent from '@/pages/recepcionar/components/AlbaranPtlComponent.vue';
import RecepcionInputComponent from '@/pages/recepcionar/components/RecepcionInputComponent.vue';
import CabeceraComponentBack from '@/components/CabeceraComponentBack';
import { esSalida } from '@/utilities/global-constants';
import { homeSharp, addCircleSharp, closeCircle, barcodeSharp } from 'ionicons/icons';
import { openAlertaEligeAlbaranPTL, openAlertaV2 } from '@/utilities/services/alert-utilities';
import { useRoute } from 'vue-router';
import SeleccionPedidosRecepcionComponent from '@/pages/recepcionar/SeleccionarPedidosRecepcionComponent.vue';
import { modalController, IonDatetimeButton } from '@ionic/vue';
import { omit, pluck } from 'underscore';
import { esUbicacion } from '@/utilities/global-constants';

import eventBus from "@/event-bus";


export default defineComponent({
    name: "CrearRecepcionPage",
    components: { AlbaranPtlComponent, CabeceraComponentBack, RecepcionInputComponent, IonDatetimeButton },
    props: ['recepcion_id'],
    data() {
        return {
            homeSharp, addCircleSharp, closeCircle, barcodeSharp,
            omit,
            element_id: 'crear-recepcion',
            pdv: undefined,
            isIOS: undefined,
            scanner_listener: undefined,
            datos_entrada: {
                codalb: undefined,
                numbul: undefined,
                fecalb: undefined,
                fecrec: (new Date()).toISOString().slice(0, 10),
                codpro: undefined,
                pedidos: undefined,
                ubicacion: undefined,
                numero_pedidos_proveedor: undefined
            },
            notas: [],
            errorPedidos: undefined,
            isPtl: undefined,
            isGrupo: undefined,
            limite: 1000,
            showme: false,
            ruta: undefined,
            qt: false,
            nompro: undefined,
            debounce_milis: 300,
            entradas_ptl: undefined
        }
    },
    created() {
        this.pdv = this.$route.meta.pdv
        this.isIOS = this.$route.meta.isIOS
    },
    computed: {
        completo: function () {
            const dat = omit(this.datos_entrada, 'fecalb', 'numero_pedidos_proveedor')
            /** En el caso de grupo no exigimos pedidos  */
            dat.pedidos = this.isGrupo === true ? true : dat.pedidos
            const valores = Object.values(dat);
            return valores.some(x => !x)
        }
    },
    watch: {
        '$route.query.q'() { this.pdv = this.$route.meta.pdv; }
    },
    updated() {
        this.pdv = this.$route.meta.pdv
    },
    async mounted() {
        const route = useRoute();
        this.ruta = route.fullPath
        this.qt = route.query.q
        await this.ProcesoInicial()
        /* 
            Si entramos en la pagina con un albaran informado ,  
            lo informamos y desencadenamos el proceso  
            Es valido tanto para numeros de albaran de terceros 
            como para albaranes de Grupo     
        */
        if (this.recepcion_id != undefined) {
            window.fake_console(this.recepcion_id)
            window.fake_console('Hemos recibido un albaran de grupo como input ')
            this.setAlbaran(this.recepcion_id)
        }

    },

    methods: {
        /** Recuperacion entradas PTL */
        async ProcesoInicial() {
            try {
                const ptl = await this.$http.post(`/get_ptl_delivery_notes/${this.pdv}`)
                this.entradas_ptl = ptl.data
            }
            catch (ex) { window.fake_console('L255', ex) }
            this.showme = true;
        },

        async chequeaPTL() {
            window.fake_console('Inicio chequeo PTL')
            var self = this
            const albaran = new RegExp(`(^| )${this.datos_entrada.codalb.toString().slice(-10)}($| )`, 'ig')
            const posibles = this.entradas_ptl.filter(x => { return albaran.test(x.codalb) })
            if (posibles && posibles.length > 0) {
                await openAlertaEligeAlbaranPTL(posibles, async function (d) {                    
                    if (d && d.values && d.values.incidencias == -1) {
                        let { nombre, nomenv, ...validos } = d.values
                        void (nombre, nomenv)
                        const { fecrec, ubicacion } = self.datos_entrada
                        self.datos_entrada = { ...validos, fecrec, ubicacion, ...{} }
                        if (validos.codpro && validos.pedidos && validos.pedidos.length > 0) {
                            const peds = await self.$http.post(`/get_purchase_orders_by_supplier/${self.pdv}/${validos.codpro}`)
                            const pedclis = peds.data.reduce((m, v) => {
                                if (validos.pedidos.indexOf(v.numped) != -1) {
                                    const { numped, pedcli, notrec } = v
                                    m.push({ numped, pedcli, notrec })
                                }
                                return m
                            }, [])
                            if (!pedclis || pedclis.length == 0) {
                                self.errorPedidos = true;
                                self.datos_entrada.pedidos = undefined;
                                self.datos_entrada.numero_pedidos_proveedor = peds.data.length;
                            }
                            else {
                                self.datos_entrada.pedidos = pedclis
                                self.datos_entrada.numero_pedidos_proveedor = peds.data.length - pedclis.length
                            }

                            self.notas = self.datos_entrada.pedidos.reduce((mx, vx) => {
                                if (vx.notrec) { mx.push([vx.numped, vx.notrec]) }
                                return mx
                            }, [])
                        }
                        self.isPtl = true
                    }
                    else if (d && d.values && d.values.incidencias) {
                        await openAlertaV2(
                            'Incidencia PTL',
                            `La entrada PTL tiene ${d.values.incidencias} incidencias. \nDebe resolverlas en Gestion2000 antes de proceder con la recepcion`
                        )
                        await self.descartar()
                    }
                    else {
                        self.isPtl = false
                    }
                })
            }
            else { 
                self.isPtl = false
            }
        },

        async chequeaAlbaranGrupo(albaran_grupo) {
            window.fake_console('Inicio chequeo albaran grupo ')
            const dat = albaran_grupo.match(esSalida).groups
            if (dat.codigo !== '9' + self.pdv && dat.tipmov != 'CV') {
                await openAlertaV2(
                    'Error albaran',
                    `Este albaran no es de tu punto de venta. `
                )
            }
            else {
                window.fake_console('Ok patron, intentarmos recuperar datos ')
                const codpro = dat.codemp
                const pdv = dat.codigo.slice(1)
                const { codsuc, docume } = dat;
                const payload = { codpro, codsuc, codalb: docume }
                const albGrupo = await this.$http.post(`/check_albaran_grupo/${pdv}`, payload)
                window.fake_console(albGrupo.data[0])
                if (albGrupo.data[0].status === 0) {
                    await openAlertaV2(
                        'Error recuperacion',
                        `No hemos podido    recuperar el albaran. :\n${albGrupo.data[0].alert} `
                    )
                }
                else {
                    const { fecrec, ubicacion, numbul } = this.datos_entrada;
                    this.datos_entrada = { ...albGrupo.data[0], fecrec, ubicacion, numbul }
                    eventBus().emitter.emit(`${this.element_id}:update`, this.datos_entrada)
                    this.isGrupo = true;
                    this.isPtl = false;
                }
            }
        },
        async SelectPedidoProveedor(proveedor) {
            var self = this;
            const tipo_listener = proveedor ? 'pedido-adicional' : 'proveedor-recepcion'
            const componentes = proveedor ? { codpro: proveedor, pedidos_input: this.datos_entrada.pedidos } : {}
            const modal = await modalController
                .create({
                    component: SeleccionPedidosRecepcionComponent,
                    cssClass: 'custom-recommendations-modal',
                    componentProps: componentes,
                })
            eventBus().emitter.once(tipo_listener, async (item) => {
                window.fake_console('Cierro el select   or')
                modal.dismiss();
                if (item) {
                    window.fake_console('retorno con valores')
                    let { numped, pedcli, notrec } = item
                    if (proveedor != undefined) {
                        try {
                            self.datos_entrada.pedidos.push({ numped, pedcli, notrec })
                        }
                        catch {
                            self.datos_entrada.pedidos = [{ numped, pedcli, notrec }]
                        }
                        window.fake_console(self.datos_entrada.pedidos)
                        self.datos_entrada.numero_pedidos_proveedor = item.numero_pedidos_proveedor
                        self.datos_entrada.codpro = item.codpro
                        self.nompro = item.nombre
                    }
                    else {
                        self.datos_entrada.codpro = item.codpro
                        self.nompro = item.nombre
                        self.datos_entrada.pedidos = [{ numped, pedcli, notrec }]
                        self.datos_entrada.numero_pedidos_proveedor = item.numero_pedidos_proveedor;
                    }
                    this.notas = self.datos_entrada.pedidos.reduce((mx, vx) => {
                        if (vx.notrec) { mx.push([vx.numped, vx.notrec]) }
                        return mx
                    }, [])
                }
                else {
                    window.fake_console('retorno sin valores ', proveedor)
                    self.datos_entrada.codpro = proveedor;
                }
                eventBus().emitter.emit('recepcion_active_field', undefined)
                return
            })
            modal.present()
        },
        async setAlbaran(codigo_albaran) {
            this.datos_entrada.codalb = codigo_albaran.slice(-10);
            if (esSalida.test(codigo_albaran)) {
                window.fake_console(codigo_albaran)
                window.fake_console('Albaran de Grupo, tratamos como tal  ')
                await this.chequeaAlbaranGrupo(codigo_albaran)
            }
            else {
                window.fake_console('No es un albaran de grupo, evaluamos si hay datos ptl ')
                await this.chequeaPTL(true)
            }
        },

        async descartar() {
            const pdv = this.pdv
            Object.assign(this.$data, this.$options.data.call(this));
            this.pdv = pdv;
            this.qt = Date.now();
            await this.ProcesoInicial()

        },
        async CrearRecepcion() {
            const { codpro, fecalb, codalb, numbul, ubicacion, pedidos } = this.datos_entrada;
            console.log({ codpro, fecalb, codalb, numbul, ubicacion, pedidos, isPtl: this.isPtl })
            if (esUbicacion.test(ubicacion)) {
                window.fake_console(parseInt((fecalb || (new Date()).toISOString().slice(0, 10)).replace(/-/g, '')))
                const payload = {
                    codpro, codalb: codalb.toString().slice(-10),
                    fecalb: parseInt((fecalb || (new Date()).toISOString().slice(0, 10)).replace(/-/g, '')), bultos: numbul,
                    ubicacion,
                    ptl: this.isPtl === true ? 1 : 0, pedidos: pluck(pedidos, 'numped')
                };
                const recepcion = await this.$http.post(`/set_recepcion/${this.pdv}`, payload)
                window.fake_console(`/set_recepcion/${this.pdv}`, payload)
                window.fake_console(recepcion.data)
                if (recepcion.data.status === 0) {
                    await openAlertaV2(
                        'Error creacion',
                        `No hemos podido crear la recpecion, error:\n${recepcion.data.alert} `)
                }
                else {
                    await openAlertaV2(
                        'Recepcion creada',
                        'Hemos creado la recepcion, reseteamos los valores ')
                    this.$router.replace('/recepciones')
                }
            }
            else {
                await openAlertaV2(
                    'Error en los datos',
                    `La ubicacion no responde con un valor válido`
                )
            }
        },
        async eliminarPedido(pd) {
            const posterior = this.datos_entrada.pedidos.filter(x => x.numped != pd)
            this.datos_entrada.pedidos = posterior.length > 0 ? posterior : undefined
            this.datos_entrada.numero_pedidos_proveedor += 1
        },
        goBack() { this.$router.go(-1) },


    },
})
</script>

<style scoped lang="scss">
@import "@/theme/footer-styles.scss";
@import "@/theme/recepciones-styles.scss";

ion-content.ion-padding {
    padding: 0.5rem !important;
}

ion-text.notas {
    color: var(--ion-color-medium) !important;
    font-weight: bold !important;
    margin-top: 1rem !important;
    padding-top: 1rem !important;
    width: 100% !important;
    text-align: center !important;
}

ion-text.pednot {
    color: var(--ion-color-medium) !important;
    font-size: smaller !important;
    font-weight: 500 !important;
    margin-top: 1rem !important;
    padding-top: 1rem !important;
    width: 100% !important;
    text-align: center !important;
}

ion-text.detnot {
    font-size: small !important;
    color: var(--ion-color-medium) !important;
    font-weight: 500 !important;
    font-style: italic !important;
    margin-left: 1rem !important;
    width: 100% !important;
}
</style>