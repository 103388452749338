<template class="margen">
    <ion-header>
            <ion-list-header>
                Selecciona el tipo de bobina 
            </ion-list-header>
    </ion-header>
    <ion-content class="ion-padding ion-margin">
        <ion-list >
            <ion-item button detail="false" class="rollo" @click="creaRollo">
                <ion-label>
                    <h3>Rollo de cable</h3>
                    <p>No hay bobina, es un rollo.<br/>Generar codigo de rollo</p>
                </ion-label>
            </ion-item>

            <ion-item class="bobinas" button detail="false" v-for="(bob, index ) in bobinas" :key="index" @click="emitir(bob)">
                <ion-label>
                    <h3>{{bob.sku}}</h3>
                    <p>{{bob.nombre}}</p>
                    <!-- 
                        <ion-note>Precio estandar: {{$filters.sDecimal(bob.precom)}} €/bob</ion-note>
                    -->

                </ion-label>
            </ion-item>
        </ion-list>

    </ion-content>
    <ion-footer></ion-footer>
</template>

<script>
    import { defineComponent } from 'vue';
    import eventBus from "@/event-bus"; 
    export default defineComponent({
        name : "SelectorBobinaComponent",
        props : ['bobinas'] , 
         methods :{
            async emitir(bob) {
                eventBus().emitter.emit('bobina-seleccionada', bob.sku, bob.nombre,   'bobina')
            }, 
            async creaRollo(){
                const rollo = `ROLLO:${this.$pdv}:${Date.now()}`
                eventBus().emitter.emit('bobina-seleccionada', rollo, undefined,   'rollo')
            }
        }

    })
</script>

<style scoped lang="scss">


    .margen {
        border: 1px solid var(--ion-border-color) !important;
        margin-bottom: 24rem !important; 
    }

    ion-item.rollo  {
        --background: var(--ion-color-light) !important;
        font-weight: 600 !important;
        --color: var(--ion-color-dark) !important; 

    }
    ion-header {
        border-bottom: 1px solid var(--ion-color-medium) !important; 
    }
    ion-list-header {
        font-size: large !important; 
        padding-bottom: 1rem !important; 
        color: var(--ion-color-dark ) !important;     
        font-weight: 800 !important;
    }
    p {
        font-size: smaller !important; 
    }
    ion-note {
        font-size: 80% !important; 
        font-weight: 500 !important;
    }
</style>