<template class="margen">
    <ion-header>
            <ion-list-header>
                Selecciona una ruta de entrega
            </ion-list-header>
    </ion-header>
    <ion-content class="ion-padding ion-margin">
        <ion-list >

            <ion-item class="direcciones" button detail="false" v-for="(ruta_pdv, index ) in rutas_pdv" :key="index" @click="emitir(ruta_pdv)">
                <ion-label>
                    <h3>{{ruta_pdv.deszon}}</h3>
                </ion-label>
            </ion-item>
        </ion-list>

    </ion-content>
    <ion-footer></ion-footer>
</template>

<script>
    import { defineComponent } from 'vue';
    import eventBus from "@/event-bus"; 
    export default defineComponent({
        name : "SelectorRutaEntregaComponent",
        props : ['rutas_pdv'] , 
        methods :{
            async emitir(ruta) {
                eventBus().emitter.emit('ruta-seleccionada', ruta)
            }, 
        }

    })
</script>

<style scoped lang="scss">


    .margen {
        border: 1px solid var(--ion-border-color) !important;
        margin-bottom: 24rem !important; 
    }

    ion-header {
        border-bottom: 1px solid var(--ion-color-medium) !important; 
    }
    ion-list-header {
        font-size: large !important; 
        padding-bottom: 1rem !important; 
        color: var(--ion-color-dark ) !important;     
        font-weight: 800 !important;
    }
    p {
        font-size: smaller !important; 
    }
    ion-note {
        font-size: 80% !important; 
        font-weight: 500 !important;
    }
</style>