<template>
    <ion-page>
        <ion-header>
            <ion-toolbar  color="primary" class="ion-justify-content-start ion-align-items-start"> 
            <ion-title>
                    <ion-row  class="ion-justify-content-start ion-align-items-start">
                        <ion-col size="40"  class="ion-float-left izquierda"                     
                            :style="isIOS ? 'padding-left: 4rem !important;': ''">
                            Resultados de la busqueda 
                        </ion-col>
                    </ion-row>                
                </ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-header>
            <ion-toolbar color="primary" style="padding-top:8px;" class="top">
                <ion-searchbar  v-model="search_manually"
                    @keyup.enter="buscar_productos(false)"
                    @ionClear="onCancel($event)"
                    autocomplete="off" autocorrect="off" 
                    autocapitalize="off" spellcheck="false"
                    show-clear-button="always"
                    :placeholder="'Buscar productos'" 
                    slot="start"
                    mode = "md">
                </ion-searchbar>       
            </ion-toolbar>

        </ion-header>

        <ion-content class="ion-padding">
            <no-search-results-spellcheck-component :busqueda="busqueda" v-if="num_alternativas === 0 && num_alternativas !=undefined "/> 

            <ion-row v-if="busqueda && lista_articulos.length>0">

                <ion-row class="ion-no-padding" v-if="etiquetas && showme">
                    <ion-col size="44" text-right>
                        <p color="info">
                            Tu busqueda "{{busqueda}}" ha generado <span style="font-size:larger"><b>{{datos.response.numFound }} resultados </b></span>
                            <br/>
                            Solo se muestran los {{Math.min(limite, lista_articulos.length)}} primeros
                        </p>            
                    </ion-col>
                </ion-row>


                <ion-list v-if="showme" class="ion-padding-left ion-padding-right">       
                    <ion-item 
                        v-for="(product,index) in lista_articulos.slice(0,limite)"
                        v-bind:key="`item-${index}`"
                        class="ion-no-padding ion-no-margin">        
                        <ion-row class="ion-no-padding ion-no-margin">    
                            <ion-col size-offset="1" size="47"  class="ion-no-margin  ion-no-padding">
                                <h3 @click = "cerrar_busqueda(product)" >{{product['sku']}}</h3>
                                <h4 @click = "cerrar_busqueda(product)" class="nombre_cortado" text-wrap><b>{{product.shortDescription}}</b></h4>
                                <p  @click = "cerrar_busqueda(product)" class="prod" text-wrap>de <span text-capitalize>{{product.ManufacturerName}}</span></p>
                            </ion-col>

    
                        </ion-row>
                    </ion-item>
                </ion-list>
            </ion-row>
        </ion-content>

        <ion-footer>
            <ion-toolbar >
                <ion-buttons slot="end" class="completo"  >    
                    <ion-button class ="completo"  expand ="block" fill="solid" color="primary" size="normal" @click="cerrar_busqueda(undefined)"> 
                        Cancelar
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-footer>




    </ion-page>
</template>


<script>

    
    import { defineComponent } from 'vue';
    import eventBus from "@/event-bus";
    import NoSearchResultsSpellcheckComponent from '@/components/search/no-search-results-spellcheck-component.vue'

    export default defineComponent({
        name: 'SearchProductComponent',
        components: { 
            NoSearchResultsSpellcheckComponent
            }, 
        props: ['search', 'isEan'],
        data() {
            return {
                pdv: undefined, 
                showme :false, 
                iconos :{'Promociones' : 'promo', 'Ofertas' :'oferta',  'Outlet': 'outlet'},
                filtrados: undefined, 
                busqueda: undefined, 
                search_manually: undefined ,
                datos: [], 
                lista_articulos : [], 
                etiquetas: undefined, 
                limite: 10, 
                num_alternativas : undefined, 
                ean_sin_resultados: undefined 
            }
        },
        created() {
                this.pdv =  this.$route.meta.pdv ;
                this.isIOS = this.$route.meta.isIOS ;
                window.fake_console('seleccion ubicaciones: entro en created', this.$route.meta.pdv)
        },
        mounted (){
            window.fake_console('mounted' , this.$route)
            this.onCancel()
            window.fake_console(this.search)
            this.buscar_productos(this.isEan)
            var self = this
            eventBus().emitter.once('buscar-alternativa',(alternativa)=>{ 
                self.num_alternativas = undefined;
                self.search = alternativa 
                self.buscar_productos(false)
                });   

        },
        updated (){
            window.fake_console('updated' , this.$route)
            this.onCancel()            
        },
        methods : {
            onCancel(){
                window.fake_console('en el cancel ')
                this.busqueda = undefined; 
                this.lista_articulos = []
                this.datos= undefined; 
                this.etiquetas= undefined;
                this.limite= 5; 
                this.num_alternativas = undefined; 
            },
            async searchProducts (busqueda){
                try 
                {
                    window.fake_console(this.pdv)
                    /** Solo un documento (por agilidad) o no -para cuando miramos solo facetas y categorias-  */
                    /** Limitamos a 5 con caracter general */
                    let url  = `/buscar/${this.pdv}?q=${busqueda}&rw=5`
                    window.fake_console(url)
                    return this.$http.get(url)
                }
                catch(ex) {
                    return null 
                }
            },
            async buscar_productos(esEAN){
                let busca 
                /** Si se trata de un EAN primero recuperamos palabras clave de google  */
                if (esEAN) {
                    const goog = await this.$http.post(`/google-ean/${this.search}`)
                    busca = goog.data
                    window.fake_console(goog.data)
                    
                }
                this.busqueda = esEAN ? busca : (this.search_manually !=undefined && this.search_manually != '' ) ? this.search_manually  : this.search
                if (this.busqueda && this.busqueda.length >=3) 
                    {
                        this.ean_sin_resultados = esEAN ? this.search : undefined; 
                        window.fake_console(esEAN, busca, this.ean_sin_resultados)
                        this.showme=false;
                        let result = await this.searchProducts(this.busqueda.toLowerCase())
                        this.datos =result.data 
                        window.fake_console(this.datos)
                        this.num_alternativas = this.datos.response.numFound 
                        if (this.datos.response.numFound>0)
                            {                            
                            this.lista_articulos = this.datos['response']['docs']
                            this.filtrar_elementos()
                            }                           
                        this.showme = true;
                    }
                else {
                    window.fake_console(this.busqueda)
                    window.fake_console('ESTOY AQUI!!!!!!!')
                    /** cierro sin resultados, redirijo al buscador manual  */
                    eventBus().emitter.emit('cerrar-busqueda',this.search)
                }
                }, 
            filtrar_elementos() {
                this.showme = false
                window.fake_console(this.lista_articulos.length)
                this.filtrados = this.lista_articulos.slice(0,this.limite)
                this.showme = true
            },

            cerrar_busqueda(el){
                window.fake_console('saliendo de la pagina de busqueda : ', el)
                const respuesta = el == undefined ? undefined : el.sku 
                window.fake_console(respuesta, this.ean_sin_resultados)
                eventBus().emitter.emit('cerrar-busqueda',respuesta , this.ean_sin_resultados)
            },
}

    });
</script>




 
<style scoped lang="scss">
    @import "@/theme/footer-styles.scss";
    ion-buttons.completo {
        width : 50% !important; 
    }
    h3, h4, h5, p {
        font-size:smaller !important; 
        margin-top: 3px !important; 
        margin-bottom: 3px !important; 
    }
    h4.nombre_cortado {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
    }

    .nombre_cortado {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
    }

    img.producto{
        margin: 5px !important;
        height: 90px;
        width: 90px;
        max-width: 90px;
        max-height: 90px;
        object-fit: contain;
        margin:auto;
    }

    ion-searchbar {
        width: 100% !important;
        margin-left: 6px !important;
    }
    ion-toolbar.top {
        margin-bottom: -0.4rem !important;
        padding-bottom: -0.4rem !important;
    }

    ion-title {
        width: 100% !important;
        padding-right: 4px;
    }
    .izquierda {
        text-align:left; 
    }


    ion-row {
        max-width: 600px !important; 
        padding: 0.5rem 1rem !important; 
    }
 
    ion-item, ion-list {
        width: 100% !important; 
        margin: auto !important; 
    }
    ion-list-header {
        color: var(--ion-color-primary) !important;
        border-bottom: solid 1px var(--ion-color-light) !important; 
        padding-bottom: 4px !important;
    }



</style>