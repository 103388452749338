import eventBus from "@/event-bus";
import { esUbicacion, teclasFuncion } from '@/utilities/global-constants';
import {  openAlertaV2 } from '@/utilities/services/alert-utilities'


const consola = function(){ void(0) }; //const consola = window.miconsola
let contador = 0 
let ubicacion , bindLector


/** 
 * Funcion de escaneado de ubicaciones  
 * Activa el listener para escuchar los eventos del escaner 
 * Evalua el resultado 
 *      - NO es ubicacion : Muestra alerta, y pide seguir escaneando o cancelar (todo a estado original)
 *      - Si es ubicacion : Nos avisa de que vamos a cambiar la ubicación activa, si hay trabajo realizado quedará en memoria para más tarde 
 * */



    /* Alerta ubicación Erronea          */
    const UbicacionErronea = async function(ubicacion, elId ) {
        consola('Abriendo alerta ubicacion erronea')
        await openAlertaV2(
                'Lectura errónea', 
                `La lectura "${ubicacion}" no corresponde a una ubicación.\n 
                Lee una ubicación para continuar `
        )
        await resetLecturaEmitter(elId)      
    }

    /** Listener, tiene que estar con la función para poder escupir datos a la variables compartida  */
        const scanListenerUbicacion = function(elId, e ) {
            consola(elId, e)
            if (e.keyCode == 13) {
                e.preventDefault();
                e.stopPropagation();
                consola(ubicacion)
                eventBus().emitter.emit(`lectura:${elId}`, ubicacion)
                consola(ubicacion)
                ubicacion = '';
            }
            else  {
                /*ubicacion+= e.keyCode == 32 ? ' ' : e.keyCode!=16 ? e.key : '';*/
                ubicacion+= e.keyCode == 32 ? ' ' : teclasFuncion.indexOf(e.keyCode)==-1 ? e.key : '';
            }
        }
    

/** FUNCION DE ESCANEADO  */
const scanUbiStart =   async function (elId) { 
    ubicacion = '';
    bindLector = scanListenerUbicacion.bind(null, elId)
    document.addEventListener(`keydown`, bindLector);   
    contador+=1
    consola('CONTADOR SUBE : '+contador)
    resetLecturaEmitter(elId)
    }

const resetLecturaEmitter = async function (elId) {
    ubicacion = ''
    eventBus().emitter.off(`lectura:${elId}`)
    window.miconsola(`Reset del emitter del Emitter de Lectura ${elId} `)
    eventBus().emitter.once(`lectura:${elId}`, async  function (ubicacion) {
        consola(`Ubicacion escaneada: verificamos lectura lectura:${elId} | ${ubicacion}`)
        if (esUbicacion.test(ubicacion)) {
            consola('Dentro de esUbicacion "SI"')
            eventBus().emitter.emit(`ubicacion:${elId}`,ubicacion); 
            ubicacion = '';
            return;
        }
        else {
            consola('Dentro de esUbicacion "NO"')
            await UbicacionErronea(ubicacion, elId)
        }
    })

}

const scanUbiStop = async function(elId) {
    window.miconsola('he parado el escaner de ubicaciones ')
    ubicacion= '';    
    try {
        document.removeEventListener(`keydown`,bindLector);
        eventBus().emitter.off(`ubicacion:${elId}`)
        eventBus().emitter.off(`lectura:${elId}`)
        contador-=1
        consola('Saliendo, contador baja : '+contador)
        return true
    }
    catch (ex) {
        consola(ex);
        return false 
    }
}



    
export { scanUbiStart, scanUbiStop      }

