<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button fill="outline" color="medium" @click="cancelChanges()">Cancelar</ion-button>
      </ion-buttons>
      <ion-title style="text-align: center;">{{ $props.filterName }}</ion-title>
      <ion-buttons slot="end">
        <ion-button fill="solid" color="primary" @click="confirmChanges()">Confirmar</ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-toolbar>
      <ion-row>
        <ion-col>
          <ion-searchbar v-if="filterName != 'Fecha de envío'" @ionInput="searchbarInput($event)"></ion-searchbar>
          <ion-row v-else>
            <ion-col style="text-align: center;">
              <ion-button id="initialDate">{{formatDate(initialDate)}}</ion-button>
              <ion-popover trigger="initialDate" size="auto">
                <ion-datetime :value="formatDate(initialDate, true)" 
                :min="formatDate(filteredItemsArr[0], true)" 
                :max="formatDate(filteredItemsArr[filteredItemsArr.length - 1], true)"
                presentation="date"
                @ionChange="changeInitialDate($event)"></ion-datetime>
              </ion-popover>
            </ion-col>
            <ion-col style="text-align: center;">
              <ion-button id="finalDate">{{formatDate(finalDate)}}</ion-button>
              <ion-popover trigger="finalDate" size="auto">
                <ion-datetime :value="formatDate(finalDate, true)" 
                :min="formatDate(filteredItemsArr[0], true)" 
                :max="formatDate(filteredItemsArr[filteredItemsArr.length - 1], true)" 
                presentation="date"
                @ionChange="changeFinalDate($event)"></ion-datetime>
              </ion-popover>
            </ion-col>
          </ion-row>
        </ion-col>
        <ion-col style="display: grid; place-items: center;" size="5">
          <ion-checkbox @ionChange="selectAll($event)"
          :checked="checkSituation"
          :indeterminate="indeterminateCheck"
          ></ion-checkbox>
        </ion-col>
      </ion-row>
    </ion-toolbar>
  </ion-header>

  <ion-content style="height: calc(100vh - 112px);"> <!-- 112 px por que es lo que mide el header-->
    <ion-list id="modal-list" :inset="true">
      <ion-item v-for="item in itemsArr" :key="item" v-show="filteredItemsArr.includes(item)">
        <ion-label v-if="filterName === 'Fecha de envío'">
          {{ formatDate(item) }}
        </ion-label>
        <ion-label v-else>{{ item }}</ion-label>
        <ion-checkbox :value="item" false :checked="isChecked(item)" @ionChange="checkboxChange($event)">
          {{ item }}
        </ion-checkbox>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
  import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonTitle,
  IonSearchbar,
  IonToolbar,
  IonRow,
  IonCol,
  IonDatetime,
  IonPopover
} from "@ionic/vue"
import { defineComponent, ref } from "vue"

export default defineComponent({
  props: {
    filterName: String,
    items: Array,
    filteredItems: Array,
    selectedItems: Array
  },
  emits: ["selection-cancel", "selection-change"],
  components: {
    IonButton,
    IonButtons,
    IonCheckbox,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonTitle,
    IonSearchbar,
    IonToolbar,
    IonRow,
    IonCol,
    IonDatetime,
    IonPopover
  },
  setup(props, { emit }) {
    const items = ref([...props.items])
    const filteredItems = ref([...props.filteredItems])
    const workingSelectedValues = ref([...props.selectedItems])
    const initialDate = ref(filteredItems.value[0])
    const finalDate = ref(filteredItems.value[filteredItems.value.length - 1])
    const checkSituation = ref(false)
    const indeterminateCheck = ref(false)

    const changeInitialDate = ev => {
      initialDate.value = parseInt(ev.target.value.replaceAll('-', ''))
      checkDates()
    }

    const changeFinalDate = ev => {
      finalDate.value = parseInt(ev.target.value.replaceAll('-', ''))
      checkDates()
    }

    const checkDates = () => {
      let filteredDates = items.value.filter(item => {
        return initialDate.value <= item && finalDate.value >= item
      })

      workingSelectedValues.value = [...filteredDates]
    }

    const isChecked = value => {
      return (
        workingSelectedValues.value.find(item => item === value) !== undefined
      )
    }

    const cancelChanges = () => {
      emit("selection-cancel")
    }

    const confirmChanges = () => {
      emit("selection-change", workingSelectedValues.value)
    }

    const searchbarInput = ev => {
      filterList(ev.target.value)
    }

    const formatDate = (dateStr, isPicker = false) => {
      let fechaStr = dateStr.toString()

      if (fechaStr.length == 8 && !isPicker) {
        let year = fechaStr.substring(0, 4)
        let month = fechaStr.substring(4, 6)
        let day = fechaStr.substring(6)
        
        return `${day}/${month}/${year}`

      } else if (fechaStr.length == 8 && isPicker) {
        let year = fechaStr.substring(0, 4)
        let month = fechaStr.substring(4, 6)
        let day = fechaStr.substring(6)

        return `${year}-${month}-${day}`

      } else {
        return fechaStr
      }
    }

    const selectAll = (ev) => {
      const { checked } = ev.detail
      
      if (checked) {
        workingSelectedValues.value = [...items.value]
      } else {
        workingSelectedValues.value = []
      }

      ev.preventDefault();
    }

    /**
     * Update the rendered view with
     * the provided search query. If no
     * query is provided, all data
     * will be rendered.
     */
    const filterList = searchQuery => {
      /**
       * If no search query is defined,
       * return all options.
       */
      if (searchQuery === undefined || searchQuery === "") {
        items.value = [...props.items]
      } else {
        /**
         * Otherwise, normalize the search
         * query and check to see which items
         * contain the search query as a substring.
         */
        const normalizedQuery = searchQuery.toLowerCase()
        items.value = props.items.filter(item => {
          return item.toLowerCase().includes(normalizedQuery)
        })
      }
    }

    const checkboxChange = ev => {
      const { checked, value } = ev.detail

      if (checked && !isChecked(value)) {
        workingSelectedValues.value = [...workingSelectedValues.value, value]
      } else if (!checked && isChecked(value)) {
        workingSelectedValues.value = workingSelectedValues.value.filter(
          item => item !== value
        )
      }

      if (workingSelectedValues.value.length == filteredItems.value.length) {
        checkSituation.value = true
        indeterminateCheck.value = false
      } else if (workingSelectedValues.value.length > 0 && workingSelectedValues.value.length < filteredItems.value.length) {
        checkSituation.value = false
        indeterminateCheck.value = true
      } else {
        checkSituation.value = false
        indeterminateCheck.value = false
      }
    }

    return {
      itemsArr: items,
      filteredItemsArr: filteredItems,
      workingSelectedValues,
      initialDate,
      finalDate,
      isChecked,
      cancelChanges,
      confirmChanges,
      searchbarInput,
      checkboxChange,
      formatDate,
      changeFinalDate,
      changeInitialDate,
      checkSituation,
      indeterminateCheck,
      selectAll
    }
  }
});
</script>

<style>

.centro {
  text-align: center !important;
  width: 100%;
}

ion-popover {
  --width: auto !important;
}

</style>