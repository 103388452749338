
    import { alertController } from '@ionic/vue';
    import { generarPdfEntrega } from '@/pages/expediciones/components/generarPdfEntrega';
  
    import {  ValidarDNI }  from '@/utilities/global_functions'
    import { entregaDistance, entregaPdvDistance } from '@/utilities/location';
    import { pluck } from 'underscore';

    import { IonicSafeString } from '@ionic/core';
    import SignaturePad from 'signature_pad';    

    const consola = function(){ void(0) }; //consola    

    



  const openAlertaEntregaFirma = async function (myhttp, envio , pdv_geoloc){
    const {lat_env, lon_env} = envio
    let cerca 
    if( envio.envio_tipo == 'REPARTO') {
        cerca = await entregaDistance([lat_env, lon_env])
      }
    else 
      {
        cerca = await entregaPdvDistance([pdv_geoloc[0], pdv_geoloc[1]])
      }
    
    window.fake_console(cerca)
    if (!cerca) { return }
    if (cerca && cerca !=true && cerca.localizacion!='pdv') {
        const {latitude, longitude} = cerca; 
        const { codemp, codcli, domenv, cpenv, pobenv } = envio 
        const payload = { codemp, codcli, domenv, cpenv, pobenv , lat_env :latitude, lon_env : longitude }
        try { await myhttp.post(`/set_delivery_pointer/${codemp}`, payload) }
        catch { alert('error !!!!! L464 expedir-utilities')}
        }

        const mis_inputs = [            
          {
            name: 'bultos',
            type: 'number',
            placeholder: 'Bultos entrega *',
            caso : ['REPARTO','RECOGIDA','AGENCIA_GES','AGENCIA_CLIENTE' ]
          },
          {
            name: 'nombre',
            type: 'text',
            required : true, 
            placeholder: 'Nombre del receptor *',
            caso : ['REPARTO','RECOGIDA','AGENCIA_GES','AGENCIA_CLIENTE' ]
          },
          {
            name: 'nif',
            type: 'text',
            placeholder: 'Nif del receptor',
            caso : ['REPARTO','RECOGIDA','AGENCIA_GES','AGENCIA_CLIENTE' ]
          },
          {
            name: 'incidencia_nif',
            type: 'text',
            placeholder: 'Motivo para no facilitar nif',
            caso : ['REPARTO','RECOGIDA','AGENCIA_GES','AGENCIA_CLIENTE' ]
          },
          {
            name: 'agencia',
            type: 'text',
            required : true, 
            placeholder: 'Agencia',
            caso : ['AGENCIA_GES','AGENCIA_CLIENTE' ]
          },
          {
            name: 'codexp_agencia',
            type: 'text',
            required : true, 
            placeholder: 'Codigo expedicion de la agencia',
            caso : ['AGENCIA_GES','AGENCIA_CLIENTE' ]
          },
          {
            type :"textarea", 
            value : '',  
            name : "error_blt",
            id : 'id_blt_error_box', 
            disabled: true,
            cssClass : 'etiqueta-error mediano'
          }
        ]
        console.log(envio.albaranes)
        console.log(typeof(envio.albaranes))
        envio.albaranes+=(';12345678')
        const albs = envio.albaranes.split(';').map( x => `<ion-col size="12" class="col-in-alert ion-align-items-center"><span>${x}</span></ion-col>`).join('')
        const albs_html = `<ion-row class="row-in-alert">${albs}</ion-row>`
        const inputs = mis_inputs.filter(x =>x.id =='id_blt_error_box' || x.caso.indexOf(envio.envio_tipo)!=-1)
        const head = `${envio.ms_uuid}` 
        const msg = `<b>${envio.codemp}.${envio.codcli} - ${envio.nomcli}</b><br/> 
                    <small>Relacion de albaranes entregados : </small><br/>
                    ${albs_html}
                    <div id="qr-entrega"></div>
                    <canvas id="canvas-pdf" width="250" height="450" hidden></canvas>
                    <canvas id="canvas-firma"></canvas>
                    
        `
        /**Si quieres guardar una imagen, clicka en 'Imagen' antes de confirmar.  */
        const alerta = await alertController
            .create({
                header: head, 
                message: new IonicSafeString(msg),
                mode : "md",
                cssClass: 'custom-firmas-alert',
                backdropDismiss: false, 
                inputs : inputs, 
                buttons: [  
                  {
                    text: 'Cancelar',
                    role: 'cancel'                
                  },
                  {
                    text: 'Grabar Entrega',   
                    role: 'confirm', 
                    async handler(data) {
                      const nif = data.nif.toUpperCase().replace(/[^A-Z0-9]/g,'')
                      console.log(nif)
                      const nif_valido = ValidarDNI(nif)
                      let control_error = document.getElementById('id_blt_error_box')       
                      if (!data.bultos || parseInt(data.bultos) != envio.bultos) {
                        control_error.value = 'Numero de bultos incorrecto, revisa'
                        return false 
                      }
                      else if (!nif_valido ||nif_valido===false) {
                        control_error.value = 'Nif no informado o invalido. En breve no será posible entregar sin facilitar nif. Si quieres evitar este bloqueo apreta "NO FACILITA NIF"'
                        return false              
                      }
                      else { 
                        const requeridos = pluck(inputs.filter(x=>x.required===true),'name')
                        const validacion = requeridos.reduce( (m,v)=> {
                          return m+= (!data[v]||data[v].length==0) ? 1 : 0 
                        },0)
                        consola(requeridos, validacion )
                        if (validacion !=0) {
                          control_error.value = 'Faltan campos obligatorios '
                          return false
                        }
                        data.has_nif = 1; 
                        console.log(firma)
                        console.log(canvas.width, canvas.height)
                        envio.firmado = firma.toDataURL();
                        console.log(envio.firmado)
                        const {has_nif, nif , nombre} = data
                        envio = {...envio, has_nif, nif, nombre}
                        console.log(' entreo en la generacion del pdf ')
                        await generarPdfEntrega(envio, canvas.width, canvas.height)
                        console.log('salgo de la generación del pdf')


                        return true
                      }
                    }
                  },            
                  {
                    text: 'NO FACILITA NIF',   
                    role: 'no_nif', 
                    handler(data) {
                        let control_error = document.getElementById('id_blt_error_box')       
                        if (!data.bultos || parseInt(data.bultos) != envio.bultos) {
                          control_error.value = 'Numero de bultos incorrecto, revisa'
                          return false 
                        }
                        else { 
                          const requeridos = pluck(inputs.filter(x=>x.required===true),'name')
                          const validacion = requeridos.reduce( (m,v)=> {
                            return m+= (!data[v]||data[v].length==0) ? 1 : 0 
                          },0)
                          consola(requeridos, validacion )
                          if (validacion !=0) {
                            control_error.value = 'Faltan campos obligatorios '
                            return false
                          }
                          data.has_nif = 0 
                          return true
                        }
                    }
                  }               ]
            })
        await alerta.present();
        const canvas = document.getElementById("canvas-firma");
        var ratio = Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d").scale(ratio, ratio);
        let firma = new SignaturePad(canvas);
        const {data, role} = await alerta.onDidDismiss();
        consola(data)
        if (role=='confirm'|| role =='no_nif'){
            data.values['firmado'] = firma.toDataURL();
            console.log(data.values)
            return data.values
        }
        else {
          return undefined
        }
      }











export {   openAlertaEntregaFirma
    }

