<template>
        <!--
        <p v-if="datos_lote" 
            v-html="JSON.stringify( datos_lote).replace(/,/g,',<br/>')">
        </p>
        -->
    
    <ion-list>
        <ion-list-header lines ="inset">
            <ion-row>
                <ion-col size="48" class="titulin">
                    {{sku}}
                </ion-col>
                <ion-col size="48" class="unidades_pedido">
                    <ion-label class="unidades_pedido">
                        {{$filters.Entero(unidades_pedido)}} pendientes
                    </ion-label>
                </ion-col>
            </ion-row>
        </ion-list-header>


        <!-- UNIDADES ALBARANADAS --> 
        <RecepcionInputComponent 
            titulo="Albaranadas"
            nombre="uds_alb"
            modo="decimal"
            decimales="2"
            placeholder = "Uds. albaran"
            v-on:update:uds_alb="datos_lote.uds_alb = $event"
            descripcion="Introduce las unidades del albaran "
            />
        <!-- alerta error unidades albaran-->
         <ion-item class="sin-borde pure-form alerta"  mode="ios"
                v-if="datos_lote.uds_alb && datos_lote.uds_alb>unidades_pedido">
                    <ion-label class="alerta">
                            Cantidad mayor a pendiente {{$filters.sDecimal(uds_ped)}} ,<br/>
                            si es correcto marca para validar la linea<br/>
                            sino edita la cantidad 
                    </ion-label>
                    <ion-toggle class="alerta" color="darkdanger" slot="end" v-model="cantidad_albaran"></ion-toggle>
            </ion-item>


        <!-- UNIDADES RECIBIDAS --> 
        <RecepcionInputComponent 
            titulo="Recibidas"
            nombre="uds_rec"
            modo="decimal"
            decimales="2"
            placeholder = "Uds. recepcion"
            v-on:update:uds_rec="datos_lote.uds_rec = $event"
            v-on:request:from_child:uds_rec="copiaUds($event)"
            copy_from="uds_alb"
            descripcion="Introduce las unidades recibidas "
            />
            <!-- ALERTA DE UNIDADES-->
            <ion-item class="sin-borde pure-form alerta"  mode="ios"
                v-if="datos_lote.uds_rec && datos_lote.uds_rec!=(datos_lote.uds_alb||datos_lote.uds_rec)">
                    <ion-label class="alerta">
                            Cantidad recibida diferente a albaranada,<br/>
                            si es correcto acepta en el boton adjunto y<br/>
                            marca el tipo de incidencia a generar 
                    </ion-label>
                    <ion-toggle class="alerta" color="darkdanger" slot="end" v-model="cantidad_recibida"></ion-toggle>
            </ion-item>



        <!-- SELECCIONAR LOTE O GENERAR ROLLO-->
        <ion-item  class="sin-borde pure-form"> 
            <ion-button expand ="block" fill="solid"  class="recepcion"
                color="darksuccess" 
                size="normal" 
                slot="start"
                @click="openBobinas"
                > 
                <span class="texto-boton-grande">Bobina</span>
            </ion-button>
                        
            <ion-input  v-model="datos_lote.bobina" class="recepcion"
                inputmode="text"
                type="text"
                readonly
                >   
            </ion-input>
            
        </ion-item>      

        <!-- LEER LOTE SI NO ES UN ROLLO GENERADO-->
        <RecepcionInputComponent 
            titulo="Lote"
            nombre="numlot"
            placeholder = "Lote"
            :pagina_origen = "element_id"
            v-on:update:numlot="datos_lote.numlot = $event"
            descripcion="Introduce o escanea el lote "
            modo="text"
            />

        <!-- ACCION EN CASO DE INCIDENCIA --> 
        <AccionIncidenciaEntradaComponent :uds_alb="datos_lote.uds_alb" :uds_rec="datos_lote.uds_rec"
            :key="`idx:${datos_lote.uds_alb}.${datos_lote.uds_rec}`"
            v-if="cantidad_recibida"
            v-on:update:radio-button-accion="resuelveAccion($event)"
            />





    </ion-list>

           <ion-toolbar >
                <ion-buttons slot="start" class="completo"  >    
                    <ion-button class ="completo"  expand ="block" fill="solid" 
                        color="darkdanger" size="normal" @click="CreaLote(undefined)"
                        >   
                        Descartar
                </ion-button>
                </ion-buttons>
                <ion-buttons slot="end" class="completo"  >    
                    <ion-button class ="completo"  expand ="block" fill="solid" 
                        id="recepcion_boton_creacion_field"
                        color="primary" size="normal" @click="CreaLote(datos_lote)"
                        :disabled = "incompleto"
                        > 
                        Crear Lote
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>



</template>

<script>
    import { defineComponent } from 'vue';
    import { codigos_bobinas} from '@/utilities/scanner/scan-sku';
    import RecepcionInputComponent  from '@/pages/recepcionar/components/RecepcionInputComponent.vue' ;
    import SelectorBobinaComponent  from '@/pages/recepcionar/components/SelectorBobinaComponent.vue' ;
    import AccionIncidenciaEntradaComponent  from '@/pages/recepcionar/components/AccionIncidenciaEntradaComponent.vue' ;
    
    import eventBus from "@/event-bus"; 
    import {  modalController } from '@ionic/vue';
    export default defineComponent({
        name : "NuevoLoteComponent",
        components: { RecepcionInputComponent, AccionIncidenciaEntradaComponent   } , 
        props : ['codpro', 'sku', 'uds_ped', 'albaran', 'recepcion']  ,
        data() { 
                return { 
                    element_id: 'nuevo-lote-recepcion',
                    pdv: undefined,
                    bobinas : undefined, 
                    datos_lote : {
                        tipo: undefined, 
                        bobina: undefined, 
                        nombre: undefined, 
                        numlot: undefined, 
                        uds_alb: undefined, 
                        uds_rec: undefined, 
                    }, 
                    cantidad_albaran: false, 
                    cantidad_recibida:  false, 
                    unidades_pedido: undefined, 
                    pmp: undefined
                    }
            },
        watch: {
            'datos_lote.uds_alb'() { 
                const {uds_alb, uds_rec} = this.datos_lote 
                this.cantidad_albaran= false; 
                this.datos_lote.accion= uds_alb == uds_rec
            },
            'datos_lote.uds_rec'() { 
                const {uds_alb, uds_rec} = this.datos_lote 
                this.cantidad_recibida = false; 
                this.datos_lote.accion= uds_alb == uds_rec
            }
        },
        created() {
                this.bobinas = codigos_bobinas[this.codpro]||codigos_bobinas[0]
        }, 
        computed :{
            incompleto: function () {
                const dat = this.datos_lote 
                if(dat.tipo === 'rollo') { 
                    delete dat.bobina 
                    delete dat.nombre 
                    }
                const valores = Object.values(dat);     
                /** validaciones de datos  */
                const control_albaran = this.cantidad_albaran||this.datos_lote.uds_alb<=this.unidades_pedido;
                const control_recibidas = this.cantidad_recibida||this.datos_lote.uds_rec ==this.datos_lote.uds_alb
                valores.push(...[control_albaran,control_recibidas])
                return valores.some(x=>!x)
                }, 
            }, 
        async mounted() {
            if (this.albaran) { eventBus().emitter.emit('uds_alb:from_parent:update' , this.albaran.toString())}
            if (this.recepcion) { eventBus().emitter.emit('uds_rec:from_parent:update' , this.recepcion.toString())}
            this.bobinas = codigos_bobinas[this.codpro]||codigos_bobinas[0];
            this.pmp = this.bobinas.reduce((m,v)=> {m[v['sku']]= v['pmp_ges']; return m } ,{})
            window.fake_console(this.bobinas)
            window.fake_console(this.pmp )
            this.unidades_pedido = this.uds_ped||0

            
            },
        methods :{
            resuelveAccion(e){
                if(e=="I")  {
                    this.copiaUds({origen: 'uds_alb', destino: 'uds_rec' })
                }
                else {
                    this.datos_lote.accion = e
                }
            }, 
            copiaUds(e){
                window.fake_console(`${e.destino}:from_parent:update` , this.datos_lote[e.origen])
                eventBus().emitter.emit(`${e.destino}:from_parent:update` , this.datos_lote[e.origen])
            },  
            async CreaLote (lot) {
                eventBus().emitter.emit('creacion-lote-nuevo',lot)
            }, 

            async openBobinas() {
                    const modal = await modalController
                        .create({
                            component: SelectorBobinaComponent ,
                            cssClass: 'custom-bobinas-modal',
                            componentProps: {bobinas: this.bobinas},
                            })
                        modal.present()
                    eventBus().emitter.once('bobina-seleccionada',(codigo, nombre,    tipo)=>{
                        modal.dismiss();
                        this.datos_lote.tipo = tipo
                        this.datos_lote.nombre = nombre
                        if(tipo=='bobina') {
                            this.datos_lote.bobina=codigo;    
                            this.datos_lote.pmp_ges_bobina = this.pmp[codigo]||0
                        }
                        else {
                            this.datos_lote.bobina = undefined; 
                            this.datos_lote.numlot = codigo 
                        }
                        eventBus().emitter.emit(`${this.element_id}:update` , this.datos_lote)
                        })
            }
        }


        
    })
</script>

<style scoped lang="scss">
    @import "@/theme/recepciones-styles.scss";
    @import "@/theme/alert-popover-styles.scss";
    @import "@/theme/footer-styles.scss";

    ion-col.titulin {
        padding: 0 1rem !important; 
    }
    small {
        color: var(--ion-color-medium) !important; 
        padding-top: 0px !important; 
    }
    ion-list{
        padding: 0px 0.5rem !important; 
        margin: 0px 0.5rem !important; 
    }
    ion-list-header {
        width: 90% !important; 
        padding-left: 0px !important; 
        font-size: x-large !important; 
        color: var(--ion-color-dark) !important; 
        font-weight: 700 !important;
        margin: 1rem !important; 
        --border-color: var(--ion-border-color) !important; 
    }
    

   
</style>

