import { tipoImagen} from '@/utilities/global-constants';
import { Camera, CameraResultType, CameraSource, CameraDirection } from '@capacitor/camera';


const takeDeliveryPhoto = async (myhttp, envio)=> {
    let {codemp, codcli, ms_uuid} = envio 
    let [ex, pdv, fec, id1,id2] = ms_uuid.split(':')
    void ([ex, pdv, fec, id1,id2] )
    var photo 
    const t = Date.now()
    try {
        const permisos = await Camera.checkPermissions()
        window.fake_console(permisos)
        }
    catch(er) {
        window.fake_console(er)
        window.fake_console('error al pedir permisos')
    }
    const cameraPhoto = await Camera.getPhoto({
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Camera,
        direction : CameraDirection.Rear,
        width: 640, /*320*/
        height: 480, /*240,*/
        quality: 100
        });
    photo = cameraPhoto.dataUrl;
    const form = photo.match(tipoImagen).groups['formato']
    const formato = form === 'png' ? 'png' : 'jpg'
    let imagen = photo.replace(tipoImagen,'')
    const fileID = `${codemp}/${codcli||'000'}/${fec.slice(0,4)||'2022'}/${ms_uuid||t}.${formato}`
    try {
        await myhttp.post(`/save_delivery_image/${codemp}`, {imagen , fileID, ms_uuid})
        return true 
    }
    catch(ex) {
        window.fake_console(ex)
        return undefined
    }
}

export {  takeDeliveryPhoto}

