<template>
    <ion-page>
        <CabeceraComponentBack 
            :pdv = "$route.meta.pdv"
            :isIOS = "$route.meta.isIOS"
            titulo="Impresion etiquetas Ubicacion"
            color="primary"
        />

        <ion-content class="ion-padding ion-margin mipag" >
           <ion-row >
                <ion-col id="selector-etiquetas-app">
                    <table class="pure-table pure-table-bordered">
                        <tbody>
                            <tr>
                                <td class="c1">
                                    <ion-title>Zona</ion-title>
                                </td>
                                <td colspan="2"  class="detalle">    
                                    <ion-select v-model="zona" placeholder="Elije zona" interface="action-sheet" mode="ios">
                                        <ion-select-option v-for="(op,index) in opciones" :value="op" :key="index">
                                            {{op}}
                                        </ion-select-option>
                                    </ion-select>
                                </td>

                            </tr>
                            <tr>
                                <td class="c1">    
                                    <ion-title>Pasillo</ion-title>
                                </td>
                                <td colspan="2" class="detalle">    
                                    <ion-select   v-model="pasillo"  placeholder="Elije pasillo" interface="action-sheet" mode="ios">
                                        <ion-select-option v-for="(ps,index) in pasillos" :value="ps" :key="index">
                                            {{`0${ps}`.slice(-2)}}
                                        </ion-select-option>
                                    </ion-select>
                                </td>
                            </tr>
                            <tr>
                                <td class="c1">
                                    <ion-title>Estante</ion-title>
                                </td>
                                <td class="detalle">    
                                    <ion-row>
                                        <ion-label>Desde</ion-label>
                                    </ion-row>
                                    <ion-select  v-model="e0" interface="action-sheet" mode="ios">
                                        <ion-select-option v-for="(ps,index) in estantes" :value="ps" :key="index">
                                            {{`0${ps}`.slice(-2)}}
                                        </ion-select-option>
                                    </ion-select>    
                                </td>
                                <td class="detalle">    
                                    <ion-row>
                                        <ion-label>Hasta</ion-label>
                                    </ion-row>
                                    <ion-select   v-model="e1" interface="action-sheet" mode="ios">
                                        <ion-select-option v-for="(ps,index) in estantes.filter(x=>x>=e0)" :value="ps" :key="index">
                                            {{`0${ps}`.slice(-2)}}
                                        </ion-select-option>
                                    </ion-select>
                                </td>
                            </tr>

                            <tr>
                                <td class="c1">
                                    <ion-title>Altura</ion-title>
                                </td>
                                <td class="detalle">        
                                    <ion-row>
                                        <ion-label>Desde</ion-label>
                                    </ion-row>
                                    <ion-select  v-model="a0" interface="action-sheet" mode="ios">
                                        <ion-select-option v-for="(ps,index) in pasillos" :value="ps" :key="index">
                                            {{`0${ps}`.slice(-2)}}
                                        </ion-select-option>
                                    </ion-select>
                                </td>
                                <td class="detalle">    
                                    <ion-row>
                                        <ion-label>Hasta</ion-label>
                                    </ion-row>
                                    <ion-select v-model="a1" interface="action-sheet" mode="ios">
                                        <ion-select-option v-for="(ps,index) in pasillos.filter(x=>x>=a0)" :value="ps" :key="index">
                                            {{`0${ps}`.slice(-2)}}
                                        </ion-select-option>
                                    </ion-select>
                                </td>
                            </tr>

                            <tr>
                                <td class="c1">
                                    <ion-title>Posicion</ion-title>
                                </td>
                                <td class="detalle">        
                                    <ion-row>
                                        <ion-label>Desde</ion-label>
                                    </ion-row>
                                    <ion-select  v-model="p0" interface="action-sheet" mode="ios">
                                        <ion-select-option v-for="(po,index) in posiciones" :value="po" :key="index">
                                            {{`0${po}`.slice(-2)}}
                                        </ion-select-option>
                                    </ion-select>
                                </td>
                                <td class="detalle">    
                                    <ion-row>
                                        <ion-label>Hasta</ion-label>
                                    </ion-row>
                                    <ion-select v-model="p1" interface="action-sheet" mode="ios">
                                        <ion-select-option v-for="(po,index) in posiciones.filter(x=>x>=p0)" :value="po" :key="index">
                                            {{`0${po}`.slice(-2)}}
                                        </ion-select-option>
                                    </ion-select>
                                </td>
                            </tr>


                        </tbody>
                    </table>
                </ion-col>
            </ion-row>
            <ion-row  class="ion-justify-content-end ion-align-items-end ion-padding ion-margin">
                <ion-button  class ="final"  expand ="block" fill="solid"  size="large"  @click="generar()" :disabled="e1==undefined || a1==undefined|| isOnline==false || !$pdv">
                    Imprimir
                </ion-button>
           </ion-row>
        </ion-content>

 

    </ion-page>
</template>

<script>

    
    import CabeceraComponentBack from '@/components/CabeceraComponentBack'
    import { defineComponent } from 'vue';
    import { openToast} from  '@/utilities/services/alert-utilities';
    import {  isOnline}  from '@/utilities/global_functions'
    export default defineComponent({
            name : "ImprimirEtiquetaUbicacionPage",
            components: {  CabeceraComponentBack},
            data() { return {  
                    isOnline, 
                    opciones: ['ALT', 'BOB', 'BOX', 'CAN', 'CRO','DEV' , 'EXP', 'EXT', 'FRO','PAL','PRE', 'REC','RUT','SOT', 'SUE' ,'VER' , 'TIE', 'TUB', 'ZOA', 'ZOB', 'ZOC','ZOD'], 
                    pasillos : [...Array(51).keys()],
                    estantes : [...Array(51).keys()],
                    posiciones : [...Array(26).keys()],
                    zona: 'ZOA', 
                    pasillo : 0, 
                    e0: 0, 
                    e1: undefined, 
                    a0:0,
                    a1: undefined , 
                    p0: undefined,
                    p1: undefined , 

             }},
             methods: {     
                // Generate cartesian product of given iterables:
                * cartesian(head, ...tail) {
                    let remainder = tail.length ? this.cartesian(...tail) : [[]];
                    for (let r of remainder) for (let h of head) yield [h, ...r];
                }, 
                componer (a) {
                    const resu = a.length== 3 
                        ? `${a[0]} ${String(a[1]).padStart(2, '0')} ${String(a[2]).padStart(2, '0')}` 
                        : `${a[0]} ${String(a[1]).padStart(2, '0')} ${String(a[2]).padStart(2, '0')} ${String(a[3]).padStart(2, '0')}`
                    return (resu)
                }, 
                async generar() {
                        await openToast('Lanzando impresiones...', `Lanzando impresiones, te redirigiremos a la pagina seleccion de ubicacion para que continues`, 2500)
                        let self = this; 
                        const pasillo = ('0'+this.pasillo.toString()).slice(-2)
                        let zona = [`${this.zona}${pasillo}`]
                        let estantes = [...Array(51).keys()].filter(v=> v>=this.e0 && v<=this.e1)
                        let alturas = [...Array(51).keys()].filter(v=> v>=this.a0 && v<=this.a1)
                        window.miconsola(this.p0, !this.p0)
                        let posiciones = (this.p0== undefined) ? undefined : [...Array(26).keys()].filter(v=> v>=this.p0 && v<=this.p1)
                        window.miconsola(posiciones, this.p0, this.p1)
                        let resu 
                        if (posiciones && posiciones.length>0) {
                            resu = [...this.cartesian(zona, estantes, alturas, posiciones)].map(x=>this.componer(x))
                        }
                        else {
                            resu = [...this.cartesian(zona, estantes, alturas)].map(x=>this.componer(x))

                        }
                        window.miconsola(resu)

                        resu.forEach(async (ubi) =>{
                            try  { 
                                await  self.$http.post(`/etiqueta/ubicacion/${self.$pdv}`, {ubi})
                                }
                            catch (ex) {
                                console.log(ex)
                                window.miconsola('Error : ', ubi)
                            }
                        })
                        self.$router.replace('/ubicar') 
                    }
             }

})
</script>

<style scoped >
    ion-content {
        max-width: 700px !important;
    }
    ion-title {
        padding-left: 4px !important; 
        padding-right: 4px !important; 
        position:relative

    }
    ion-button.final {
        float:right !important;     
    }
    ion-label {
        font-size: 1.2rem !important; 
        padding: 4px !important; 
        margin: 4px !important; 
        text-align: center;
        width: 100% !important;
    }
    ion-select {
        border: 1px solid var(--ion-color-light) !important; 
        font-size: 1.4rem !important; 
        width: 100% !important; 
    }

    table{
        width: 100% !important;
    }
    tr {
        height: 5rem !important;
        
    }
    td.c1 {
        width:38% !important
    }
    td.detalle {
        text-align: center;
        width: 31% !important;
        }
    

        
</style>