/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  console.log(`${process.env.BASE_URL}service-worker.js`)
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      window.fake_console(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      window.fake_console('Service worker has been registered.')
    },
    cached () {
      window.fake_console('Content has been cached for offline use.')
    },
    updatefound () {
      window.fake_console('New content is downloading.')
      window.fake_console('Nuevo contenido descargando ')
    },
    // When the SW is updated we will dispatch an event we can listen to in our .vue file
    updated(){
      //if (confirm('Actualización descargada. Refrescamos ahora? '))
      //{
        window.location.reload()
      //}
    },
    offline () {
      window.fake_console('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
