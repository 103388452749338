/**
    Seleccion ubicaciones 
    Paso previo a la entrada en la asignación de articulos a una ubicación concreta
    Muestra un boton 'Grande' que permite elegir una nueva ubicación y dirigirnos a la pagina de detalle 
    así mismo muestra tb. las ubicaciones en curso que tenemos pendientes de confirmar / concluir 
 */

<template>
    <ion-page  >
        <CabeceraComponent 
            :pdv = "$route.meta.pdv"
            :isIOS = "$route.meta.isIOS"
            titulo="Seleccionar Ubicacion"
            color="primary"
        />
        <ion-content class="ion-padding" >   
            <progress-bar-component :done="hechos" :totals="totales" v-if="hechos"/>
                <ion-row >
                    <ion-col size="36">
                        <ion-button expand="block" fill="outline" size="large"  class="trigger_scan" >
                                <ion-title>Seleccionar Ubicacion
                                <br/> 
                                <ion-text class="ion-text-wrap">
                                    Escanea una ubicación o elige de la lista adjunta
                                </ion-text>
                            </ion-title>
                        </ion-button>
                    </ion-col>
                    <ion-col size="12">
                        <ion-button class="alternativa impresion" fill="solid" expand="block" color="tertiary" routerLink="/etiqueta-ubicacion" routerDirection="none">
                            <i class="fas fa-print blanca">
                                 <ion-text class="ion-text-wrap en-botton">
                                    Imprime etiquetas ubicacion
                                 </ion-text>
                            </i> 
                        </ion-button>
                    </ion-col>
                </ion-row>
            
            <!-- cambio ion-col por ion-row  -->
            <!-- Lista de ubicaciones pendientes de cerrar -->
            <ion-row>

                        <ion-card >
                            <ion-card-header class="head"  style="width:100% !important;">
                                <ion-title class="super"> Ubicaciones en curso </ion-title>
                                <ion-card-subtitle size="large" >
                                    <ion-row class="titulin" >
                                        <ion-col size="29" offset ="1" >Ubicacion</ion-col>
                                        <ion-col size="18" ># Articulos</ion-col>
                                    </ion-row>
                                </ion-card-subtitle>
                            </ion-card-header>

                            <ion-card-content  v-if="largo>0">
                                <ion-row v-for="(u,index) in datos" :key="`ubic:${index}`"
                                    class="pares-articulo-lote-ubicacion altos"
                                    @click="irUbicacion(u.ubi)" >
                                    <!-- primera linea--> 
                                    <ion-col size="35" 
                                        offset="1"
                                        class="ion-padding-left ion-text-nowrap  sku nombre_cortado ion-text-start">
                                        {{u.ubi}}
                                    </ion-col>
                                    <ion-col size="12"  class="ion-padding-left ion-text-nowrap  medios ion-text-right">
                                        <span class="pad-1-derecha">{{ $filters.Entero(u.arts)}}</span>
                                    </ion-col>
                                </ion-row>
                            </ion-card-content>
                            <ion-card-content v-else>
                                <ion-row no-padding no-margin  no-lines >
                                        <ion-col class="centro">
                                            <ion-label >No hay ubicaciones en curso pendientes de confirmar en Gestion 2000</ion-label>
                                        </ion-col>
                                </ion-row>      
                            </ion-card-content>
                        </ion-card>
                </ion-row> 
        </ion-content>
    </ion-page>
</template>

<script>
    import CabeceraComponent from '@/components/CabeceraComponent'
    import ProgressBarComponent from '@/components/ProgressBarComponent'
    import { totales, hechos } from '@/utilities/scanner/scan-sku'

    import { defineComponent } from 'vue';

    import { trashOutline,barcodeSharp } from 'ionicons/icons';
    import { scanUbiStart, scanUbiStop} from '@/utilities/scanner/scan-ubi'
    import { clavesUbicacionStorage , loadUbicacionStorage} from '@/utilities/storage/storage-ubicar';
    import eventBus from "@/event-bus"; 
    export default defineComponent({
            name : "SeleccionUbicacionesPage",
            components: { CabeceraComponent, ProgressBarComponent } , 
            data() {
                return {
                    pdv : this.$route.meta.pdv, 
                    element_id : 'seleccion_ubicaciones', 
                    clave: undefined  ,  
                    trashOutline,barcodeSharp, 
                    totales, hechos, 
                    datos: [
                    ], 
                    }
            }, 
            async created() {
                this.pdv =  this.$route.meta.pdv 
            },
            async mounted() { 
                window.fake_console('seleccion ubicaciones: entro en mounted  ', this.pdv)
                let self = this; 
                await this.recuperarClaves()
                await scanUbiStart(this.element_id) 
                eventBus().emitter.once(`ubicacion:${this.element_id}`,async (ubicacion)=>{
                    window.miconsola(ubicacion)
                    window.miconsola(' Nos dirigimos a la ubicacion ')
                    await self.irUbicacion(ubicacion)
                }) 
            }, 
            async unmounted() {
                //eventBus().emitter.off(`ubicacion:${this.element_id}`)
                await scanUbiStop(this.element_id)
            }, 
            methods: {     

                async recuperarClaves(){
                    const claves = await clavesUbicacionStorage(this.pdv)
                    window.fake_console(claves)
                    if (claves) {
                        const datos = await claves.reduce(async (m,v) =>  {
                            const memo = await m ;
                            window.fake_console(v)
                            const add = await loadUbicacionStorage(v, undefined)
                            window.fake_console(add)
                            if (add && add.length>0 ) { 
                                window.fake_console(add)
                                memo.push({'ubi': add[0].ubi, 'arts': add.length})
                                }
                            return memo 
                        }, [])
                        if (datos.length>0) {
                            this.datos = datos 
                        }
                    }
                }, 
                async irUbicacion(ubi){
                    window.fake_console(ubi)
                    window.fake_console('entro en ir a ubicacion')
                    window.fake_console(ubi)
                    this.$router.replace(`/ubicar/${ubi}`)
                }
            }, 
            computed :{
                largo: function () {
                    return Object.keys(this.datos).length
                    }
            }
            
 
    })
</script>
 
<style scoped lang="scss">
    @import "@/theme/selectores-lotes-styles.scss";
</style>

<style scoped>
    /*ion-row, ion-card , ion-card-subtitle , ion-card-content , ion-card-header , ion-title  , .titulin {
        width: 100! important;
    }*/
    ion-row.altos {
        height: 3rem !important;
        
    }
    ion-text.en-botton {
        font-family: 'Roboto';
        font-size: 0.9rem !important; 
        color: var(--ion-color-blanco)  !important;
        text-transform: none !important;
        line-height: 0.4rem !important;
    }
    i.fas.blanca {
        font-size: 2rem !important; 
        color: var(--ion-color-blanco)  !important;
        line-height: 1rem !important; 
        }
    ion-card {
        width: 100% !important; 
    }
</style>