<template>
  <ion-app>
    <FontAwesomeLibraryComponent/>
        <!-- Aqui incorporaremos el contenido de las navegaciones  
        <ion-content id="main-content" scroll-y ="true" >
          -->
        <ion-content>
            <router-view> </router-view>
        </ion-content>
  </ion-app>

</template>

<script>
import { IonApp, IonContent, alertController } from '@ionic/vue';
import { defineComponent } from 'vue';
import eventBus from "@/event-bus"; 
import FontAwesomeLibraryComponent from '@/components/FontAwesomeLibraryComponent.vue'


export default defineComponent({
  name: 'App',
  data() {
    return {
      usuario: this.$current_user_name, 
      deferredPrompt: null, 
      intentos : 0 
    }
  },  
  components: { IonApp , IonContent, FontAwesomeLibraryComponent }, 
  methods: {
    
    async openIosAlert() {
      const ancho_imagen = 1072
      const largo_imagen = 1775 
      const ancho_screen = window.screen.width
      const ancho = parseInt(ancho_screen*0.85)
      const alto = parseInt(largo_imagen*ancho/ancho_imagen)
      window.fake_console('app ',alto,ancho)
      const alerta = await alertController
        .create({
          cssClass: 'my-custom-class',
          message: `<img src="assets/tool-tip-install-ios.png" style="width:${ancho} !important; height:${alto} !important; border-radius: 2px">`,
          mode : "md",
          buttons: [
            {
              text: 'Ok',
              handler: () => { window.fake_console('app ','Confirm Okay')},
            },
          ]
        })
      return alerta.present();
    }, 
    async openInstallPromotion() {
      const toast = await alertController
        .create({
          header: 'Instala la aplicación ',
          message: 'Irá mucho más rápida y la tendrás accesible desde tu pantalla principal sin necesidad de entrar en Chrome.',
          position: 'middle',
          buttons: [
            {
              text: 'Si',
              //side: 'end',
              icon: 'checkmark-circle-sharp',
              handler: () => {
                window.fake_console('app ','Favorite clicked');
                this.intentos+=1
                this.install();
                toast.dismiss()
              }
            }, {
              text: 'No',
              //side: 'end',
              icon: 'close-circle-sharp',					
              role: 'cancel',
              handler: () => {
                this.dismiss()
                window.fake_console('app ','Cancel clicked');
              }
            }
          ]
        })
      return toast.present();
   },
    async dismiss() {
      window.fake_console('app ','El usuario no ha querido ')
      this.deferredPrompt = null;
    },
    async install() {
      window.fake_console('app ','Instalando.... ')
      this.deferredPrompt.prompt();
    }, 
    getDevice() {
      const w = window.innerWidth 
      const h = window.innerHeight 
      return { 'ancho': w , 'largo' : h , 
       'tamanyo': w < 576 ? 1 : w < 900 ? 3 : w < 1200 ? 5 : 5, 
        'tipo' : w < 576 ? 'sm' : w < 900 ? 'md' : w < 1200 ? 'lg' : 'xl'}
     }
  },

  mounted() {
    window.fake_console('entro en mounted de app.vue')
    /* Deteccion de IOS y propuesta de que el usuario siga pasos de instalación  */
      const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test( userAgent );
      }
      // Detects if device is in standalone mode
      const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
      // Checks if should display install popup notification:
      if (isIos() && !isInStandaloneMode()) {
        this.openIosAlert()
      }
    // Detecta cambios de ancho
      window.addEventListener('resize', () => {
          eventBus().emitter.emit('device',this.getDevice());
        })
    // Inicialización del tamaño de pantalla y tipo de dispositivo 
        eventBus().emitter.emit('device',this.getDevice());
  }, 
  
  created(){
      console.log('entro en created de app.vue')
      /** Control para instalar la app en android  */
      window.addEventListener("beforeinstallprompt", e => {
          console.log('estoy en before-prompt')
          e.preventDefault();
          // Stash the event so it can be triggered later.
          this.deferredPrompt = e;
          if (this.intentos==0) {this.openInstallPromotion()}
        });
      window.addEventListener("appinstalled", () => {
          console.log('ENTRO EN EL CASO DE YA INSTALADA')
          this.deferredPrompt = null;
        });
  }
});
</script>
<style lang="scss" >

  @import "@/theme/main-styles.scss";
  @import "@/theme/icon-styles.scss";
  @import "@/theme/alert-popover-styles.scss";
  @import "@/theme/botones-etiqueta.scss";
  @import "@/theme/alert-firma-styles.scss";

</style>