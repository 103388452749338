
import { createRouter, createWebHistory } from '@ionic/vue-router';
import eventBus from "@/event-bus";
import UbicarArticulosPage from '@/pages/ubicar/UbicarArticulosPage.vue'
import SeleccionUbicacionesPage from '@/pages/ubicar/SeleccionUbicacionesPage.vue'
import ImprimirEtiquetaUbicacionPage from '@/pages/ubicar/ImprimirEtiquetaUbicacionPage.vue'

//import SearchProductComponent from '@/components/search/search-product-component.vue'
import SeleccionProductoStockComponent from '@/components/search/seleccion-producto-stock-component.vue'
import ProductPageComponent from '@/components/search/product-page-component.vue'

/** Recepciones */
import CrearRecepcionPage from '@/pages/recepcionar/CrearRecepcionPage.vue'
import SeleccionRecepcionesPage from '@/pages/recepcionar/SeleccionRecepcionesPage.vue'
import SeleccionarPedidosRecepcionComponent from '@/pages/recepcionar/SeleccionarPedidosRecepcionComponent.vue'
import CrearEntradaFromRecepcionPage from '@/pages/recepcionar/CrearEntradaFromRecepcionPage.vue'
import RevisarCrossdockingClientesPage from  '@/pages/recepcionar/RevisarCrossdockingClientesPage.vue'
import RevisarEntradaPage from  '@/pages/recepcionar/RevisarEntradaPage.vue'

import RevisionStockCrossDockingComponent from  '@/pages/recepcionar/components/RevisionStockCrossDockingComponent.vue'

/** Envios */
import SeleccionEnviosPage from '@/pages/enviar/SeleccionEnviosPage.vue';
import CrearEnvioPage from '@/pages/enviar/CrearEnvioPage.vue';
import RevisionEnvioEntregadoPage from '@/pages/enviar/RevisionEnvioEntregadoPage.vue';


/** Expediciones */
import ExpedicionesCargarCamionPage from '@/pages/expediciones/ExpedicionesCargarCamionPage.vue';
import SeleccionExpedicionesPage from '@/pages/expediciones/SeleccionExpedicionesPage.vue';
import ExpedicionesRepartoEnCursoPage from '@/pages/expediciones/ExpedicionesRepartoEnCursoPage.vue';
import ExpedicionesCargarRutaRepartoPage from '@/pages/expediciones/ExpedicionesCargarRutaRepartoPage.vue';
import ExpedicionesRepartoRealizadoPage from '@/pages/expediciones/ExpedicionesRepartoRealizadoPage.vue';


import AsignarTransportesPage from '@/pages/configurar/AsignarTransportesPage.vue';
import CambiarDelegadosPage from '@/pages/configurar/CambiarDelegadosPage.vue';
import FormEntregaPdfPage from '@/pages/expediciones/FormEntregaPdfPage.vue';



/** Mostrador */
import CustomerListPage from '@/pages/mostrador/CustomerListPage.vue';
import BuscarProdutoMostradorPage from '@/pages/mostrador/BuscarProductoMostradorPage.vue';
import BuscarFavoritosClientePage from '@/pages/mostrador/BuscarFavoritosClientePage.vue';
import ListaMostradorPage from '@/pages/mostrador/ListaMostradorPage.vue';

/** Preparar Pedidos  */
import CustomerWithOrdersListPage from '@/pages/preparar-pedido/CustomerWithOrdersListPage.vue';
import CustomerOrdersListPage from '@/pages/preparar-pedido/CustomerOrdersListPage.vue';
import PrepararPedidoMostradorPage from '@/pages/preparar-pedido/PrepararPedidoMostradorPage.vue';

/** Utilidades */
import TestVelocidadPage from '@/pages/TestVelocidad.vue'



  /** 
   * Variable para incorporar el pdv en todos los componentes al navegar
   * Cambia si cambia el pdv en login 
   */
  let pdv = undefined; 
  let nompos = undefined;
  let ubiku_recepcion =undefined;  
  let pdv_geoloc =undefined; 
  let isIOS = undefined; 
  let logged  = false; 
  let nombres_clientes = false; 
  let ubiku_rol = undefined; 
  let mis_pdvs = undefined 

  eventBus().emitter.on('memory-data',(dl)=>{
    pdv= dl.pdv;
    nompos = dl.nompos; 
    isIOS = dl.isIOS;
    logged = dl.logged;
    ubiku_recepcion = dl.ubiku_recepcion;
    pdv_geoloc = dl.pdv_geoloc ;
    ubiku_rol = dl.ubiku_rol;
    mis_pdvs = dl.mis_pdvs; 
    nombres_clientes = dl.clientes_pdv.reduce((m,v)=> {
      const {cliente,nombre} = v
      m[cliente]= nombre; 
      return m 
    } ,{})
    });


  eventBus().emitter.on('deeplink-log',(dl)=>{
    window.fake_console('deep-link logging....')
    pdv= dl.pdv;
    nompos = dl.nompos; 
    isIOS = dl.isIOS;
    logged = dl.logged;
    ubiku_recepcion = dl.ubiku_recepcion;
    pdv_geoloc = dl.pdv_geoloc;
    });


import Login from '@/pages/Login.vue'
import HomePage from '@/pages/HomePage.vue'
const routes = [
  {
    path: '/',
    name: 'plain_login', 
    principal: 'si', 
    component: Login
  },
  {
    path: '/login',
    principal: 'si', 
    name: 'login',
    props: true, 
    component: Login
  },
  {
    path: '/login/:forzar_pdv',
    principal: 'si', 
    name: 'login_forzado',
    props:true, 
    component: Login
  },
  {
    path: '/home',
    principal: 'si', 
    name: 'home',
    component: HomePage
  },
  {
    path: '/ubicar/:ubi',
    name: 'ubicar_ubicacion',
    component: UbicarArticulosPage, 
    props: true
  },
  {
    path: '/producto/:sku',
    name: 'detalle_producto',
    component: ProductPageComponent, 
    props: true
  },
  
  {
    path: '/ubicar',
    name: 'ubicar',
    component: SeleccionUbicacionesPage  
  
  },
  {
    path: '/buscar',
    name: 'buscar',
    component: SeleccionProductoStockComponent   
  },

  {
    path: '/test-velocidad',
    name: 'test_velocidad',
    component: TestVelocidadPage   
  },

  {
    path: '/etiqueta-ubicacion',
    name: 'etiqueta_ubicacion',
    component: ImprimirEtiquetaUbicacionPage   
  },
  
  {
    path: '/selector-producto',
    name: 'selector_producto',
    component: SeleccionProductoStockComponent   
  },
  
  {
    path: '/recepcionar',
    name: 'recepcionar',
    component: CrearRecepcionPage   , 
    props: true

  },

  {
    path: '/recepcionar/:recepcion_id',
    name: 'recepcionar_recepcion',
    component: CrearRecepcionPage, 
    props: true
  },

  {
    path: '/recepciones',
    name: 'recepciones',
    component: SeleccionRecepcionesPage   , 
    props: true

  },
  {
    path: '/entrada',
    name: 'entrada',
    component: CrearEntradaFromRecepcionPage   , 
    props: true

  },

  {
    path: '/selector-pedidos-entrada',
    name: 'selector_pedidos_entrada',
    component: SeleccionarPedidosRecepcionComponent   , 
    props: true
  },
  

  {
    path: '/revisar-cross-docking',
    name: 'revisar_cross_docking',
    component: RevisarCrossdockingClientesPage   , 
    props: true
  },
  {
    path: '/revisar-entrada',
    name: 'revisar_entrada',
    component: RevisarEntradaPage   , 
    props: true
  },

  {
    path: '/revisar-stock-crossdocking',
    name: 'revisar_stock_crossdocking',
    component: RevisionStockCrossDockingComponent   , 
    props: true
  },


  /** ENVIOS */

  {
    path: '/envios',
    name: 'envios',
    component: SeleccionEnviosPage   , 
    props: true
  },
  {
    path: '/crear-envio',
    name: 'crear_envio',
    component: CrearEnvioPage   , 
    props: true
  },
  
  {
    path: '/revisar-entrega-envio',
    name: 'revisar_entrega_envio',
    component: RevisionEnvioEntregadoPage   , 
    props: true
  },
  
  {
    path: '/form-entrega',
    name: 'form_entrega',
    component: FormEntregaPdfPage   , 
    props: true
  },
  



  


  /** EXPEDICIONES */

  {
    path: '/expediciones-carga',
    name: 'expediciones-carga',
    component: ExpedicionesCargarCamionPage   , 
    props: true
  },
  
  {
    path: '/expediciones-ruta',
    name: 'expediciones-ruta',
    component: ExpedicionesCargarRutaRepartoPage   , 
    props: true
  },

  {
    path: '/expediciones-reparto',
    name: 'expediciones-reparto',
    component: ExpedicionesRepartoEnCursoPage   , 
    props: true
  },
  {
    path: '/expediciones-entregado',
    name: 'expediciones-entregado',
    component: ExpedicionesRepartoRealizadoPage   , 
    props: true
  },
  
  {
    path: '/expediciones',
    name: 'expediciones-general',
    component: SeleccionExpedicionesPage   , 
    props: true
  },
  
  {
    path: '/asignar-transportes',
    name: 'asignar-transportes',
    component: AsignarTransportesPage   , 
    props: true
  },
  {
    path: '/asignar-delegados',
    name: 'asignar-delegados',
    component: CambiarDelegadosPage   , 
    props: true
  },
  

  
  
  
  /** CARRITOS DEL MOSTRADOR */
  {
    path: '/clientes-carrito-mostrador',
    name: 'clientes_carrito_mostrador',
    component: CustomerListPage   , 
    props: true
  },
  {
    path: '/carrito-mostrador/:cliente',
    name: 'carrito_mostrador',
    component: ListaMostradorPage   , 
    props: true
  },
  {
    path: '/busqueda-mostrador/:cliente',
    name: 'busqueda_mostrador',
    component: BuscarProdutoMostradorPage   , 
    props: true
  },
  {
    path: '/favoritos-mostrador/:cliente',
    name: 'favoritos_mostrador',
    component: BuscarFavoritosClientePage   , 
    props: true
  },

  /** PREPARACION DE PEDIDOS  */
  
  {
    path: '/clientes-preparacion-pedidos',
    name: 'clientes_preparacion_pedidos',
    component: CustomerWithOrdersListPage   , 
    props: true
  },
  {
    path: '/pedidos-cliente/:cliente',
    name: 'pedidos_cliente',
    component: CustomerOrdersListPage   , 
    props: true
  }
  ,
  {
    path: '/preparar-pedido-cliente/:cliente/:pedido',
    name: 'preparar_pedido_cliente',
    component: PrepararPedidoMostradorPage   , 
    props: true
  }  
]

const principales = routes.reduce((m,v) => {
    if (v.principal=='si') { m.push(v.name) }
    return m 
  } ,[])


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  void(from)
  let new_deeplink


  if ( principales.indexOf(to.name)== -1  ) {
    new_deeplink = to.fullPath  
    eventBus().emitter.emit('new-deeplink',new_deeplink);
    window.fake_console('lanzando nuevo! ' , new_deeplink)
  }
  window.fake_console(to, pdv, logged)
    to.meta.pdv = pdv;
    to.meta.isIOS = isIOS;
    to.meta.nompos = nompos; 
    to.meta.ubiku_recepcion = ubiku_recepcion; 
    to.meta.pdv_geoloc = pdv_geoloc; 
    to.meta.ubiku_rol = ubiku_rol; 
    to.meta.mis_pdvs = mis_pdvs; 
    if (to.params.cliente) {
      to.meta.nombre_cliente=  nombres_clientes[to.params.cliente]
    }
    /** si no estamos logados pasamos por la carga  */
  if (logged=== false && to.name!='login') {
    next('/login');
  } 
  else {  
    next();
  }


})
 
export default router
