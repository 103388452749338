import { alertController } from '@ionic/vue';
import {  openAlertaV2 } from  '@/utilities/services/alert-utilities';
import { checkIP }  from '@/utilities/global_functions'
import { loadStorage } from '@/utilities/storage/storage-utilities-main'
//import { Browser } from '@capacitor/browser';
import { Geolocation } from '@capacitor/geolocation';


const consola = function(){ void(0) }; //consola
const DISTANCIA = 1000; 
const ENTORNO =  window.location.host.indexOf('beta.ges.digital')==-1; 
//console.log( window.location, ENTORNO)

const openAlertaDistancia  = async function() {
    const alerta = await alertController
        .create({
            header: 'Operacion no permitida', 
            message: 'Esta operación no puede llevarse a cabo si no estas en el punto de venta',
            mode : "md",
            backdropDismiss: false, 
            buttons: ["Ok"]
        })
    await alerta.present();      
    const {data} = await alerta.onDidDismiss();
    consola(data)
    return false
}

const openAlertaDistanciaEntrega  = async function(distancia) {
    const dt = (parseInt(distancia/100))/10
    const alerta = await alertController
        .create({
            header: 'Uff, estas un poco lejos...', 
            message: `Parece que estas un poco lejos del punto de entrega que figura en el albarán (a unos ${dt} km).<br/>
                    Si es correcto y quieres seguir, confirma. <br/>
                    Si el error es de Google que no ha localizado bien la dirección, pulsa 'Es correcto, error de Google' y lo corregiremos.<br/>
                    En caso contrario cancela.`,
            mode : "md",
            backdropDismiss: false, 
            cssClass: 'custom-rutas-alert',  
            buttons: [  
                {
                  text: 'Cancelar',
                  role: 'cancel'                
                },
                {
                  text: 'Confirmar',   
                  role: 'confirm'
                },
                {
                    text: 'Es correcto, error de Google',   
                    role: 'correct'
                  },
  
              ]
        })
    await alerta.present();
    const {data, role} = await alerta.onDidDismiss();
    consola(data)
    if (role=='confirm'){
        return true
    }
    else if (role =='correct') {
        return false
    }
    else {
       return undefined
    }
}



const getPosition = function (options){
    return new Promise((resolve, reject) => 
        navigator.geolocation.getCurrentPosition(resolve, reject, options)
    );
}

const pdvDistance =  async function( control, pdv_in, silent){
    //console.log(window.location.host, ENTORNO )
    if (ENTORNO && ENTORNO === true) {
        return true
    }
    window.fake_console(control, typeof(control),  pdv_in, (typeof pdv_in === 'string' || pdv_in instanceof String) ? JSON.parse(pdv_in) : pdv_in)
    if ( control === true )    {    
        const loc = await getGeoloc(1000*60*10)
        let pdv = (typeof pdv_in === 'string' || pdv_in instanceof String) ? JSON.parse(pdv_in) : pdv_in
        pdv = Array.isArray(pdv) ? {latitude : pdv[0], longitude : pdv[1]} : pdv
        window.fake_console( loc, pdv, pdv_in )
        const lat = (loc.latitude-pdv.latitude)*110120
        const lon = (loc.longitude-pdv.longitude)*85000
        const dist = Math.ceil(Math.sqrt(Math.pow(lat,2)  +Math.pow(lon,2) )-(loc.accuracy||0))
        window.fake_console(dist, lat, lon )
        if ( dist > DISTANCIA ) {
            if (!silent ) {await openAlertaDistancia()}
            return undefined 
        }
        else {
            return true 
        }
    }  
    else {
        return true 
    }
}


const entregaDistance =  async function( punto_entrega){
        const loc = await getGeoloc(1000*60*10)
        let punto = punto_entrega
        punto = Array.isArray(punto) ? {latitude : punto[0], longitude : punto[1]} : punto
        window.fake_console( loc, punto, punto_entrega )
        const lat = (loc.latitude-punto.latitude)*110120
        const lon = (loc.longitude-punto.longitude)*85000
        const dist = Math.ceil(Math.sqrt(Math.pow(lat,2)  +Math.pow(lon,2) )-(loc.accuracy||0))
        window.fake_console(dist, lat, lon )
        if ( dist > 500 ) {
            const confirmacion = await openAlertaDistanciaEntrega(dist)
            return confirmacion === false ? loc : confirmacion
        }
        else {
            return true 
        }
}

const entregaPdvDistance =  async function( pointer_pdv ){
    const loc = await getGeoloc(1000*60*10)
    let punto = pointer_pdv
    punto = Array.isArray(punto) ? {latitude : punto[0], longitude : punto[1]} : punto
    window.fake_console( loc, punto, pointer_pdv )
    const lat = (loc.latitude-punto.latitude)*110120
    const lon = (loc.longitude-punto.longitude)*85000
    const dist = Math.ceil(Math.sqrt(Math.pow(lat,2)  +Math.pow(lon,2) )-(loc.accuracy||0))
    window.fake_console(dist, lat, lon )
    if ( dist > 500 ) {
        await openAlertaDistancia()
        return undefined  
    }
    else {
        return true 
    }
}


const DistanciaEnvio =  async function( punto_entrega ){
    const loc = await getGeoloc(1000*60*10)
    let punto = punto_entrega
    punto = Array.isArray(punto) ? {latitude : punto[0], longitude : punto[1]} : punto
    window.fake_console( loc, punto, punto_entrega )
    const lat = (loc.latitude-punto.latitude)*110120
    const lon = (loc.longitude-punto.longitude)*85000
    const dist = Math.ceil(Math.sqrt(Math.pow(lat,2)  +Math.pow(lon,2) )-(loc.accuracy||0))
    //console.log(dist)
    return Math.ceil(dist/100)/10
}



const DistanciaLinealEntrePuntos =   function( objeto_pointers ){

    const {lat_ent, lon_ent, lat_env, lon_env } = objeto_pointers 
    if( !lat_ent||!lat_env||!lon_ent||!lon_env ){
        return undefined
    }
    else 
    {
        const lat = (lat_ent-lat_env)*110120
        const lon = (lon_ent-lon_env)*85000
        const dist = Math.ceil(Math.sqrt(Math.pow(lat,2)  +Math.pow(lon,2) ))
        return Math.ceil(dist)
    }
}



const getGeoloc = async function( maxAge) {
    let maximumAge = maxAge||0
    const atStore = await checkIP()
    if (atStore === true) {
        const pdv_geoloc = await loadStorage('pdv_geoloc')
        const [latitude, longitude] = pdv_geoloc 
        window.fake_console({latitude, longitude, accuracy: 1 , localizacion :'pdv'})
        return {latitude, longitude, accuracy: 1 , localizacion :'pdv'}
    }
    try {
        const permisosGeo = await Geolocation.checkPermissions()
        if (!permisosGeo) {await Geolocation.requestPermissions('camera')}
        const position = await Geolocation.getCurrentPosition({maximumAge});
        window.fake_console(position)
        const {latitude, longitude, accuracy } = position.coords 
        return {latitude, longitude, accuracy } 
            
        }
    catch (error) {
        window.fake_console(error)
        await openAlertaV2 ("Error localizacion",'Tu navegador no soporta la geolocalización') // if the browser doesn't support geolocation
        return 
    }
  

}



 /** Abrir ventana con google maps para identificar el pointer de entrega, 
   *  solo se muestra para repartos. 
   */
 const irGoogleMaps = async function (envio){
    window.fake_console(envio)
    const {lat_env,lon_env} = envio
    //await Browser.open({url:`https://www.google.es/maps/search/${lat_env},${lon_env}?shorturl=1`})
    window.open(`https://www.google.es/maps/search/${lat_env},${lon_env}?shorturl=1`, '_blank', 'noreferrer');
  }

  const RutaGoogleMaps = async function(envio){
    const position = await getPosition({maximumAge:0})
    const {latitude, longitude } = position.coords 
    window.fake_console({latitude, longitude})
    const {lat_env,lon_env} = envio
    const destino = `'${lat_env},${lon_env}'`
    const origen = `'${latitude},${longitude}'`
    const goog_url = `https://www.google.com/maps/dir/${origen}/${destino}`
    //await Browser.open({url : goog_url })
    window.open(goog_url, '_blank', 'noreferrer');
  }





export {getGeoloc, pdvDistance , irGoogleMaps , RutaGoogleMaps , 
    entregaDistance, entregaPdvDistance, DistanciaEnvio , 
    DistanciaLinealEntrePuntos
    }