<template>
    <ion-page :key="qt">
        <CabeceraComponentBack  
            :pdv = "pdv"
            :isIOS = "$route.meta.isIOS"
            :titulo="`Crear envio`"
            color="primary"
            previo="envios"
        />
        <ion-content class="ion-padding" v-cloak>     
            <ion-fab vertical="top" horizontal="end" slot="fixed" >
                    <ion-fab-button                          
                        :key="`scan-activo:${blinker}`"
                        :class="blinker ? 'activo' : 'inactivo'"
                        >
                        <ion-icon :icon="barcodeSharp" :class="blinker ? 'blink' : 'not-blink'" :key="`ion-scan:${blinker}`"></ion-icon>
                    </ion-fab-button>
            </ion-fab>

            <ion-row  class="spin ion-justify-content-center "  v-if="!showme">
                <ion-col  v-if="!showme" class="spin ion-align-self-center" size="48" > 
                    <ion-spinner  v-if="!showme"></ion-spinner>            
                </ion-col>
            </ion-row>
        
            <ion-row class="recepcion ion-no-margin con-borde" v-if="showme">
                <ion-col   size="48" class="ion-no-padding ion-no-margin">

                    <ion-list class="ion-no-padding ion-no-margin" >

                        <!-- Cliente-->
                        <ion-item lines="none"> 
                            <ion-label class="negrita ">Cliente :  
                                {{datos_envio.codemp}}.{{datos_envio.codcli}}
                            </ion-label>
                        </ion-item>      
                        <ion-item class="detalle" > 
                            <ion-input class="detalle medio " :value="datos_envio.nomcli"   readonly />
                        </ion-item>      

                        <!-- Direccion de entrega  -->
                        <ion-item lines="none"> 
                            <ion-label class="negrita" >
                                    Direccion de entrega
                                     <span :class="distancia < 50 ? 'verde': distancia <100 ? 'primario' :' rojo'" v-if="distancia">( Dist: {{$filters.Entero(distancia)}} <sub>Km</sub> )</span>
                            </ion-label>

                                <ion-fab  class="fijo"  v-if="datos_envio.lat_env">
                                   
                                    <ion-fab-button  :color="distancia < 50 ? 'darksuccess': distancia <100 ? 'primary' :' danger'"  @click="irGoogleMaps()" >
                                        <i class="fak fa-solid-location-dot-circle-question en-fab"></i>  
                                        
                                    </ion-fab-button>
                                </ion-fab>
                            
                        
                            <!--     
                            <div @click="irGoogleMaps" v-if="datos_envio.lat_env">
                                <i class="fak fa-solid-location-dot-circle-question fracaso en-fab-especial"></i>
                            </div>
                            -->
                        </ion-item>   
                        <ion-item class="detalle" lines="none"> 
                            <ion-input class="detalle medio" :value="datos_envio.nomenv"   readonly />
                        </ion-item>      
                        <ion-item class="detalle" lines="none"> 
                            <ion-input class="detalle medio" :value="datos_envio.domenv"   readonly />
                        </ion-item>      
                        <ion-item class="detalle" > 
                            <ion-input class="detalle medio" :value="`${datos_envio.cpenv} ${datos_envio.pobenv}`"   readonly />
                        </ion-item>     
                        
                        <!-- Observaciones  -->
                        <ion-item lines="none"> 
                            <ion-label class="negrita">Observaciones en albaranes</ion-label>
                        </ion-item>      
                        
                        <ion-item class="detalle"  v-if="datos_envio.obsent.length>0"> 
                            <ion-input v-for="(obs,index) in datos_envio.obsent" 
                                class="detalle medio"  :value="`- ${obs.alb} : ${obs.obsent}`"   readonly 
                                :key="`cmt-${index}`"
                                />
                        </ion-item>      
                        <ion-item class="detalle"  v-else> 
                            <ion-input class="detalle medio"  value="No hay observaciones"   readonly />
                        </ion-item>      



                        <!-- Tipo de Envio -->
                        <ion-item lines="none"> 
                            <ion-label class="negrita">Tipo de envio</ion-label>
                        </ion-item>    
                        <ion-item style="overflow: auto;">
                            <ion-buttons>
                                <ion-button @click="elegirTipo(tipo)" 
                                    v-for="tipo in tipos_reparto_envio"
                                    :key="tipo.k"
                                    class="cuarto" size="small" 
                                    :fill="datos_envio.envio_tipo===tipo.k ? 'solid': 'outline'"
                                    :color="datos_envio.envio_tipo===tipo.k ? 'dark': 'medium'">
                                    {{tipo.v}}
                                </ion-button>
                            </ion-buttons>  
                        </ion-item>


                        <!-- bultos  -->
                        <ion-item lines="none" class="borde-arriba pure-form" > 
                            <ion-label class="negrita tres_cuartos">Bultos</ion-label>
                            <ion-input slot="end" 
                                v-model="datos_envio.bultos"
                                class="bultos"
                                id="entrada-bultos"
                                inputmode="numeric"
                                type="number"
                                debounce="1500"
                                @ionFocus="scanGenericStop(element_id)"
                                @ionChange="salir"
                                placeholder="Numero" 
                            />


                        </ion-item>   

                        <!-- albaranes  class="borde-arriba" -->
                        <ion-item lines="none" class="borde-arriba"> 
                            <ion-label class="negrita">Albaranes</ion-label>
                        </ion-item>    


                        <ion-item  class="sin-borde">
                                <ion-row class="sin-nada">
                                    <ion-col size="16" v-for="(alb ,index) in datos_envio.albaranes " :key="index" 
                                        class="ion-no-padding ion-no-margin">
                                        <ion-badge mode="md" class="agrupado" color="default" >
                                            <div class="agrupado">
                                                <ion-text class="principal">
                                                {{alb}}
                                                </ion-text>
                                                <ion-icon :icon="closeCircle" class="badge elimina" @click="eliminarAlbaran(alb)">
                                                </ion-icon>
                                            </div>
                                        </ion-badge>
                                    </ion-col>
                                </ion-row>
                        </ion-item>

                    </ion-list>
                </ion-col>
            </ion-row>        


        </ion-content>

        <ion-footer >
            <ion-toolbar >
                <ion-buttons slot="start" class="completo"  >    
                    <ion-button class ="completo"  expand ="block" fill="solid" 
                        id="recepcion_boton_reset_field"
                        color="darkdanger" size="normal" @click="goBack()"
                        >   
                        Descartar
                </ion-button>
                </ion-buttons>
                <ion-buttons slot="end" class="completo"  >    
                    <ion-button class ="completo"  expand ="block" fill="solid" 
                        id="recepcion_boton_creacion_field"
                        color="primary" size="normal" @click="CrearEnvio()"
                        :disabled="completo"
                        > 
                        Crear
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-footer>




    </ion-page>

</template>
    

<script>

    /**
     * NOTAS: 
     * El escaner solo dirige salidas al campo albaranes, 
     * todos los demas son solo de introducción manual 
     * Si leemos algo o vienen de la inicialización de la pagina 
     */
    import { defineComponent } from 'vue';
    import CabeceraComponentBack from '@/components/CabeceraComponentBack';
    import { homeSharp,  addCircleSharp , closeCircle, barcodeSharp} from 'ionicons/icons';
    import {  openAlertaV2 , openAlertaBinaria } from  '@/utilities/services/alert-utilities';
    import { useRoute } from 'vue-router';
    import { omit  } from 'underscore';
    import {  esAlbaran , esUbicacion} from '@/utilities/global-constants';
    import { scanGenericStart, scanGenericStop  } from '@/utilities/scanner/scan-generic'
    import eventBus from "@/event-bus"; 
    import { recuperarGoogleGeoLocEntrega} from '@/pages/enviar/components/expedir-utilities.js' ;
    import {  tipos_reparto , ubicaciones_entrega} from '@/utilities/global-constants';
    import { DistanciaEnvio }  from '@/utilities/location'
    import { Browser } from '@capacitor/browser';

    export default defineComponent({
            name : "CrearEnvioPage",
            components: {  CabeceraComponentBack  } , 
            props: ['albaran_id', 'cp_rutas'],  
            data() { 
                return { 
                    tipos_reparto, 
                    homeSharp,  addCircleSharp, closeCircle,barcodeSharp ,   
                    esUbicacion, scanGenericStop, 
                    omit, 
                    element_id: 'crear-envio-page',
                    pdv: this.$route.meta.pdv, 
                    nompos: this.$route.meta.nompos, 
                    isIOS: undefined, 
                    scanner_listener: undefined, 
                    blinker : false ,
                    datos_envio :  {
                        codemp: undefined, 
                        bultos: undefined, 
                        fecenv:  parseInt((new Date()).toISOString().replace(/-/g,'').slice(0,8)), 
                        codcli: undefined, 
                        nomcli : undefined, 
                        nomenv : undefined, 
                        domenv : undefined, 
                        cpenv : undefined,
                        telefo: undefined,  
                        pobenv : undefined, 
                        obsent : undefined, 
                        albaranes: undefined, 
                        envio_tipo : undefined , 
                        tipo_cliente: undefined 
                    },    
                    isGrupo: undefined,      
                    limite:  1000, 
                    showme :false, 
                    ruta: undefined, 
                    qt :false , 
                    debounce_milis: 300, 
                    rutas_pdv: undefined, 
                    distancia : undefined                  
                    
                    }
            },
            created() {
                this.pdv =  this.$route.meta.pdv 
                this.nompos =  this.$route.meta.nompos 
                this.isIOS = this.$route.meta.isIOS 
                this.rutas_pdv = JSON.parse(this.cp_rutas||'{}')
                this.tipos_reparto_envio = this.tipos_reparto.filter (x => x.k != 'ESPERA')
            },
            computed :{
                completo: function () {
                    /** Para ser valido necesitamos todo salvo la fecha y las observaciones  */
                    console.log(this.datos_envio)
                    const dat = omit(this.datos_envio,'fecenv', 'obsent','telefo')
                    console.log(dat)
                    const valores = Object.values(dat);                    
                    return valores.some(x=>!x)
                    }
                }, 
            watch: {
                '$route.query.q'() { 
                    this.pdv =  this.$route.meta.pdv ;
                    this.nompos =  this.$route.meta.nompos 
                }  
            },
            updated() {
                this.pdv =  this.$route.meta.pdv ;
                this.nompos =  this.$route.meta.nompos ;

            },
            async mounted() {
                const route = useRoute();
                this.ruta = route.fullPath
                this.qt = route.query.q
                if (this.albaran_id)
                    {   this.AlbaranInicial(this.albaran_id, 'inicial')    }
                else {
                    await openAlertaV2( 'Error albaran', 
                        `No hay ningun albaran que chequear `) 
                    this.$router.go(-1)
                    }
                await this.escaner_generico()
            }, 
            async unmounted() {
                await this.pararScanner()
            }, 

            methods: {
                async irGoogleMaps(){
                    const {lat_env,lon_env} = this.datos_envio
                    window.fake_console(this.datos_envio)
                    await Browser.open ({ url : `https://www.google.es/maps/search/${lat_env},${lon_env}?shorturl=1`})
                }, 

                async imprimir_etiqueta_envio(envio) {
                    /* const nompos = await loadStorage('nompos'); */
                    const { nompos } = this; 
                    const payload = Object.assign({nompos, ...envio})
                    let bls = [...Array(payload.bultos).keys()]
                        try  { 
                            await bls.reduce (async (mx,vx)=> {
                                await  this.$http.post(`/etiqueta/envio/${this.$pdv}`, { ...payload , bl: vx+1})    
                                return mx 
                                }, 0)
                            }
                        catch (ex) {
                            console.log(ex)
                            window.miconsola('Error : ', envio)
                        }
                    } , 

                async salir () {
                    let inputField = document.getElementById('entrada-bultos');
                    let input = await inputField.getInputElement()
                    input.blur(); 
                    inputField.blur();
                    await this.escaner_generico();
                }, 
                async AlbaranInicial(alb_id, caso) {
                    const alb = alb_id.match(esAlbaran).groups
                    const { codemp, codsuc, numalb, codcli} = alb 
                    const payload = {codsuc, numalb, codcli }
                    const albaran = await this.$http.post(`/get_delivery_note/${codemp}`, payload)

                    if (!albaran) {
                      this.goBack()
                      await openAlertaV2(
                        'No tienes permisos',
                        `Albarán de otro PDV. No tienes perfil en el PDV origen del albarán`
                      )
                    } else if ( albaran.data[0].status === 0 && !albaran.data[0].ms_uuid  ) {
                            await this.pararScanner()
                            await openAlertaV2(
                                'Error recuperacion', 
                                `No hemos podido recuperar el albaran.<br/>${albaran.data[0].alert} `
                            )
                                if (caso === 'inicial') {this.goBack() }
                            }
                    else if (albaran.data[0].status === 0 && albaran.data[0].ms_uuid ) {
                         const confirmacion = await openAlertaBinaria('Albaran ya enviado ' , 
                            `El albarán se expidió anteriormente en el envio ${albaran.data[0].ms_uuid}.<br/> 
                             Si quieres ver los detalles del mismo confirma, sino cancela.<br/>
                            `, 'Cancelar','Confirmar')
                                if ( confirmacion === true ){
                                        this.$router.replace({'name' :'revisar_entrega_envio', params : { envio_id_intro: albaran.data[0].ms_uuid  }})
                                        return 
                                } else {
                                    this.goBack()
                                }
                    }
                    else if (caso === 'inicial') {
                            const {fecenv, bultos } = this.datos_envio;   
                            const {codemp, codsuc, codcli, nomcli, nomenv, domenv, cpenv, pobenv, obsent,  tipo_cliente, telefo  } = albaran.data[0] 
                            this.datos_envio = {codemp, codsuc, codcli, nomcli, nomenv, domenv, tipo_cliente,telefo, envio_tipo: undefined, 
                                cpenv, pobenv, obsent : obsent!= null ? [{'alb' : numalb, obsent}] :[] , fecenv,  bultos, albaranes: [numalb]  }
                           this.showme = true; 
                        } 
                    else {
                        const {codemp,  codcli,   domenv, cpenv , obsent } = albaran.data[0] 
                        if (codemp != this.$pdv || codcli != this.datos_envio.codcli ){
                            await this.pararScanner()
                            await openAlertaV2(
                                'Albarán erroneo', 
                                `Albarán de un pdv o cliente distinto al inicial `
                            )     
                            await this.escaner_generico()                                     
                        }                        
                        else if (domenv !=this.datos_envio.domenv || cpenv != this.datos_envio.cpenv) {
                            await this.pararScanner()
                            await openAlertaV2(
                                'Direccion distinta', 
                                `Direccion de envio distinta a la del albarán anterior. No podemos añadirlo al mismo envio`,
                            )     
                            await this.escaner_generico()                                     
                            
                        }
                        else if (this.datos_envio.albaranes.indexOf(numalb)!=-1)
                        {
                            await this.pararScanner()
                            await openAlertaV2(
                                'Albarán erroneo', 
                                `El albaran ya esta en la relacion `
                                ) 
                            await this.escaner_generico() 
                        }
                        else {
                            this.datos_envio.albaranes.push(numalb)
                            if (obsent!=null) {
                                    this.datos_envio.obsent.push({'alb' : numalb , obsent })
                            }
                            
                        }
                    }
                }, 
                async elegirTipo(e, forzar) {
                    this.datos_envio.envio_tipo = e.k

                    /**
                     * Solelectric	Era 28 Bajos	Palma De Mallorca	07198
                    Instal.Lacions Mayol, C.B.	Albocasser, 9	Manacor	07500
                     * 
                     */
                
                    /**
                     * San Telmo	Av. Sant Telmo	Andratx	07159
                        this.datos_envio.nomenv = 'Instalaciones Electricas Roscam'
                        this.datos_envio.domenv = 'Ed. Naves Blancas C/Can Calafat 63 L.22'
                        this.datos_envio.pobenv = '(Polig.Son Oms) Palma De Mallorca'   
                        this.datos_envio.cpenv = '07610'

                        this.datos_envio.nomenv = 'Instalaciones Electricas Roscam'
                        this.datos_envio.domenv = 'Ed. Naves Blancas C/Can Calafat 63 L.22'
                        this.datos_envio.pobenv = '(Polig.Son Oms) Palma De Mallorca'   
                        this.datos_envio.cpenv = '07610'
                    */

                    window.fake_console(this.datos_envio)
                    if (this.datos_envio.cpenv == '99999'){
                        this.datos_envio.cpenv = null
                    }
                    
                    const coordenadas = await recuperarGoogleGeoLocEntrega(this.$http, this.datos_envio, forzar)
                    if (coordenadas && coordenadas === 'forzar') {
                        await this.elegirTipo(e, true)
                    }
                    else if (coordenadas && coordenadas !=false) {
                        const {lat_env, lon_env} = coordenadas                
                        this.datos_envio.lat_env = lat_env; 
                        this.datos_envio.lon_env = lon_env; 
                        this.distancia = await DistanciaEnvio({latitude: lat_env, longitude: lon_env })
                    }
                    else if (coordenadas === false ){
                        this.$router.replace('/envios')
                    }
                }, 
                async CrearEnvio() {
                    const  { codemp, bultos, codcli, nomcli , nomenv , fecenv, domenv , cpenv , lat_env, lon_env, pobenv , obsent , albaranes, envio_tipo, telefo }  = this.datos_envio;
                    const zona_reparto = this.rutas_pdv[cpenv] ? this.rutas_pdv[cpenv][0]['deszon'] : 'Ruta PENDIENTE'
                    //const ubicacion = envio_tipo == 'REPARTO' ?  (this.rutas_pdv[cpenv][0]['deszon']||'Ruta PENDIENTE') : undefined 
                    const ubicacion = envio_tipo == 'REPARTO' ?  zona_reparto : ubicaciones_entrega[envio_tipo] 
                    const envio =  { codemp, bultos, codcli, nomcli , nomenv , fecenv,  domenv ,  lat_env, lon_env, cpenv , pobenv , obsent , albaranes,  ubicacion, envio_tipo, telefo }
                    envio.obsent = envio.obsent.length == 0 ? null : envio.obsent 
                    /**
                     * https://api.mapbox.com/geocoding/v5/mapbox.places/miquel%20servet%202%2C08193%20bellaterra%2C%20%20spain.json?types=postcode%2Caddress%2Ccountry%2Clocality%2Cplace%2Cdistrict%2Cregion%2Cneighborhood&access_token=pk.eyJ1IjoicmF1bHNhbmNoZXoiLCJhIjoiY2w0emJpYTAyMDVzZjNlcDZjMzNzdWhobiJ9.vrNU5Ds0ZBwUNfUJmiIxiA
                     */

                    const envi = await this.$http.post(`/set_envio/${this.pdv}`, { envio } )
                    if(envi.data.status === 0  ){
                        await this.pararScanner()
                        await openAlertaV2(
                            'Error creacion', 
                            `No hemos podido crear el envio, error:\n${envio.data.alert} `
                            ) 
                        await this.escaner_generico() 
                             
                        }
                    else {
                        envio.ms_uuid = envi.data.ms_uuid; 
                        envio.bultos = parseInt(envio.bultos)||1
                        await this.imprimir_etiqueta_envio(envio)
                        await openAlertaV2(
                            'Envio creada', 
                            'Hemos creado la envio y lanzado a imprimir las etiquetas, reseteamos los valores '
                        ) 
                        this.goBack()
                    }
                },

                async eliminarAlbaran(pd){
                    const posterior = this.datos_envio.albaranes.filter(x=>x !=pd)
                    this.datos_envio.albaranes= posterior.length>0 ? posterior : undefined
                    }, 
                
                goBack() { this.$router.replace('/envios')},

                async lectorEventosGenericos() {
                    let self = this; 
                    eventBus().emitter.off(`lectura_generica:${this.element_id}`)
                    eventBus().emitter.once(`lectura_generica:${this.element_id}`,async (input_scaner)=>{
                       const alb = esAlbaran.test(input_scaner);
                       if (alb){
                             this.AlbaranInicial(input_scaner, undefined) 
                       }
                      await self.lectorEventosGenericos()
                    })
                },
                async pararScanner () {
                    await scanGenericStop(this.element_id)
                    this.blinker = false;
                }, 
                async escaner_generico() {
                    /** Hacemos reset del scanner cada vez que entramos en el campo  */
                    await scanGenericStop(this.element_id)
                    this.blinker = false
                    await scanGenericStart(this.element_id, 'desde escaner_generico')
                    this.blinker = true
                    await this.lectorEventosGenericos()
                },

                


            },    
        })
</script>

<style scoped lang="scss">
    @import "@/theme/footer-styles.scss";
    @import "@/theme/recepciones-styles.scss";
    ion-row.recepcion {
        padding: 7px !important; 
    }
    ion-content.ion-padding{
        padding: 0.5rem !important; 
    }
    ion-input.detalle {
        padding-left: 1rem !important; 
        --padding-top:  0rem !important; 
        --padding-bottom:  0.2rem !important; 
    }
    ion-item.detalle {
        --min-height: 2rem !important; 
        --padding-top:  0.1rem !important; 
        --padding-bottom:  0.1rem !important; 
    }
        
    .negrita {
        font-weight: 550 !important; 
        font-size: 105% !important; 
    }
    
    ion-button.cuarto {
        text-transform: none !important;
        white-space: normal!important; 
        width: 25% !important; 
        height: 3rem !important; 
        --border-radius: 0.4rem !important;
        font-weight: 500 !important;
        margin-bottom: 0.5rem !important; 
    }
    ion-button.cuarto.a-la-derecha  {
        float: right !important; 
    }
    ion-item.borde-arriba {
        border-top: 1px solid var(--border-color) !important; 
    }
    ion-input.bultos {
        margin-right: 0px !important; 
        margin-top: 0.3rem !important; 
        min-width: 25% !important;
        max-width: 25% !important;
    }
    ion-label.tres_cuartos {
        min-width: 75% !important;
        max-width: 75% !important;
        width: 75% !important; 
    }
ion-fab.fijo {
    position: relative !important; 
    top: 0.5rem !important; 
    right : 1rem !important; 
    z-index : 99999 !important; 
}
</style>