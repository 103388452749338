import { Storage } from '@capacitor/storage';
    const schema = 'ubiku.ges.digital'
    const consola = function(){ void(0) }; //consola


    /** ------------------------------------------------------------------------------------------ */
    const saveEanStorage = async function(pdv,clave, valor) {
        await Storage.set({ key: `${schema}:eans:${pdv}:${clave}` , value: valor})
    }

    /** ------------------------------------------------------------------------------------------ */
    const loadEanStorage = async function(pdv, clave) {
        try {
                const {value} = await Storage.get({ key: `${schema}:eans:${pdv}:${clave}` })
                return value
                    
            }
        catch(ey) {
            return undefined
        }            
    }

    /** ------------------------------------------------------------------------------------------ */
    const delEanStorage = async function(pdv,clave ) {
        await Storage.remove({ key: `${schema}:eans:${pdv}:${clave}` });
    }

    const delEanAll = async function(pdv ) {
        try {
            const {keys}  = await Storage.keys()
            const borrables = keys.filter(v => v.indexOf( `${schema}:eans:${pdv}`)!=-1)
            await borrables.reduce(async (m,v)=> { 
                const memo = await m ;
                await Storage.remove({ key: v });
                return memo
            } , 0)
            return true 
        }
        catch{
            return false 
        }
    
    }

    const listEanStorage =  async function( pdv ) {
        try {
            const {keys}  = await Storage.keys()
            consola(keys ,`${schema}:eans:${pdv}`)
            return keys.filter(v => v.indexOf( `${schema}:eans:${pdv}`)!=-1).map(x => x.replace(`${schema}:eans:${pdv}:`,''))
        }
        catch {
            return undefined
        }
    }

export { 
        saveEanStorage, loadEanStorage, delEanStorage , listEanStorage , delEanAll

    }

