
<template>
    <ion-page >
        <CabeceraComponentBack  
            :pdv = "pdv"
            :isIOS = "$route.meta.isIOS"
            titulo="Lista de Mostrador "
            color="primary"
            previo="clientes-carrito-mostrador"

        />
        <ion-content class="ion-padding" v-if="cliente" >                   
  
              <ion-row >
                    <ion-col size="9">
                        <ion-button class="alternativa impresion" fill="solid" expand="block" color="tertiary" @click="BusquedaManual()">
                            <i class="fas fa-shapr fa-search blanca">
                                <ion-text class="ion-text-wrap en-botton">
                                    Buscar producto
                                 </ion-text>
                            </i> 
                        </ion-button>       
                    </ion-col>
                    <ion-col size="30">
                        <ion-button expand="block" fill="outline" size="large"  class="trigger_scan" >
                                <ion-title>Lista en curso de cliente
                                <br/> 
                                <ion-text class="ion-text-wrap estrecho">
                                    <b>{{nomcli}}</b><br/>( {{cliente}} )
                                </ion-text>
                            </ion-title>
                        </ion-button>
                    </ion-col>

                    <ion-col size="9">
                        <ion-button class="alternativa impresion" fill="solid" expand="block" color="darksuccess" @click="BusquedaFavoritos()">
                            <i class="fas fa-sharp fa-star blanca">
                                <ion-text class="ion-text-wrap en-botton">
                                    Habitual Cliente
                                 </ion-text>
                            </i> 
                        </ion-button>       
                    </ion-col>
                </ion-row>

                <ion-row >
                    <ion-list  class="ion-no-padding"  v-if="datos && datos.length>0">       
                        <ion-list-header class="head"  style="width:100% !important;">
                            <ion-row>
                                <ion-col size="48">
                                    <ion-text class="fake-titulo"> Articulos lista  </ion-text>
                                </ion-col>
                            </ion-row>
                        </ion-list-header>

                        <ion-item-sliding v-for="(product,index) in datos"
                                v-bind:key="`item-${index}`" >
                            <ion-item 
                                v-bind:key="`item-${index}`"
                                class="ion-no-padding ion-no-margin">        
                                <ion-row >    

                                        <ion-col size-offset="1" size="47"  class="ion-no-margin  ion-no-padding">
                                            <h3 @click = "ElementoStockSeleccionado(product)" > {{ $filters.showSku(product.sku)   }}
                                                    <span class="lote forzar_derecha" :class="product.tipo =='stock' ? 'verde' : 'rojo'">{{ $filters.Initcap(product.tipo||'')   }}</span>

                                            </h3>
                                            <h4 @click = "ElementoStockSeleccionado(product)" class="nombre_cortado" text-wrap>
                                                {{product.shortDescription}}
                                            </h4>
                                        </ion-col>

                                        <!-- RESULTADOS DESDE EL STOCK -->
                                        <ion-col size-offset="1" size="30"  class="ion-no-margin  ion-no-padding">
                                            <h5 @click = "ElementoStockSeleccionado(product)" class="full-h5"> 
                                                <span class="lote" >Suc: {{ product.codsuc   }}</span>
                                                <span class="lote forzar_derecha" v-if="product.ubi">Ubic : {{ product.ubi   }}</span>
                                                <span class="lote forzar_derecha rojo" v-else>No Ubicado</span>
                                            </h5>
                                            <h5 @click = "ElementoStockSeleccionado(product)" class="nombre_cortado" text-wrap v-if="product.lote!=null">
                                                <b><span class="lote">Lote : {{ product.lote  }}</span></b>
                                            </h5>
                                        </ion-col>
                                        <ion-col size="11"  class="ion-no-margin  ion-no-padding ion-text-end">
                                            <ion-text  @click = "ElementoStockSeleccionado(product)" class="stock" text-wrap>
                                                <span class="stock"><b>{{$filters.Entero(product.uds_alb)}}</b></span>
                                            </ion-text>
                                            <ion-text @click = "ElementoStockSeleccionado(product)" class="mini-lista" text-wrap>
                                            {{product.unimed}}{{product.uds_alb>1 ? 's' : '' }}
                                            </ion-text>
                                        </ion-col>

                                         <ion-col size="6"  class="ion-no-margin  ion-no-padding ion-text-end  ion-align-items-baseline"
                                            @click="checkLinea(product)">
                                            <ion-icon :icon="product.done ? checkbox : squareOutline" 
                                                class="muy-grande carro"
                                                :color="product.done ? (product.block===true ? 'medium' : 'darksuccess'): 'secondary'"> </ion-icon>
                                    </ion-col>
                                    </ion-row>
                                </ion-item>


                                    
                          
                            <ion-item-options side="end">
                                <ion-item-option color="danger" @click="borrar(product)">
                                    <ion-icon slot="bottom" class="muy-grande" :icon="trashOutline"></ion-icon>
                                    <span class="boton-opciones">Borrar</span>
                                </ion-item-option>
                            </ion-item-options>
                        
                        </ion-item-sliding>
                    </ion-list>


                    <ion-item v-else>
                        <ion-row no-padding no-margin  no-lines >
                                <ion-col class="centro">
                                    <ion-label >No hay articulos en la lista de cliente </ion-label>
                                </ion-col>
                        </ion-row>      
                    </ion-item>

                </ion-row>
        </ion-content>
     
     

     
     
     <ion-footer >
            <ion-toolbar color="primary" >    

                <ion-buttons slot="start" class="completo" color="darkdanger" >    
                    <ion-button class ="completo"  expand ="block" fill="solid" color="darkdanger"
                        id="borrar_lista"
                        size="normal" @click="DelAll()"
                        :disabled ="!datos || datos.length==0"
                        > 
                        <b>Borrar</b>
                    </ion-button>
                </ion-buttons>



                <ion-buttons slot="end" class="completo"  >    
                    <ion-button class ="completo"  expand ="block" fill="solid" 
                        id="traspasar_lista"
                        size="normal" @click="UpdateMysql()"
                        :disabled ="!datos || datos.length==0"
                        > 
                        <b>Traspasar</b>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-footer>


    </ion-page>
</template>

<script>
    import CabeceraComponentBack from '@/components/CabeceraComponentBack';
    import { reject  } from 'underscore';
    import { defineComponent } from 'vue';
    
    import { scanSkuMostradorStart, scanSkuStop } from '@/utilities/scanner/scan-sku'
    import { saveMostradorStorage, loadMostradorStorage ,delMostradorStorage } from '@/utilities/storage/storage-mostrador'
    import { openAlertaV2 , openAlertaSiNo } from  '@/utilities/services/alert-utilities';
    import {  isOnline}  from '@/utilities/global_functions'
    import { trashOutline , star, search, documentTextSharp , checkbox,  squareOutline } from 'ionicons/icons';

    import eventBus from "@/event-bus"; 

 
    export default defineComponent({
            name : "ListaMostradorPage",
            components: { CabeceraComponentBack  } , 
            props:['cliente', 'origen'],
            data() {
                return {
                    trashOutline, star,  search, documentTextSharp, checkbox,  squareOutline , 
                    element_id : 'selector_mostrador_articulos', 
                    datos: [], 
                    nomcli: undefined, 
                    pdv: undefined
                }
            }, 
            async created() {
                this.pdv =  this.$route.meta.pdv 
                this.nomcli = this.$route.meta.nombre_cliente
                window.fake_console('listado de mostrador: entro en created', this.$route.meta.pdv, this.cliente)
            },
            async mounted() { 
                window.fake_console(this.datos)
                window.fake_console('mounted', this.cliente)
                await this.inicializar(true)
                this.escanear()
            },      

            async unmounted() {
                await scanSkuStop(this.element_id)
            }, 

            methods: {     
                async recuperar_bbdd() {
                        const [pdv, codcli] = this.cliente.split('.')
                        const lista_bbdd = await this.$http.post(`/get_carrito/${pdv}/${codcli}`)
                        const dat = lista_bbdd.data 
                        return (dat && dat.length>0) ? dat : undefined 
                }, 
                async inicializar(forzar){
                    let lista_previa = undefined; 
                    
                    const stg = await loadMostradorStorage(this.cliente, this.pdv)
                    /** 
                     * Si venimos del listado de clientes recuperamos lo que haya en base de datos 
                     * Y ofrecemos añadirlo / consolidarlo 
                     * */
                    if (this.origen == 'lista-clientes' && forzar == true) { lista_previa = await this.recuperar_bbdd() }
                    window.fake_console(this.origen, stg , lista_previa)
                    /** si hay dos listas le hacemos elegir  */
                    if (lista_previa) {
                        const confirmacion = await openAlertaSiNo(this.cliente, 'Lista previa existente!', `
                            Hay una lista previa del cliente ${this.nombre}.<br/>\n
                            Si quieres recuperarla pulsa "SI", sino "CANCELAR"`)    
                        if (confirmacion) {                            
                            lista_previa = lista_previa.reduce((m,valor)=> {
                                const {sku,codemp, codsuc , lote, tipo } = valor 
                                const clau = `${sku}.${codemp}.${codsuc}.${!lote ? 'N' : lote }.${!tipo ? 'pedido' : tipo}`
                                valor['fila'] = clau;
                                m.push(valor)
                                return m 
                            } , []) 
                            
                            await saveMostradorStorage(this.cliente, this.pdv, lista_previa)
                            this.datos = lista_previa
                            
                        }
                        else {
                            const [pdv, codcli ] = this.cliente.split('.')
                            await this.$http.post(`/set_carrito/${pdv}/${codcli}`,{ carrito: [] } )
                            this.datos = stg                            
                        }
                    }
                    /*
                    else if (lista_previa) {
                        await saveMostradorStorage(this.cliente, this.$pdv, lista_previa)
                        this.datos = lista_previa 
                    }*/
                    else { 
                        if (stg){ this.datos = stg }
                    }
                    try
                        {
                        this.datos.sort( function(a,b) {
                            const a1 = a.done==true ? 'Z' : 'A';
                            const b1 = b.done==true ? 'Z' : 'A';
                            const a2 = (a.ubi||'NOUBI').toString()
                            const b2 = (b.ubi||'NOUBI').toString()
                            /** primero ordenamos por pendientes / hecho, después por zona ascendente */
                            return a1.localeCompare(b1)||a2.localeCompare(b2)
                        })
                        }
                    catch {
                        return 
                    }   
                },
                async borrar(item) {
                    this.update_data(item, 'remove')
                    await saveMostradorStorage(this.cliente, this.$pdv, this.datos)
                },
                
                async ElementoStockSeleccionado(item) {
                    window.fake_console(this.$router)
                    window.fake_console(item)
                    this.$router.replace({'name' :'detalle_producto', params : { sku: item.sku , origen: `/carrito-mostrador/${this.cliente}`}})    
                        window.fake_console(item)
                 },
                
                async DelAll(){
                    await delMostradorStorage(this.cliente, this.$pdv)
                    this.datos = [];
                },

                async BusquedaManual(termino_entrada, tipo_busqueda) { 
                    const {nombre, cliente} = this; 
                    const origen =  `/carrito-mostrador/${cliente}`
                    let parametros 
                    if ( termino_entrada ) {
                        parametros ={nombre, cliente, origen, termino_entrada, tipo_busqueda} 
                    }
                    else {
                        parametros ={nombre, cliente, origen} 
                    }
                    window.fake_console('REDIRIJO A LA BUSQUEDA MANUAL ', parametros)
                    this.$router.replace({'name' : 'busqueda_mostrador' , params: parametros } )
                }, 

                async BusquedaFavoritos() { 
                    const {nombre, cliente} = this; 
                    const origen =  `/carrito-mostrador/${cliente}`
                    let parametros ={nombre, cliente, origen} 
                    window.fake_console('REDIRIJO A LA BUSQUEDA DE FAVORITOS ', parametros)
                    this.$router.replace({'name' : 'favoritos_mostrador' , params: parametros } )
                }, 


                async UpdateMysql() {
                    window.fake_console(this.datos)
                    let self = this
                    const codcli = this.cliente.split('.')[1]
                    window.fake_console(this.datos[0])
                    const payload = this.datos.reduce((memo,val)=> {
                        const {sku, codsuc,  uds_alb, lote, tipo} = val; 
                        memo.push({ codsuc,  codmar:sku.slice(0,3), artpro:sku.slice(4), uds_alb, lote, tipo})
                        return memo 
                    } , [])
                    window.fake_console('FUNCIÓN PENDIENTE')
                    if (isOnline) { 
                        /** Grabamos el carrito  */
                        try
                        {
                            const feedback = await self.$http.post(`/set_carrito/${this.pdv}/${codcli}`,{ carrito: payload})
                            if (feedback.data.status == 1) {
                                await openAlertaV2('Lista transferida', `
                                        Has grabado la lista del cliente ${this.cliente}.<br/>\n
                                        Borramos memoria y te dirigimos a la pantalla de seleccion de clientes  `) 
                                await this.DelAll()
                                this.$router.replace('/clientes-carrito-mostrador')
                                }
                            else { throw 'feedback negativo' }
                        }                        
                        catch(ex) {
                            window.fake_console('ERROR ', ex)
                        }
                        
                    }
                    /* Sino guardamos para más adelante */
                    else {
                        await openAlertaV2('Sin Cobertura!!' , `
                            Ahora mismo no tienes cobertura. \n 
                            No te preocupes, el trabajo queda grabado en tu movil y puedes actualizarlo en Gestion 2000  cuando estes en una zona con cobertura.\n
                            Te redirigimos a la pagina de selección de clientes.  `) 
                        this.$router.replace('/clientes-carrito-mostrador')
                    }
                }, 
                async checkLinea(item) {
                    if ( item.block!==true )
                        {                    
                            window.fake_console('En la entrada ... ')
                            window.fake_console(item)
                            this.showme = false;
                            item.done = !item.done ; 
                            window.fake_console('lo marco')
                            const clau = `${item.sku}.${this.$pdv}.${item.codsuc}.${!item.lote ? 'N' :item.lote}.${!item.tipo ? 'pedido' : item.tipo}`
                            let self = this 
                            const quedan = reject(this.datos , (e)=> e.fila ==  clau )
                            window.fake_console(quedan)
                            if (item.done === true ) { quedan.push(item) }
                            else { quedan.unshift(item) }
                            this.datos = quedan; 
                            window.fake_console(quedan)
                            window.fake_console(this.datos)
                            await saveMostradorStorage(self.cliente, self.$pdv, self.datos)  
                            if (this.datos.length == 0 ) {
                                await delMostradorStorage(this.cliente, this.$pdv)
                            }                        
                            this.showme = true; 
                        }
                },



                async update_data(item){
                    const clau = `${item.sku}.${this.$pdv}.${item.codsuc}.${!item.lote ? 'N' :item.lote}.${!item.tipo ? 'pedido' : item.tipo}`
                    let self = this 
                    const quedan = reject(this.datos , (e)=> e.fila ==  clau )
                    this.datos = quedan; 
                    await saveMostradorStorage(self.cliente, self.$pdv, self.datos)  
                    if (this.datos.length == 0 ) {
                        await delMostradorStorage(this.cliente, this.$pdv)
                    }                        
                    return 
                }, 

                async lectorEventos() {
                    let self = this; 
                    eventBus().emitter.off(`resultados:${this.element_id}`)
                    eventBus().emitter.once(`resultados:${this.element_id}`,async (resultados)=>{
                        window.fake_console(resultados)
                        const {estado, accion, termino } = resultados 
                        if ( estado == true ) {
                            switch ( accion ){
                                case 'add':
                                    window.fake_console('entro en el add ')
                                    self.inicializar()
                                    break
                                case 'filtra' :
                                    window.fake_console('en FILTRA')
                                    self.BusquedaManual( termino, 'filtra')
                                    break
                                case 'busca' :    
                                    window.fake_console('EN BUSCA')
                                    self.BusquedaManual( termino, 'busca')
                                    break
                                default : 
                                    window.fake_console('NO HACER NADA')
                                    void(0)                
                                    break
                            }
                        }
                        window.fake_console('reseteo el lector de eventos')
                        self.lectorEventos()
                    })
                },
                async escanear() {
                    await scanSkuMostradorStart(this.element_id, this.cliente, this.pdv , this.$http)
                    await this.lectorEventos()
                },
            }, 
            computed :{
                largo: function () {
                    return Object.keys(this.datos).length
                    }
            },
 
    })
</script>

<style scoped  lang="scss">
    @import "@/theme/footer-styles.scss";

    @import "@/theme/seleccion-pedido-styles.scss";
    span.boton-opciones {
        font-size: larger !important; 
        padding-bottom: 0.3rem !important; 
        font-weight: bold !important;
    }
    ion-badge {
        width: 100% !important; 
    }
    ion-row.altos {
        height: 3rem !important;
        
    }
    ion-text.en-botton {
        font-family: 'Roboto';
        font-size: 0.9rem !important; 
        color: var(--ion-color-blanco)  !important;
        text-transform: none !important;
        line-height: 0.4rem !important;
    }
    i.fas.blanca, i.far.blanca {
        font-size: 2rem !important; 
        color: var(--ion-color-blanco)  !important;
        line-height: 1rem !important; 
        }
    ion-row {
        max-width: 600px !important; 
        padding: 0.3rem 0.2rem !important; 
    }

    ion-item {
        --inner-padding-end : 0px !important; 
        --padding-end : 0px !important; 
    }
    ion-text.fake-titulo {
        color: var(--ion-color-primary) !important; 
    }
    span.oscuro {
        color: var(--ion-color-medium) !important; 
        padding-left: 0.3rem !important; 
    }
    span.primario {
        color: var(--ion-color-primary) !important; 
        padding-left: 0.3rem !important; 
    }
    span.peque {
        font-size: smaller !important; 
        padding-right: 0.3rem !important; 
    }
    i.fas.tiempo , i.far.tiempo  {
        color: red; 
        font-weight: 600 important; ;
        font-size: 1.8rem !important;
        padding-right: 0.4rem !important
    }

    ion-item.iniciado {
        border-right: 10px  solid !important;
        border-image: linear-gradient(to bottom, white, var(--ion-color-darkdanger), white) 2;
    }

    ion-icon.crossdock {
        font-size: xx-large !important; 
        font-weight: 1000 !important;

    }
    .margen-down {
        margin-bottom: 0.3rem !important; 
    }

</style>