<template>
    <ion-col @click="transicion" v-if="link!='none'">
        
        <ion-card :routerLink="link =='none' ? '' : '/'+link" routerDirection="none" :disabled="inactivo" >
            <ion-card-header>
                <ion-card-title>
                    <small>{{titulo}}</small>
                </ion-card-title>
            

            </ion-card-header>
            <ion-card-content>
                {{detalle}}
            </ion-card-content>
        </ion-card>
    </ion-col>
    <ion-col v-else>
        <ion-card  :disabled="inactivo" >
            <ion-card-header>
                <ion-card-title>
                    <small>{{titulo}}</small>
                </ion-card-title>
            </ion-card-header>
            <ion-card-content>
            {{detalle}}
            </ion-card-content>
        </ion-card>
        <i class="fas fa-regular fa-warehouse-full fa-fade tiempo" ></i>
    </ion-col>

</template>

<script>
    import {    IonCol,
                IonCard, IonCardHeader,  IonCardTitle, IonCardContent, 
            } from '@ionic/vue';
    import eventBus from "@/event-bus"; 
    import { defineComponent } from 'vue';
    export default defineComponent({
            name : "TarjetaHome",
            components :  {    
                IonCol,IonCard, IonCardHeader,  IonCardTitle, IonCardContent, 
            }, 
            props: ['link','titulo','detalle','inactivo'], 
            methods: {   
                transicion() {
                      if (!this.inactivo) {eventBus().emitter.emit('transicion',true);}
                      else {return }
                    }
              }
    })
</script>

<style scoped>
        ion-card {
            margin:0.5rem  4px !important;
            height: 8rem !important;
            width: 100% !important;
            }

        ion-card-title{
            text-align: center;
        }
        ion-card-header {
            padding : 10px 6px !important; 
        }
        div.tiempo {
            z-index : 9999!important; 
            position: relative !important; 
            top: 0.2rem !important; 
            right: 0.2rem !important; 
            display: flex !important; 
        }

        i.fas.tiempo , i.fak.tiempo , i.far.tiempo {
            z-index : 9999!important; 
            position: relative !important; 
            top: 0.4rem !important; 
            right: 0.4rem !important; 
            display: flex !important; 
            color: dark !important; 
            font-weight: 500 !important; ;
            font-size: 1.6rem !important;
            float: right !important; 
            
        }
</style>