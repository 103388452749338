<template>
    <ion-page :key="qt">
        <ion-header>
            <ion-toolbar   color="primary" class="ion-justify-content-start ion-align-items-start"> 
                <ion-title>
                    <ion-row  class="ion-justify-content-start ion-align-items-start">
                        <ion-col size="40"  class="ion-float-left izquierda" 
                            :style="isIOS ? 'padding-left: 4rem !important;': ''">
                            Revision Entrada ({{pdv}})
                        </ion-col>
                    </ion-row>                
                </ion-title>
                <ion-buttons slot="end" >
                    <ion-button size="default" color="light"  routerLink="/recepciones" routerDirection="none">
                        <i class="fa-sharp fa-solid fa-circle-left muy-grande" color="light" ></i>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content>
            
            <ion-row  class="spin ion-justify-content-center "  v-if="!showme">
                <ion-col  v-if="!showme" class="spin ion-align-self-center" size="48" > 
                    <ion-spinner  v-if="!showme"></ion-spinner>            
                </ion-col>
            </ion-row>
                 
                 
            <ion-row v-if="showme && entrada_g2k">
                <div class="resumen" v-if="resumen_entrada">
                        <ion-row  class=" cross-docking resumen-header " >
                            <ion-col class="resumen-header superior centro ion-align-self-end" size="30">Lineas entrada</ion-col>
                            <ion-col class="resumen-header borde-izquierdo superior centro ion-align-self-end" size="18">Valor</ion-col>
                            <ion-col class="resumen-header  centro ion-align-self-end" size="7">Stock</ion-col>
                            <ion-col class="resumen-header  centro ion-align-self-end" size="7">Cross</ion-col>
                            <ion-col class="resumen-header  centro ion-align-self-end" size="8">Mixtas</ion-col>
                            <ion-col class="resumen-header  centro ion-align-self-end" size="8">Total</ion-col>
                            <ion-col class="resumen-header borde-izquierdo centro  ion-align-self-center" size="9">Stock</ion-col>
                            <ion-col class="resumen-header  centro resalta ion-align-self-center" size="9">Cliente</ion-col>
                        </ion-row>
                        <ion-row  class=" cross-docking resumen-detalle " >
                            <ion-col class="resumen-detalle centro" size="7">{{$filters.Entero(resumen_entrada.linstock)}}</ion-col>
                            <ion-col class="resumen-detalle centro" size="7">{{$filters.Entero(resumen_entrada.lincross)}}</ion-col>
                            <ion-col class="resumen-detalle centro" size="7">{{$filters.Entero(resumen_entrada.linmix)}}</ion-col>
                            <ion-col class="resumen-detalle centro" size="9">{{$filters.Entero(resumen_entrada.lineas)}}</ion-col>
                            <ion-col class="resumen-detalle borde-izquierdo centro" size="9">
                                {{$filters.sIntPorcentaje(resumen_entrada.stock*100/resumen_entrada.valor)}}
                            </ion-col>
                            <ion-col class="resumen-detalle resalta centro" size="9">
                                {{$filters.sIntPorcentaje(resumen_entrada.commited*100/resumen_entrada.valor)}}
                            </ion-col>
                        </ion-row>

                </div>

                <div class="revision-stock">
                    <ion-toolbar color="primary" style="padding-top:0px;" class="top">
                            <ion-searchbar  v-model="search_manually"
                                id="filtro_revision_entrada"
                                :debounce="debounce_milis" 
                                @ionClear="onCancel($event)"
                                autocomplete="off" autocorrect="off" 
                                autocapitalize="off" spellcheck="false"
                                show-clear-button="always"
                                placeholder="Filtrar lineas en repaso" 
                                inputmode ="search"
                                type="search"
                                @ionChange="filtroInputSearch"
                                @ionBlur="getFoco($event, false)"
                                @ionFocus="getFoco($event,true)"
                                slot="start"
                                mode = "md">
                        </ion-searchbar>       
                    </ion-toolbar>
                    <ion-row  class=" cross-docking stock-sum-header " >
                        <ion-col class="stock-sum-header centro" size="22">Referencia</ion-col>
                        <ion-col class="stock-sum-header centro" size="8">Uds</ion-col>
                        <ion-col class="stock-sum-header centro" size="14">Ubicacion</ion-col>                        
                    </ion-row>

            


                    <div class="borde" v-for="(tipo) in Object.keys(repaso_todos)"
                         lines="none" :key="`caso:${tipo}`">
                    
                    <ion-list >

                        <div class="stock-sum-title" v-if="repaso_todos[tipo] && cabeceras_activas[tipo]">
                            <ion-row class="cross-docking" v-if="cabeceras_activas[tipo]" >
                                <ion-col class="stock-sum-detalle superior" :size="tipo === 'stock' ? 48 : 22"  v-if="repaso_todos[tipo]" >      
                                    <span class="izquierda primario">
                                        {{tipo === 'stock' ? 'Material para stock'  : $filters.Initcap( tipo)}} 
                                    </span>
                                </ion-col>
                                <ion-col :size="tipo === 'stock' ? 0 : 26" v-if="repaso_todos[tipo][0]['ubicacion'] && tipo !='stock'">
                                    <ion-button 
                                        size="small" class="boton-impresion-etiqueta" 
                                        :disabled="cabeceras_completas[tipo]"
                                        @click="imprimir_etiqueta(repaso_todos[tipo][0]['ubicacion'])"
                                        >
                                            <ion-icon slot="start" :icon="printSharp" class="impresion"></ion-icon>
                                            <span class="boton-impresion-etiqueta ">{{repaso_todos[tipo][0]['ubicacion']}}</span>
                                    </ion-button>

                                </ion-col>
                            </ion-row> 
                        </div>

                        <div class="stock-sum" v-if="repaso_todos[tipo]">
                            <div  v-for="(stock,index) in repaso_todos[tipo]"  :key="`stock-sum-${index}`" >
                                <ion-row class=" separata cross-docking stock-sum-detalle" v-if="sku_activas[stock['_id']]">
                                    <ion-col size="40" class="estructura">
                                        <ion-row class="estructura">   
                                            <ion-col class="stock-sum-detalle superior" size="48">      
                                                <span class="izquierda primario sku-stock">
                                                    {{stock.sku}} 
                                                </span>
                                            </ion-col>
                                        </ion-row>
                                        <ion-row class="estructura">  
                                            <ion-col  size="30" class="nota">
                                                <ion-note v-if="stock.numlot"><i>Lote: {{stock.numlot}}</i></ion-note>
                                            </ion-col>
                                            <ion-col class="stock-sum-detalle"   size="8">
                                                <span class="forzar_derecha ">{{$filters.sDecimal(stock.unidad)}}</span>
                                            </ion-col>
                                        </ion-row>
                                    </ion-col>
                                    <ion-col class="stock-sum-detalle superior estructura" size="8" >      
                                        <ion-toggle mode="md" color="primary"  @ionChange="control_revision" v-model="stock.revisado" ></ion-toggle>
                                        <div class="stock-sum-detalle" v-if="tipo==='stock' && stock.ubicacion" >
                                            <span class="limite-inferior">{{stock.ubicacion}}</span> 
                                        </div>
                                        <div class="stock-sum-detalle alerta" v-else-if="tipo==='stock' && !stock.ubicacion" >
                                            <span class="limite-inferior">NO-UBICADO</span> 
                                        </div>

                                    </ion-col>
                                    
                                    
                                </ion-row>  
                            </div>
                        </div>
                    </ion-list>
                    </div>
                </div>
            </ion-row>
               
        </ion-content>

        <ion-footer >
            <ion-toolbar color="primary" >    
                <ion-buttons slot="end" class="completo" color="primary" >    
                    <ion-button class ="completo"  expand ="block" fill="outline" 
                        id="crear_entrada"
                        size="normal" @click="RevisarEntrada()"
                        :disabled ="pendientes_revision!=0"
                        > 
                        <b>Entrada G2K</b>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-footer>


    </ion-page>

</template>
    

<script>
    import { defineComponent } from 'vue';
    import {   addCircleSharp , printSharp} from 'ionicons/icons';
    import { useRoute } from 'vue-router';
    import { filter ,  extend, map, chain, some , mapObject, pluck , countBy } from 'underscore';
    import { loadEntradafromStorage   } from '@/utilities/storage/storage-entradas'
    import { scanGenericStart, scanGenericStop  } from '@/utilities/scanner/scan-generic'
    import { RegexBobinasPdv, transformSchneiderBrand} from '@/utilities/scanner/scan-sku'
    import eventBus from "@/event-bus"; 
    import { openAlertaV2 } from '@/utilities/services/alert-utilities'

    import { eans_articulo } from '@/utilities/scanner/scan-sku'
    import { DetallesReferencia } from '@/utilities/scanner/scan-sku';
    export default defineComponent({    
        name : "RevisarEntradaPage",
        data() { 
            return { 
                filter, element_id: 'revisar-entrada-albaran',
                pdv: undefined,
                isIOS: undefined, 
                 addCircleSharp, printSharp, 
                showme :false, 
                ruta: undefined, 
                qt :false , 
                debounce_milis: 300   , 
                lineas_cd : undefined, 
                entrada_g2k: [] , 
                repaso_todos: {},
                crossdocking : undefined , 
                search_manually: undefined , 
                input_activo: false,
                sku_activas : {}, 
                cabeceras_activas :{}, 
                pendientes_revision : undefined ,  
                cabeceras_completas : {}
                }
        },
        props : ['codpro', 'codalb'] , 
        created() {
            this.pdv =  this.$route.meta.pdv 
            this.isIOS = this.$route.meta.isIOS 
        },
        updated() {
            this.pdv =  this.$route.meta.pdv 
        },
        async unmounted() {
            await scanGenericStop(this.element_id)
        }, 

        computed :{
            resumen_entrada: function () {
                return this.entrada_g2k.reduce((m,x)=>{
                    const sk = `${x.codmar}.${x.artpro}`
                    m['lineas']= (m['lineas']|0)+1
                    m['linstock'] = (m['linstock']|0)+((this.cd_per_sku[sk]|0)== 0 ? 1 : 0 ) 
                    m['lincross'] = (m['lincross']|0)+((this.cd_per_sku[sk]|0)== x['udsrec'] ? 1 : 0 ) 
                    m['linmix'] = m['lineas']-m['linstock']-m['lincross'] 
                    m['valor'] = (m['valor']|0)+(x['vstock']|0)
                    m['commited'] = (m['commited']|0)+((this.cd_per_sku[sk]|0)*(x.pmp_ges|0))
                    m['stock'] = (m['valor']|0)-(m['commited']|0)
                    return m 
                }, {})
                }, 
            cd_per_sku: function () {
                return this.crossdocking .reduce((m,v)=> {
                    const sku  = v.numlot ?`${v.codmar}.${v.artpro}|.|${v.numlot}`   : `${v.codmar}.${v.artpro}`  
                    m[sku] = (m[sku]|0)+v['unidad']
                    return m 
                }, {})
                }

        }, 
        async mounted() {
            this.pdv =  this.$route.meta.pdv;               
            const route = useRoute();
            this.ruta = route.fullPath
            this.qt = route.query.q
            this.entrada_g2k = await loadEntradafromStorage(this.pdv, this.codpro, this.codalb, 'entrada')
            this.crossdocking = await loadEntradafromStorage(this.pdv, this.codpro, this.codalb, 'crossdocking')
            window.fake_console(this.entrada_g2k)
            window.fake_console(this.crossdocking)
            window.fake_console(this.entrada_g2k)
            await this.preparaDatosRevision()
            this.onCancel()
            await this.escaner_generico()
        },
        methods: {
            getFoco (ev, si) {
                window.fake_console('evento escuchado')
                if(si===true) {
                    this.input_activo = true; 
                    window.fake_console('activado')
                }
                else {
                    this.input_activo=false; 
                    window.fake_console('desactivado')
                }
            },
            onCancel(){
                this.showme = false
                this.search_manually = undefined; 
                this.filtrar()
                this.showme = true 
                },
            async lectorEventosGenericos() {
                let self = this; 
                eventBus().emitter.off(`lectura_generica:${this.element_id}`)
                eventBus().emitter.once(`lectura_generica:${this.element_id}`,async (input_scaner)=>{
                    self.input_activo = false;
                    let buscar = input_scaner
                    /** miramos si el termino tiene includo un codigo de bobina , en cuyo caso lo eliminamos */
                    if ( RegexBobinasPdv.test(input_scaner) && input_scaner.replace(RegexBobinasPdv,'').length > 2)
                        { 
                            buscar = input_scaner.replace(RegexBobinasPdv,'')
                            window.fake_console(input_scaner, buscar)
                        }
                    self.search_manually = buscar
                    self.filtrar('Via lector Generico' , 'exacta') 
                    self.lectorEventosGenericos()
                })
            },
            async filtroInputSearch() {
               window.fake_console(this.input_activo)
               window.fake_console(this.search_manually)
                if (this.input_activo) {
                    window.fake_console('lanzo la busqueda')
                    this.filtrar('Caja de busqueda via filtroInputSearch')
                }
            }, 
            async escaner_generico() {
                await scanGenericStart(this.element_id, 'desde escaner_generico')
                await this.lectorEventosGenericos()
            },

            goBack() {
                this.$router.go(-1) 
            },

            async preparaDatosRevision(){
                const a = this.entrada_g2k.reduce((m,v)=> {
                        const sk = `${v.codmar}.${v.artpro}`
                        const {udsrec, desart, codsuc, numlot} = v
                        window.fake_console(v)
                        if (m[sk]=== undefined) {
                            m[sk] = extend({ 'sku':sk}, {udsrec, desart, codsuc , numlot }, {eans: eans_articulo[sk]|undefined})
                        }
                        else { m[sk]['udsrec']+=udsrec }
                        window.fake_console(m)
                        return m 
                    }, {})
                const stock = map(a, (x,k) => {
                        const rollo = new RegExp(/^rollo/i) 
                        const referencia = x.numlot ? `${k}|.|${x.numlot}` : k  
                        window.fake_console(referencia)
                        x['_id'] = referencia 
                        x['udscross'] = this.cd_per_sku[referencia]|0
                        x['unidad'] = x['udsrec']-x['udscross']   
                        x['revisado'] = false
                        window.fake_console(x)
                        const detalles = DetallesReferencia(k )
                        window.fake_console(k, detalles, x.codsuc)
                        window.fake_console(referencia, x.codsuc, detalles)
                        if (detalles.length>0 ){
                            window.fake_console(detalles[0])
                            if (! x.numlot)
                            {                            
                                const seleccionada = detalles.filter(y=>y.codsuc == x.codsuc)[0]||{}
                                x['ubicacion'] = seleccionada['ubi']
                                window.fake_console (seleccionada) 
                                window.fake_console('caso sin lote', seleccionada) 
                            }                        
                            else if (rollo.test(x.numlot)){
                                /** proponemos ubicacion donde hay rollos de esta referencia  */
                                const seleccionada = detalles.filter(y=>rollo.test(y.numlot))[0]||{}
                                x['ubicacion'] = seleccionada['ubi']
                                window.fake_console('caso rollo ', seleccionada)
                            }
                            else {
                                /** Es una bobina, se ha de ubicar ad-hoc */
                                x['ubicacion'] = undefined 
                                window.fake_console('resto de sacos ')
                            }
                        }
                        window.fake_console(x)
                        return x 
                    }).filter(u=>u.unidad!=0)
                window.fake_console(stock)

                const crossdock = this.crossdocking.reduce((m,u)=> {
                    const clave = `pedido ${u.numpedc}`
                    const sku = `${u.codmar}.${u.artpro}`
                    const referencia = u.numlot ? `${sku}|.|${u.numlot}` : sku
                    const {ubicacion, unidad,  codsuc, numlot} = u

                    if (m[clave] === undefined ) 
                        {
                            m[clave] = [{sku, ubicacion, unidad, codsuc, numlot, _id: referencia, revisado:false}]
                        }
                    else 
                        {
                            m[clave].push({sku, ubicacion, unidad, codsuc, numlot, _id: referencia, revisado: false })
                        }
                    return m 
                }, {} )
                window.fake_console({stock, ...crossdock})
                window.fake_console(crossdock)
                if (stock.length>0 ){
                    this.repaso_todos = { stock , ...crossdock}
                }
                else {  
                    this.repaso_todos = { ...crossdock}    
                }
                this.control_revision()
                this.filtrar()
                /** Variable para generar el filtro de las cabeceras */
                window.fake_console(crossdock)
                window.fake_console(stock)
                window.fake_console(this.repaso_todos)
                window.fake_console(this.entrada_g2k)
                window.fake_console(this.crossdocking)
            }, 

        async filtrar () {
            this.showme = false; 
            const terminos = transformSchneiderBrand(this.search_manually)
            window.fake_console(terminos )
            let final , cabeceras
            if (!terminos || terminos === false ) {
                final =  chain(this.repaso_todos).values().flatten().reduce((m,v)=> {
                    const sk = v['numlot'] ? v['sku']+'|.|'+v['numlot'] : v['sku']
                    m[sk]=1 ; 
                    return m 
                    },{}).value()
                cabeceras = mapObject(this.repaso_todos, (v) =>  v ? 1 :0 )
                window.fake_console(cabeceras)

                }
            else {
                const secondarySearch = terminos.toLowerCase().replace(/ +$/,'').split(' ').map(v => new RegExp(`${v}`,'i'))
                final = chain(this.repaso_todos).values().flatten().reduce((m,v)=> {
                    const sk = v['numlot'] ? v['sku']+'|.|'+v['numlot'] : v['sku']
                        if (some(secondarySearch , t => t.test(JSON.stringify(v).toLowerCase())))
                        {  m[sk] = 1 }
                        else { m[sk] = undefined }
                        return m 
                    },{}).value()
                cabeceras = mapObject(this.repaso_todos,(v)=> {
                    const sks = pluck(v,'sku')
                    return some(sks, s=> final[s] ) ? 1 : undefined 
                })
            }
            
            this.sku_activas = final 
            this.cabeceras_activas = cabeceras
            window.fake_console(this.sku_activas)
            window.fake_console(this.cabeceras_activas)
            this.showme = true
        }, 
        async control_revision(){
            const pendientes  = chain(this.repaso_todos).values().flatten().pluck('revisado').countBy().value()
            this.pendientes_revision = pendientes[false]||0
            window.fake_console(chain(this.repaso_todos).values().flatten().pluck('revisado').countBy().value())  
            this.cabecera_completa()
        }, 
        async imprimir_etiqueta(ubi) {
            try  { 
                await  this.$http.post(`/etiqueta/ubicacion/${this.$pdv}`, {ubi:ubi})
                }
            catch (ex) {
                console.log(ex)
                window.miconsola('Error : ', ubi)
            }
        } , 
        async cabecera_completa(){
            const cabeceras_pendientes = chain(this.repaso_todos).mapObject((v)=> {
                const pendientes = countBy(pluck(v,'revisado'))[false]
                return  pendientes && pendientes != 0 
            }).value()
            this.cabeceras_completas  = cabeceras_pendientes
        }, 

        async RevisarEntrada(){
            try {
                const { entrada_g2k, crossdocking, codalb, codpro } = this ;
                const confirmacion_entrada = await this.$http.post(`/set_entrada/${this.$pdv}`,{ entrada_g2k, crossdocking, codalb, codpro }  )
                const dat = confirmacion_entrada.data 
                window.fake_console(dat)
                if (dat.status && dat.status == 1 )
                {
                    await openAlertaV2('Entrada grabada!', dat.alert)   
                    this.$router.replace('/recepciones')
                }
                else {
                    openAlertaV2('Error en la entrada!', dat.alert)

                }
            }
            catch (e) {
                console.log(e)
                console.log(e)
            }
        }, 


        },    
    })
</script>

<style scoped lang="scss">
    @import "@/theme/footer-styles.scss";
    @import "@/theme/seleccion-producto-stock.scss";
    @import "@/theme/revisar-crossdocking-cliente.scss";

    ion-note {
        font-style: italic !important;
        font-weight: 700 !important;
        padding-left: 0.3rem !important; 
    }
    ion-col.nota {
        padding-top: 0px !important; 
        padding-bottom: 0px !important; 
    }
    ion-row.separata {
        border-bottom: 1px solid var(--ion-color-light) !important; 
    }
    span.sku-stock {
        font-weight: 700 !important;
    }
    ion-toogle {
        padding: 0.2rem 0.8rem !important; 
    }



    span.boton-impresion-etiqueta {
        color: var(--ion-color-white) !important; 
        font-size: 1.1rem !important; 
        padding: 0.3rem 0.8rem !important; 
    }
    ion-button.boton-impresion-etiqueta{
        width: 100% !important; 
    }
 
    ion-icon.impresion  {
        color: var(--ion-color-white) !important; 
        /*padding-right: 0.4rem !important; */
    }


</style>