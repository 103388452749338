<template>
    <ion-page>
          <ion-content  key="`content:${qt}`">
            <ion-fab vertical="top" horizontal="end" slot="fixed" v-if="pdv && impresoras && impresoras.length>1 && $logged">
                    <ion-fab-button  color="primary" @click="elegir_impresora()">
                      <i class="fas  fa-print en-fab"></i>  
                    </ion-fab-button>
            </ion-fab>

            <ion-fab vertical="top" horizontal="start" slot="fixed" v-if="pdv && $logged && delegado && pdvsSalidas.indexOf(pdv)!=-1">
                <ion-fab-button  color="darkdanger" @click="irPagina('asignar-transportes')">
                   <i class="fak fa-solid-truck-circle-user en-fab"></i>
                </ion-fab-button>
            </ion-fab>
            <ion-fab vertical="bottom" horizontal="start" slot="fixed" v-if="pdv && $logged && ubiku_rol == 10  && pdvsSalidas.indexOf(pdv)!=-1">
                <ion-fab-button  color="darksuccess" @click="irPagina('asignar-delegados')">
                   <i class="fas fa-user-shield en-fab"></i>
                </ion-fab-button>
            </ion-fab>

            <ion-grid class="google">     
              <ion-row v-if= "pdvs && showme" class="selects"> 
                <ion-select  :class="pdvSel==undefined ? 'rojo' : 'gris'"
                    placeholder="Elegir PDV" v-model="pdvSel" @ionChange="setPdv($event)"  key="pdvs_sel">
                        <ion-select-option   v-for="(p,index) in pdvs" :key="`pdv:${index}`"  :value="p">
                            {{p.nompdv}}
                        </ion-select-option>    
                    </ion-select>
                    
              </ion-row>

              <ion-row class="google ion-align-items-center ion-justify-content-center">
                <ion-button id="google-login-btn" v-on:click="loginWithGoogle" fill="outline" size ="large" color="primary" class="login">
                    <ion-icon slot="start" icon="assets/google.svg"/>
                    Login 
                </ion-button>
              </ion-row>
              <ion-row class="google ion-align-items-start ion-justify-content-center">
                <ion-img src="img/icons/android-chrome-192x192.png"/> 
              </ion-row>
            </ion-grid>
          </ion-content>
    </ion-page>
</template>

<script>
    import { defineComponent } from 'vue';
    import { LoginWithGoogle } from '@/utilities/services/login_services';
    import { saveStorage, loadStorage , LimpiaTodo } from '@/utilities/storage/storage-utilities-main'
    import { openAlertaV2 , OpenAlertaImpresoras } from '@/utilities/services/alert-utilities'
    import axios from 'axios';
    import {procesoInicial} from '@/main';

    export default defineComponent({
        name : "Login",
        props : ['forzar_pdv'] , 
        watch: {
            async 'forzar_pdv' (new_pdv) {
                const pdvs = await axios.get('/pdvs', {params :{'dev': this.$deviceUuid, 'forzar_pdv': new_pdv} } )        
                this.pdvs = pdvs.data
                this.forcePdv()
                }                   
            },
        data() {
            return {
                LimpiaTodo, 
                pdv : undefined, 
                pdvs : undefined ,
                pdvSel: undefined,
                impresoras : undefined , 
                delegado : undefined, 
                ubiku_rol : this.$route.meta.ubiku_rol , 
                showme : false,
                pdvsSalidas : [],
            }
        },            
        async created() {
                this.pdv =  this.$route.meta.pdv ;
                this.nompos =  this.$route.meta.nompos ;
                this.pdv_geoloc =  this.$route.meta.pdv_geoloc ;
                this.ubiku_rol = this.$route.meta.ubiku_rol 
                this.qt= Date.now()
            },
        async mounted () {
           await this.montar_actualizar()
        },

        async update () {
           await this.montar_actualizar()
        },
        
        methods: {
            async montar_actualizar() {
                 window.fake_console('entro en login ')
                window.fake_console(this.pdv)
                this.qt = Date.now()
                window.fake_console('en el mount : ', this.forzar_pdv)
                const pdvs = await axios.get('/pdvs', {params :{'dev': this.$deviceUuid, 'forzar_pdv': this.forzar_pdv} } )        
                this.pdvs = pdvs.data
                const especiales = await axios.get('/pdvs_test')
                const pdvs_especiales = especiales.data 
                this.pdvsSalidas = pdvs_especiales.salidas||[]

                window.fake_console(this.pdvs)
                const unico = this.pdvs.length == 1 ? this.pdvs[0]['pdv'] : undefined
                const pdv_actual = await loadStorage('pdv')
                this.pdv = pdv_actual||unico
                window.fake_console({pdv: this.pdv, unico , pdv_actual})
                this.pdvSel = this.pdvs.filter( v => v.pdv == this.pdv )[0]
                window.fake_console(this.pdvSel)
                this.impresoras = this.pdvSel ? this.pdvSel['impresoras']||undefined : undefined
                if (this.$logged === true ){
                    const chk = await  this.$http.post(`/check/${this.pdv}`)
                    const d = chk.data 
                        if (d.racrol === 0 && this.$pdv !=d.pdv_principal){
                                window.fake_console({racrol: d.racrol, pdv: this.$pdv, pdv_principal : d.pdv_principal })
                                window.fake_console({name : 'login',  params : { forzar_pdv : d.pdv_principal }})
                                this.$router.replace({name : 'login',  params : { forzar_pdv : d.pdv_principal }})   
                            }
                    window.fake_console(chk.data)
                    this.delegado = chk.data.delegado
                }
                this.showme = true;
                window.fake_console(this.impresoras )
                //window.fake_console(this.pdvSel, this.pdvSel.zonas_cd||1)
                try {
                    const {pdv, nompdv, zonas_cd, ubiku_recepcion, lat, lon } = this.pdvSel
                    await saveStorage('pdv', pdv)
                    await saveStorage('nompos',  nompdv)
                    await saveStorage('zonas_crossdocking', zonas_cd||1)
                    await saveStorage('ubiku_recepcion',  ubiku_recepcion)
                    await saveStorage('pdv_geoloc', JSON.stringify( [lat, lon]))
                    window.fake_console(this.pdvSel)
                }
                catch (EEE){
                    if(this.pdvs.length == 1) {
                        await this.forcePdv()
                    }
                    window.fake_console(EEE)
                }
                window.fake_console('llego tras la grabacion teorica')

                window.fake_console(this.pdvSel)
                window.fake_console('acabo mount')
            },
            
            
            
            
            async irPagina(destino) {
                this.$router.replace(`/${destino}`)
            },
            async forcePdv(){
                this.showme = false
                const unico = this.pdvs[0]
                this.pdvSel = unico
                this.pdv =  unico.pdv 
                this.showme = true
                this.impresoras = unico.impresoras 
                const nombre = unico.nompdv 
                const {pdv, nompdv, zonas_cd, ubiku_recepcion,  lat, lon } = unico
                window.fake_console(nombre)
                await saveStorage('pdv', pdv)
                await saveStorage('nompos', nompdv)
                window.fake_console(unico.zonas_cd||1)
                await saveStorage('zonas_crossdocking', zonas_cd||1)
                await saveStorage('ubiku_recepcion',  ubiku_recepcion)
                await saveStorage('pdv_geoloc',  JSON.stringify( [lat, lon]))
                await this.loginWithGoogle() 
            }, 

            async setPdv(event) {
                this.pdv =  event.detail.value.pdv 
                this.impresoras = event.detail.value.impresoras 
                const nombre = event.detail.value.nompdv 
                const {pdv, nompdv, zonas_cd, ubiku_recepcion,  lat, lon } = event.detail.value
                window.fake_console(nombre)
                await saveStorage('pdv', pdv)
                await saveStorage('nompos', nompdv)
                window.fake_console(event.detail.value.zonas_cd||1)
                await saveStorage('zonas_crossdocking', zonas_cd||1)
                await saveStorage('ubiku_recepcion',  ubiku_recepcion)
                await saveStorage('pdv_geoloc',  JSON.stringify( [lat, lon]))
                if (this.$logged === true ) {
                    await openAlertaV2('Cambio de pdv', `
                        Has cambiado el pdv seleccionado a  "${nompdv}.\n
                        Vamos a hacer el login` 
                    ) 
                    await procesoInicial('LOGIN')
                }
                else {
                    await openAlertaV2('Cambio de pdv', `
                        Has cambiado el pdv seleccionado a  "${nompdv}.\n
                        A continuacion has de hacer el login`
                    )
                    await this.loginWithGoogle() 
                }
            }, 

            async elegir_impresora() {
                const prt = await loadStorage('printer');
                const printer = JSON.parse(prt)
                window.fake_console(prt)
                const {pdv} = this
                const respuesta = await OpenAlertaImpresoras(this.impresoras, printer[pdv] )
                if (respuesta) {
                    await this.$http.post(`/set_printer/${this.pdv}`, {'impresora' : respuesta.values})
                    
                    const nueva = { [pdv]  : respuesta.values||'undefined' }
                    window.fake_console(nueva)
                    await saveStorage('printer', JSON.stringify(nueva))
                    this.$router.replace('/home')
 
                }
                window.fake_console(respuesta)
            }, 
            async loginWithGoogle() {
                //await LimpiaTodo()
                window.fake_console('en el login de google (pagina login)')
                const self = this
                await LoginWithGoogle(self)
            }
        }
})

</script>

<style scoped>
    ion-fab {
        /*margin-top: 1rem !important; 
        margin-right: 1rem !important; */
        margin: 2rem !important; 
    }
    ion-row.selects {
        margin: 12px !important; 
        height: 15% !important; 
    }
    ion-select.rojo {
        border: 1px solid var(--ion-color-danger) !important; 
    }
    ion-select.gris {
        border: 1px solid var(--ion-color-light) !important; 
    }
    ion-select {
        font-size: large !important;
        font-weight: 400 !important;
        color: var(--ion-color-primary) !important; 
        margin: auto !important;
        height: 3.5rem !important; 
        width: 18em !important; 
        padding: 36px 18px !important;
        border-radius: 10px;
    }
    ion-grid.google {
        height: 70%;
        margin-top: 20%;
        margin-bottom: 10%;
        width: 90%;
    }
    ion-row.google {
        height: 35%;
    }
    .alert-radio-group{
        max-height: 90% !important; 
    }
    .alert-wrapper  {
        --max-width: 94%;
        --width: 94%;
        --max-height: 70%;
        --height: 50% !important; 
        position: absolute !important; 
        top: 1rem !important;             
    }

</style>