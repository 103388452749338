<template>
    <ion-header>
        <ion-toolbar  :color="color===undefined ? 'primary' : color " class="ion-justify-content-start ion-align-items-start"> 
            <ion-buttons slot="start" >
                <ion-button size="default" color="light"  routerLink="/home" routerDirection="none">
                    <ion-icon :icon="homeSharp" color="light" class="muy-grande"> </ion-icon>
                </ion-button>
            </ion-buttons>
           <ion-title>
                <ion-row  class="ion-justify-content-start ion-align-items-start">
                        
                    <ion-col size="40"  class="ion-float-left izquierda"  
                        :style="isIOS ? 'padding-left: 4rem !important;': ''">
                        {{titulo}}  ({{pdv}})
                    </ion-col>
                </ion-row>                
            </ion-title>
            <ion-buttons slot="end" >
                <ion-button size="default" color="light"  :routerLink="`/${previo||'ubicar'}`" routerDirection="none">
                    <i class="fa-sharp fa-solid fa-circle-left muy-grande" color="light" ></i>
                </ion-button>
            </ion-buttons>


        </ion-toolbar>
    </ion-header>
</template>

<script>
    import { defineComponent } from 'vue';
    import { homeSharp} from 'ionicons/icons';
    export default defineComponent({
        name : "CabeceraComponentBack",
        data() { return {  homeSharp}},
        props : ['titulo', 'color', 'pdv','isIOS', 'previo'] 
    })
</script>

<style scoped>
    ion-title {
        width: 100% !important; 
        padding-right: 4px;
    }
    .izquierda {
        text-align:left; 
    }

</style>