/**
    Seleccion expecidiones 
    Listado de las envios generadas y aun no expedidas a cliente 
 */

<template>
    <ion-page  >
        <CabeceraComponent 
            :pdv = "$route.meta.pdv"
            :isIOS = "$route.meta.isIOS"
            titulo="Reparto en curso"
            color="darksuccess"
            :at_ges="EnPdv||false"
        />

        <ion-content class="ion-padding"  key="`content:${qt}`"> 

            <!-- añadir envios-->
            <ion-fab vertical="bottom" horizontal="end" edge="true" slot="fixed" @click="InicializaReparto()" v-if="EnPdv">
                    <ion-fab-button  color="danger" >
                        <i class="fak fa-sharp-regular-box-circle-plus  crossdock blanco"></i>
                    </ion-fab-button>
            </ion-fab>
            
            <ion-fab vertical="bottom" horizontal="start" edge="true"  slot="fixed"  v-if="!EnPdv" >
                    <ion-fab-button  color="darkdanger" :disabled="largo==0" @click="ProximasParadas()">
                        <i class="fak fa-solid-route-clock  crossdock blanco"></i>
                    </ion-fab-button>
            </ion-fab>
            


            <ion-row>
                <ion-list v-if="showme  && largo>0" class="ion-no-padding"  ref="ion_item_options_slider" >       
                    <ion-list-header class="head"  style="width:100% !important;">
                        <ion-row>
                            <ion-col size="38">
                                <ion-text class="fake-titulo"> Reparto en curso ({{vehiculo_actual}})</ion-text>
                            </ion-col>  
                            <ion-col size="10">
                                    <!-- 
                                        <ion-button class="alternativa impresion" fill="solid" expand="block" color="darkdanger" @click="DIBUJAR_DUMMY()">
                                        <i class="fas fa-map     blanca">
                                            <ion-text class="ion-text-wrap en-botton">
                                                Esquema<br/>Ruta
                                            </ion-text>
                                        </i> 
                                    </ion-button>
                                    -->
                                    <ion-button class="alternativa impresion" fill="solid" expand="block" color="darkdanger" :href="maps_ruta" v-if="maps_ruta">
                                        <i class="fas fa-map     blanca">
                                            <ion-text class="ion-text-wrap en-botton">
                                                Esquema<br/>Ruta
                                            </ion-text>
                                        </i> 
                                    </ion-button>

                                    
                            </ion-col>   
                        </ion-row>
                    </ion-list-header>
                        <ion-reorder-group :disabled="false" @ionItemReorder="doReorder($event)">
                            <ion-item-sliding  v-for="(envio,index) in datos" v-bind:key="`item-${index}`"   >
                                <ion-item  class="ion-no-padding ion-no-margin"   >        
                                    <ion-reorder slot="start">   
                                        <i class="far  fa-sort reordenar "></i>                               
                                    </ion-reorder>    
                                            
                                    <ion-row class="ion-no-padding ion-no-margin">    
                                        <ion-col size-offset="1" size="47"  class="ion-no-margin  ion-no-padding"   >
                                            <h3 class="nombre_cortado" text-wrap>
                                                {{String(envio.codemp)}}.{{String(envio.codcli)}} - {{envio.nomcli}}
                                            </h3>
                                        </ion-col>
                                        <ion-col  size-offset="1" size="40"  class="ion-no-margin  ion-no-padding">
                                            <h5 class="full-h5 intermedio"> 
                                                <span class="lote oscuro"><b>{{ envio.nomenv  }}</b></span> 
                                            </h5>
                                            <h5 class="nombre_cortado" text-wrap >
                                                <span class="lote oscuro">
                                                    <small><b>{{ envio.domenv}}</b></small>
                                                </span> 
                                            </h5>
                                        </ion-col>
                                        <ion-col size="7" class="ion-no-margin  ion-no-padding"  @click="RutaGoogleMaps(envio)">
                                            <i :class="`fas fa-map-location-dot mapa `" />
                                        </ion-col>

                                        <ion-col  size-offset="1" size="36"  class="ion-no-margin  ion-no-padding">
                                            <h5 class="nombre_cortado" text-wrap >
                                                <span class="lote oscuro">
                                                    <small> <b>{{ envio.cpenv||''}} - {{envio.pobenv}}</b></small>
                                                </span> 
                                            </h5>                                                
                                        </ion-col>
                                        <ion-col size="11" class="ion-no-margin  ion-no-padding ion-text-end">
                                            <ion-text class="ion-text-wrap">
                                                <small>Bultos: </small>{{envio.bultos}}
                                            </ion-text>   
                                        </ion-col>
                                        <ion-col size-offset="1" size="47"  class="ion-no-margin  ion-no-padding">
                                            <h5 class="full-h5 intermedio" text-wrap >
                                                <span class="lote oscuro"><b><small>Albaran. : </small>{{envio.albaranes}}</b></span>
                                            </h5>
                                        </ion-col>
                                        <ion-col size="16"  v-if="envio.obsent"  class="ion-no-margin  ion-no-padding">
                                            <h5 class="nombre_cortado " text-wrap > 
                                                <span class="oscuro">
                                                    Observaciones :
                                                </span>
                                            </h5>
                                        </ion-col>
                                        <ion-col   size="32"  v-if="envio.obsent"  class="ion-no-margin ion-text-start ion-no-padding">
                                            <h5  v-for="(ix,index) in envio.obsent" :key ="`obs-${index}`" >
                                                <span class="lote">
                                                    - <small>   {{ix.alb}} : {{ix.obsent}} </small>
                                                </span> 
                                            </h5>      
                                        </ion-col>
                                        <ion-col size-offset="1" size="47"  v-if="envio.incidencia_ent" class="ion-no-margin  ion-no-padding">
                                                <h5 class="nombre_cortado" text-wrap v-for="(ix,index) in envio.incidencia_ent" :key ="`inci-${index}`">
                                                    <span class="lote fracaso">
                                                        - <small>   {{ix.k}} : {{ix.v}} </small>
                                                    </span> 
                                                </h5>      
                                        </ion-col>
                                    </ion-row>

                                </ion-item>          


                                <ion-item-options side="start">                                    
                                        <ion-item-option color="success" @click="callPhone(envio)" v-if="envio.telefono">
                                            <i class="fas fa-sharp fa-circle-phone-flip muy-grande" slot="bottom"></i> 
                                            <span class="boton-opciones">Llamar</span>
                                        </ion-item-option>
                                </ion-item-options>

                                <ion-item-options side="end">            
                                    <ion-item-option color="secondary" @click="FotoEntrega(envio)" v-if="envio.situa==2 && envio.envio_tipo =='REPARTO'">
                                        <i class="fas fa-camera muy-grande" slot="bottom"></i> 
                                        <span class="boton-opciones">Imagen<br/></span>
                                    </ion-item-option>

                                    <ion-item-option color="darksuccess" @click="Entregar(envio)" v-if="envio.situa==2 && envio.envio_tipo =='REPARTO'">
                                        <i class="fas fa-person-carry-box   muy-grande" slot="bottom"></i> 
                                        <span class="boton-opciones">Entrega<br/></span>
                                    </ion-item-option>

                                    <ion-item-option color="danger" @click="IncidenciaEntrega(envio)" v-if="envio.situa==2 && envio.envio_tipo =='REPARTO'">
                                        <i class="far fa-message-exclamation muy-grande" slot="bottom"></i> 
                                        <span class="boton-opciones">Incidencia<br/></span>
                                    </ion-item-option>
                                
                                </ion-item-options>

                            </ion-item-sliding>
                        </ion-reorder-group>    
                    </ion-list> 
                
                    <ion-list v-else  class="ion-no-padding"  ref="ion_item_options_slider"
                        >       
                        <ion-list-header class="head"  style="width:100% !important;">
                            <ion-row>
                                <ion-col size="48">
                                    <ion-text class="fake-titulo"> Reparto en curso ({{vehiculo_actual}})</ion-text>
                                </ion-col>  
                            </ion-row>
                        </ion-list-header>
                        <ion-item>
                            <ion-row no-padding no-margin  no-lines >
                                    <ion-col class="centro">
                                        <ion-text >No hay envios en reparto</ion-text>
                                    </ion-col>
                            </ion-row>      
                        </ion-item>
                    </ion-list>                  
                </ion-row> 
        </ion-content>

        <ion-tab-bar slot="bottom" color="darksuccess">

            
            <ion-tab-button  :disabled="EnPdv" >
                <div class="dummy" v-if="!EnPdv">x</div>
                <ion-label>Proximas<br/>Paradas</ion-label>
            </ion-tab-button>



            <ion-tab-button  @click="irPagina('expediciones-general' ,1 )"   :disabled="!EnPdv && ubiku_rol != 5" >
                <i class="fas fa-sharp fa-truck-ramp-box tiempo"/>
                <ion-label>Carga<br/>Actual</ion-label>
            </ion-tab-button>

            <ion-tab-button  selected>
                <i class="fas fa-route tiempo"/>
                <ion-label>Reparto</ion-label>
            </ion-tab-button>


            <ion-tab-button  @click="irPagina('expediciones-entregado', null)">
                <i class="fak fa-sharp-regular-house-circle-check tiempo"/>
                <ion-label>Entregado</ion-label>
            </ion-tab-button>



            <ion-tab-button  :selected="EnPdv"  :disabled="!EnPdv" >
                <i class="fak fa-sharp-regular-box-circle-plus tiempo" v-if="!EnPdv"/>
                <ion-label :class="EnPdv ? 'fab-in-tab' :''">
                    Añadir<br/>Envios
                </ion-label>
            </ion-tab-button>




        </ion-tab-bar>
        
        
    </ion-page>
</template>

<script>
    import CabeceraComponent from '@/components/CabeceraComponent'
    import { defineComponent } from 'vue';
    import { sortBy, pluck  } from 'underscore';
    import { openAlertaBinaria , openAlertaV2  } from '@/utilities/services/alert-utilities'    
    import { openAlertaIncidencia ,  openAlertaVehiculo } from '@/pages/enviar/components/expedir-utilities.js' ;
    import {  openAlertaEntregaFirma } from '@/pages/expediciones/components/entrega-utilities.js' ;
    
    /*
    TEMPLATE PARA MODELO CON FIRMAS 
    import {  openAlertaEntregaFirma } from '@/pages/expediciones/components/entrega-utilities.js' ;
    */
    
    import {  openAlertaEntrega } from '@/pages/enviar/components/expedir-utilities.js' ;
    
    
    import { saveStorage, loadStorage } from '@/utilities/storage/storage-utilities-main'
    import { openToast} from  '@/utilities/services/alert-utilities';


    import { css_entrega, ubicaciones_entrega, estados_visuales, colores_situacion  } from '@/utilities/global-constants';
    import { pdvDistance}  from '@/utilities/location'
    import { saveRutaToStorage  } from '@/utilities/storage/storage-repartos'
    import { RutaGoogleMaps , getGeoloc}  from '@/utilities/location'
    import { takeDeliveryPhoto} from '@/pages/enviar/components/image-utilities.js' ;

    import { useRoute } from 'vue-router';
    import { Browser } from '@capacitor/browser';


    export default defineComponent({
            name : "ExpedicionesRepartoEnCursoPage",
            components: { CabeceraComponent } , 
            data() {
                return {
                    css_entrega , ubicaciones_entrega, estados_visuales, colores_situacion , 
                    RutaGoogleMaps, 
                    pdv : this.$route.meta.pdv, 
                    nompos : this.$route.meta.nompos, 
                    qt :false , 
                    showme : false, 
                    datos: [], 
                    vehiculo_actual : undefined, 
                    pdv_geoloc :  this.$route.meta.pdv_geoloc , 
                    ubiku_rol : this.$route.meta.ubiku_rol , 
                    EnPdv : undefined, 
                    mis_vehiculos : undefined, 
                    maps_ruta: undefined 

                    
                }
            }, 
            async created() {
                this.pdv =  this.$route.meta.pdv ;
                this.nompos =  this.$route.meta.nompos ;
                this.pdv_geoloc =  this.$route.meta.pdv_geoloc ;
                this.ubiku_rol = this.$route.meta.ubiku_rol 

            },
            async mounted() { 
                this.$NoBackNavigator()
                window.fake_console('seleccion ubicaciones: entro en mounted  ', this.pdv)
                const route = useRoute();
                this.qt = route.query.q||Date.now()
                this.EnPdv = await pdvDistance(this.$geofence, this.pdv_geoloc, true ) === true||undefined 
                await this.recuperar_vehiculo()
                await this.recuperar_envios()
                await this.MapaRuta()
            }, 
            methods: {   
                async callPhone(e) {
                    document.location.href = `tel:${e.telefono}`
                    }, 
                async DIBUJAR_DUMMY() {
                    const pdv_geoloc = this.pdv_geoloc
                    const codrep = this.datos[0].codrep 
                    const avance = await this.$http.post(`/avance_ruta/${this.pdv}`, {codrep})
                    const puntos = avance.data                     
                    window.fake_console(avance.data)
                    await Browser.open({ url:`https://ubiku.ges.services/dibujar_ruta/${this.pdv}?puntos=${JSON.stringify(puntos)}&pdv_geoloc=[${pdv_geoloc}]`})
                    //window.open(`https://ubiku.ges.services/dibujar_ruta/${this.pdv}?puntos=${JSON.stringify(puntos)}&pdv_geoloc=[${pdv_geoloc}]`, '_blank', 'noreferrer');
                },

                async MapaRuta() {
                    const pdv_geoloc = this.pdv_geoloc
                    const codrep = this.datos[0].codrep 
                    const avance = await this.$http.post(`/avance_ruta/${this.pdv}`, {codrep})
                    const puntos = avance.data                     
                    window.fake_console(avance.data)
                    this.maps_ruta =  `https://ubiku.ges.services/dibujar_ruta/${this.pdv}?puntos=${JSON.stringify(puntos)}&pdv_geoloc=[${pdv_geoloc}]`
                },



                async FotoEntrega(envio) {
                    const resultado = await takeDeliveryPhoto(this.$http, envio)
                    if(resultado == true) {
                        await openAlertaV2(
                                'Imagen grabada ', 
                                `Hemos grabado una imagen asociada a la entrega del envio ${envio.ms_uuid}`
                            )
                    }
                    else {
                        await openAlertaV2(
                                'Error al grabar imagen', 
                                `Ha habitu un error al intentar grabar la imagen para la entrega del envio ${envio.ms_uuid}`
                            )
                    }

                }, 
                async recuperar_vehiculo() {
                    try {
                        this.vehiculo_actual = await loadStorage('vehiculo')
                        const rec = await this.$http.post(`/get_transportes_pdv/${this.pdv}`) 
                        const recursos = rec.data.vehiculos||[] 
                        this.mis_vehiculos = recursos.filter( x => {
                            return x.activo === 1
                        })
                        const matriculas = pluck(this.mis_vehiculos,'matricula')
                        const tiene_vehiculo_principal = this.mis_vehiculos.filter(x => x.email == this.$current_user)
                        if (!this.vehiculo_actual || matriculas.indexOf(this.vehiculo_actual)== -1) {

                            if (tiene_vehiculo_principal.length == 1)  {
                                await saveStorage('vehiculo',tiene_vehiculo_principal[0].matricula)
                                this.vehiculo_actual = tiene_vehiculo_principal[0].matricula
                            }
                            else if (matriculas.length == 1) {
                                await saveStorage('vehiculo',matriculas[0])
                                this.vehiculo_actual = matriculas[0]
                            }

                            else {
                                const seleccionado = await openAlertaVehiculo(this.mis_vehiculos, undefined)
                                if (seleccionado) {
                                    this.vehiculo_actual = seleccionado
                                }
                                else {
                                    await openToast('Vehiculo no elegido', `No has elegido vehiculo, te redirigimos a la home`, 2000)
                                    setTimeout(()=> this.$router.replace('/home')   , 750 )
                                }
                            }
                        }
                    }
                    catch (ex) {
                        alert(JSON.stringify(ex))
                    }
                },
                 

                async doReorder (event) {
                    // The `from` and `to` properties contain the index of the item
                    // when the drag started and ended, respectively
                    window.fake_console('Dragged from index', event.detail.from, 'to', event.detail.to);

                    // Finish the reorder and position the item in the DOM based on
                    // where the gesture ended. This method can also be called directly
                    // by the reorder group
                    window.fake_console(this.datos)
                    this.datos = event.detail.complete(this.datos);
                    window.fake_console(this.datos)
                    await this.grabarRepartoReordenado()
                },    

                async IncidenciaEntrega(envio) {
                    let slider = this.$refs.ion_item_options_slider
                    const motivo_incidencia = await openAlertaIncidencia(envio)
                    const ahora = (new Date()).toISOString().replace(/\..*$/,'').replace('T',' ')

                    if (motivo_incidencia) {
                        const incidencia =  [{'k' : ahora , 'v':motivo_incidencia}]
                        const {codrep, vehiculo, ms_uuid} = envio;
                        if (envio.incidencia_ent) {
                            envio.incidencia_ent.push(...incidencia)
                        }
                        else {
                            envio.incidencia_ent = incidencia
                        }
                        const envios = {ms_uuid, incidencia_ent: envio.incidencia_ent}
                        const payload = { codrep, vehiculo,  envios };
                        window.fake_console(payload)
                        const actualizacion = await this.$http.post(`/claim_expedicion/${this.pdv}`, payload)
                        window.fake_console(actualizacion.data)
                        window.fake_console(actualizacion.data)
                        if(actualizacion.data.status === 0  ){
                            await openAlertaV2(
                                'Error al generar la incidencia ', 
                                `No hemos podido realizar la operacion, error:\n${actualizacion.data.alert} `
                            )
                            }
                        else {
                            await openAlertaV2(
                                `Incidencia creada`, 
                                `${actualizacion.data.alert}`
                            )
                        }
                        await slider.$el.closeSlidingItems()
                        await this.recuperar_envios()
                        this.qt = Date.now();

                    }
                    window.fake_console(motivo_incidencia)

                }, 

                async Entregar(envio){
                    let slider = this.$refs.ion_item_options_slider
                    const confirmacion = this.pdv == '044' 
                        ? await openAlertaEntregaFirma(this.$http, envio)
                        : await openAlertaEntrega(this.$http, envio)
                    
                    /*const confirmacion = await openAlertaEntrega(this.$http, envio)*/
                    if(this.pdv === '044') {
                        console.log()
                    }
                    if (!confirmacion) { return }


                    try {
                        const {codrep, vehiculo , ms_uuid} = envio
                        const {latitude, longitude} = await getGeoloc(60*1000*2)
                        const {has_nif, nif , nombre, firmado } = confirmacion 
                        const envios = { codrep, ms_uuid, vehiculo, has_nif, lat_ent : latitude , lon_ent : longitude, entrega_id :{ nif , nombre } }
                        const payload = { codrep, vehiculo, envios };
                        console.log({has_nif, nif , nombre, firmado } )
                        if (this.pdv != '044')
                            {                        
                                const actualizacion = await this.$http.post(`/deliver_expedicion/${this.pdv}`, payload)
                                window.fake_console(actualizacion.data)
                                if(actualizacion.data.status === 0  ){
                                    await openAlertaV2(
                                        'Error en la creación de la entrega ', 
                                        `No hemos podido realizar la operacion, error:\n${actualizacion.data.alert} `
                                    )
                                    this.qt = Date.now()
                                    }
                                else {
                                    await openAlertaV2(
                                        'Entrega realizada ', 
                                        `${actualizacion.data.alert} con el codigo ${actualizacion.data.ms_uuid}`
                                    )
                                }                       
                            }
                        await slider.$el.closeSlidingItems()
                        await this.recuperar_envios()
                        this.qt = Date.now();
                        
                    }
                    catch (ex) {
                        window.fake_console(ex)
                    }
                }, 

                async InicializaReparto() {
                    /** Si hay carga previa, tienes que aceptarla o resolverla  */
                    if (this.datos.length>0) {
                        const previos =  await openAlertaBinaria('Envios en reparto !', 
                                `Hay envios en proceso de reparto, puedes mantenerlos en la nueva ruta clickando 'Integrar' o 
                                cancela y descargalos desde la pantalla de cargas antes de continuar. 
                                `, 'Cancelar', 'Integrar')
                        window.fake_console(previos)    
                        if (previos ) {
                            await this.cerrarRepartos()
                        }
                        else { return }
                    }
                    try {                    
                        const recep = await this.$http.post(`/get_expediciones/${this.pdv}`, {vehiculo: this.vehiculo_actual})
                        /** solo puede ver los del vehiculo, que además chequearemos que sean suyos */
                        const datos = recep.data.filter( y => y.envio_tipo == 'REPARTO' && y.vehiculo == this.vehiculo_actual && y.situa ==1) 
                        if (datos.length>0) {
                            await saveRutaToStorage(this.pdv, this.vehiculo_actual , datos )
                            const payload = {vehiculo : this.vehiculo_actual, nomusu: this.$current_user_name }
                            window.fake_console(payload)
                            await this.$http.post(`/lock_transport/${this.pdv}`, payload)
                            this.$router.replace('/expediciones-ruta')
                        }
                        else { throw 'No hay envios'}
                    }
                    catch (ex) {
                        await openAlertaV2(
                            'Error al generar ruta', 
                            `Ha habido un error en la generación de la ruta : ${typeof(ex)=='object' ? JSON.stringify(ex) : ex }`
                        )
                        window.fake_console(ex)
                        window.fake_console(ex)
                        this.datos = []
                    }           
                    this.showme = true ;  
                },   
                async irPagina(pag, sit){
                    const ruta = {name : pag, params :{situacion: sit}}
                    this.$router.push(ruta)
                }, 

           



                
                async recuperar_envios(){
                    try {                    
                        const recep = await this.$http.post(`/get_expediciones/${this.pdv}`, {vehiculo: this.vehiculo_actual})
                        /** solo puede ver los del vehiculo, que además chequearemos que sean suyos */
                        const datos = recep.data.filter( y => y.envio_tipo == 'REPARTO' && y.vehiculo == this.vehiculo_actual && y.situa == 2 ) 
                        window.fake_console(recep)
                        window.fake_console(datos)
                        if (datos.length>0) { 
                            const dat = datos.map( x=> {
                                x.obsent = !x.obsent ||x.obsent==='null' ? undefined : JSON.parse(x.obsent) 
                                x.incidencia_ent = x.incidencia_ent === null ? undefined : JSON.parse(x.incidencia_ent)
                                const alb = JSON.parse(x.albaranes)
                                x.albs = alb
                                window.fake_console(x.albs, alb.length>1)
                                x.multi_alb = alb.length>1 ? true : undefined 
                                x.albaranes = alb.join( ' , ' ); 
                                const tel = !x.telefo ? undefined : x.telefo.replace(/[^0-9]/g,'')           
                                x.telefono = tel && tel.length == 9 ? tel : undefined 
                                return x
                            })
                            this.datos = sortBy(dat, 'orden_rep') 
                            }
                        else { throw 'No hay envios'}
                    }
                    catch (ex) {
                        window.fake_console(ex)
                        window.fake_console(ex)
                        this.datos = []
                    }           
                    this.showme = true ;          
                }, 

           async cerrarRepartos(){
                try {
                        const payload = {   vehiculo : this.vehiculo_actual };
                        const actualizacion = await this.$http.post(`/unset_expedicion/${this.pdv}`, payload)
                        window.fake_console(actualizacion.data)
                        if(actualizacion.data.status === 0  ){
                            await openAlertaV2(
                                'Error en el cierre del reparto ', 
                                `No hemos podido realizar la operacion, error:\n${actualizacion.data.alert} `
                            )
                            }
                        else {
                            await openAlertaV2(
                                `Reparto ${this.vehiculo_actual} cerrado`, 
                                `${actualizacion.data.alert}`
                            )
                        }
                }
                catch (ex) {
                    window.fake_console(ex)
                }
            },

            async grabarRepartoReordenado(){
                try {
                    const envios = this.datos.reduce((m,v)=>{ m.push(v.ms_uuid); return m },[])
                    const codrep = this.datos[0]['codrep']
                    window.fake_console(envios)
                    const payload = { codrep, envios, vehiculo : this.vehiculo_actual};
                    const actualizacion = await this.$http.post(`/sort_expedicion/${this.pdv}`, payload)
                    window.fake_console(actualizacion.data)
                    window.fake_console(actualizacion.data)
                    if(actualizacion.data.status === 0  ){
                        await openAlertaV2(
                            'Error en la creación del reparto ', 
                            `No hemos podido realizar la operacion, error:\n${actualizacion.data.alert} `
                        )
                        this.qt = Date.now()
                        }
                    else {
                        await openAlertaV2(
                            'Reparto creado ', 
                            `${actualizacion.data.alert} con el codigo ${actualizacion.data.ms_uuid}`
                        )
                        await this.recuperar_envios()
                    }
                }
                catch (ex) {
                    window.fake_console(ex)
                }
            },


        async ProximasParadas() {
                const {latitude, longitude} = await getGeoloc(60*1000*2)

                const destinos = this.datos.reduce((m,v) => {
                    const {lat_env,lon_env} = v
                    m.push([lat_env,lon_env])
                    return m  
                }, [])
                destinos.push(this.pdv_geoloc)
                destinos.unshift([latitude,longitude])
                const goog_arr = destinos.slice(0,6).reduce((m,x ) => { return m+=`/'${x[0]},${x[1]}'` }, '')
                const goog_url = `https://www.google.com/maps/dir${goog_arr}`
                //window.open(goog_url, '_blank', 'noreferrer');
                await Browser.open({url: goog_url})
        }, 
        

            }, 
            computed :{
                largo: function () {
                    return Object.keys(this.datos).length
                    }
            }
            
 
    })
</script>
 

<style scoped  lang="scss">
    @import "@/theme/seleccion-pedido-styles.scss";
    
    ion-reorder {
        margin-right:  0.5rem !important; 
        /*width: 3rem !important; */
    }
    ion-label.fab-in-tab {
        padding-top:1.5rem !important;
        font-weight: 900 !important;
    }
    ion-tab-bar {
        height: 5rem !important; 
    }
    ion-segment {
        width: 100% !important; 
    }
    ion-segment-button.todas {
        width: 20% ª!important; 
    }
    span.boton-opciones {
        font-size: 1.05rem !important; 
        padding-bottom: 0.3rem !important; 
        font-weight: bold !important;
        text-align: center !important;
    }
    ion-badge {
        width: 100% !important; 
    }
    ion-row.altos {
        height: 3rem !important;
        
    }
    ion-text.en-botton {
        font-family: 'Roboto';
        font-size: 0.9rem !important; 
        color: var(--ion-color-blanco)  !important;
        text-transform: none !important;
        line-height: 0.4rem !important;
    }
    ion-row {
        max-width: 600px !important; 
        padding: 0.3rem 0.2rem !important; 
    }

    ion-item {
        --inner-padding-end : 0px !important; 
        --padding-end : 0px !important; 
    }
    ion-text.fake-titulo {
        color: var(--ion-color-darksuccess) !important; 
        text-align: center !important;
    }
    span.oscuro {
        color: var(--ion-color-medium) !important; 
        padding-left: 0.3rem !important; 
    }
    span.primario {
        color: var(--ion-color-primary) !important; 
        padding-left: 0.3rem !important; 
    }
    span.peque {
        font-size: smaller !important; 
        padding-right: 0.3rem !important; 
    }
    i.fas.tiempo , i.fak.tiempo , i.far.tiempo {
        color: dark; 
        font-weight: 600 important; ;
        font-size: 22px !important;
        padding-right: 0.4rem !important
    }

    ion-item.iniciado {
        border-right: 10px  solid !important;
        border-image: linear-gradient(to bottom, white, var(--ion-color-darkdanger), white) 2;
    }
    .sin-margen {
        margin-top: 0px !important; 
    }

    i.crossdock {
        position:absolute;
        top:50%;
        left:50%;
    }
  
    i.blanco {
        color: var(--ion-color-blanco) !important;
        font-size: xx-large !important; 
        font-weight: 300 !important;
        transform:translate(-50%,-50%)

    }

    i.fas.mapa, i.far.mapa {
        font-size: 2rem !important; 
        float: right !important; 
        position: relative !important; 
        top : 0.2rem !important; 
        color: var(--ion-color-danger)  !important;
        line-height: 1rem !important; 
        }

    i.fas.reordenar, i.far.reordenar {
        font-size: 2rem !important; 
        color: var(--ion-color-medium)  !important;
        margin: 0.3rem !important;
        }


    div.dummy {
        color: var(--ion-color-darksuccess)  !important;
    }





</style>