<template>
    <ion-page :key="qt">
        <ion-header>
            <ion-toolbar   color="primary" class="ion-justify-content-start ion-align-items-start"> 
                <ion-buttons slot="start" >
                    <ion-button size="default" color="light"  routerLink="/home" routerDirection="none">
                        <ion-icon :icon="homeSharp" color="light" class="muy-grande"> </ion-icon>
                    </ion-button>
                </ion-buttons>
                
                <ion-title>
                    <ion-row  class="ion-justify-content-start ion-align-items-start">
                        <ion-col size="40"  class="ion-float-left izquierda" 
                            :style="isIOS ? 'padding-left: 4rem !important;': ''">
                            Favoritos del cliente  {{cliente}} 
                        </ion-col>
                    </ion-row>                
                </ion-title>
            </ion-toolbar>
            <ion-toolbar color="primary" style="padding-top:2px;" class="top">
                    <ion-searchbar  v-model="search_manually" 
                        id="busqueda_filtrada_stocks"
                        ref="search_busqueda_filtrada_favoritos"
                        :debounce="debounce_milis" 
                        @ionClear="onCancel($event)"
                        autocomplete="off" autocorrect="off" 
                        autocapitalize="off" spellcheck="false"
                        show-clear-button="always"
                        placeholder="Filtrar productos" 
                        inputmode ="search"
                        type="search"
                        @ionChange="filtroInputSearch"
                        @ionBlur="getFoco($event, false)"
                        @ionFocus="getFoco($event,true)"
                        slot="start"
                        mode = "md"
                        size="36">
                    </ion-searchbar>
                </ion-toolbar>
                <ion-toolbar color="primary" class="top segmento" >
                    <ion-segment  v-model="sorter"  mode="ios" color="primary"  class="todas">
                        <ion-segment-button value="f">
                            Lo más reciente
                        </ion-segment-button>
                        <ion-segment-button value="n3">
                            Mas comprado 3m
                        </ion-segment-button>
                        <ion-segment-button value="n">
                            Mas comprado 12m
                        </ion-segment-button>
                    </ion-segment>
                </ion-toolbar>  
        </ion-header>

        <ion-content>
                <ion-fab vertical="top" horizontal="end" slot="fixed" >
                    <ion-fab-button color="darkdanger" @click="goBack">
                        <ion-icon :icon="listSharp"></ion-icon>
                    </ion-fab-button>                    
                </ion-fab>

            <ion-row  class="spin ion-justify-content-center "  v-if="!showme">
                <ion-col  v-if="!showme" class="spin ion-align-self-center" size="48" > 
                    <ion-spinner  v-if="!showme"></ion-spinner>            
                </ion-col>
            </ion-row>
                 
                 
            <ion-row v-if="showme">
                <ion-row class="ion-no-padding"
                     v-if="showme && lista_articulos.length >0 && (!search_manually|| search_manually.length==0)">
                    <ion-col size="44" text-right>
                        <p color="info">
                            <span style="font-size:larger"><b>{{datos_tot }} articulos en stock, </b>
                            filtra para seleccionar, se muestran los {{Math.min(limite, lista_articulos.length)}} primeros
                            </span> 
                        </p>            
                    </ion-col>
                </ion-row>
                <ion-row class="ion-no-padding" v-else-if="showme && lista_articulos.length >0 && search_manually && search_manually.length>0">
                    <ion-col size="44"  text-right>
                        <p color="info">
                            Tu busqueda "{{search_manually}}" ha generado <span style="font-size:larger"><b>{{datos_tot }} resultados </b></span>
                            <br/>
                            Solo se muestran los {{Math.min(limite, lista_articulos.length)}} primeros
                        </p>            
                    </ion-col>
                </ion-row>

                <!-- BUSQUEDA SIN RESULTADOS-->
                <ion-row class="ion-padding ion-margin" v-else>
                    <ion-title class="ion-padding-top ion-margin-top texto-mas-grande" >Busqueda sin resultados </ion-title>
                        <div class="ion-padding-top ion-margin-top ion-text-justify texto-mas-grande">
                            <ion-text >
                            No hemos encontrado ningun resultado para  tu busqueda "<strong><i>'{{search_manually}}'</i></strong>"
                            entre los historicos del cliente. <br/><br/>
                            Puedes editar manualmente la busqueda,o ir a la busqueda completa 
                            <br/><br/>
                            </ion-text>
                        </div>
                </ion-row>
                
      
                <!-- class="ion-no-padding ion-no-margin">         -->
                <ion-list v-if="showme && lista_articulos.length >0 " class="ion-no-padding">       
                    <ion-item 
                        v-for="(product,index) in lista_articulos"
                        v-bind:key="`item-${index}`"
                        class="ion-no-padding ion-no-margin">        
                        <ion-row >    

                            <ion-col size-offset="1" size="47"  class="ion-no-margin  ion-no-padding">
                                <h3 @click = "ElementoStockSeleccionado(product)" > 
                                    {{ $filters.showSku(product.sku)   }}
                                     <!--<span class="lote rojo" v-if="product.cables === true "> (Para Pedidos) </span>-->
                                </h3>
                                <h4 @click = "ElementoStockSeleccionado(product)" class="nombre_cortado" text-wrap>
                                    {{product.shortDescription}}
                                </h4>
                            </ion-col>

                            <!-- RESULTADOS DESDE EL STOCK -->
                            <ion-col size-offset="1" size="32"  class="ion-no-margin  ion-no-padding" >
                                <h5 @click = "ElementoStockSeleccionado(product)" class="full-h5"> 
                                    <span class="lote" v-if="product.cables !== true " >Suc: {{ product.codsuc   }}</span>
                                    <span class="lote forzar_derecha" v-if="product.ubi">Ubic : {{ product.ubi   }}</span>
                                    <span class="lote rojo" v-else-if="product.cables===true">Para pedir a proveedor</span>
                                    <span class="lote forzar_derecha rojo" v-else>No Ubicado</span>
                                </h5>
                                <h5 @click = "ElementoStockSeleccionado(product)" class="nombre_cortado" text-wrap v-if="product.lote!=null">
                                    <b><span class="lote">Lote : {{ product.lote  }}</span></b>
                                </h5>
                            </ion-col>
                            <ion-col size="11"  class="ion-no-margin  ion-no-padding ion-text-end" >
                                <ion-text  @click = "ElementoStockSeleccionado(product)" class="stock" text-wrap>
                                    <span class="stock"><b>{{$filters.Entero(product.stoalm)}}</b></span>
                                </ion-text>
                                <ion-text @click = "ElementoStockSeleccionado(product)" class="mini-lista" text-wrap>
                                   {{product.unimed}}{{product.stoalm>1 ? 's' : '' }}
                                </ion-text>
                            </ion-col>

                           
                            <ion-col size="5"  class="ion-no-margin  ion-no-padding ion-text-end" @click="anyadirProducto(product)">
                                    <ion-icon :icon="cartSharp" :color="product.search ? 'darkdanger' : 'primary'" class="muy-grande carro"> </ion-icon>
                            </ion-col>
                            

                            <!--
                            <ion-col size-offset="1" size="45"  class="ion-no-margin  ion-no-padding" >
                                <h5 @click = "ElementoStockSeleccionado(product)" class="full-h5"> 
                                    <span class="lote"  >Ult. Compra: {{ product.f   }}</span>
                                    <span class="lote forzar_derecha"> Uds 3M : {{ product.n3   }}  / Uds 12M : {{ product.n  }}  </span>
                                </h5>
                            </ion-col>      
                            -->
                        </ion-row>
                    </ion-item>
                </ion-list>
            </ion-row>
        </ion-content>


 


    </ion-page>

</template>
    

<script>
    import { defineComponent } from 'vue';
    import { closeCircleSharp , homeSharp,  listSharp,printSharp, thumbsDownSharp, cartSharp, addCircleSharp ,logoGoogle} from 'ionicons/icons';
    import { stock_total_con_cables , RegexBobinasPdv,  ArticulosLote } from '@/utilities/scanner/scan-sku'

    import { useRoute } from 'vue-router';
    import { transformSchneiderBrand  } from '@/utilities/global-constants';
    import { scanGenericStart, scanGenericStop  } from '@/utilities/scanner/scan-generic'
    import { openAlertaUnidadesCarrito , openAlertaV2 } from  '@/utilities/services/alert-utilities';
    import { add2MostradorStorage } from '@/utilities/storage/storage-mostrador'
    import eventBus from "@/event-bus"; 
    import { extend, sortBy, partition, every,  } from 'underscore';

    export default defineComponent({
            name : "BuscarFavoritosClientePage",
            data() { 
                return { 
                    element_id: 'busqueda-favoritos-mostrador',
                    pdv: undefined,
                    nomcli: undefined, 
                    isIOS: undefined, 
                    search_manually : undefined, 
                    datos : undefined, 
                    datos_tot: undefined , 
                    closeCircleSharp, homeSharp,  printSharp , thumbsDownSharp, cartSharp, listSharp, logoGoogle,addCircleSharp, 
                    limite: 50 , 
                    sorter : 'f', 
                    lista_articulos: undefined , 
                    showme :false, 
                    ruta: undefined, 
                    qt :false , 
                    input_activo: false,
                    debounce_milis: 300, 
                    }
            },
            props : ['cliente', 'origen'] , 
            created() {
                this.pdv =  this.$route.meta.pdv 
                this.nomcli = this.$route.meta.nombre_cliente
                this.isIOS = this.$route.meta.isIOS 
            },
            watch: {
                '$route.query.q'() { this.onCancel();}, 
                async sorter (oldval, newval) { 
                    if (oldval !=newval) { await this.filtrar () }                
                }
            },
            updated() {
                this.pdv =  this.$route.meta.pdv 
            },
            async mounted() {
                this.pdv =  this.$route.meta.pdv 
                const route = useRoute();
                this.ruta = route.fullPath
                this.qt = route.query.q
                await this.buildCustomerFavoritesList()
                await this.filtrar()
                await this.escaner_generico()
                
            },
            async unmounted() {
                await scanGenericStop(this.element_id)
            }, 
            methods: {
                
                getFoco (ev, si) {
                    window.fake_console('evento escuchado')
                    if(si===true) {
                        this.input_activo = true; 
                        scanGenericStop(this.element_id)
                        window.fake_console('desactivado')
                    }
                    else {
                        this.input_activo=false; 
                        this.escaner_generico()
                        window.fake_console('activado')
                    }
                },
                goBack() {
                    window.fake_console('en goback ', {'name' : 'carrito_mostrador' , params : {'cliente' :this.cliente }})
                    this.$router.replace({'name' : 'carrito_mostrador' , params : {'cliente' :this.cliente }})
                    
                },
                async lectorEventosGenericos() {
                    let self = this; 
                    eventBus().emitter.off(`lectura_generica:${this.element_id}`)
                    eventBus().emitter.once(`lectura_generica:${this.element_id}`,async (input_scaner)=>{
                       self.input_activo = false;
                       let buscar = input_scaner
                       /** miramos si el termino tiene includo un codigo de bobina , en cuyo caso lo eliminamos */
                       if ( RegexBobinasPdv.test(input_scaner) && input_scaner.replace(RegexBobinasPdv,'').length > 2)
                            { 
                                buscar = input_scaner.replace(RegexBobinasPdv,'')
                                window.fake_console(input_scaner, buscar)
                            }
                       self.search_manually = buscar
                       self.filtrar('Via lector Generico' , 'exacta') 
                       self.lectorEventosGenericos()
                    })
                },
                async escaner_generico() {
                    await scanGenericStop(this.element_id)
                    await scanGenericStart(this.element_id, 'desde escaner_generico')
                    await this.lectorEventosGenericos()
                },
                async filtroInputSearch() {
                    window.fake_console(this.input_activo)
                    if (this.input_activo) {
                        this.filtrar('Caja de busqueda via filtroInputSearch')
                    }
                }, 
                onCancel(){
                    this.showme = false
                    this.pdv =  this.$route.meta.pdv 
                    window.fake_console('en el cancel ')
                    this.search_manually = undefined; 
                    this.filtrar()
                },

                async filtrar () {
                    this.showme = false
                    window.fake_console('Entro en el filtro/busqueda :', this.input_activo)
                    window.fake_console(this.search_manually)
                    const terminos = transformSchneiderBrand(this.search_manually)
                    window.fake_console(terminos)
                    let dat 
                    if (!terminos || terminos === false || terminos.length<3) {
                        dat = this.datos
                    }
                    else {
                        const primarySearch = new RegExp(`(${terminos.toLowerCase().replace(/ +$/,'').replace(/ +/g,'.*')})`, 'i')
                        const secondarySearch = terminos.toLowerCase().replace(/ +$/,'').split(' ').map(v => new RegExp(`${v}`,'i'))
                        const [primario,resto] = partition(this.datos, v=>primarySearch.test(v.tags))
                        const secundario =  resto.filter(v=>every(secondarySearch, t => t.test(v.tags)))                    
                        dat= primario.concat(secundario)||[]
                    }
                    const srt = this.sorter
                    window.fake_console(this.sorter)
                    this.lista_articulos = sortBy(dat , x =>-x[srt] ).slice(0,this.limite)
                    this.showme = true
                }, 

                async ElementoStockSeleccionado(item) {
                    window.fake_console(this.$router)
                    window.fake_console(item)
                    this.$router.replace({'name' :'detalle_producto', params : { sku: item.sku , origen: `/favoritos-mostrador/${this.cliente}`}})    
                        window.fake_console(item)
                 },

                async anyadirProducto(sku_data) {
                    await scanGenericStop(this.element_id)
                    window.fake_console(this.cliente, this.pdv, sku_data)
                    /** abrimos el selector de unidades solo si no hay gestión de lotes o es un 'cable' para hacer pedidos abiertos */
                    if (!sku_data.lote || sku_data.cables === true ) {
                        await openAlertaUnidadesCarrito(this.cliente, this.pdv, sku_data)
                    }
                    /** Si es un lote definido, arrastramos todo lo que tenga el lote si poder cambiar cantidad  */
                    else {
                        const esrollo = (/rollo/ig).test(sku_data.lote)
                        const lots = esrollo ? [sku_data] : ArticulosLote(sku_data.lote)
                        lots.reduce(async (memo,v) => {
                            const m = await memo ;
                            const articulo_data = v
                            articulo_data.uds_alb = articulo_data.stoalm 
                            articulo_data.tipo = 'stock'
                            await add2MostradorStorage(this.cliente, this.pdv, articulo_data)
                            return m
                        },0)
                        openAlertaV2('Productos añadidos' , `
                            Hemos añadido ${lots.length} articulo/s del lote ${sku_data.lote} a la lista.  `
                        )
                    }
                    await this.escaner_generico()
                }, 

                async buildCustomerFavoritesList (){
                    this.showme = false 
                    try 
                    {
                        window.fake_console(this.pdv)
                        let url  = `/frecuentes/${this.cliente}`
                        window.fake_console(url)
                        const b = await  this.$http.get(url)
                        const resultado = b.data.reduce((m,v)=> {
                            const sk = v.sku
                            m[sk]= v 
                            return m
                            } ,{})
                        this.datos= []
                        window.fake_console(resultado)
                        if (resultado ) {
                            const arts = stock_total_con_cables.reduce( (ma, va)=> {
                                const sk = va['sku']
                                const ct = resultado[sk]
                                if (ct) {
                                    ma.push(extend({},ct,va))
                                }
                                return ma 
                            }, [])
                            const cables_wip = arts.reduce((m,v)=> {
                                    if (v.lote && v.sku.slice(0,3)!='BOB' ) {
                                        const sk = v.sku 
                                        const inicializa = {'cables':true, 'stoalm':0, 'ubi':undefined, lote: undefined ,hay_stock: false , dispo : 0}
                                        m[sk]= Object.assign({},v, inicializa)
                                    }
                                    return m 
                            },{})
                            this.datos = arts.concat(Object.values(cables_wip))
                            window.fake_console(this.datos.slice(0,10))
                            this.datos_tot = this.datos.length||0
                        }
                    }
                    catch(ex) {
                        console.log(ex)
                        this.showme = true
                    }
            },

            },    
        })
</script>

<style scoped lang="scss">
    @import "@/theme/seleccion-producto-stock.scss";
    ion-item > ion-row {
        padding-right : 0rem !important; 
    }
    .in-segment-color {
        color: var(--ion-color-blanco) !important; 
    }
    .segment-button-checked {
        color: var(--ion-color-primary) !important; 
        font-weight: bold !important; 
    }
    ion-segment-button {
        font-size:1.1rem !important;
    }
    ion-icon.carro {
        position: relative !important;
        top: 1px !important; 
        right: 1px !important;
        font-size: 2rem !important;
    }
    span.uds_solr {
        padding-right: 1rem !important; 
    }
</style>