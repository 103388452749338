<template >
    <div :class="isEditing ? 'seleccionado ' : ''">    

      <ion-item :class="isEditing ? 'ion-focus' : ''"  class="sin-borde pure-form">  
            <ion-button expand ="block" fill="solid"  class="recepcion"
                :color="isEditing ? 'darkdanger' : 'darksuccess'" 
                size="normal" 
                slot="start"
                @click="isEditing=true; modelValue=undefined;"
                > 
                <span class="texto-boton-grande">{{titulo}}</span>
            </ion-button>
            <ion-input  v-model="modelValue" 
                    class="recepcion"
                    :id="referencia"
                    :inputmode="modo"
                    :autocapitalize="autocap||'off'"
                    :debounce="modo=='numeric' ? 1500 : 0 "
                    @ionChange="modo==='numeric' ? isEditing=false : void(0)"
                    @ionInput="actualizaPadre($event, undefined)"
                    @ionBlur="isEditing=false"
                    @ionFocus="isEditing=true"
                    :type="modo=='decimal' ? 'number' : modo=='numeric' ? 'number' : 'text' "
                    :step="modo=='decimal' && decimales ? Math.pow(10,-decimales) : null"
                    :pattern="nombre=='ubicacion' ? esUbicacion : undefined "
                    :placeholder="placeholder"
                    :disabled="org === 'grupo'"
                    @keyup.enter="isEditing=false"
                >   
                
            </ion-input>
            <!-- COPIAR EN EL CAMPO DESDE OTRO SITION -->
            <ion-button   class="recepcion mini" color="danger" v-if="copy_from" @click="requestData" >Copiar</ion-button>
            
        </ion-item>
        <!-- 
            NOTA EXPLICATIVA, ANULAMOS POR AHORA, DISTORSIONA PANTALLA Y CONFUNDE

        <ion-item :class="isEditing ?'ion-focused'  : '' " v-if="isEditing"> 
            <p class="nota-form" color="info" v-html="descripcion">                
            </p>                         
        </ion-item>  

        -->



    </div>
</template>

<script>
    import { defineComponent } from 'vue';  
    import eventBus from "@/event-bus"; 
    import { esUbicacion } from '@/utilities/global-constants';
    export default defineComponent({
        name : "RecepcionInputComponent",
        props : ['titulo', 'nombre', 'placeholder', 'descripcion', 'modo' , 'pagina_origen',  'copy_from', 'autocap', 'decimales', 'org'] ,
        data() { 
            return { 
                esUbicacion, 
                modelValue: undefined, 
                isEditing: false
            }
        },
       watch: {
              async 'isEditing' (newVal, oldVal ) {
                /** Entrada inicial, hacemos foco  */
                let inputField = document.getElementById(this.referencia);
                let input = await inputField.getInputElement()

                if (newVal===true && oldVal!=newVal){
                    window.fake_console('DE FALSE A TRUE ')
                    await inputField.setFocus()
                    eventBus().emitter.emit('recepcion_active_field' ,this.nombre) 
                }
                /** lanzamos la salida  y devolvemos el control al parent */
                else if(oldVal=== true && oldVal!=newVal ) {
                    window.fake_console('CASO 2, DE TRUE A FALSE Y NO PEDIDOS ')
                    input.blur(); 
                    inputField.blur()
                    eventBus().emitter.emit('recepcion_active_field' ,undefined) 
                }
                else {
                    window.fake_console('Caso no contemplado .... ', newVal, oldVal, this.nombre )     
                }
                }
              }, 
        mounted() {

            /** Activamos el campo si es el que tiene el foco */
            eventBus().emitter.on('recepcion_active_field',  async (nuevo)=> { 
                window.fake_console('recepcion_active_field', nuevo)
                this.isEditing = this.nombre == nuevo;
            })
            
            eventBus().emitter.on(`${this.nombre}:from_parent:update`,  async (nuevo)=> { 
                window.fake_console(this.modelValue, nuevo)
                this.modelValue = nuevo
                this.actualizaPadre(undefined, nuevo)
                window.fake_console(this.modelValue, nuevo)
            })

            if(this.pagina_origen!=undefined) {
                eventBus().emitter.on(`${this.pagina_origen}:update`,  async (nuevos_datos)=> { 
                    if (nuevos_datos[this.nombre]) {
                        this.modelValue = nuevos_datos[this.nombre]
                    }
                })

            }

            
        }, 
        computed :{
            referencia: function () {
                return `recepcion_${this.nombre}_field`
                }
        },
        methods :{
            requestData() {
                window.fake_console(`this.$emit(request:from_child:${this.nombre}, { destino: ${this.nombre}, origen: ${this.copy_from} })`)
                this.$emit(`request:from_child:${this.nombre}`, {destino: this.nombre, origen: this.copy_from})
            }, 
            actualizaPadre(e, val) {
                if(this.autocap) {
                    this.modelValue = e.target.value.toUpperCase()
                }
                if (e) 
                {  this.$emit(`update:${this.nombre}`, e.target.value) }
                else { this.$emit(`update:${this.nombre}`,val) }
                } 
            
        }
    })
</script>

<style scoped lang="scss">
    @import "@/theme/recepciones-styles.scss";


    .seleccionado {
        border: 2px dashed var(--ion-color-darksuccess) !important; 
        background-color: var(--color-resaltado-positivo) !important; 
    }
    ion-button.mini {
        padding-left: 0.4rem !important; 
        padding-right: 0.4rem !important; 
        

    }
</style>