<template>
    <ion-page :key="qt">
        <ion-header>
            <ion-toolbar   color="primary" class="ion-justify-content-start ion-align-items-start"> 
                <ion-buttons slot="start" v-if="!origen" >
                    <ion-button size="default" color="light"  routerLink="/home" routerDirection="none">
                        <ion-icon :icon="homeSharp" color="light" class="muy-grande"> </ion-icon>
                    </ion-button>
                </ion-buttons>
                
                <ion-title>
                    <ion-row  class="ion-justify-content-start ion-align-items-start">
                        <ion-col size="40"  class="ion-float-left izquierda" 
                            :style="isIOS ? 'padding-left: 4rem !important;': ''">
                            Busqueda articulos stock ({{pdv}})
                        </ion-col>
                    </ion-row>                
                </ion-title>
                <ion-buttons slot="end" >
                    <ion-button size="default" color="light"  @click="ElementoStockSeleccionado(undefined)" v-if="origen">
                        <ion-icon :icon="closeCircleSharp" color="light" class="muy-grande"> </ion-icon>
                    </ion-button>
                    <ion-button size="default" color="light"  @click="goBack" v-else>
                        <i class="fa-sharp fa-solid fa-circle-left muy-grande" color="light" ></i>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
            <ion-toolbar color="primary" style="padding-top:2px;" class="top">
                    <ion-searchbar  v-model="search_manually"
                        id="busqueda_filtrada_stocks"
                        ref="search_busqueda_filtrada_stocks"
                        :debounce="debounce_milis" 
                        @ionClear="onCancel($event)"
                        autocomplete="off" autocorrect="off" 
                        autocapitalize="off" spellcheck="false"
                        show-clear-button="always"
                        placeholder="Filtrar productos stock" 
                        inputmode ="search"
                        type="search"
                        @ionChange="filtroInputSearch"
                        @ionBlur="getFoco($event, false)"
                        @ionFocus="getFoco($event,true)"
                        slot="start"
                        mode = "md">
                    </ion-searchbar>

                </ion-toolbar>

                <ion-toolbar color="primary" class="top segmento" >
                    <ion-segment 
                        v-model="pendientes" value="0" mode="ios" color="primary" 
                        class="todas">
                            <ion-segment-button value="0">
                                Todos
                            </ion-segment-button>
                            <ion-segment-button value="1">
                                Pendientes
                            </ion-segment-button>
                        </ion-segment>

                </ion-toolbar>  


        </ion-header>

        <ion-content>
            
            <!-- Fabicon de impresion, solo aparece si es busqueda directa  --> 
            <ion-fab vertical="top" horizontal="end" slot="fixed" v-if="!origen && !impresion_activa">
                    <ion-fab-button  color="darksuccess" @click="ActivarDesactivarImpresion">
                        <i class="fas fa-sharp fa-print en-fab"></i>  
                    </ion-fab-button>
            </ion-fab>

            <ion-row  class="spin ion-justify-content-center "  v-if="!showme">
                <ion-col  v-if="!showme" class="spin ion-align-self-center" size="48" > 
                    <ion-spinner  v-if="!showme"></ion-spinner>            
                </ion-col>
            </ion-row>
                 
                 
            <ion-row v-if="showme">
                <ion-row class="ion-no-padding"
                     v-if="showme && lista_articulos.length >0 && (!search_manually|| search_manually.length==0)">
                    <ion-col size="44" text-right>
                        <p color="info">
                            <span style="font-size:larger"><b>{{datos_tot }} articulos en stock, </b>
                            filtra para seleccionar, se muestran los {{Math.min(limite, lista_articulos.length)}} primeros
                            </span> 
                        </p>            
                    </ion-col>
                </ion-row>
                <ion-row class="ion-no-padding" v-else-if="showme && lista_articulos.length >0 && search_manually && search_manually.length>0">
                    <ion-col size="44"  text-right>
                        <p color="info">
                            Tu busqueda "{{search_manually}}" ha generado <span style="font-size:larger"><b>{{datos_tot }} resultados </b></span>
                            <br/>
                            Solo se muestran los {{Math.min(limite, lista_articulos.length)}} primeros
                        </p>            
                    </ion-col>
                </ion-row>

                <!-- BUSQUEDA SIN RESULTADOS-->
                <ion-row class="ion-padding ion-margin" v-else>
                    <ion-title class="ion-padding-top ion-margin-top texto-mas-grande" >Busqueda sin resultados </ion-title>
                        <div class="ion-padding-top ion-margin-top ion-text-justify texto-mas-grande">
                            <ion-text >
                            No hemos encontrado ningun resultado para  tu busqueda "<strong><i>'{{search_manually}}'</i></strong>"
                            entre los articulos del pdv. <br/><br/>

                            Puedes editar manualmente la busqueda, o escanear para seguir buscando. 
                            <br/>
                            </ion-text>
                        </div>
                </ion-row>
                
                <!-- BUSQUEDA DE UN EAN -->
                <ion-row class="ion-no-padding ion-no-margin" v-if="ean_sin_resultados ">
                    <ion-col size="44" text-right>
                        <p>
                             <span class="p-alert" > No tenemos registrado el EAN <b>"{{ean_sin_resultados}}"  </b>
                            <br/>
                            Buscalo manualmente, cuando selecciones un producto y lo ubiques lo grabaremos 
                            en Gestión2000 para que puedas encontrarlo en proximas busquedas.
                            </span>
                        </p>            
                    </ion-col>
                </ion-row>


                <!-- class="ion-no-padding ion-no-margin">         -->
                <ion-list v-if="showme && lista_articulos.length >0 " class="ion-no-padding">       
                    <ion-item 
                        v-for="(product,index) in lista_articulos"
                        v-bind:key="`item-${index}`"
                        class="ion-no-padding ion-no-margin">        
                        <ion-row :class="product.resaltado ? 'resaltado' : ''">    

                            <ion-col size-offset="1" size="47"  class="ion-no-margin  ion-no-padding">
                                <h3 @click = "ElementoStockSeleccionado(product)" > {{ $filters.showSku(product.sku)   }}</h3>
                                <h4 @click = "ElementoStockSeleccionado(product)" class="nombre_cortado" text-wrap>
                                    {{product.shortDescription}}
                                </h4>
                            </ion-col>
                            <ion-col size-offset="1" size="36"  class="ion-no-margin  ion-no-padding">
                                <h5 @click = "ElementoStockSeleccionado(product)" class="full-h5"> 
                                    <span class="lote" >Suc: {{ product.codsuc   }}</span>
                                    <span class="lote forzar_derecha" v-if="product.ubi">Ubic : {{ product.ubi   }}</span>
                                    <span class="lote forzar_derecha rojo" v-else>No Ubicado</span>
                                </h5>
                                <h5 @click = "ElementoStockSeleccionado(product)" class="nombre_cortado" text-wrap v-if="product.lote!=null">
                                    <b><span class="lote">Lote : {{ product.lote  }}</span></b>
                                </h5>
                            </ion-col>
                            <ion-col size="11"  class="ion-no-margin  ion-no-padding ion-text-end">
                                <ion-text  @click = "ElementoStockSeleccionado(product)" class="stock" text-wrap>
                                    <span class="stock"><b>{{$filters.Entero(product.stoalm)}}</b></span>
                                </ion-text>
                                <ion-text @click = "ElementoStockSeleccionado(product)" class="mini-lista" text-wrap>
                                   {{product.unimed}}
                                </ion-text>
                            </ion-col>
    
                        </ion-row>
                    </ion-item>
                </ion-list>
            </ion-row>
        </ion-content>

        <ion-footer v-if="origen">
            <ion-toolbar >
                <ion-buttons slot="end" class="completo"  >    
                    <ion-button class ="completo"  expand ="block" fill="solid" color="primary" size="normal" @click="ElementoStockSeleccionado(undefined)"> 
                        Cancelar
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-footer>

        <ion-footer v-else-if="impresion_activa">
            <ion-toolbar >
                <ion-buttons slot="start" class="completo"  >    
                    <ion-button class ="completo"  expand ="block" fill="solid" 
                        color="darkdanger" size="small"
                        @click="ActivarDesactivarImpresion()"
                        > 
                        Cancelar
                    </ion-button>
                </ion-buttons>

                <ion-buttons slot="end" class="completo"  >    
                    <ion-button class ="completo"  expand ="block" fill="solid" color="darksuccess" size="small" @click="Imprimir"> 
                        Imprimir
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-footer>



    </ion-page>

</template>
    

<script>
    import { defineComponent } from 'vue';
    import { closeCircleSharp , homeSharp,  thumbsDownSharp} from 'ionicons/icons';
    import {  openAlertaV2 } from  '@/utilities/services/alert-utilities';
    import { stockPdvFiltrado ,resetResaltados , RegexBobinasPdv} from '@/utilities/scanner/scan-sku'
    import { useRoute } from 'vue-router';
    import {scanGenericStart, scanGenericStop  } from '@/utilities/scanner/scan-generic'
    import {  isOnline}  from '@/utilities/global_functions'
    import {   pick  } from 'underscore';
    import eventBus from "@/event-bus"; 

    export default defineComponent({
            name : "SeleccionProductoStockComponent",
            data() { 
                return { 
                    element_id: 'busqueda-manual-generica',
                    pdv: undefined,
                    isIOS: undefined, 
                    search_manually : undefined, 
                    closeCircleSharp, homeSharp,  thumbsDownSharp, 
                    limite: this.origen ? 25 : 100 , 
                    datos_tot: undefined , 
                    lista_articulos: undefined , 
                    showme :false, 
                    ruta: undefined, 
                    qt :false , 
                    input_activo: false,
                    debounce_milis: 300, 
                    impresion_activa: false , 
                    pendientes: 0
                    }
            },
            props : ['origen', 'input_inicial_filtro', 'ean_sin_resultados'] , 
            created() {
                this.pdv =  this.$route.meta.pdv 
                this.isIOS = this.$route.meta.isIOS 
            },
            watch: {
                '$route.query.q'() { this.onCancel();}, 
                pendientes () { 
                    window.fake_console('entrando en el watch');
                    this.filtrar ();  
                }
            },
            updated() {
                this.pdv =  this.$route.meta.pdv 
            },
            async mounted() {
                this.pdv =  this.$route.meta.pdv 
                const route = useRoute();
                this.ruta = route.fullPath
                this.qt = route.query.q
                this.onCancel()
                if (this.input_inicial_filtro !=  undefined ) {
                    this.search_manually = this.input_inicial_filtro+'' 
                    await this.filtrar(' Desde mounted')
                }
                await this.escaner_generico()
                
            },
            async unmounted() {
                await scanGenericStop(this.element_id)
            }, 
            methods: {
                
                getFoco (ev, si) {
                    window.fake_console('evento escuchado')
                    if(si===true) {
                        this.input_activo = true; 
                        window.fake_console('activado')
                    }
                    else {
                        this.input_activo=false; 
                        window.fake_console('desactivado')
                    }
                },
                goBack() {
                    this.$router.go(-1)
                },

                async Imprimir(){
                    /**etiqueta/producto/${this.$pdv}?sku=${sku}&ubi=${ubi} */
                    let self = this
                    /** Si esta online dejamos imprimir etiquetas  */
                    if (isOnline) { 
                        await this.lista_articulos.reduce( async (memo, v)=> {
                            const m = await memo ;
                            if ( v.resaltado )
                            {
                                window.fake_console(v)
                                const payload = pick (v ,[ 'sku','ubi','lote','refpro'] )
                                if (payload.lote && /^rollo\d/i.test(payload.lote)) {
                                    payload.unimed = (v.unimed||'').toLowerCase()
                                    payload.stoalm = v.stoalm
                                }
                                window.fake_console(payload)
                                await  self.$http.post(`/etiqueta/producto/${self.$pdv}`, payload)
                                v['resaltado'] = false; 
                            }
                            return m 
                        }, [] )
                    }
                    /* Sino  lanzamos alerta*/
                    else {
                        await openAlertaV2('Sin Cobertura!!' , `
                            Ahora mismo no tienes cobertura. \n 
                            No tienes cobertura, acercate a una zona con cobertura para imprimir .  `
                            )
                    }
                }, 

                async ActivarDesactivarImpresion() {
                    this.impresion_activa = !this.impresion_activa
                    if (!this.impresion_activa){
                       await resetResaltados()
                    }
                },
                async lectorEventosGenericos() {
                    let self = this; 
                    eventBus().emitter.off(`lectura_generica:${this.element_id}`)
                    eventBus().emitter.once(`lectura_generica:${this.element_id}`,async (input_scaner)=>{
                       self.input_activo = false;
                       let buscar = input_scaner
                       /** miramos si el termino tiene includo un codigo de bobina , en cuyo caso lo eliminamos */
                       if ( RegexBobinasPdv.test(input_scaner) && input_scaner.replace(RegexBobinasPdv,'').length > 2)
                            { 
                                buscar = input_scaner.replace(RegexBobinasPdv,'')
                                window.fake_console(input_scaner, buscar)
                            }
                       self.search_manually = buscar
                       self.filtrar('Via lector Generico' , 'exacta') 
                       self.lectorEventosGenericos()
                    })
                },
                async escaner_generico() {
                    await scanGenericStart(this.element_id, 'desde escaner_generico')
                    await this.lectorEventosGenericos()
                },
                async filtroInputSearch() {
                    window.fake_console(this.input_activo)
                    if (this.input_activo) {
                        this.filtrar('Caja de busqueda via filtroInputSearch')
                    }
                }, 
                onCancel(){
                    this.showme = false
                    this.pdv =  this.$route.meta.pdv 
                    window.fake_console('en el cancel ')
                    this.search_manually = undefined; 
                    const d = stockPdvFiltrado(false, undefined, this.pendientes)
                    this.datos_tot = d.length||0 
                    window.fake_console(this.datos_tot,d.slice(0,5))
                    this.lista_articulos = d.slice(0,this.limite)
                    this.showme = true 
                    },

                async filtrar (comentario, tipo_filtro) {
                    window.fake_console('COMENTARIO :', comentario, this.input_activo)
                    window.fake_console(this.search_manually)
                    const dx = stockPdvFiltrado(this.search_manually||false, tipo_filtro, this.pendientes)
                    const d = this.pendinetes == 0 ? dx : dx.filter(y=>(y.stoalm||0)>0)
                    window.fake_console(d)
                    this.datos_tot = d.length 
                    /** Desactivamos los articulos marcados para impresion  */
                    this.lista_articulos.reduce( (m,v) =>  {
                        v['resaltado'] = false; 
                        return m+=1
                    } ,0)
                    this.lista_articulos = d.slice(0,this.limite)
                    this.showme = true
                    
                }, 
                async ElementoStockSeleccionado(item) {
                    window.fake_console(item)
                    if (this.origen) {
                        if (this.ean_sin_resultados && item) {
                            item.ean_sin_resultados = this.ean_sin_resultados
                        }
                        eventBus().emitter.emit(this.origen, item )   
                    }
                    /* caso impresion  */
                    else if (this.impresion_activa) {
                        item.resaltado = !item.resaltado
                    }
                    else {
                        this.$router.replace({'name' :'detalle_producto', params : { sku: item.sku , origen: '/buscar'}})
                        window.fake_console(item)
                    }                    
                 },

            },    
        })
</script>

<style scoped lang="scss">
    @import "@/theme/footer-styles.scss";
    @import "@/theme/seleccion-producto-stock.scss";
    .in-segment-color {
        color: var(--ion-color-blanco) !important; 
    }
    .segment-button-checked {
        color: var(--ion-color-primary) !important; 
        font-weight: bold !important; 
    }
</style>