<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button id="cancel" fill="outline" color="medium" @click="cancel()">
          <ion-label>Cancelar</ion-label>
          <ion-spinner style="display: none;" name="crescent"></ion-spinner>
        </ion-button>
      </ion-buttons>
      <ion-title style="text-align: center;">Filtros</ion-title>
      <ion-buttons slot="end">
        <ion-button id="send" fill="solid" color="primary" @click="confirm()" :strong="true">
          <ion-label>Confirmar</ion-label>
          <ion-spinner style="display: none;" name="crescent"></ion-spinner>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">

    <ion-list :inset="true" >
      <ion-item v-for="(filterObj) in getFilters()" :key="filterObj.filterName" :button="true" :detail="false" :id="filterObj.filterName">
        <ion-label>{{ filterObj.filterName }}</ion-label>
        <ion-badge slot="end">{{ filterObj.selectedItems.length }}</ion-badge>
      </ion-item>
    </ion-list>

    <ion-modal v-for="(filterObj, index) in getFilters()" :key="index" :trigger="filterObj.filterName" ref="modal">
      <app-typeahead
        :filterName="filterObj.filterName"
        :items="filterObj.items"
        :filteredItems="filterObj.filteredItems"
        :selectedItems="filterObj.selectedItems"
        @selection-change="filterSelectionChanged($event, index)"
        @selection-cancel="closeModal(index)"
      ></app-typeahead>
    </ion-modal>
  </ion-content>

  <ion-footer>
    <ion-button id="clean-all" style="display: flex; align-items: center;" fill="clear" @click="cleanAll()">
      <ion-label>Limpiar todo</ion-label>
      <ion-spinner style="display: none;" name="crescent"></ion-spinner>
    </ion-button>
  </ion-footer>
</template>

<script>
  import {
    IonContent,
    IonHeader,
    IonTitle,
    IonList,
    IonFooter,
    IonItem,
    IonLabel,
    IonToolbar,
    IonButtons,
    IonButton,
    IonModal,
    IonBadge,
    IonSpinner
  } from '@ionic/vue';
  import { defineComponent, ref } from 'vue';
  import AppTypeahead from "./filtros/AppTypeahead.vue";
  import { modalController } from '@ionic/vue';
  import { pluck } from "underscore";

  export default defineComponent({
    name: "ModalFiltros",
    components: {
      AppTypeahead,
      IonContent,
      IonHeader,
      IonTitle,
      IonToolbar,
      IonButtons,
      IonButton,
      IonList,
      IonFooter,
      IonItem,
      IonLabel,
      IonModal,
      IonBadge,
      IonSpinner
    },
    props: {
      rutas: Array,
      filters: Array,
      datos: Array,
      datosFiltrados: Array,
      datosFiltradosConRuta: Array
    },
    setup(props) {
      const modal = ref()
      const rutas_pdv = ref(props.rutas)
      const filters = ref(props.filters)
      const datos = ref(props.datos)
      const datosFiltrados = ref(props.datosFiltrados)
      const datosFiltradosConRuta = ref(props.datosFiltradosConRuta)
      
      const filterSelectionChanged = (filtersEvent, index) => {
        filters.value[index].selectedItems = filtersEvent;
        
        //Iteracion de los filtros que no sean este. Con el objetivo de quitar los items del otro
        filtrar()

        modal.value[index].$el.dismiss();
      }

      const cancel = () => {
        loadingButton('cancel', false)
        return modalController.dismiss([null, null], 'cancel');
      }

      const confirm = () => {
        loadingButton('send', false)
        return modalController.dismiss([filters.value, datosFiltrados.value], 'confirm');
      }

      const closeModal = (index) => {
        return modal.value[index].$el.dismiss();
      }

      const loadingButton = (idButton, isActive = true) => {
        if (!isActive) {
          document.getElementById(idButton).firstChild.style.display = 'none' // Label
          document.getElementById(idButton).lastChild.style.display = 'block' // Spinner
        } else {
          document.getElementById(idButton).firstChild.style.display = 'block'
          document.getElementById(idButton).lastChild.style.display = 'none'
        }
      }

      const cleanAll = () => {
        return modalController.dismiss([null, null], 'clean')
      }

      const extraerNumero = (str) => {
        // Expresión regular para encontrar un número entre paréntesis al final del string
        const regex = /\((\d+)\)$/;

        // Ejecutamos la expresión regular en el string
        const match = str.match(regex);

        // Si hay una coincidencia, devolver el número encontrado
        if (match) {
          return match[1];
        } else {
          return null; // O cualquier valor que quieras devolver si no hay coincidencia
        }
      }

      const filtrar = () => {

        let newDatosFiltrados = (datosFiltradosConRuta.value.length == 0) ? datos.value : datosFiltradosConRuta.value
        
        filters.value.filter(flt => flt.selectedItems.length > 0).forEach((filter) => {

          switch (filter.filterName) {
            case 'Rutas':
              newDatosFiltrados = newDatosFiltrados.filter(x => filter.selectedItems.includes(x.ubicacion))
              break;
            case 'Albaranes':
              newDatosFiltrados = newDatosFiltrados.filter(x => filter.selectedItems.includes(x.albaranes))
              break;
            case 'Nombres clientes':
              newDatosFiltrados = filter.selectedItems.flatMap((item) => {
                return newDatosFiltrados.filter(x => parseInt(extraerNumero(item)) == x.codcli)
              })
              break;
              
            case 'Fecha de envío':
              newDatosFiltrados = newDatosFiltrados.filter(x => filter.selectedItems.includes(x.fecenv))
              break;

            case 'Vehiculo':
              newDatosFiltrados = newDatosFiltrados.filter(x => filter.selectedItems.includes(x.vehiculo))
              break;

            case 'Chofers':
              newDatosFiltrados = newDatosFiltrados.filter(x => filter.selectedItems.includes(x.chofer))
              break;
          
            default:
              newDatosFiltrados = datosFiltrados.value
              break;
          }
        })
        
        let rutasAux = pluck(newDatosFiltrados, 'ubicacion')
        let rutasPdv = rutas_pdv.value.filter(x => rutasAux.includes(x.deszon)).map(ruta => { return ruta.deszon })
        let albaranes = pluck(newDatosFiltrados, 'albaranes')
        let nomcli = newDatosFiltrados.map((x) => { return `${x.nomcli} (${x.codcli})` })
        let fecenv = pluck(newDatosFiltrados, 'fecenv')
        let matricula = pluck(newDatosFiltrados, 'vehiculo')
        let chofer = pluck(newDatosFiltrados, 'chofer')

        filters.value[0].filteredItems =  [...new Set(rutasPdv)].filter(Boolean)
        filters.value[1].filteredItems =  [...new Set(albaranes)].filter(Boolean)
        filters.value[2].filteredItems =  [...new Set(nomcli)].filter(Boolean)
        filters.value[3].filteredItems =  [...new Set(fecenv)].filter(Boolean)
        filters.value[4].filteredItems =  [...new Set(matricula)].filter(Boolean)
        filters.value[5].filteredItems =  [...new Set(chofer)].filter(Boolean)
          
        datosFiltrados.value = newDatosFiltrados.flat()
      }

      const getFilters = () => { return filters.value; }

      return {
        modal,
        getFilters,
        filterSelectionChanged,
        cancel,
        confirm,
        closeModal,
        cleanAll
      }
    }
  });
  
</script>

<style>

ion-spinner {
	height: revert-layer !important;
}

</style>