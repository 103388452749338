<template>
    <ion-list class="ion-no-padding ion-no-margin" >

        <!-- Fecha albaran-->
        <ion-item> 
            <ion-label position="fixed" class="mitad">Fecha  PTL </ion-label>
            <ion-datetime-button datetime="datetime" :value="valores.fecalb" disabled></ion-datetime-button>

            <ion-modal :keep-contents-mounted="true">
                <ion-datetime presentation="date" id="datetime"></ion-datetime>
            </ion-modal>
        </ion-item>      

        <!-- Fecha Recepcion-->
        <ion-item > 
            <ion-label position="fixed" class="mitad">Fecha Recepcion</ion-label>
            <ion-datetime-button datetime="datetime" :value="valores.fecrec" disabled></ion-datetime-button>

            <ion-modal :keep-contents-mounted="true">
                <ion-datetime presentation="date" id="datetime"></ion-datetime>
            </ion-modal>
        </ion-item>      

        <!-- Bultos  -->
        <ion-item>  
            <ion-label position="fixed" class="mitad">Bultos</ion-label>
            <ion-input :value="valores.numbul"  disabled />                            
        </ion-item>

        <!-- Proveedor  -->
        <ion-item > 
            <ion-label position="fixed" class="mitad">Proveedor</ion-label>
            <ion-input :value="valores.codpro"   disabled />
        </ion-item>
    </ion-list>
</template>

<script>
    import { defineComponent } from 'vue';
    export default defineComponent({
        name : "AlbaranPtlComponent",
        props : ['valores'] 
    })
</script>

<style scoped lang="scss">
    @import "@/theme/recepciones-styles.scss";
</style>