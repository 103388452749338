import { Storage } from '@capacitor/storage';
const consola = function(){ void(0) }; //consola

const schema = 'ubiku.ges.digital'

        const saveEntradaToStorage = async function(pdv, codpro, codalb,etapa, datos) {
            try 
                {
                    const clave = `${schema}:entrada:${etapa}:${pdv}:${codpro}:${codalb}`
                    await Storage.set({ key: clave, value : JSON.stringify (datos)});
                    consola(1)
                    return true 
                }        
            catch { return false }
            }
        /** ------------------------------------------------------------------------------------------ */

        const loadEntradafromStorage = async function(pdv, codpro, codalb, etapa) {
            try 
            {const clave = `${schema}:entrada:${etapa}:${pdv}:${codpro}:${codalb}`
                const {value} = await Storage.get({ key: clave})
                return value == undefined ? undefined: JSON.parse(value )
            }
            catch {
                return undefined 
            }
        }
        /** ------------------------------------------------------------------------------------------ */

        const delEntradaFromStorage = async function(pdv, codpro, codalb,etapa) {
            try 
            {
                if(etapa === undefined ) {
                    ['recepcion','entrada','crossdocking','repaso'].forEach(async(e)=>{
                        const clave = `${schema}:entrada:${e}:${pdv}:${codpro}:${codalb}`
                        await Storage.remove({ key: clave });    
                    })
                }
                else {
                    const clave = `${schema}:entrada:${etapa}:${pdv}:${codpro}:${codalb}`
                    await Storage.remove({ key: clave });
                }
                return true    
            }
            catch {
                return false 
            }
        }

export { saveEntradaToStorage, loadEntradafromStorage , delEntradaFromStorage}

