import { Storage } from '@capacitor/storage';
const consola = function(){ void(0) }; //consola

const schema = 'ubiku.ges.digital'

        const saveRutaToStorage = async function(pdv, vehiculo, datos) {
            try 
                {
                    const clave = `${schema}:envio:${pdv}:${vehiculo}`
                    await Storage.set({ key: clave, value : JSON.stringify (datos)});
                    consola(1)
                    return true 
                }        
            catch { return false }
            }
        /** ------------------------------------------------------------------------------------------ */

        const loadRutafromStorage = async function(pdv,vehiculo) {
            try 
            {const clave = `${schema}:envio:${pdv}:${vehiculo}`
                const {value} = await Storage.get({ key: clave})
                return value == undefined ? undefined: JSON.parse(value )
            }
            catch {
                return undefined 
            }
        }
        /** ------------------------------------------------------------------------------------------ */

        const delRutaFromStorage = async function(pdv, vehiculo) {
            try 
            {
                const clave = `${schema}:envio:${pdv}:${vehiculo}`
                await Storage.remove({ key: clave });
                return true    
            }
            catch {
                return false 
            }
        }

export { saveRutaToStorage, loadRutafromStorage , delRutaFromStorage }

