/**
    Seleccion expecidiones 
    Listado de las envios generadas y aun no expedidas a cliente 
 */

<template>
    <ion-page  >
        <ion-header>
            <ion-toolbar   color="dark" class="ion-justify-content-start ion-align-items-start"> 
                <ion-title>
                    <ion-row  class="ion-justify-content-start ion-align-items-start">
                        <ion-col size="40"  class="ion-float-left izquierda" 
                            :style="isIOS ? 'padding-left: 4rem !important;': ''">
                            Creacion Reparto {{pdv}} ({{vehiculo_actual}})
                        </ion-col>
                    </ion-row>                
                </ion-title>
                <ion-buttons slot="end" >
                    <ion-button size="default" color="light"  routerLink="/expediciones-reparto" routerDirection="none">
                        <i class="fa-sharp fa-solid fa-circle-left muy-grande" color="light" ></i>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>



        <ion-content class="ion-padding" >      

            <ion-fab vertical="bottom" horizontal="start" edge="true" slot="fixed" >
                    <ion-fab-button  color="darksuccess" @click="verRutaGoogleMaps()">
                        <i class="fas fa-solid fa-route crossdock blanco"></i>
                    </ion-fab-button>
            </ion-fab>

            <ion-fab vertical="bottom" horizontal="end" edge="true" slot="fixed" >
                    <ion-fab-button  color="darkdanger" @click="grabarReparto()">
                        <i class="fas fa-sharp fa-floppy-disk crossdock blanco"></i>
                    </ion-fab-button>
            </ion-fab>



            <ion-row >
                <ion-col size="38">
                    <ion-button expand="block" fill="outline" size="large"  class="trigger_scan" >
                            <ion-title> 
                            <ion-text class="ion-text-wrap estrecho">
                                Ordena los envios segun la ruta de reparto que quieras seguir y pulsa ggrabar al final.
                            </ion-text>
                        </ion-title> 
                    </ion-button>
                </ion-col>       
                <ion-col size="10">
                        <ion-button class="alternativa impresion" fill="solid" expand="block" color="darksuccess" @click="DIBUJAR_DUMMY()">
                            <i class="fas fa-map blanca">
                                <ion-text class="ion-text-wrap en-botton">
                                    Sintesis Ruta
                                 </ion-text>
                            </i> 
                        </ion-button>       
                    </ion-col>            
            </ion-row>

            <ion-row key="`content:${qt}`">
                <ion-list v-if="showme  && largo>0" class="ion-no-padding" >       
                    <ion-list-header class="head"  style="width:100% !important;">
                        <ion-row>
                            <ion-col size="48">
                                <ion-text class="fake-titulo"> Envios a incorporar ({{vehiculo_actual}})</ion-text>
                            </ion-col>  
                        </ion-row>
                    </ion-list-header>

                    <ion-reorder-group :disabled="false" @ionItemReorder="doReorder($event)">
                            
                        <ion-item  class="ion-no-padding ion-no-margin"  v-for="(envio,index) in datos" v-bind:key="`item-${index}`"  >        
                            <ion-reorder slot="start">   
                                <i class="far  fa-sort reordenar "></i>                               
                            </ion-reorder>    
                            
                            <ion-row >    
                                <ion-col size-offset="1" size="48"  class="ion-no-margin  ion-no-padding"   >
                                    <h3 class="nombre_cortado" text-wrap>
                                       {{String(envio.codemp)}}.{{String(envio.codcli)}} - {{envio.nomcli}}
                                    </h3>
                                </ion-col>
                                <ion-col size-offset="1" size="41"  class="ion-no-margin  ion-no-padding">
                                    <h5 class="full-h5 intermedio"> 
                                        <span class="lote oscuro"><b>{{ envio.nomenv  }}</b></span> 
                                    </h5>
                                    <h5 class="nombre_cortado" text-wrap >
                                        <span class="lote oscuro">
                                            <small><b>{{ envio.domenv}}</b></small>
                                        </span> 
                                    </h5>
                                </ion-col>
                                <ion-col size="7" class="ion-no-margin  ion-no-padding"  @click="RutaGoogleMaps(envio)">
                                    <i :class="`fas fa-map-location-dot mapa `" />
                                </ion-col>

                                <ion-col size-offset="1" size="37"  class="ion-no-margin  ion-no-padding">
                                    <h5 class="nombre_cortado" text-wrap >
                                        <span class="lote oscuro">
                                            <small> <b>{{ envio.cpenv||''}} - {{envio.pobenv}}</b></small>
                                        </span> 
                                    </h5>                                                
                                </ion-col>
                                <ion-col size="11" class="ion-no-margin  ion-no-padding ion-text-end">
                                        <ion-text class="ion-text-wrap">
                                            <small>Bultos: </small>{{envio.bultos}}
                                            </ion-text>   
                                </ion-col>
                                <ion-col size-offset="1" size="48"  class="ion-no-margin  ion-no-padding">
                                    <h5 class="full-h5 intermedio" text-wrap >
                                        <span class="lote oscuro"><b><small>Albaran. : </small>{{envio.albaranes}}</b></span>
                                    </h5>
                                </ion-col>

                            </ion-row>
                        </ion-item>                                
                    </ion-reorder-group>

                </ion-list> 
        
                <ion-list v-else  class="ion-no-padding" >       
                    <ion-list-header class="head"  style="width:100% !important;">
                        <ion-row>
                            <ion-col size="48">
                                <ion-text class="fake-titulo"> Envios a incorporar ({{vehiculo_actual}})</ion-text>
                            </ion-col>  
                        </ion-row>
                    </ion-list-header>
                    <ion-item>
                        <ion-row no-padding no-margin  no-lines >
                                <ion-col class="centro">
                                    <ion-text >No hay ningun envio que añádir</ion-text>
                                </ion-col>
                        </ion-row>      
                    </ion-item>
                </ion-list>                  
            </ion-row> 
        </ion-content>



        <ion-tab-bar slot="bottom" color="dark">

            <ion-tab-button selected>
                <ion-label class="fab-in-tab">Mapa de<br/>la ruta</ion-label>
            </ion-tab-button>

            <ion-tab-button disabled v-for="(i) in [1,2,3]" :key="i"><div class="dummy">x</div></ion-tab-button>

            <ion-tab-button selected >
                <ion-label class="fab-in-tab ultimo">Grabar<br/>reparto</ion-label>
            </ion-tab-button>


        </ion-tab-bar>
        
        
        
    </ion-page>
</template>

<script>
    import { defineComponent } from 'vue';
    import { loadStorage } from '@/utilities/storage/storage-utilities-main'
    import {  openAlertaV2 } from  '@/utilities/services/alert-utilities';
    import { loadRutafromStorage, saveRutaToStorage } from '@/utilities/storage/storage-repartos'
    import { pluck, sortBy } from 'underscore';
    import { useRoute } from 'vue-router';
    import { Browser } from '@capacitor/browser';

    import { RutaGoogleMaps}  from '@/utilities/location'


    import { css_entrega, ubicaciones_entrega, estados_visuales, colores_situacion  } from '@/utilities/global-constants';

    export default defineComponent({
            name : "ExpedicionesCargarRutaRepartoPage",
            data() {
                return {
                    css_entrega , ubicaciones_entrega, estados_visuales, colores_situacion , 
                    RutaGoogleMaps, 
                    pdv : this.$route.meta.pdv, 
                    nompos : this.$route.meta.nompos, 
                    showme : false, 
                    qt :false , 
                    datos: [], 
                    vehiculo_actual : undefined, 
                    pendientes : [], 
                    googleRoute: undefined , 
                    pdv_geoloc : this.$route.meta.pdv_geoloc ,
                }
            }, 
            async created() {
                this.pdv =  this.$route.meta.pdv ;
                this.nompos =  this.$route.meta.nompos ;
                this.isIOS = this.$route.meta.isIOS 
                this.pdv_geoloc =  this.$route.meta.pdv_geoloc                
            },
            async mounted() { 
                this.$NoBackNavigator()
                window.fake_console('seleccion ubicaciones: entro en mounted  ', this.pdv)
                const route = useRoute();
                this.qt = route.query.q||Date.now()
                await this.recuperar_vehiculo()
                await this.recuperar_envios()
            }, 
            methods: {   
                async DIBUJAR_DUMMY() {
                    const pdv_geoloc = this.pdv_geoloc
                    const puntos = this.datos.reduce((m,v)=> {
                        m.push([v.lat_env,v.lon_env])
                        return m 
                    }, [])
                    await Browser.open({ url: `http://ubiku.ges.services/dibujar_ruta/${this.pdv}?puntos=${JSON.stringify(puntos)}&pdv_geoloc=[${pdv_geoloc}]` });
                    //window.open(`http://ubiku.ges.services/dibujar_ruta/${this.pdv}?puntos=${JSON.stringify(puntos)}&pdv_geoloc=[${pdv_geoloc}]`, '_blank', 'noreferrer');
                },
                async grabarReparto(){
                    try {
                        const envios = this.datos.reduce((m,v)=>{ m.push(v.ms_uuid); return m },[])
                        const payload = { envios, vehiculo : this.vehiculo_actual};
                        const actualizacion = await this.$http.post(`/set_expedicion/${this.pdv}`, payload)
                        window.fake_console(actualizacion.data)
                        if(actualizacion.data.status === 0  ){
                            await openAlertaV2(
                                'Error en la creación del reparto ', 
                                `No hemos podido realizar la operacion, error:\n${actualizacion.data.alert} `
                            )
                            this.qt = Date.now()
                            }
                        else {
                            await openAlertaV2(
                                'Reparto creado ', 
                                `${actualizacion.data.alert} con el codigo ${actualizacion.data.ms_uuid}`
                            )
                            this.$router.push('/expediciones-reparto')
                        }
                    }
                    catch (ex) {
                        console.log(ex)
                    }
                },

                async doReorder (event) {
                    // The `from` and `to` properties contain the index of the item
                    // when the drag started and ended, respectively
                    //console.log('Dragged from index', event.detail.from, 'to', event.detail.to);

                    // Finish the reorder and position the item in the DOM based on
                    // where the gesture ended. This method can also be called directly
                    // by the reorder group
                    this.datos = event.detail.complete(this.datos);
                },       

                async irExpediciones(){
                    this.$router.push('/expediciones')
                }, 

                async verRutaGoogleMaps(){
                    const destinos = this.datos.reduce((m,v) => {
                        const {lat_env,lon_env} = v
                        m.push([lat_env,lon_env])
                        return m  
                    }, [])
                    destinos.unshift(this.pdv_geoloc)
                    destinos.push(this.pdv_geoloc)
                    const goog_arr = destinos.reduce((m,x ) => { return m+=`/'${x[0]},${x[1]}'` }, '')
                    const goog_url = `https://www.google.com/maps/dir${goog_arr}`
                    this.googleRoute = goog_url;
                    //window.open(goog_url, '_blank', 'noreferrer');
                    await Browser.open({ url:goog_url})
                }, 

                async recuperar_vehiculo() {
                    try {
                        /** recuperaos los vehiculos y vemos  */
                        this.vehiculo_actual = await loadStorage('vehiculo')
                        if (!this.vehiculo_actual) {
                            this.$router.replace('/expediciones')
                        }
                    }
                    catch (ex) {
                        alert(JSON.stringify(ex))
                    }
                },
                async marcarIncidenciaPointer(envio){
                    window.fake_console('L290 :',envio)
                    const { vehiculo, ms_uuid } = envio;
                    const date = new Date(Date.now())
                    const incidencia_ent =  [{"k": `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`, "v": "Dirección no localizada"}]
                    const envios = {ms_uuid,incidencia_ent }
                    const payload = { vehiculo,  envios };
                    window.fake_console(payload)
                    const actualizacion = await this.$http.post(`/set_pointer_expedicion/${this.pdv}`, payload)
                    if(actualizacion.data.status === 0  ){
                        await openAlertaV2(
                                'Error al marcar el envio', 
                                `No hemos podido marca el envio como 'Direccion no encontrada', error:\n${actualizacion.data.alert} `
                            )
                    }
                }, 


                async optimizar() {

                    const origen = [ this.nompos, ...this.pdv_geoloc ]
                    const paradas = this.datos.map(x =>  {
                        return [x.dest, x.lat_env, x.lon_env]
                    })
                    /** Obtenemos ruta optimizada  */
                    try {
                        const opt = await this.$http.post(`/optimiza_ruta/${this.pdv}`, {origen, paradas, vehiculo: this.vehiculo_actual })
                        window.fake_console(origen, paradas)
                        const { optimizacion, err } = opt.data
                        console.log(err)                        
                        if (err ) {
                            const e = JSON.stringify(err)
                            /** consideramos tanto errores de falta de ruta como de carencia de pointer */
                            let errores = e.match(/(dest_[0-9]{3})/ig)||[]
                            console.log(errores)
                            await this.datos.reduce((m,y)=> {
                                if (!y.lat_env || isNaN(y.lat_env) || !y.lon_env || isNaN(y.lon_env) ){
                                    errores.push(y.dest)
                                }
                                return m+=1
                            }, 0)

                            if (errores.length >0 ){
                                await openAlertaV2(`Rutas no encontradas`, `
                                    Hay ${errores.length} envio/s para los que no hemos podido encontrar una ruta de entrega. <br/><br/>
                                    Los eliminamos del reparto y quedarán como carga pendiente hasta que se solucione.  <br/><br/>
                                    Si necesitas entregarlos de forma urgente, fuerza la posición en la pantalla de carga.  <br/><br/>
                                    Fijaremos como punto de entrega la posición del codigo postal. 
                                    `)
                            }
                            const erroneos = this.datos.filter( y => errores.indexOf(y.dest)!=-1)
                            this.datos = this.datos.filter( y => errores.indexOf(y.dest)==-1)
                            window.fake_console(erroneos)
                            await erroneos.reduce( async(memo,envio) => {
                                let m = await memo 
                                await this.marcarIncidenciaPointer(envio)
                                return m+=1
                            }, 0 )
                            this.optimizar()
                        }
                        /** reordenamos en base a la ruta propuesta en pantalla  */
                        const ordinales = pluck(optimizacion.activities, 'id')
                        const datos = sortBy(this.datos, (d)=> {return ordinales.indexOf(d.dest)} )
                        this.datos = datos 
                        await saveRutaToStorage(this.pdv, this.vehiculo_actual, datos )
                        this.qt = Date.now()
                    }
                    catch (ex){
                        console.log(ex)
                    }
                }, 

                async recuperar_envios(){
                    try {                    
                        const datos = await loadRutafromStorage(this.pdv, this.vehiculo_actual)
                        if (datos.length>0) {
                            window.fake_console(datos)
                            const dat = datos.map( (x,i) => {
                                x.dest = `dest_${(i.toString()).padStart(3,0)}`
                                x.albaranes = JSON.parse(x.albaranes).join( ' , ' ); 
                                x.estado_visual = this.estados_visuales[x.situa]||'question-circle'
                                return x })
                            this.datos = dat
                            await this.optimizar()
                        }
                        else { throw 'No hay envios'}
                    }
                    catch (ex) {
                        console.log(ex)
                        window.fake_console(ex)
                        this.datos = []
                    }           
                    this.showme = true ;          
                }, 
            }, 
            computed :{
                largo: function () {
                    return Object.keys(this.datos).length
                    }
            }
            
 
    })
</script>
 

<style scoped  lang="scss">
    @import "@/theme/seleccion-pedido-styles.scss";

    ion-reorder {
        margin-right:  0.5rem !important; 
        /*width: 3rem !important; */
    }
    ion-label.fab-in-tab {
        width: auto !important; 
        padding-top:1.5rem !important;
        font-weight: 900 !important;

        }
    ion-label.fab-in-tab.primero{
        float: left !important;
        padding-right: 15% !important
    }

    ion-label.fab-in-tab.ultimo{
        float: right !important;
        padding-left: 15% !important

    }
    
    ion-tab-bar {
        height: 5rem !important; 
    }
    ion-segment {
        width: 100% !important; 
    }
    ion-segment-button.todas {
        width: 20% ª!important; 
    }
    span.boton-opciones {
        font-size: 1.05rem !important; 
        padding-bottom: 0.3rem !important; 
        font-weight: bold !important;
        text-align: center !important;
    }
    ion-badge {
        width: 100% !important; 
    }
    ion-row.altos {
        height: 3rem !important;
        
    }
    ion-text.en-botton {
        font-family: 'Roboto';
        font-size: 0.9rem !important; 
        color: var(--ion-color-blanco)  !important;
        text-transform: none !important;
        line-height: 0.4rem !important;
    }

    ion-row {
        max-width: 600px !important; 
        padding: 0.3rem 0.2rem !important; 
    }

    ion-item {
        --inner-padding-end : 0px !important; 
        --padding-end : 0px !important; 
    }
    ion-text.fake-titulo {
        color: var(--ion-color-dark) !important; 
        text-align: center !important;
    }
    span.oscuro {
        color: var(--ion-color-medium) !important; 
        padding-left: 0.3rem !important; 
    }
    span.primario {
        color: var(--ion-color-primary) !important; 
        padding-left: 0.3rem !important; 
    }
    span.peque {
        font-size: smaller !important; 
        padding-right: 0.3rem !important; 
    }
    i.fas.tiempo , i.fak.tiempo , i.far.tiempo {
        color: dark; 
        font-weight: 600 important; ;
        font-size: 1.8rem !important;
        padding-right: 0.4rem !important
    }

    ion-item.iniciado {
        border-right: 10px  solid !important;
        border-image: linear-gradient(to bottom, white, var(--ion-color-darkdanger), white) 2;
    }
    .sin-margen {
        margin-top: 0px !important; 
    }

    i.crossdock {
        position:absolute;
        top:50%;
        left:50%;
    }
  
    i.blanco {
        color: var(--ion-color-blanco) !important;
        font-size: xx-large !important; 
        font-weight: 300 !important;
        transform:translate(-50%,-50%)
    }

    .girada {
        transform: rotate(90deg) translate(-50%,50%) !important;
    }
    i.fas.blanca, i.far.blanca {
        font-size: 2rem !important; 
        color: var(--ion-color-blanco)  !important;
        line-height: 1rem !important; 
        }
    i.fas.mapa, i.far.mapa {
        font-size: 2rem !important; 
        float: right !important; 
        position: relative !important; 
        top : 0.2rem !important; 
        color: var(--ion-color-danger)  !important;
        line-height: 1rem !important; 
        }

    i.fas.reordenar, i.far.reordenar {
        font-size: 2rem !important; 
        color: var(--ion-color-medium)  !important;
        margin: 0.3rem !important;
        }
    div.dummy {
        color: var(--ion-color-dark)  !important;
    }




</style>