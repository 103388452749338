import { jsPDF } from "jspdf";
import { QrCode } from '@grapecity/wijmo.barcode.common';
import { globalFilters } from '@/utilities/filters'

//canvas-pdf

const generarPdfEntrega = async function (envio, w, h ) {
    const {codemp, nompos, ms_uuid, codcli, nomenv, domenv, cpenv, pobenv, albaranes,has_nif, nif, nombre, firmado, fecenv, bultos, bl = 1 }	= envio
   console.log(envio)
    const canvas = document.getElementById("canvas-pdf");
    const dt = JSON.stringify({ms_uuid,bultos, bl})
    let qrCode = new QrCode('#qr-entrega', {value:dt , version:5  });
    const Qr =  new Image()
    Qr.src = qrCode.getDataUrl()
    await Qr.decode()
    console.log({codemp, nompos, ms_uuid, codcli, nomenv, domenv, cpenv, pobenv, albaranes, fecenv, bultos, bl}	)                    
    const ctx = canvas.getContext('2d'); 
    ctx.drawImage(Qr,173, 5 )
    const img = new Image() 
    img.src = '/assets/logo_ges.png';
    await img.decode()
    ctx.fillStyle = "#ffffff";
    ctx.fillRect(0, 0, ctx.width, ctx.height);
    ctx.fillStyle = "black";
    ctx.font = "normal bold 12px Roboto ";
    ctx.fillText(`${nompos}` , 8, 60);
    ctx.font = "normal bold 10px Roboto ";
    ctx.fillText(`${ms_uuid}` , 8, 75);
    ctx.beginPath(); 
    ctx.moveTo(5,83);
    ctx.lineTo(245,83);
    ctx.lineWidth = 1;
    ctx.stroke();
    ctx.font = "normal bold 12px Roboto ";
    ctx.fillText('Cliente:' , 12, 100);
    ctx.fillText('Albaranes Entregados:' , 12, 183);
    ctx.fillText(`Firmado:` , 12, 268);
    ctx.fillText('Nif:' , 12, 283);
    ctx.font = "normal 13px Roboto ";
    ctx.fillText( globalFilters.Initcap2(nombre) , 70, 268)
    ctx.fillText( has_nif === 1 ? nif : 'No facilita' , 40, 283)
    ctx.fillText(nomenv , 25, 118)
    ctx.fillText(nomenv , 25, 118)
    ctx.fillText(domenv , 25, 135)
    ctx.fillText(`${cpenv} - ${pobenv}` , 25, 153)
    ctx.beginPath(); 
    ctx.moveTo(1,425);
    ctx.lineTo(259,425);
    ctx.moveTo(125,425);
    ctx.lineTo(125,450);
    ctx.rect(6,252, 238,168);
    ctx.lineWidth = 1;
    ctx.stroke();
    /*
                        ctx.rect(6,270, 238,135);

    const g = 1000000
    const iterador  = [1,2,3,4,5,6,7,8,9,10,11]
    iterador.map(i => {
        albaranes.push(g+i)
    })
    */
    ctx.font = "normal bold 12px Roboto ";
    let albs = albaranes.split(';').map( (x,i) => {
        return [x, 203+(Math.floor(i/3)*15) , 12+((i%3)*75 )]
    })    
    albs.forEach(y => {ctx.fillText(y[0],y[2],y[1]) })

    ctx.font = "normal 12px Roboto ";
    ctx.fillText('Fecha:' , 6, 442);
    ctx.fillText('Bultos:' , 131, 442);

    ctx.textAlign = 'right';
    ctx.font = "normal bold 14px Roboto ";
    ctx.fillText(`${codemp} ${codcli}`,241,100) 
    ctx.fillText(globalFilters.num2Date(fecenv) , 120, 442)
    ctx.fillText(`${bultos}` , 240, 442)
    ctx.drawImage(img,5,10 )

    const firma_pdf = new Image()
    firma_pdf.src = firmado
    console.log(firma_pdf.src)
    await firma_pdf.decode()
    console.log(8,275,w*234/(250*250), h*135/(450*450) )
    ctx.drawImage(firma_pdf,8,275,238, 135  )
    const doc = new jsPDF('p', 'px', [canvas.width, canvas.height]);
    var img1 = canvas.toDataURL("image/png");
    doc.addImage(img1, 'JPEG', 0, 0, canvas.width, canvas.height);
    doc.save('test.pdf');
    console.log('LLEGO AL FINAL!!!! ')

    return    

    //await ctx.drawImage(png,0,0,canvas.width,canvas.height)
    
    
}






export {   generarPdfEntrega
}

