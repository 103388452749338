/**
    Seleccion expecidiones 
    Listado de las envios generadas y aun no expedidas a cliente 
 */

<template>
    <ion-page  >
        <CabeceraComponent 
            :pdv = "$route.meta.pdv"
            :isIOS = "$route.meta.isIOS"
            titulo="Repartos realizados"
            color="primary"
            :at_ges="EnPdv||false"
        />
        <ion-content class="ion-padding" >   

            <ion-row>
                <ion-list v-if="showme  && largo>0" class="ion-no-padding" ref="ion_item_options_slider" >       
                    <ion-list-header class="head"  style="width:100% !important;">
                        <ion-row>
                            <ion-col size="48">
                                <ion-text class="fake-titulo"> Repartos realizados ({{vehiculo_actual}})</ion-text>
                            </ion-col>  
                        </ion-row>
                    </ion-list-header>

                        <ion-item class="ion-no-padding ion-no-margin"  v-for="(envio,index) in datos" v-bind:key="`item-${index}`"  >         
                            <ion-row  >    
                                <ion-col size-offset="1" size="44"  class="ion-no-margin  ion-no-padding">
                                    <h3 class="nombre_cortado" text-wrap>
                                        {{String(envio.codemp)}}.{{String(envio.codcli)}} - {{envio.nomcli}}
                                    </h3>
                                </ion-col>
                                <ion-col size="4" class="ion-no-margin  ion-no-padding">
                                    <i :class="`${icono_estado(envio)}   ${colores_situacion[envio['situa']]} tiempo`"/>
                                </ion-col>
                                <ion-col size-offset="1" size="48"  class="ion-no-margin  ion-no-padding">
                                    <h5 class="full-h5 sin-margen"> 
                                        <span class="lote oscuro"><b><small>Nomenv : </small>
                                            {{ envio.nomenv  }}
                                            </b> 
                                        </span> 
                                        <span class="lote peque forzar_derecha">Importe: {{ $filters.Entero(envio.valor)  }} €</span>
                                    </h5>
                                    <h5 class="nombre_cortado" text-wrap >
                                        <span class="lote oscuro">
                                            <small>
                                                <b>{{ envio.cpenv||''}} - {{envio.pobenv}}</b>
                                            </small>
                                        </span> 
                                        <span class="lote peque forzar_derecha" >Fecha: {{ $filters.num2Date(envio.fecenv)   }} </span>
                                    </h5>
                                </ion-col>

                                <ion-col size-offset="1" size="48"  class="ion-no-margin  ion-no-padding">
                                    <h5 class="full-h5 sin-margen" text-wrap >
                                        <span class="lote oscuro"><b><small>Albaran. : </small>{{envio.albaranes}}</b></span>
                                    </h5>
                                </ion-col>

                                <ion-col size-offset="1" size="33"  class="ion-no-margin  ion-no-padding">
                                    <h5 class="full-h5 sin-margen" text-wrap >
                                        <span class="lote oscuro"><b><small>Cargado por : </small>{{envio.carga_uname}}</b></span>
                                    </h5>
                                </ion-col>
                                <ion-col  size="15"  class="ion-no-margin  ion-no-padding">
                                        <h3 class="full-h5 sin-margen" >
                                        <span class="lote forzar_derecha"   :class="css_entrega[envio['envio_tipo']]">
                                            <b>{{envio.ubicacion}}</b>
                                        </span>
                                    </h3>
                                    
                                </ion-col>
        
                            </ion-row>
                        </ion-item>

    
                </ion-list> 
                
                <ion-list v-else  class="ion-no-padding"  ref="ion_item_options_slider" >       
                    <ion-list-header class="head"  style="width:100% !important;">
                        <ion-row>
                            <ion-col size="48">
                                <ion-text class="fake-titulo"> Repartos realizados ({{vehiculo_actual}})</ion-text>
                            </ion-col>  
                        </ion-row>
                    </ion-list-header>
                    <ion-item>
                        <ion-row no-padding no-margin  no-lines >
                                <ion-col class="centro">
                                    <ion-text >No repartos en el dia para este vehiculo</ion-text>
                                </ion-col>
                        </ion-row>      
                    </ion-item>
                </ion-list>                  
            </ion-row> 
        </ion-content>

        <ion-tab-bar slot="bottom" color="primary">

            <ion-tab-button disabled >
                <div class="dummy" v-if="!EnPdv">x</div>
            </ion-tab-button>

            <ion-tab-button  @click="irA('/expediciones')" :disabled="ubiku_rol==5 && !EnPdv">
                <i class="fas fa-sharp fa-truck-ramp-box tiempo"/>
                <ion-label>Carga<br/>Actual</ion-label>
            </ion-tab-button>

            <ion-tab-button  @click="irA('/expediciones-reparto')"   :disabled="ubiku_rol!=5">
                <i class="fas fa-route tiempo"/>
                <ion-label>Reparto</ion-label>
            </ion-tab-button>

            <ion-tab-button  selected >
                <i class="fak fa-sharp-regular-house-circle-check tiempo"/>
                <ion-label>Entregado</ion-label>
            </ion-tab-button>

            <ion-tab-button disabled  >
                <div class="dummy" v-if="!EnPdv">x</div>
            </ion-tab-button>

        </ion-tab-bar>
        
        
    </ion-page>
</template>

<script>
    import CabeceraComponent from '@/components/CabeceraComponent'
    import { defineComponent } from 'vue';
    import { sortBy,  pluck } from 'underscore';
    import { saveStorage, loadStorage } from '@/utilities/storage/storage-utilities-main'
    import { openToast} from  '@/utilities/services/alert-utilities';
    import { openAlertaVehiculo   } from '@/pages/enviar/components/expedir-utilities.js' ;
    import {  css_entrega, ubicaciones_entrega, estados_visuales ,colores_situacion } from '@/utilities/global-constants';
    import { pdvDistance , RutaGoogleMaps }  from '@/utilities/location'
    import { useRoute } from 'vue-router';


    export default defineComponent({
            name : "ExpedicionesRepartoRealizadoPage",
            components: { CabeceraComponent } , 
            props: ['situacion'], 
            data() {
                return {
                    css_entrega, ubicaciones_entrega, estados_visuales, colores_situacion,  
                    RutaGoogleMaps, 
                    pdv : this.$route.meta.pdv, 
                    ubiku_rol : this.$route.meta.ubiku_rol , 
                    qt :false , 
                    nompos : this.$route.meta.nompos, 
                    element_id : 'seleccion_expedicione_reparto', 
                    clave: undefined  ,  
                    showme : false, 
                    datos: [], 
                    mis_vehiculos : undefined, 
                    vehiculo_actual : undefined, 
                    EnPdv : undefined, 
                    pdv_geoloc : this.$route.meta.pdv_geoloc ,

                }
            }, 
            async created() {
                this.pdv =  this.$route.meta.pdv ;
                this.nompos =  this.$route.meta.nompos ;
                this.pdv_geoloc =  this.$route.meta.pdv_geoloc ;
                this.ubiku_rol = this.$route.meta.ubiku_rol 
            },
            async mounted() { 
                this.$NoBackNavigator()
                window.fake_console('seleccion ubicaciones: entro en mounted  ', this.pdv)
                const route = useRoute();
                this.qt = route.query.q||Date.now()
                this.EnPdv = await pdvDistance(this.$geofence, this.pdv_geoloc, true ) === true||undefined 
                await this.recuperar_vehiculo()
                await this.recuperar_envios()
            }, 
            methods: {     
               
                async recuperar_vehiculo() {
                    try {
                        /** recuperaos los vehiculos y vemos   
                        this.vehiculo_actual = await loadStorage('vehiculo')
                        const rec = await this.$http.post(`/get_recursos_pdv/${this.pdv}`) 
                        const recursos = rec.data.vehiculos||[] 
                        this.mis_vehiculos = recursos.filter( x => {
                            return x.email == this.$current_user || x.tipo.toLowerCase()!='turismo'
                        })
                        const matriculas = pluck(this.mis_vehiculos,'matricula')
                        const tiene_vehiculo_principal = this.mis_vehiculos.filter(x => x.email == this.$current_user && x.main_vehicle == 1)
                        */

                        this.vehiculo_actual = await loadStorage('vehiculo')
                        const rec = await this.$http.post(`/get_transportes_pdv/${this.pdv}`) 
                        const recursos = rec.data.vehiculos||[] 
                        this.mis_vehiculos = recursos.filter( x => {
                            return x.activo === 1
                        })
                        const matriculas = pluck(this.mis_vehiculos,'matricula')
                        const tiene_vehiculo_principal = this.mis_vehiculos.filter(x => x.email == this.$current_user)
                        if (!this.vehiculo_actual || matriculas.indexOf(this.vehiculo_actual)== -1) {

                            if (tiene_vehiculo_principal.length == 1)  {
                                await saveStorage('vehiculo',tiene_vehiculo_principal[0].matricula)
                                this.vehiculo_actual = tiene_vehiculo_principal[0].matricula
                            }
                            else if (matriculas.length == 1) {
                                await saveStorage('vehiculo',matriculas[0])
                                this.vehiculo_actual = matriculas[0]
                            }

                            else {
                                const seleccionado = await openAlertaVehiculo(this.mis_vehiculos, undefined)
                                if (seleccionado) {
                                    this.vehiculo_actual = seleccionado
                                }
                                else {
                                    await openToast('Vehiculo no elegido', `No has elegido vehiculo, te redirigimos a la home`, 2000)
                                    setTimeout(()=> this.$router.replace('/home')   , 750 )
                                }
                            }
                        }
                    }
                    catch (ex) {
                        alert(JSON.stringify(ex))
                    }
                },
                 

                async recuperar_envios(){
                    try {                    
                        const recep = await this.$http.post(`/get_expediciones/${this.pdv}`, {vehiculo: this.vehiculo_actual})
                        /** solo puede ver los del vehiculo, que además chequearemos que sean suyos */
                        const datos = recep.data.filter( y => y.envio_tipo == 'REPARTO' && y.vehiculo == this.vehiculo_actual && y.situa==3) 
                        if (datos.length>0) {
                            window.fake_console(datos)
                            const dat = datos.map( x => {
                                x.albaranes = JSON.parse(x.albaranes).join( ' , ' ); 
                                return x })
                            this.datos = sortBy(dat , x=> {return  x.codcli})
                        }
                        else { throw 'No hay envios'}
                    }
                    catch (ex) {
                        console.log(ex)
                        window.fake_console(ex)
                        this.datos = []
                    }           
                    this.showme = true ;          
                }, 


            /** Función para devoler el icono a mostrar  */
                icono_estado (x) {
                    return  this.estados_visuales[x['envio_tipo']]||this.estados_visuales[x['situa']]||'question-circle'
                }, 
            /** Funcion que devuelve la etiqueta de 'ubicación' del envio  */
                ubi_text(x) {
                    return  (['REPARTO','ESPERA'].indexOf(x.envio_tipo) !=-1 ) ?  x.ubicacion : this.ubicaciones_entrega[x['envio_tipo']] 
                }, 


                async irA(a){
                    this.$router.replace(a)
                }

            }, 
            computed :{
                largo: function () {
                    return Object.keys(this.datos).length
                    }
            }
            
 
    })
</script>
 

<style scoped  lang="scss">
    @import "@/theme/seleccion-pedido-styles.scss";

    ion-label.fab-in-tab {
        padding-top:1.5rem !important;
        font-weight: 900 !important;
    }
    ion-tab-bar {
        height: 5rem !important; 
    }
    ion-segment {
        width: 100% !important; 
    }
    ion-segment-button.todas {
        width: 20% ª!important; 
    }
    span.boton-opciones {
        font-size: 1.05rem !important; 
        padding-bottom: 0.3rem !important; 
        font-weight: bold !important;
        text-align: center !important;
    }
    ion-badge {
        width: 100% !important; 
    }
    ion-row.altos {
        height: 3rem !important;
        
    }
    ion-text.en-botton {
        font-family: 'Roboto';
        font-size: 0.9rem !important; 
        color: var(--ion-color-blanco)  !important;
        text-transform: none !important;
        line-height: 0.4rem !important;
    }
    ion-row {
        max-width: 600px !important; 
        padding: 0.3rem 0.2rem !important; 
    }

    ion-item {
        --inner-padding-end : 0px !important; 
        --padding-end : 0px !important; 
    }
    ion-text.fake-titulo {
        color: var(--ion-color-primary) !important; 
        text-align: center !important;
    }
    span.oscuro {
        color: var(--ion-color-medium) !important; 
        padding-left: 0.3rem !important; 
    }
    span.primario {
        color: var(--ion-color-primary) !important; 
        padding-left: 0.3rem !important; 
    }
    span.peque {
        font-size: smaller !important; 
        padding-right: 0.3rem !important; 
    }
    i.fas.tiempo , i.fak.tiempo , i.far.tiempo {
        color: dark; 
        font-weight: 600 important; ;
        font-size: 1.8rem !important;
        padding-right: 0.4rem !important
    }

    .sin-margen {
        margin-top: 0px !important; 
    }

    i.crossdock {
        position:absolute;
        top:50%;
        left:50%;
    }
  
    i.blanco {
        color: var(--ion-color-blanco) !important;
        font-size: xx-large !important; 
        font-weight: 300 !important;
        transform:translate(-50%,-50%)

    }




</style>