import { Storage } from '@capacitor/storage';
const consola = function(){ void(0) }; //consola

const schema = 'ubiku.ges.digital'

        const delMostradorStorage = async function(clave, pdv ) {
            await Storage.remove({ key: `${schema}:mostrador:${pdv}:${clave}` });
        }
        /** ------------------------------------------------------------------------------------------ */

        const saveMostradorStorage = async function(clave, pdv, valor) {

            await Storage.set({ key: `${schema}:mostrador:${pdv}:${clave}` , value: JSON.stringify(valor)})
        }
        
        const add2MostradorStorage = async function(clave, pdv, valor) {
            window.fake_console(valor)
            /** const clau = `${item.sku}.${this.$pdv}.${item.codsuc}.${!item.lote ? 'N' :item.lote}`*/
            const {sku,codsuc , lote, tipo } = valor 
            window.fake_console (sku,codsuc , lote, tipo)
            const clau = `${sku}.${pdv}.${codsuc}.${!lote ? 'N' : lote }.${!tipo ? 'pedido' : tipo}`
            valor['fila'] = clau;
            const previos = await loadMostradorStorage(clave, pdv)||[]
            /** sustituimos los que ya existen (eliminamos el viejo y añadimos el nuevo) */
            let nuevo = (Array.isArray(previos) ?  previos : []).filter(y =>y.fila != clau)
            window.fake_console(valor)
            if (valor.uds_alb>0) { nuevo.unshift(valor) }
            await Storage.set({ key: `${schema}:mostrador:${pdv}:${clave}` , value: JSON.stringify(nuevo)})
        }

        /** ------------------------------------------------------------------------------------------ */
        const loadMostradorStorage = async function(clave, pdv) {
            window.fake_console(clave, pdv )
            try {
                const k = pdv == undefined && clave.indexOf(`${schema}:mostrador`)!= -1 
                    ? clave 
                    : `${schema}:mostrador:${pdv}:${clave}`
                consola(k)
                const {value} = await Storage.get({ key: k})
                return value == undefined ? undefined: JSON.parse(value )
            }
            catch(ey) {
                return undefined
            }            
        }

        const FlagPedidoMostradorStorage = async function(pedido, pdv, valor) {
            const { fila, done } = valor 
            const previos = await loadMostradorStorage(pedido, pdv)||[]
            /** sustituimos los que ya existen (eliminamos el viejo y añadimos el nuevo) */
            let nuevo = (Array.isArray(previos) ?  previos : []).filter(y =>y.fila != fila)
            if (done===true) {nuevo.push(valor)} else {nuevo.unshift(valor)}
            await Storage.set({ key: `${schema}:mostrador:${pdv}:${pedido}` , value: JSON.stringify(nuevo)})
            return nuevo
        }


export { saveMostradorStorage, loadMostradorStorage, delMostradorStorage,   add2MostradorStorage, 
        FlagPedidoMostradorStorage 
    }

