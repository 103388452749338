/**
    Seleccion expecidiones 
    Listado de las envios generadas y aun no expedidas a cliente 
 */

<template>
  <ion-page>
    <CabeceraComponent :pdv="$route.meta.pdv" :isIOS="$route.meta.isIOS" titulo="Envios en curso" color="primary" />

    <ion-content class="ion-padding" key="`content:${qt}`">

      <ion-fab vertical="top" horizontal="end" slot="fixed">
        <ion-fab-button :key="`scan-activo:${blinker}`" :class="blinker ? 'activo' : 'inactivo'">
          <i class="fas fa-solid fa-barcode-read muy-grande" :class="blinker ? 'blink' : 'not-blink'"
            :key="`ion-scan:${blinker}`"></i>
        </ion-fab-button>
      </ion-fab>

      <ion-fab vertical="top" horizontal="start" slot="fixed">
        <!--<ion-fab-button  color="primary"  @click="FiltrarRuta()" v-if="!filtro ||filtro.deszon == 'all' ">
                        <i class="fas fa-sharp fa-filter en-fab"></i>  
                    </ion-fab-button>
                    <ion-fab-button  color="danger"  @click="FiltrarRuta(true)" v-else>
                        <i class="far fa-sharp fa-filter-circle-xmark en-fab"></i>  
                    </ion-fab-button>-->
        <ion-fab-button color="primary" @click="abrirModalDeFiltros()" v-if="numSelectedItems == 0">
          <i class="fas fa-sharp fa-filter en-fab"></i>
        </ion-fab-button>
        <ion-fab-button color="danger" @click="abrirModalDeFiltros()" v-else>
          <ion-badge id="num-selected-items" color="primary">
            {{ numSelectedItems }}
          </ion-badge>
          <i class="fas fa-sharp fa-filter en-fab icon-filter"></i>
        </ion-fab-button>
      </ion-fab>

      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button color="darksuccess" @click="ActivarDesactivarMenuEntregas(!agrupacion_entrega_activa)">
          <i class="fak fa-sharp-regular-house-circle-check en-fab"/>
        </ion-fab-button>
      </ion-fab>

      <ion-row>
        <ion-col size="48">
          <ion-button expand="block" fill="outline" size="large" class="trigger_scan">
            <ion-title>
              <ion-text class="ion-text-wrap estrecho">
                Escanea un albarán para crear un envio, o clicka uno de la lista para gestionarlo
              </ion-text>
            </ion-title>
          </ion-button>
        </ion-col>
      </ion-row>


      <ion-row>
        <ion-list v-if="showme && largo > 0" class="ion-no-padding" ref="ion_item_options_slider">

          <ion-list-header class="head" style="width:100% !important;">
            <ion-row>
              <ion-col size="48">
                <ion-text class="fake-titulo">
                  Envios en curso
                  <i class="fas fa-solid fa-person-shelter fa-fade en-title" v-if="EnPdv === true"></i>
                  <i class="fas fa-solid fa-house-person-leave fa-fade en-title" v-else></i>
                </ion-text>
              </ion-col>
            </ion-row>
          </ion-list-header>
          <!--:disabled="(situacion_envio||1)>=2"-->
          <ion-item-sliding v-for="(envio, index) in datos_filtrados_mostrar" v-bind:key="`item-${index}`"
            :disabled="EnPdv !== true">
            <ion-item @click="(agrupacion_entrega_activa) ? resaltar(envio) : ProximasParadas(envio)" class="ion-no-padding ion-no-margin"
              :class="envio.situa == 1 ? 'iniciado' : ''">
              <ion-row :class="envio.resaltado ? 'resaltado' : ''">
                <ion-col size-offset="1" size="44" class="ion-no-margin  ion-no-padding">
                  <h3 class="nombre_cortado" text-wrap>
                    {{ String(envio.codemp) }}.{{ String(envio.codcli) }} - {{ envio.nomcli }}
                  </h3>
                </ion-col>
                <ion-col size="4" class="ion-no-margin  ion-no-padding">
                  <i :class="`${icono_estado(envio)}  ${colores_situacion[envio['situa']]} tiempo`" />
                </ion-col>
                <ion-col size-offset="1" size="48" class="ion-no-margin  ion-no-padding">
                  <h5 class="full-h5 intermedio">
                    <span class="lote oscuro"><b>
                        {{ envio.nomenv }}
                      </b>
                    </span>
                  </h5>

                  <h5 class="full-h5 intermedio">
                    <span class="lote oscuro">
                      <small>{{ envio.domenv }}</small>
                    </span>
                    <span class="lote peque forzar_derecha">Fecha: {{ $filters.num2Date(envio.fecenv) }} </span>
                  </h5>
                </ion-col>
                <ion-col size-offset="1" size="37" class="ion-no-margin  ion-no-padding">
                  <h5 class="nombre_cortado intermedio" text-wrap>
                    <span class="lote oscuro">
                      <small> <b>{{ envio.cpenv || '' }} - {{ envio.pobenv }}</b></small>
                    </span>
                  </h5>
                </ion-col>
                <ion-col size="11" class="ion-no-margin  ion-no-padding ion-text-end">
                  <ion-text class="ion-text-wrap">
                    <small>Bultos: </small>{{ envio.bultos }}
                  </ion-text>
                </ion-col>

                <ion-col size-offset="1" :size="envio.ubicacion ? 33 : 48" class="ion-no-margin  ion-no-padding">
                  <h5 class="full-h5 intermedio" text-wrap>
                    <span class="lote oscuro"><b><small>Albaran. : </small>{{ envio.albaranes }}</b></span>
                  </h5>
                </ion-col>

                <ion-col size="15" class="ion-no-margin  ion-no-padding" v-if="envio.ubicacion">
                  <h3 class="full-h5 intermedio">
                    <span class="lote forzar_derecha" :class="css_entrega[envio['envio_tipo']]">
                      <b>{{ ubi_text(envio) }}</b>
                    </span>
                  </h3>
                </ion-col>

                <ion-col size-offset="1" size="48" v-if="envio.incidencia_ent" class="ion-no-margin  ion-no-padding">
                  <h5 class="nombre_cortado" text-wrap v-for="(ix, index) in envio.incidencia_ent" :key="`inci-${index}`">
                    <span class="lote fracaso">
                      - <small> {{ ix.k }} : {{ ix.v }} </small>
                    </span>
                  </h5>
                </ion-col>

              </ion-row>

            </ion-item>
            <!--
                            <ion-item-options side="start" v-show="!situacion_envio">                                    
                            -->
            <ion-item-options side="start">
              <ion-item-option @click="revisar_entrega_envio(envio.ms_uuid)">
                <i class="fas fa-eye muy-grande" slot="bottom"></i>
                <span class="boton-opciones">Datos</span>
              </ion-item-option>

              <ion-item-option color="darkdanger" @click="enEspera(envio)" :disabled="!EnPdv"
                v-show="envio.situa == 0 && envio.envio_tipo == 'REPARTO'">
                <i class="fak fa-solid-pause-clock  muy-grande" slot="bottom"></i>
                <span class="boton-opciones">Espera</span>
              </ion-item-option>

              <ion-item-option color="success" @click="callPhone(envio)" v-show="envio.telefono">
                <i class="fas fa-sharp fa-circle-phone-flip muy-grande" slot="bottom"></i>
                <span class="boton-opciones">Llamar</span>
              </ion-item-option>

              <ion-item-option color="darksuccess" @click="imprimir_etiqueta_envio(envio)" :disabled="!EnPdv"
                v-show="envio.situa == 0">
                <i class="fas fa-sharp fa-print muy-grande" slot="bottom"></i>
                <span class="boton-opciones">Print</span>
              </ion-item-option>

              <ion-item-option color="danger" @click="irGoogleMaps(envio)" v-show="envio.envio_tipo == 'REPARTO'">
                <i class="fas  fa-location-dot  muy-grande" slot="bottom"></i>
                <span class="boton-opciones">Mapa</span>
              </ion-item-option>
            </ion-item-options>

            <ion-item-options side="end" v-show="EnPdv && envio.situa == 0">

              <ion-item-option color="darksuccess" @click="Entregar(envio)"
                v-show="envio.situa == 0 && ['REPARTO', 'ESPERA'].indexOf(envio.envio_tipo) == -1">
                <i class="fak fa-sharp-regular-house-circle-check muy-grande" slot="bottom"></i>
                <span class="boton-opciones">Entrega</span>
              </ion-item-option>

              <ion-item-option color="primary" @click="cambioRuta(envio)" v-show="envio.situa == 0">
                <i class="fak fa-sharp-regular-road-circle-question muy-grande" slot="bottom"></i>
                <span class="boton-opciones">Cambio<br />Ruta</span>
              </ion-item-option>

              <ion-item-option color="danger" @click="borrar_envio(envio)" v-show="envio.situa == 0">
                <i class="far fa-sharp fa-trash-can muy-grande" slot="bottom"></i>
                <span class="boton-opciones">Borrar</span>
              </ion-item-option>

            </ion-item-options>
          </ion-item-sliding>

          <ion-infinite-scroll
              @ionInfinite="loadDataDynamic($event)"
              threshold="100px"
              id="infinite-scroll">
                <ion-infinite-scroll-content
                loading-spinner="crescent">
                </ion-infinite-scroll-content>
          </ion-infinite-scroll>
        </ion-list>

        <ion-item v-else-if="!showme && largo > 0">
          <ion-row no-padding no-margin no-lines>
            <ion-col class="centro">
              <ion-text>Cargando...</ion-text>
            </ion-col>
          </ion-row>
        </ion-item>

        <ion-item v-else>
          <ion-row no-padding no-margin no-lines>
            <ion-col class="centro">
              <ion-text>No hay envios en curso pendientes</ion-text>
            </ion-col>
          </ion-row>
        </ion-item>

      </ion-row>
    </ion-content>

    <ion-tab-bar slot="bottom" color="primary" v-if="!agrupacion_entrega_activa">

      <ion-tab-button @click="accion(undefined, undefined)" :selected="situacion_envio == undefined">
        <i class="fas fa-th-large tiempo" />
        <ion-label>Todos</ion-label>
      </ion-tab-button>

      <ion-tab-button @click="accion(0)" :selected="situacion_envio == 0 && en_espera != true">
        <i class="far fa-sharp fa-boxes-stacked tiempo" />
        <ion-label>Preparado</ion-label>
      </ion-tab-button>
      <ion-tab-button @click="accion(0, true)" :selected="situacion_envio == 0 && en_espera == true">
        <i class="fak fa-sharp-solid-pause-clock tiempo" />
        <ion-label>Espera</ion-label>
      </ion-tab-button>

      <ion-tab-button  @click="accion(1)" :selected="situacion_envio == 1">
        <i class="fas fa-truck-ramp-box tiempo"/>
        <ion-label>Cargado</ion-label>
      </ion-tab-button>
      <ion-tab-button  @click="accion(2)" :selected="situacion_envio == 2">
        <i class="fas fa-truck-fast tiempo"/>
        <ion-label>Reparto</ion-label>
      </ion-tab-button>
      <ion-tab-button  @click="accion(3)" :selected="situacion_envio == 3">
        <i class="fak fa-sharp-regular-house-circle-check tiempo"/>
        <ion-label>Entrega</ion-label>
      </ion-tab-button>
    </ion-tab-bar>

    <ion-footer v-else>
      <ion-toolbar >
        <ion-buttons slot="start" class="completo"  >    
          <ion-button class ="completo"  expand ="block" fill="solid" 
            color="darkdanger" size="small"
            @click="ActivarDesactivarMenuEntregas(!agrupacion_entrega_activa)"> 
            Cancelar
          </ion-button>
        </ion-buttons>

        <ion-buttons slot="end" class="completo"  >    
          <ion-button class ="completo"  expand ="block" fill="solid" color="darksuccess" size="small" @click="entregarAgrupados()"> 
            Entregar
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
        
    </ion-page>
</template>

<script>

  import "@/theme/footer-styles.scss";
  import CabeceraComponent from '@/components/CabeceraComponent'

  import { defineComponent } from 'vue';
  import { sortBy, mapObject, reduce, pluck } from 'underscore';
  import { trashOutline, barcodeSharp, printSharp } from 'ionicons/icons';
  import { scanGenericStart, scanGenericStop } from '@/utilities/scanner/scan-generic'
  import { delEnvioFromStorage } from '@/utilities/storage/storage-envios'
  import { esAlbaran } from '@/utilities/global-constants';
  import { openAlertaEntrega, openAlertaPonerEnEspera, openAlertaEntregasAgrupadas } from '@/pages/enviar/components/expedir-utilities.js';
  import { openAlertaV2, openAlertaBinaria } from '@/utilities/services/alert-utilities';
  import SelectorRutaEntregaComponent from '@/pages/enviar/components/SelectorRutaEntregaComponent.vue';
  import { modalController,IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/vue';
  import { pdvDistance, irGoogleMaps, getGeoloc } from '@/utilities/location'
  import eventBus from "@/event-bus";
  import { css_entrega, ubicaciones_entrega, estados_visuales, colores_situacion, esEnvio } from '@/utilities/global-constants';
  import { useRoute } from 'vue-router';
  import { Browser } from '@capacitor/browser';
  import ModalFiltros from '../../components/ModalFiltros.vue';
  
  const NUM_ITEMS_DYNAMIC = 80;

  export default defineComponent({
    name: "SeleccionEnviosPage",
    components: { CabeceraComponent, IonInfiniteScroll, IonInfiniteScrollContent },
    data() {
      return {
        css_entrega, ubicaciones_entrega, estados_visuales, colores_situacion,
        irGoogleMaps,
        pdv: this.$route.meta.pdv,
        qt: false,
        nompos: this.$route.meta.nompos,
        element_id: 'seleccion_envios',
        clave: undefined,
        showme: false,
        trashOutline, barcodeSharp, printSharp,
        datos: [],
        situacion_envio: undefined,
        en_espera: undefined,
        filtro: { 'deszon': 'all' },
        datos_filtrados: [],
        datos_filtrados_mostrar: [],
        datos_filtrados_con_ruta: [],
        blinker: false,
        rutas_pdv: undefined,
        cp_rutas: undefined,
        pdv_geoloc: undefined,
        EnPdv: undefined,
        isIOS: undefined,
        filtros: [],
        numSelectedItems: 0,
        agrupacion_entrega_activa: false
      }
    },
    async created() {
      this.pdv = this.$route.meta.pdv;
      this.nompos = this.$route.meta.nompos;
      this.pdv_geoloc = this.$route.meta.pdv_geoloc
      this.isIOS = this.$route.meta.isIOS
    },
    async mounted() {
      this.$NoBackNavigator()
      window.fake_console('seleccion ubicaciones: entro en mounted  ', this.pdv)
      const route = useRoute();
      this.qt = route.query.q || Date.now()
      this.EnPdv = await pdvDistance(this.$geofence, this.pdv_geoloc, true) || undefined
      window.fake_console(this.$geofence, this.pdv_geoloc, await pdvDistance(this.$geofence, this.pdv_geoloc, true), this.EnPdv)
      window.fake_console(this.pdv_geoloc)
      await this.recuperar_envios()
      /** El escaner solo esta activo si tenemos permitidas las operaciones  */

      //window.fake_console(this.EnPdv, this.$geofence, this.pdv_geoloc, true)
      if (this.EnPdv) { await this.escaner_generico() }
    }, 
    async unmounted() {
      await this.pararScanner()
    },  
    methods: {    
                
      async Entregar(envio) {
        let slider = this.$refs.ion_item_options_slider
        await this.pararScanner()
        const confirmacion = await openAlertaEntrega(this.$http, envio, this.pdv_geoloc)
        if (!confirmacion) {
          await this.escaner_generico()
          return
        }
        try {
          const actualizacion = await this.entregarEnvio(envio, confirmacion)
          window.fake_console(actualizacion.data)
          if (actualizacion.data.status === 0) {
            await openAlertaV2(
              'Error en la creación de la entrega ',
              `No hemos podido realizar la operacion, error:\n${actualizacion.data.alert} `
            )
            this.qt = Date.now()
          }
          else {
            await openAlertaV2(
              'Entrega realizada ',
              `${actualizacion.data.alert} con el codigo ${actualizacion.data.ms_uuid}`
            )
            await slider.$el.closeSlidingItems()
            await this.escaner_generico()
            await this.recuperar_envios()
            this.qt = Date.now();
          }
        }
        catch (ex) {
          window.fake_console(ex)
        }
      },

      /**
        * Sistema replicado de imprimir productos donde seleccionas varios envios para entregar y entregarlos de golpe
        * Asi evitamos que se haga uno por uno.
        */
      async entregarAgrupados() {
        let enviosParaEntregar = this.datos_filtrados_mostrar.filter(dato_filtrado => { return dato_filtrado.resaltado })
        let bultosTotales = pluck(enviosParaEntregar, 'bultos').reduce((a, b) => a + b, 0)

        /**
         * Validación de que si los pedidos son de clientes externos y además no son del mismo cliente
         * se mostrará una alerta de que los envios que se van a entregar no son del mismo cliente.
         * 
         * Simplemente una alerta
        */
        let isEnvioValido = (
          enviosParaEntregar.length > 0 &&
          !enviosParaEntregar.every(x => x.codcli == enviosParaEntregar[0].codcli)
        )
        ?
        await openAlertaBinaria(
          '¡Clientes diferentes!',
          'Los clientes externos de estos envíos no son los mismos.<br><br>¿Seguro que quieres continuar?',
          'Cancelar',
          'Confirmar'
        )
        :
        true

        // Si se dice cancelar no sigue
        if (!isEnvioValido) return

        const res = await openAlertaBinaria(
          'Alerta',
          `Vas a entregar <strong>${enviosParaEntregar.length} envío/s</strong>.<br><br>Dale a confirmar si el 
          número de bultos que vas a entregar es <strong>${bultosTotales}</strong>.`,
          'Cancelar',
          'Confirmar'
        )

        // Si se dice cancelar no sigue
        if (!res) return

        const resEntregasAgrupadas = await openAlertaEntregasAgrupadas(this.$http, enviosParaEntregar, this.pdv_geoloc, bultosTotales)
        
        if (!resEntregasAgrupadas) return

        let actualizaciones = await Promise.all(
          enviosParaEntregar.map(async (envio) => {
            return await this.entregarEnvio(envio, resEntregasAgrupadas)
          })
        )

        let haveError = actualizaciones.filter(({ status }) => status == 0)

        if (haveError.length > 0) {
          await openAlertaV2(
            `Hay ${haveError.length} envíos que no han podido entregarse.`,
            `Los envíos son: <br><br> ${haveError.map(envio => { return envio.ms_uuid }).join('<br> - ')}<br><br>Por favor, revísalo.
            <br><br>Los demás han sido entregados`
          )
        } else {
          await openAlertaV2(
            `Los envíos se han entregado correctamente`,
            `Los envíos que se han entregado son: <br><br>${enviosParaEntregar.map(envio => { return envio.ms_uuid }).join('<br> - ')}.` +
            `<br><br>Se han entregado con ${bultosTotales} bultos.`
          )
        }

        await this.ActivarDesactivarMenuEntregas()
        await this.recuperar_envios() 
      },

      async entregarEnvio(envio, confirmacion) {
        try {
          const { codrep, vehiculo, ms_uuid } = envio
          const { latitude, longitude } = await getGeoloc(60 * 1000 * 2)
          const { has_nif, nif, nombre, agencia, codexp_agencia } = confirmacion
          let envios
          if (envio.envio_tipo == 'RECOGIDA') {
            envios = { codrep, ms_uuid, vehiculo: null, has_nif, lat_ent: latitude, lon_ent: longitude, entrega_id: { nif, nombre } }
          }
          else {
            envios = { codrep, ms_uuid, vehiculo: null, has_nif, lat_ent: latitude, lon_ent: longitude, entrega_id: { nif, nombre, agencia, codexp_agencia } }
          }

          const payload = { codrep, vehiculo, envios };
          return await this.$http.post(`/deliver_expedicion/${this.pdv}`, payload)
        } catch (ex) {
          window.fake_console(ex)
        }
      },

      async resaltar(envio) {
        envio.resaltado = !envio.resaltado
      },

      /**
       * Activa o desactiva el menú de entregas
       */
      async ActivarDesactivarMenuEntregas(isActive) {
        this.agrupacion_entrega_activa = isActive
        await this.clean()

        if (!this.agrupacion_entrega_activa) {
          this.resetResaltados()
        } else {
          this.datos_filtrados = sortBy(this.getEnviosEntregables(), x => x.nomcli)
          this.datos = this.datos_filtrados
          this.updateFilteredItems(this.datos_filtrados)
        }
        
        this.datos_filtrados_mostrar = this.datos_filtrados.slice(0, NUM_ITEMS_DYNAMIC)
      },

      getEnviosEntregables() {
        return this.datos_filtrados.filter(envio => this.EnPdv && envio.situa == 0 && !['REPARTO', 'ESPERA'].includes(envio.envio_tipo))
      },

      resetResaltados() {
        this.datos_filtrados.reduce((m, v) => {
          v['resaltado'] = false;
          return m += 1
        }, 0)
        return
      },

      async callPhone(e) {
        document.location.href = `tel:${e.telefono}`
        //window.open(`'tel:${e.telefono}'`, '_self')
      },

      async getSelectedItems() {
        return pluck(this.filtros, 'selectedItems')
      },

      /** Poner un envio en espera, 
       *  Solo posible en el pdv 
       * solo válido para repartos, no para Agencias o recogidas 
       * */
      async enEspera(envio) {
        let slider = this.$refs.ion_item_options_slider
        await this.pararScanner()
        const zona_espera = await openAlertaPonerEnEspera(envio)
        window.fake_console(zona_espera, envio)
        if (zona_espera) {
          const payload = { codexp: envio.ms_uuid, ubicacion: zona_espera, envio_tipo: "ESPERA" };
          envio.ubicacion = zona_espera;
          envio.envio_tipo = "ESPERA";
          payload.nuevo_estado = 0;
          const actualizacion = await this.$http.post(`/update_envio_ruta/${this.pdv}`, payload)
          window.fake_console(actualizacion.data[0])
          if (actualizacion.data[0].status === 0) {
            await openAlertaV2(
              'Error actualizacion envio',
              `No hemos podido realizar la operacion, error:\n${actualizacion.data[0].alert} `
            )
          }
          else {
            await openAlertaV2(
              'Envio actualizadoa espera',
              `${actualizacion.data[0].alert} `
            )
          }
        }
        await this.escaner_generico()
        await slider.$el.closeSlidingItems()
        this.qt = Date.now();
      },

      /** Parada de escaneo */
      async pararScanner() {
        await scanGenericStop(this.element_id)
        this.blinker = false;
      },

      /** Llamada al proceso de entrega  */
      async procesoEntrega(envio) {
        alert('Proceso de entrega pendiente de acabar ')
        window.fake_console(envio)
      },

      /** Impresion de la etiqueta de envio. 
       *  Solo posible en el pdv 
       */
      async imprimir_etiqueta_envio(envio) {
        let slider = this.$refs.ion_item_options_slider
        const { nompos } = this
        const payload = Object.assign({ nompos, ...envio })
        let bls = [...Array(payload.bultos).keys()]
        payload['albaranes'] = payload.albaranes.split(' , ')
        try {
          bls.reduce(async (mx, vx) => {
            await this.$http.post(`/etiqueta/envio/${this.$pdv}`, { ...payload, bl: vx + 1 })
            return mx
          }, 0)
        }
        catch (ex) {
          window.fake_console(ex)
          window.miconsola('Error : ', envio)
        }
        await slider.$el.closeSlidingItems()
        this.qt = Date.now();

      },

      /** Cambio de 'ruta' o medio de entrega (reparto -> agencia... )
       *  Solo valido en pdv
       */
      async cambioRuta(envio) {
        let self = this;
        let slider = this.$refs.ion_item_options_slider
        window.fake_console(slider)
        const modal = await modalController
          .create({
            component: SelectorRutaEntregaComponent,
            cssClass: 'custom-bobinas-modal',
            componentProps: { rutas_pdv: this.rutas_pdv },
          })
        modal.present()
        eventBus().emitter.once('ruta-seleccionada', async (ruta) => {
          modal.dismiss();
          window.fake_console(ruta)
          envio.ubicacion = ruta.deszon;
          envio.envio_tipo = ruta.envio_tipo;
          const { ms_uuid, ubicacion, envio_tipo } = envio;
          const payload = { codexp: ms_uuid, ubicacion, envio_tipo };
          payload.nuevo_estado = 0;
          window.fake_console(payload)
          const actualizacion = await this.$http.post(`/update_envio_ruta/${this.pdv}`, payload)
          window.fake_console(actualizacion.data[0])
          await this.pararScanner()
          if (actualizacion.data[0].status === 0) {
            await openAlertaV2(
              'Error actualizacion envio',
              `No hemos podido realizar la operacion, error:\n${actualizacion.data[0].alert} `
            )
          }
          else {
            await openAlertaV2(
              'envio actualizada',
              `${actualizacion.data[0].alert} `
            )
          }
          await this.escaner_generico()
          await slider.$el.closeSlidingItems()
          self.qt = Date.now();
          window.fake_console(ruta)
        })
      },

      /**
       * Borrado de un envio y liberación de los albaranes
       * Solo valido en el pdv 
       */

      async borrar_envio(envio) {
        const { ms_uuid } = envio;
        const payload = { codexp: ms_uuid };
        payload.nuevo_estado = -1;
        window.fake_console(payload)
        const actualizacion = await this.$http.post(`/update_envio_status/${this.pdv}`, payload)
        window.fake_console(actualizacion.data[0])
        await this.pararScanner()
        if (actualizacion.data[0].status === 0) {
          await openAlertaV2(
            'Error actualizacion envio',
            `No hemos podido realizar la operacion, error:\n${actualizacion.data[0].alert} `
          )
        }
        else {
          await openAlertaV2(
            'envio actualizada',
            `${actualizacion.data[0].alert} `
          )
        }
        await delEnvioFromStorage(this.pdv, ms_uuid)
        this.showme = false;
        await this.recuperar_envios()
        await this.escaner_generico()

      },

      /** 
       * Recuperación de los envios actuales  
       * 
      */
      async recuperar_envios() {
        this.showme = false;
        try {
          const rt = await this.$http.post(`/get_rutas_pdv/${this.pdv}`)
          this.rutas_pdv = rt.data.map(x => {
            x['envio_tipo'] = 'REPARTO';
            return x
          })
          let cp_rutas = rt.data.reduce((m, v) => {
            mapObject(v.zipcodes, (zv, zk) => {
              let kn = { 'n': zv, zonacl: v.zonacl, deszon: v.deszon }
              if (m[zk]) { m[zk].push(kn) }
              else { m[zk] = [kn] }
              m[zk] = sortBy(m[zk], xn => -xn.n)
              return 1
            })
            return m
          }, {})
          window.fake_console(cp_rutas)
          this.cp_rutas = cp_rutas;
          await reduce(this.ubicaciones_entrega, async (memo, v, k) => {
            const m = await memo
            m.unshift({ 'deszon': v, 'envio_tipo': k })
            return m
          }, this.rutas_pdv)
          window.fake_console(this.rutas_pdv)

          /** construimos la relacion inversa codigo postal - ruta  */
          const recep = await this.$http.post(`/get_envios/${this.pdv}`)
          const datos = recep.data.map(envio => {return {...envio, resaltado: false}})
          if (datos.length > 0) {
            window.fake_console(datos)
            const dat = datos.map((x) => {
              const tel = !x.telefo ? undefined : x.telefo.replace(/[^0-9]/g, '')
              x.telefono = tel && tel.length == 9 ? tel : undefined
              x.albaranes = JSON.parse(x.albaranes).join(' , ');
              x.incidencia_ent = x.incidencia_ent === null ? undefined : JSON.parse(x.incidencia_ent)
              return x
            })

            this.datos = sortBy(dat, x => { return x.ms_uuid })
            window.fake_console(this.datos)
            this.datos_filtrados = this.datos
            this.datos_filtrados_mostrar = this.datos_filtrados.slice(0, NUM_ITEMS_DYNAMIC)

            this.generateFilters()
          }
          else { throw 'No hay envios' }
        }
        catch (ex) {
          window.fake_console(ex)
          this.datos = []
          this.datos_filtrados = []
          this.datos_filtrados_mostrar = []
        }
        this.showme = true;
      },

      /**
       * Carga de datos dinámica de 50 en 50 envíos para que no se sobrecarge
       * en la carga inicial y no haya errores provocados por la lentitud
       */
      async loadDataDynamic(event) {
        setTimeout(() => {
          let max, min, maxProvisional = this.datos_filtrados_mostrar.length + NUM_ITEMS_DYNAMIC;

          if (maxProvisional >= this.datos_filtrados.length) { 
            max = this.datos_filtrados.length;
            min = this.datos_filtrados_mostrar.length;
          } else {
            max = maxProvisional;
            min = max - NUM_ITEMS_DYNAMIC;
          }

          window.fake_console(`MIN: ${min} | MAX: ${max} | MAX_PROVISIONAL: ${maxProvisional}`);

          for (let i = min; i < max; i++) {
            if (!this.datos_filtrados_mostrar.includes(this.datos_filtrados[i])) {
              this.datos_filtrados_mostrar.push(this.datos_filtrados[i]);
            }
          }

          window.fake_console("Loaded data");
          event.target.complete();

          // App logic to determine if all data is loaded
          // and disable the infinite scroll
          window.fake_console(`Length: ${this.datos_filtrados_mostrar.length}`);
        }, 500);
      },

      /**
       * Entrar al detalle del pedido en caso de que no tengan LECTOR
       * @param {String} ms_uuid 
       */
      revisar_entrega_envio(ms_uuid) {
        this.$router.replace({ 'name': 'revisar_entrega_envio', params: { envio_id_intro: ms_uuid } })
      },

      /** Funcion de control del listener de los escaneos  */
      async lectorEventosGenericos() {
        let self = this;
        eventBus().emitter.off(`lectura_generica:${this.element_id}`)
        eventBus().emitter.once(`lectura_generica:${this.element_id}`, async (input_scaner_in) => {
          await self.pararScanner()
          /** es de mi pdv  */
          const input_scaner = input_scaner_in.replace(/\?/g, '_')
          window.fake_console(input_scaner)
          let dat
          try {
            dat = input_scaner.match(esAlbaran).groups
          }
          catch {

            /*** Chequeamos si es un envio, si lo es ofrecemos la posibilidad de ir a verlo detallado  */
            const { ms_uuid } = JSON.parse(input_scaner)
            if (ms_uuid && esEnvio.test(ms_uuid) === true) {
              const confirmacion = await openAlertaBinaria('Etiqueta de envio ',
                `La lectura corresponde a un envio.<br/> Si quieres ver los detalles del mismo confirma, sino cancela.<br/>`, 
                'Cancelar', 'Confirmar'
              )
              
              if (confirmacion === true) {
                this.revisar_entrega_envio(ms_uuid)
                return
              }
            }

            await openAlertaV2('Albaran erroneo', `La lectura no corresponde a un albarán.<br/>
              Recuerda que en esta pantalla lo que hacemos es generar envios desde documentos de albarán.`
            )
            await self.escaner_generico()
            return
          }

          window.fake_console(dat)
          if (dat.codemp === self.pdv && dat.tipmov == 'CV') {
            window.fake_console(`/crear-envio/${input_scaner}`)
            window.fake_console(this.cp_rutas)
            const cp_rutas = this.cp_rutas
            self.$router.replace({ 'name': 'crear_envio', params: { albaran_id: input_scaner, cp_rutas: JSON.stringify(cp_rutas) } })
          }

          else if (dat.codemp != self.pdv && dat.tipmov == 'CV') {
            const confirmacion = await openAlertaBinaria('Albarán de otro PDV ',
              `El albarán corresponde a otro punto de venta.<br/>
              Confirma que no es un error y quieres crear un envio`, 'Cancelar', 'Confirmar')
            if (confirmacion !== true) {
              await self.escaner_generico()
            }
            const cp_rutas = this.cp_rutas
            self.$router.replace({ 'name': 'crear_envio', params: { albaran_id: input_scaner, cp_rutas: JSON.stringify(cp_rutas) } })
          }

          else {
            await openAlertaV2('Albaran erroneo', `
                                  La lectura facilitada no corresponde con un albaran valido`
            )
            await self.escaner_generico()
          }
        })
      },

      /**
       * Activacion del escaneo y listeners 
       */
      async escaner_generico() {
        window.fake_console('activado escaner')
        /** Hacemos reset del scanner cada vez que entramos en el campo  */
        await scanGenericStop(this.element_id)
        this.blinker = true
        await scanGenericStart(this.element_id, 'desde escaner_generico')
        this.blinker = true
        await this.lectorEventosGenericos()
      },

      async ProximasParadas(envio) {
        window.fake_console(envio)
        if (envio.envio_tipo != 'REPARTO') {
          return
        }
        try {

          const codrep = envio.codrep
          const avance = await this.$http.post(`/avance_ruta/${this.pdv}`, { codrep })
          const puntos = avance.data
          /** verificamos si hay al menos una entrega hecha, sino hay que insertar como inicio el pdv */
          const hechas = puntos.filter(x => x[2] === 'done')
          if (hechas.length === 0) {
            puntos.unshift([...this.pdv_geoloc, 'done'])
          }
          window.fake_console(avance.data)
          if (this.isIOS === true) {
            window.open(`https://ubiku.ges.services/dibujar_ruta/${this.pdv}?puntos=${JSON.stringify(puntos)}`, '_blank', 'noreferrer');
          }
          else {
            await Browser.open({ url: `https://ubiku.ges.services/dibujar_ruta/${this.pdv}?puntos=${JSON.stringify(puntos)}` });
          }
        }
        catch (ex) {
          console.log('error!!! L589')
          console.log(ex)
        }
      },

      /** 
       * Lanzar o desactivas el filtro de rutas  
       * 
      async FiltrarRuta(clean) {
        if (clean) {
          this.filtro = { 'deszon': 'all' }
          await this.filtrar()
        }
        else {
          const existentes = pluck(this.datos, 'ubicacion')
          let rutas = this.rutas_pdv.filter(x => existentes.indexOf(x.deszon) != -1)
          const x = await openAlertaRutas(rutas)
          if (x) {
            this.filtro = x.values
            await this.filtrar()
          }
        }
      },*/

      async filtrar() {
        window.fake_console(this.situacion_envio, this.en_espera)
        window.fake_console(this.filtro)
        this.generateFilters()
        let fl = this.datos
        if (this.situacion_envio != undefined) {
          fl = fl.filter(x => (x.situa == this.situacion_envio && (this.en_espera === true ? x.envio_tipo == 'ESPERA' : x.envio_tipo != 'ESPERA')))
          this.datos_filtrados_con_ruta = fl
        } else {
          this.datos_filtrados_con_ruta = []
        }
        window.fake_console(fl)
        if (!this.filtro || this.filtro.deszon === 'all') {
          this.datos_filtrados = fl
        }
        else {
          const filtros = pluck(this.filtro, 'deszon')
          window.fake_console(filtros)
          this.datos_filtrados = fl.filter(x => filtros.indexOf(x.ubicacion) != -1)
        }

        this.datos_filtrados_mostrar = this.datos_filtrados.slice(0, NUM_ITEMS_DYNAMIC)
        this.updateFilteredItems(this.datos_filtrados)
      },

      getDatosForFilters(datosForFilters) {
        // Creación de todos los filtros con sus datos correspondientes.
        const existentes = pluck(datosForFilters, 'ubicacion')
        const rutas = this.rutas_pdv.filter(x => existentes.indexOf(x.deszon) != -1).map(ruta => { return ruta.deszon })
        const albaranes = pluck(datosForFilters, 'albaranes');
        const nombreCliente = datosForFilters.map((x) => { return `${x.nomcli} (${x.codcli})` })
        const fechaEnvio = pluck(datosForFilters, 'fecenv');
        const vehiculos = pluck(datosForFilters, 'vehiculo');
        const chofer = pluck(datosForFilters, 'chofer');

        const rutasItems = [...new Set(rutas)].filter(Boolean).sort()
        const albaranesItems = [...new Set(albaranes)].filter(Boolean).sort()
        const nombreClienteItems = [...new Set(nombreCliente)].filter(Boolean).sort()
        const fechaEnvioItems = [...new Set(fechaEnvio)].filter(Boolean).sort()
        const vehiculosItems = [...new Set(vehiculos)].filter(Boolean).sort()
        const choferItems = [...new Set(chofer)].filter(Boolean).sort()

        return [rutasItems, albaranesItems, nombreClienteItems, fechaEnvioItems, vehiculosItems, choferItems]
      },

      updateFilteredItems(newDatos) {
        const datosUpdate = (this.datos_filtrados_con_ruta.length == 0) ? newDatos : this.datos_filtrados_con_ruta

        let arrayDatos = this.getDatosForFilters(datosUpdate)

        arrayDatos.forEach((dato, i) => {this.filtros[i].filteredItems = dato})
      },

      generateFilters() {
        let arrayDatos = this.getDatosForFilters(this.datos)

        this.filtros = [ // Quitamos duplicados con el set pero añadimos el set a un array para tratarlo mejor
          { filterName: 'Rutas', items: arrayDatos[0], filteredItems: arrayDatos[0], selectedItems: [] },
          { filterName: 'Albaranes', items: arrayDatos[1], filteredItems: arrayDatos[1], selectedItems: [] },
          { filterName: 'Nombres clientes', items: arrayDatos[2], filteredItems: arrayDatos[2], selectedItems: [] },
          { filterName: 'Fecha de envío', items: arrayDatos[3], filteredItems: arrayDatos[3], selectedItems: [] },
          { filterName: 'Vehiculo', items: arrayDatos[4], filteredItems: arrayDatos[4], selectedItems: [] },
          { filterName: 'Chofers', items: arrayDatos[5], filteredItems: arrayDatos[5], selectedItems: [] }
        ];

        this.numSelectedItems = 0;
      },
      
      // Cuando se clicka al icono de filtros se abre el modal de todos los filtros. Sustituyendo a FiltrarRuta()
      async abrirModalDeFiltros() {
        // Abrir modal y enviarle el this.filtros
        const modal = await modalController.create({
          component: ModalFiltros,
          componentProps: {
            "rutas": this.rutas_pdv,
            "filters": this.filtros,
            "datos": this.datos,
            "datosFiltrados": this.datos_filtrados,
            "datosFiltradosConRuta": this.datos_filtrados_con_ruta
          }
        });

        modal.present();

        // Esperamos los nuevos filtros seleccionados del modal
        const { data, role } = await modal.onWillDismiss();

        if (role === 'clean') {
          this.ActivarDesactivarMenuEntregas(false)
        }

        if (role === 'confirm') {
          this.filtros = data[0]
          this.numSelectedItems = this.filtros.flatMap((filtro) => {return filtro.selectedItems}).length
          this.datos_filtrados = data[1]
          this.datos_filtrados_mostrar = this.datos_filtrados.slice(0, NUM_ITEMS_DYNAMIC)
          this.resetResaltados()
        }
      },

      async clean() {
        this.numSelectedItems = 0
        this.situacion_envio = undefined
        this.en_espera = undefined
        await this.recuperar_envios()
      },

      /** Gestion del cambio de tab en el selector de estados  */
      async accion(a, b) {
        this.situacion_envio = a;
        this.en_espera = b
        await this.filtrar()
      },
      /** Función para devoler el icono a mostrar  */
      icono_estado(x) {
        return this.estados_visuales[x['envio_tipo']] || this.estados_visuales[x['situa']] || 'question-circle'
      },
      /** Funcion que devuelve la etiqueta de 'ubicación' del envio  */
      ubi_text(x) {
        return (['REPARTO', 'ESPERA'].indexOf(x.envio_tipo) != -1) ? x.ubicacion : this.ubicaciones_entrega[x['envio_tipo']]
      },
    },

    computed: {
      largo: function () {
        return Object.keys(this.datos).length
      },
    }
  })
</script>
 

<style scoped  lang="scss">
  @import "@/theme/seleccion-pedido-styles.scss";

  ion-tab-bar {
    height: 5rem !important;
  }

  ion-segment {
    width: 100% !important;
  }

  ion-segment-button.todas {
    width: 20% ª !important;
  }

  span.boton-opciones {
    font-size: 1.05rem !important;
    padding-bottom: 0.3rem !important;
    font-weight: bold !important;
    text-align: center !important;
  }

  ion-badge {
    width: 100% !important;
  }

  ion-row.altos {
    height: 3rem !important;

  }

  ion-text.en-botton {
    font-family: 'Roboto';
    font-size: 0.9rem !important;
    color: var(--ion-color-blanco) !important;
    text-transform: none !important;
    line-height: 0.4rem !important;
  }

  ion-row {
    max-width: 600px !important;
    padding: 0.3rem 0.2rem !important;
  }

  ion-item {
    --inner-padding-end: 0px !important;
    --padding-end: 0px !important;
  }

  ion-text.fake-titulo {
    color: var(--ion-color-primary) !important;
    text-align: center !important;
  }

  span.oscuro {
    color: var(--ion-color-medium) !important;
    padding-left: 0.3rem !important;
  }

  span.primario {
    color: var(--ion-color-primary) !important;
    padding-left: 0.3rem !important;
  }

  span.peque {
    font-size: smaller !important;
    padding-right: 0.3rem !important;
  }

  i.fas.tiempo,
  i.fak.tiempo,
  i.far.tiempo,
  i.fad.tiempo {
    color: dark;
    font-weight: 600 important;
    font-size: 1.8rem !important;
    font-size: 22px !important;
    padding-right: 0.4rem !important
  }

  ion-item.iniciado {
    border-right: 10px solid !important;
    border-image: linear-gradient(to bottom, white, var(--ion-color-darkdanger), white) 2;
  }

  .sin-margen {
    margin-top: 0px !important;
  }

  i.separa {
    padding-left: 0.4rem !important;
    float: right !important;
    font-size: larger !important;
    color: var(--ion-color-primary) !important;
    display: inline !important;
  }

  i.crossdock {
    font-size: xx-large !important;
    font-weight: 300 !important;
    color: var(--ion-color-dark) !important;
  }

  #num-selected-items {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    font-size: 13px;
    border-radius: 5%;
    background: #3911c9;
    width: 16px;
    height: 16px;
    line-height:16px;
    display: block;
    text-align: center;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
  }

  .icon-filter {
    position: relative;
    top: 4px;
    z-index: 1;
    font-size: 24px;
    color: white;
  }
  ion-row.resaltado {
    border: 2px dashed var(--ion-color-darksuccess) !important; 
    background-color: var(--color-resaltado-positivo) !important; 
  }

  .alert-message::-webkit-scrollbar-track {
    background: orange;        /* color of the tracking area */
  }
</style>