<template>
    <ion-page>
        <CabeceraComponentBack 
            :pdv = "$route.meta.pdv"
            :isIOS = "$route.meta.isIOS"
            titulo="Asignacion Vehiculos"
            color="primary"
        />
        <ion-header>
            <ion-toolbar color="primary" style="padding-top:8px;" class="top">
                <ion-searchbar  v-model="search_manually"
                    @ionChange="filtrar_elementos"
                    autocomplete="off" autocorrect="off" 
                    autocapitalize="off" spellcheck="false"
                    show-clear-button="always"
                    :placeholder="'Filtrar'" 
                    slot="start"
                    mode = "md">
                </ion-searchbar>       
            </ion-toolbar>

        </ion-header>

        <ion-content class="ion-padding" key="`content:${qt}`"> 

            <ion-fab vertical="top" horizontal="start" slot="fixed">
                    <ion-fab-button  color="darkdanger" @click="changing=true" v-if="changing === false ">
                        <i class="fak fa-solid-truck-pen en-fab"></i>
                    </ion-fab-button>
                    <ion-fab-button  color="danger" @click="addTruck" v-if="changing">
                      <i class="fak fa-solid-truck-circle-plus en-fab"></i>
                    </ion-fab-button>
            </ion-fab>

            <ion-fab vertical="bottom" horizontal="start" slot="fixed" v-if="changing">
                    <ion-fab-button  color="darksuccess" @click="saveTruck" v-if="changing === true ">
                        <i class="fas fa-floppy-disk en-fab"></i>
                    </ion-fab-button>
            </ion-fab>

            <ion-row  class="spin ion-justify-content-center "  v-if="!showme">
                <ion-col  v-if="!showme" class="spin ion-align-self-center" size="48" > 
                    <ion-spinner  v-if="!showme"></ion-spinner>            
                </ion-col>
            </ion-row>

           <ion-row v-else>
                <ion-row  class="vehiculo" v-for="(v, index) in datos" :key="`ele:${index}`" >
                    <ion-col class="vehiculo" :class="v.editing ? 'en-edicion' : '' " size="48" lines="none" v-if="v.visible === true">
                        
                        <ion-item class="vehiculo oscuro" >
                            <ion-label class="etiqueta" position="fixed">Matricula {{v.bloqueado}}</ion-label>
                            <ion-input v-model="datos[index]['matricula']" placeholder="Introduce matricula" :readonly="!changing || v.bloqueado">   </ion-input>
                            <ion-checkbox v-model="datos[index]['activo']" :disabled="!changing || v.bloqueado" @ionChange="mostrar(v)">

                            </ion-checkbox>

                        </ion-item>

                        <ion-item  class="vehiculo rojo"  lines="none" v-if="v.bloqueado">
                            <ion-input  readonly>Bloqueado por  {{v.bloqueado}}</ion-input>
                        </ion-item >

                        <ion-item class="vehiculo" lines="none" >
                            <ion-label  class="etiqueta" position="fixed">Modelo</ion-label>
                            <ion-input v-model="datos[index]['modelo']" placeholder="Introduce modelo" :readonly="!changing || v.bloqueado"></ion-input>
                        </ion-item >
                        <ion-item class="vehiculo" lines="none" >
                            <ion-label  class="etiqueta" position="fixed" >Chofer
                                <i class="fas fa-sharp fa-user-slash desasignar"  v-if="changing && !v.bloqueado" @click="desasignaTruck(v)"></i>
                            </ion-label>
                            <ion-input  :placeholder="datos[index]['persona'] ? '' : 'Clicka y selecciona conductor'"  :disabled="!changing || v.bloqueado"
                                 @click="CambioConductor(v, false)">
                                 {{datos[index]['persona']}}
                                </ion-input>
                            <i class="fas fa-trash-xmark borrado" @click="delTruck(index)" v-if="changing && !v.bloqueado"></i>    
                        </ion-item >
                    </ion-col>
                </ion-row>
           </ion-row>

        </ion-content>

 

    </ion-page>
</template>

<script>

    
    import CabeceraComponentBack from '@/components/CabeceraComponentBack'
    import { defineComponent } from 'vue';
    import { isOnline }  from '@/utilities/global_functions';
    import { useRoute } from 'vue-router';
    import { sortBy , uniq, union, keys  } from 'underscore';
    import { alertController } from '@ionic/vue';
    import { chain, every } from 'underscore';
    import {  openAlertaV2 } from  '@/utilities/services/alert-utilities';


    export default defineComponent({
            name : "AsignarTransportesPage",
            components: {  CabeceraComponentBack},
            async created() {
                this.pdv =  this.$route.meta.pdv ;
                this.nompos =  this.$route.meta.nompos ;
                this.pdv_geoloc =  this.$route.meta.pdv_geoloc ;
                this.ubiku_rol = this.$route.meta.ubiku_rol 

            },
            data() { return {  
                isOnline, 
                datos : [], 
                originales : {}, 
                personas : [],
                search_manually: undefined , 
                changing : false, 
                showme: false , 
                cambios: {}
             }},
            async mounted() { 
                window.fake_console('seleccion ubicaciones: entro en mounted  ', this.pdv)
                const route = useRoute();
                this.qt = route.query.q||Date.now()
                const chk = await  this.$http.post(`/check/${this.pdv}`)
                if (chk.data.delegado !== true ) {
                    await openAlertaV2('Operacion no permitida', `
                        Tu perfil no permite acceder a esta operación `) 
                    this.$router.replace('/login')
                }
                await this.recuperar_vehiculo()
            }, 
            methods: {     
                async mostrar (i) {
                    i.activo = i.activo === true ? 1 : 0 ; 
                },
                async recuperar_vehiculo() {
                    try {
                        /** recuperaos los vehiculos y vemos  */
                        const res = await this.$http.post(`/get_transportes_pdv/${this.pdv}`) 

                        if (res == false) {
                            throw 'No hay vehículos creados'
                        }

                        const {vehiculos , personas}= res.data
                        window.fake_console(res.data)
                        this.datos = vehiculos.map( (v)=> { v.visible = true ; return v })
                        window.fake_console(this.datos)
                        this.originales = vehiculos.reduce((m,v)=> {
                            const u = v.email 
                            m[u] = Math.max(m[u]||0, v.activo)
                            window.fake_console(v.activo, m)
                            return m 
                         } ,{})
                        window.fake_console(this.originales)
                        this.personas = sortBy(personas, v =>{return v.prioridad+v.email })
                        this.showme = true ; 
                        this.qt = new Date()
                    }
                    catch(ex){
                        console.log(ex)
                        alert('ERROR : '+JSON.stringify(ex))
                    }
                }, 
                async addTruck() {
                    //this.changing = !this.changing 
                    this.showme = false
                    this.datos.unshift({matricula: undefined, email: undefined , activo: 0, visible: true})
                    this.showme = true  
                    this.qt = new Date()
                    
                }, 
                async desasignaTruck(i) {
                    window.fake_console(i)
                    delete i.email
                    delete i.persona 
                    this.qt = new Date()
                },

                async saveTruck(){                    
                    const matriculaOk  = chain(this.datos).countBy('matricula').filter((v)=>v>1).value().length == 0 
                    const nulasOk = this.datos.filter(y => !(/^[0-9]{4}[A-Z]{3}$/i).test(y.matricula)).length === 0 
                    const activasOk = this.datos.filter(y =>y.activo === 1).length >0
                    const usuariosOk = this.datos.filter(y => !y.email).length === 0
                    
                    window.fake_console({matriculaOk, nulasOk, activasOk})
                    if (matriculaOk === true && nulasOk === true && activasOk === true &&  usuariosOk === true) {
                        const nuevos = this.datos.reduce((m,v)=> {
                            const u = v.email 
                            m[u] = Math.max(m[u]||0, v.activo) === 1 ? 5 : 1 
                            return m 
                        },{}) 
                        const claves = uniq(union(keys(nuevos), keys(this.originales)))
                        const cambios = claves.reduce((m,v)=>{
                            const iguales = this.originales[v]=== nuevos[v]
                            if (iguales!==true ) {
                                m[v] = nuevos[v]||1
                            }
                            return m 
                        } , {})
                        this.cambios = cambios
                        window.fake_console(cambios)
                        try
                            {
                                window.fake_console(this.cambios)
                                const feedback = await this.$http.post(`/update_transportes_pdv/${this.pdv}`,{ asignaciones: this.datos, cambios : this.cambios})
                                if (feedback.data.status == 1) {
                                    await openAlertaV2('Recursos actualizados', `
                                            Has actualizado los recursos de transporte del pdv `) 
                                    }
                                else { throw 'feedback negativo' }
                            }                        
                            catch(ex) {
                                console.log('error')
                                console.log(ex)
                                window.fake_console('ERROR ', ex)
                            }
                            this.changing = !this.changing 
                    }
                    else {
                        await openAlertaV2(
                            'Error de introduccion', 
                            `Hay errores en los datos introducidos
                            <ul id="lista_errores">
                                ${!nulasOk ? '<li>Matriculas erroneas o no informadas</li>': ''}
                                ${!matriculaOk ? '<li>Matriculas repetidas</li>': ''}
                                ${!activasOk ? '<li>Ningun vehiculo activo</li>': '' }
                                ${!usuariosOk ? '<li>Vehiculos sin chofer asignado</li>': '' }
                            </ul>`)
                    }


                }, 
                async saveTruck_OLD(){
                    let matriculaOk, modeloOk,usuarios 
                    //let unico , chofers
                    let valido = true 
                    const ultimo = this.datos.filter(x => x.editing === true )[0]
                    if (ultimo) {
                            /*
                                chofers = pluck(this.datos, 'email')
                                unico = ultimo.email && chofers.indexOf(ultimo.email)==-1
                            */
                            ultimo.matricula = ultimo.matricula ? ultimo.matricula.toUpperCase() : undefined; 
                            window.fake_console(ultimo.matricula)
                            window.fake_console(ultimo, (/^[0-9]{4}[A-Z]{3}$/i).test(ultimo.matricula ))
                            matriculaOk = ultimo.matricula &&  (/^[0-9]{4}[A-Z]{3}$/i).test(ultimo.matricula)
                            modeloOk = ultimo.modelo && ultimo.modelo.length>5
                            usuarios = ultimo.modelo && ultimo.persona &&  ultimo.email  
                            
                            
                            if (matriculaOk && modeloOk && usuarios ){  
                                valido = true 
                            }
                            else {
                                valido = false 
                                /* ${!unico ? '<li>El chofer ya tiene un vehiculo asignado. Desasigna primero</li>': ''} */
                                await openAlertaV2(
                                    'Error de introduccion', 
                                    `Hay errores en los datos introducidos <ul>
                                        ${!matriculaOk ? '<li>Patron de matricula erroneo</li>': ''}
                                        ${!modeloOk ? '<li>Modelo no informado o muy corto</li>': ''}
                                        ${!usuarios ? '<li>Error en el usuario</li>': ''}
                                    </ul>`
                                )
                            }
                    }
                    /** pasamos las validaciones generales  */
                    else {
                        window.fake_console('validaciones generales ')
                        const repetidas  = chain(this.datos).countBy('matricula').filter((v)=>v>1).value()
                        window.fake_console(repetidas)
                    }


                    if (valido === true)
                        {
                            try
                            {
                                if (ultimo) {
                                    const ml = ultimo.email 
                                    window.fake_console('L184 :', ml, this.cambios)
                                    this.cambios[ml]= 5; 
                                }
                                window.fake_console(this.cambios)
                                const feedback = await this.$http.post(`/update_transportes_pdv/${this.pdv}`,{ asignaciones: this.datos, cambios : this.cambios})
                                if (feedback.data.status == 1) {
                                    await openAlertaV2('Recursos actualizados', `
                                            Has actualizado los recursos de transporte del pdv `) 
                                    }
                                else { throw 'feedback negativo' }
                            }                        
                            catch(ex) {
                                console.log('error')
                                console.log(ex)
                                window.fake_console('ERROR ', ex)
                            }
                            this.changing = !this.changing 
                        }
                    
                }, 
                async delTruck(indice){
                    this.datos.splice(indice,1)
                }, 
                async filtrar_elementos () {
                    this.showme = false; 
                    const terminos = this.search_manually
                    if (!terminos || terminos === false ) {
                        this.datos.forEach( v => v.visible = true)
                    }
                    else {
                        const primarySearch = new RegExp(`(${terminos.toLowerCase().replace(/ +$/,'').replace(/ +/g,'.*')})`, 'i')
                        const secondarySearch = terminos.toLowerCase().replace(/ +$/,'').split(' ').map(v => new RegExp(`${v}`,'i'))
                        this.datos.forEach( v => {
                            v.visible = false; 
                            if ( primarySearch.test(JSON.stringify(v).toLocaleLowerCase()) ||every(secondarySearch, t => t.test(JSON.stringify(v).toLowerCase()))) {
                                v.visible = true
                            }
                        })
                    }
                    this.showme = true
                },    

                async CambioConductor(vehiculo, all){
                    let self = this;
                    const mis_inputs = this.personas.map( (v) => {
                            const Etiqueta = all === true ? `${v.nombre} (${v.posicion})` : `${v.nombre}`
                            return {'label' : Etiqueta, 'type' :'radio', 'value' : v , prioridad: v.prioridad }
                    })
                    const head = `Selecciona conductor`
                    const msg = `Por favor, elige el conductor. `
                    const alerta = await alertController
                        .create({
                            header: head, 
                            message: msg,
                            mode : "md",
                            cssClass: 'custom-rutas-alert',
                            backdropDismiss: false, 
                            inputs :mis_inputs.filter(x => all === false ? x.prioridad==1 : true ), 
                            buttons: [  
                                {
                                    text: all === true ? 'Chofers' : 'Todos',
                                    role: 'none',
                                    handler: () => {
                                        self.CambioConductor(vehiculo, all=== false ? true : false )
                                        return true
                                    },
                                },
                                {
                                    text: 'Cancelar',
                                    role: 'cancel',
                                    handler: () => {
                                    window.fake_console('Alert canceled');
                                    },
                                },
                                {
                                    text: 'Seleccionar',   
                                    role: 'confirm',
                                    handler: ( data ) => {
                                        window.fake_console('Alert confirmed');
                                        return data ? true : false
                                    },
                                },
                                ]
                        })
                    await alerta.present();
                    
                    const {data, role} = await alerta.onDidDismiss();
                    window.fake_console(data)
                    if (role=='confirm'){
                        const {email, nombre} = data.values 
                        vehiculo.email = email; 
                        vehiculo.persona = nombre; 
                        return data.values
                    }
                    else {
                        return undefined
                    }
                }
            }
})
</script>
<style scoped >
    ion-content {
        max-width: 700px !important;
    }
    ion-title {
        padding-left: 4px !important; 
        padding-right: 4px !important; 
        position:relative

    }
    ion-label.etiqueta {
        width: 10rem !important; 
        margin-top: 5px !important; 
        margin-bottom: 5px !important; 
    }
    ion-col.vehiculo {
        margin: 0.2rem 0rem !important; 
        border: 1px solid var(--ion-color-medium) !important; 
        padding: 0px !important; 
    }

    ion-item.oscuro > * {
        font-weight: bold !important;
    }
    ion-item.vehiculo {
        --min-height: 26px !important;
        --max-height : 1.3rem !important; 
        --padding-top:  0.1rem !important; 
        --padding-bottom:  0.1rem !important; 
    }
    ion-item.vehiculo > * {
        --padding-top:  0.1rem !important; 
        --padding-bottom:  0.1rem !important; 

    }

        
    ion-item > ion-row {
        padding-right : 0rem !important; 
    }
    
    i.borrado  {
        float: right !important; 
        position: relative; 
        font-size: 1.8rem !important; 
        color: var(--ion-color-darkdanger) !important; 
    }
    i.desasignar {
        float: right !important; 
        position: relative; 
        right: 0.4rem !important; 
        font-size: 1.8rem !important; 
        color: var(--ion-color-darkdanger) !important; 
    }
    ion-col.en-edicion > *{
        --background: var(--ion-color-ligero) !important;
    }
    .centrado {
         vertical-align: middle;
    }

</style>