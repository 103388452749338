<template>
    <ion-page>
        <ion-header>
            <ion-toolbar  color="primary" class="ion-justify-content-start ion-align-items-start"> 
            <ion-title>
                    <ion-row  class="ion-justify-content-start ion-align-items-start">
                        <ion-col size="40"  class="ion-float-left izquierda" 
                            :style="isIOS ? 'padding-left: 4rem !important;': ''">
                            Seleccion Lote / Sucursal
                        </ion-col>
                    </ion-row>                
                </ion-title>
            </ion-toolbar>
        </ion-header>


        <ion-content class="ion-padding ion-margin" >
              <ion-card >
                <ion-card-header class="head">
                    <ion-title class="super"> Lotes / Sucursales </ion-title>
                    <ion-card-subtitle size="large">
                        <ion-row>
                            <span v-if="skus" class="danger" >Ojo al seleccionar<br/> Hay más de un sku asociados al ean </span>
                        </ion-row>
                        <ion-row class=" titulin">
                            <ion-col size="29" offset ="1" >Referencia</ion-col>
                            <ion-col size="6" >Suc.</ion-col>
                            <ion-col size="12" >Unid.</ion-col>
                        </ion-row>
                    </ion-card-subtitle>
                </ion-card-header>

                <ion-card-content  v-if="datos.lotes">
                    <ion-row v-for="(item,index) in datos.lotes"  v-bind:key="index"
                        class="pares-articulo-lote-ubicacion"
                        @click="SeleccionarOpcion(item)" >
                        <!-- primera linea--> 
                        <ion-col size="31" 
                            offset="1"
                            class="ion-padding-left ion-text-nowrap  sku nombre_cortado ion-text-start">
                            {{ $filters.showSku(item.sku)   }}
                        </ion-col>
                        <ion-col size="4"  class=" medios  ion-text-center">
                            {{ item.codsuc }}
                        </ion-col>
                        <ion-col size="12"  class=" derecha  medios"> 
                            <span class="pad-1-derecha">{{ $filters.Entero(item['stoalm'])}}</span>
                        </ion-col>
                        <!-- segunda linea , solo si exite--> 
                        <ion-col size = "47"  offset="1" v-if="item.lote" class="ion-padding-left ion-text-nowrap    medios ion-text-left">
                                <span class="lote">Lote : {{ item.lote  }}</span>
                        </ion-col>
                        <!-- Segunda linea --> 
                        <ion-col size = "47"  offset="1" class="ion-padding-left  nombre_cortado mini-lista ion-text-start"> 
                           {{item.shortDescription}}
                        </ion-col>
                    </ion-row>
                </ion-card-content>
            </ion-card>
            
        </ion-content>
    </ion-page>

</template>
    

<script>
    import { defineComponent } from 'vue';

    import {  pluck, uniq } from 'underscore';
    import eventBus from "@/event-bus"; 

    export default defineComponent({
            name : "SeleccionLoteComponent",
            data() { 
                return { 
                    isIOS: undefined
                }
            },
            props: ['datos'], 
            mounted() {
                window.fake_console(this.datos)
                window.fake_console('SeleccionLoteComponent ',this.datos)
            },
            created() {
                this.isIOS = this.$route.meta.isIOS 
            },
            computed :{
                largo: function () {
                    return Object.keys(this.datos).length
                    }, 
              skus: function()
                    {
                        window.fake_console(pluck(this.datos.lotes,'sku'))
                        window.fake_console( uniq(pluck(this.datos.lotes,'sku')))
                    return uniq(pluck(this.datos.lotes,'sku')).length>1                    
                    }, 
                
            },
            methods: {
                async SeleccionarOpcion(item) {
                    eventBus().emitter.emit('cerrar-selector',item)    
                 },
            },    
        })
</script>

<style scoped lang="scss">

  @import "@/theme/selectores-lotes-styles.scss";
        span.danger {
            width: 100% important; 
            text-align: left !important; 
            color: var(--ion-color-darkdanger) !important;
            padding-left : 2rem !important; 
            font-size: x-large !important;
            }
       
</style>