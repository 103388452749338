/**
   Test de velocidad
 */

<template>
    <ion-page  >
        <CabeceraComponent 
            :pdv = "$route.meta.pdv"
            :isIOS = "$route.meta.isIOS"
            titulo="Test de velocidad"
            color="danger"
        />
        <ion-content class="ion-padding" >   
                <ion-row>
                    <ion-button expand="block" fill="outline" size="large"  class="trigger_scan" >
                            <ion-title>Test de velocidad
                                <!-- <br/> -->
                                <ion-text class="ion-text-wrap">Identifica la calidad de la señal que tienes</ion-text>
                            </ion-title>
                    </ion-button>
                    <p v-if="location">{{location}}</p>
                </ion-row>
            
            <!-- test geoloca-->
            <ion-row>
                <ion-button @click="getLoc"> Get Location </ion-button>
            </ion-row>

            <ion-row>
                <ion-button @click="takePhoto">Photo </ion-button>
            </ion-row>
            <!-- Lista de ubicaciones pendientes de cerrar -->
             <ion-col>
                    <ion-card >
                        <ion-card-header class="head">
                            <ion-title class="super"> Resultados  </ion-title>
                        </ion-card-header>

                        <ion-card-content  v-if="!showme">
                            <ion-row  class="spin ion-justify-content-center "  >
                                <ion-col  v-if="!showme" class="spin ion-align-self-center" size="48" > 
                                    <ion-spinner  v-if="!showme"></ion-spinner>            
                                </ion-col>
                            </ion-row>
                        </ion-card-content>

                        <ion-card-content v-else>
                            <ion-row v-for="(m,index) in mensajes" :key="index" >
                                <ion-col size="30" 
                                    offset="1"
                                    class="ion-padding-left ion-text-nowrap  sku nombre_cortado ion-text-start">
                                    {{m.k}}
                                </ion-col>
                                <ion-col size="17"  class="ion-padding-left ion-text-nowrap  medios ion-text-right">
                                    <span class="pad-1-derecha">{{  m.v }}</span>
                                </ion-col>
                            </ion-row>
                        </ion-card-content>
                    </ion-card>
                </ion-col>
     
        </ion-content>
    </ion-page>
</template>

<script>
    import CabeceraComponent from '@/components/CabeceraComponent'
    import { defineComponent } from 'vue';
    import  {getGeoloc}  from '@/utilities/location'
    import { Camera, CameraResultType, CameraSource, CameraDirection } from '@capacitor/camera';
    import {  tipoImagen } from '@/utilities/global-constants';


    export default defineComponent({
            name : "TestVelocidadPage",
            components: { CabeceraComponent  } , 
            data() {
                return {
                     
                    showme : false, 
                    end_time:  undefined, 
                    start_time: undefined,
                    mensajes : undefined,  
                    downloadSize : 83132, 
                    location : undefined , 
                    pdv_geoloc : this.$route.meta.pdv_geoloc ,
                    userImageLink :"https://media.geeksforgeeks.org/wp-content/cdn-uploads/20200714180638/CIP_Launch-banner.png"
                }
            }, 
            async mounted() { 
                this.pdv_geoloc =  this.$route.meta.pdv_geoloc 
                let self = this; 
                let downloadImgSrc = new Image();
                downloadImgSrc.onload = function () {
                    self.end_time = new Date().getTime();
                    self.displaySpeed();
                };
                this.time_start = new Date().getTime();
                downloadImgSrc.src = `${this.userImageLink}?${this.time_start}`;
                this.showme = true; 
            }, 
            methods: {     
                async getLoc() {
                    const {latitude, longitude, accuracy} = await getGeoloc(120000)
                    this.location = `Latitud: ${latitude}, Longitud: ${longitude}, Accuracy : ${accuracy}`
                }, 
                async  displaySpeed() {
                        let timeDuration = (this.end_time - this.time_start) / 1000;
                        const loadedBits = this.downloadSize * 8;
                        /* Converts a number into string
                        using toFixed(2) rounding to 2 */
                        const bps = (loadedBits / timeDuration).toFixed(2);
                        const speedInKbps = (bps / 1024).toFixed(2);
                        const speedInMbps = (speedInKbps / 1024).toFixed(2);
                        this.mensajes = [
                            {'k': 'Duracion (millis)', 'v' :  this.$filters.Entero(timeDuration*1000) } , 
                            {'k': 'Descarga total (bytes) ', 'v' :  this.$filters.Entero(this.downloadSize) } , 
                            {'k': 'Velocidad Kbps ', 'v' :  this.$filters.Entero(speedInKbps) }  , 
                            {'k': 'Velocidad Mbps ', 'v' :  this.$filters.sDecimal(speedInMbps) }    
                        ]
                }, 

                async takePhoto () {
                    try {
                        const permisos = await Camera.checkPermissions()
                        window.fake_console(permisos)
                        //await Camera.requestPermissions('camera')
                        }
                    catch(er) {
                        console.log(er)
                        console.log('error al pedir permisos')
                    }
                    const cameraPhoto = await Camera.getPhoto({
                        resultType: CameraResultType.DataUrl,
                        source: CameraSource.Camera,
                        direction : CameraDirection.Rear,
                        width: 640, /*320*/
                        height: 480, /*240,*/
                        quality: 100
                        });
                    this.photo = cameraPhoto.dataUrl;
                    const form = this.photo.match(tipoImagen).groups['formato']
                    const formato = form === 'png' ? 'png' : 'jpg'
                    let imagen = this.photo.replace(tipoImagen,'')
                    
                    //console.log(imagen)
                    const fileID = `${this.$pdv}/${'000'}/${'2022'}/${Date.now()}.${formato}`
                    //let imageBuffer = Buffer.from(imagen, "base64");
                    this.$http.post(`/save_delivery_image/${this.$pdv}`, {imagen , fileID})
                    }


            }
    })
</script>

<style scoped lang="scss">
  @import "@/theme/selectores-lotes-styles.scss";
    ion-row.altos {
        height: 3rem !important;
    }
</style>
