
var globalFilters = {    
    // signal_numero
        sMoneda: function (n){
            return [undefined, null, 0,].indexOf(n)!=-1 ? '-' : (n>0 ? '+' :'')+Math.round(n).toLocaleString('de-DE')+' €'
        },
        //numero
        Moneda : function  (n){
            return [undefined, null, 0,].indexOf(n)!=-1 ? '-' : Math.round(n).toLocaleString('de-DE')+' €'
        }
        ,
        //entero
        Entero : function  (n){
            return [undefined, null, 0,].indexOf(n)!=-1 ? '-' : Math.round(n).toLocaleString('de-DE')
        }, 
        DecimalPart: function(n){
            return [undefined, null, 0,].indexOf(n)!=-1 ? '' : ','+parseInt((n%1)*100).toString().padStart(2,0)+' €'
        }
        ,
        //signal_entero
        sEntero :function (n){
            return [undefined, null, 0,].indexOf(n)!=-1 ? '-' : (n>0 ? '+' :'')+Math.round(n).toLocaleString('de-DE')
        }  ,
        //signal_entero
        sPorcentaje :function (n){
            return [undefined, null, 0,].indexOf(n)!=-1 ? '-' : Math.round(n*100).toLocaleString('de-DE', {minimumFractionDigits: 1, maximumFractionDigits: 1})+' %'
        }  
        ,
        //Entero mostrado como porcentaje
        sIntPorcentaje :function (n){
            return [undefined, null, 0,].indexOf(n)!=-1 ? '-' : (n).toLocaleString('de-DE', {minimumFractionDigits: 0, maximumFractionDigits: 0})+' %'
        }  ,
        sDecimal: function (n){
            /*window.fake_console(n, [undefined, null, 0,].indexOf(n)!=-1 ? '-' : parseFloat(n).toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}))*/
            return [undefined, null, 0,].indexOf(n)!=-1 ? '-' : parseFloat(n).toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})
        },
        sDecimalRenderer: function (n){
            return [undefined, null, 0,].indexOf(n.value)!=-1 ? '-' : Math.round(n.value).toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})
        },
        Capitalize : function(n) {
            if (typeof n !== 'string') return ''
            return n.charAt(0).toUpperCase() + n.slice(1).toLowerCase()
          },
        Initcap : function(n) {
            if (typeof n !== 'string') return ''
            return n.toLowerCase().replace(/(?:^|\s)[a-z]/g, function (m) {
                return m.toUpperCase();
             });
        },
        Initcap2 : function(n) {
            if (typeof n !== 'string') return ''
            const t=  n.toLowerCase().replace(/(?:^|\s|\.)[a-z]/g, function (m) {
                return m.toUpperCase();
             });
             return t.replace(/\./g,' ').replace(/[A-Z] /g, function(e){return e.replace(' ','.')})
        },
        num2Month : function(n){
            if (Number.isNaN(n)||n===undefined ) return '-'
            else 
                {            
                    const x = n.toString()
                    return `${x.slice(0,4)}.${x.slice(4,6)}`
                }
        }, 
        num2Date : function(n){
            if (Number.isNaN(n)||n===undefined ) return '-'
            else 
                {            
                    const x = n.toString()
                    return `${x.slice(0,4)}.${x.slice(4,6)}.${x.slice(6,8)}`
                }
        }, 
        num2DateTime : function(n){
            if (Number.isNaN(n)||n===undefined ) return '-'
            else 
                {            
                    const x = n.toString()
                    return `${x.slice(0,4)}.${x.slice(4,6)}.${x.slice(6,8)} ${x.slice(8,10)}:${x.slice(10,12)}:${x.slice(12,14)} `
                }
        }, 
        showSku: function (t){
            return !t ? t : `${t.slice(0,3).toUpperCase()}  ${t.slice(4)}`
        }, 
        linesTag: function(n) {
            return [undefined, null, 0,].indexOf(n)!=-1 ? '-' 
                : Math.round(n).toLocaleString('de-DE')+ (n>1 ? ' Lineas' :' Linea')
        }

    }
    function initcap(t) {
        return t[0].toUpperCase()+(t.slice(1).toLowerCase())
    }
    
    
    
export { globalFilters, initcap  }
