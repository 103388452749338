/**
    Seleccion recepciones 
    Paso previo a la generacion de una recepcioñn
    Muestra un boton 'Grande' explicativo y la lista de recepciones en curso pendientes de entrada 
    Si hacemos una lectura o pulsamos 'Nueva' iremos a generar una  nueva recepción
    Si por contra picamos sobre una existente vamos al proceso de entrada. 
 */

<template>
    <ion-page  >
        <CabeceraComponent 
            :pdv = "$route.meta.pdv"
            :isIOS = "$route.meta.isIOS"
            titulo="Recepciones en curso"
            color="primary"
        />
        <ion-content class="ion-padding" >   

            <ion-fab vertical="top" horizontal="start" slot="fixed">
                    <ion-fab-button color="danger" @click="goCrossdocking">
                        <ion-icon :icon="shuffle" class="crossdock"></ion-icon>
                    </ion-fab-button>
            </ion-fab>

            <ion-fab vertical="top" horizontal="end" slot="fixed" >
                    <ion-fab-button  
                        :key="`scan-activo:${blinker}`"
                        :class="blinker ? 'activo' : 'inactivo'"
                        >
                        <ion-icon :icon="barcodeSharp" :class="blinker ? 'blink' : 'not-blink'" :key="`ion-scan:${blinker}`"></ion-icon>
                    </ion-fab-button>
            </ion-fab>



                <ion-row >
                    <ion-col size="36">
                        <ion-button expand="block" fill="outline" size="large"  class="trigger_scan" >
                                <ion-title>Seleccionar Recepcion
                                <br/> 
                                <ion-text class="ion-text-wrap estrecho">
                                    Escanea un albarán de grupo, clicka el boton adjunto o elige una recepcion de la lista adjunta
                                </ion-text>
                            </ion-title>
                        </ion-button>
                    </ion-col>
                    <ion-col size="12">
                        <ion-button class="alternativa impresion" fill="solid" expand="block" color="tertiary" routerLink="/recepcionar" routerDirection="none">
                            <i class="fas fa-plus blanca">
                                 <ion-text class="ion-text-wrap en-botton">
                                    Nueva Recepcion
                                 </ion-text>
                            </i> 
                        </ion-button>
                    </ion-col>
                </ion-row>
            <ion-row>

                <ion-list v-if="showme  && largo>0" class="ion-no-padding">       
                    <ion-list-header class="head"  style="width:100% !important;">
                        <ion-row>
                            <ion-col size="36">
                            <ion-text class="fake-titulo"> Recepciones en curso </ion-text>
                        </ion-col>
                        <ion-col size="12">
                            <ion-badge mode="md" color="danger">
                                % vendido
                            </ion-badge>
                        </ion-col>
                        </ion-row>
                    </ion-list-header>

                        <ion-item-sliding v-for="(recepcion,index) in datos"
                            v-bind:key="`item-${index}`" >
                            <ion-item 
                                class="ion-no-padding ion-no-margin"
                                :class="recepcion.situa==1 ? 'iniciado' : ''">        
                                <ion-row @click = "irEntrada(recepcion)"  >    

                                    <ion-col size-offset="1" size="36"  class="ion-no-margin  ion-no-padding">
                                        <h3 class="nombre_cortado" text-wrap>
                                            {{String(recepcion.codpro).padStart(5,'0')}} - {{recepcion.nombre}}
                                        </h3>
                                    </ion-col>
                                    <ion-col size="4" class="ion-no-margin  ion-no-padding">
                                        <i class="fas fa-clock-o tiempo" :style="`color: ${getColor(recepcion.retraso)} !important`"/>
                                    </ion-col>
                                    <ion-col size="6" class="ion-no-margin  ion-no-padding">
                                        <ion-badge mode="md" :color="recepcion.comprometido===100 ? 'danger' : recepcion.comprometido>=75 ? 'darkdanger' 
                                                : recepcion.comprometido>=50 ? 'warning' : recepcion.comprometido>=35 ? 'warning' :'success'  ">
                                            {{ $filters.Entero(recepcion.comprometido)  }}% 
                                        </ion-badge>
                                    </ion-col>
                                    <ion-col size-offset="1" size="47"  class="ion-no-margin  ion-no-padding">
                                        <h5 class="full-h5"> 
                                            <span class="lote oscuro"><b><small>Albaran : </small>
                                                {{ recepcion.codalb  }}
                                                </b> 
                                            </span> 
                                            <span class ="lote primario" v-if="recepcion.ptl == 1"> <b>(PTL) </b></span>
                                            <span class ="lote primario" v-if="recepcion.tiptra == 'G'"> <b>(Grupo) </b></span>
                                            <span class="lote peque forzar_derecha" >Fecha: {{ $filters.num2Date(recepcion.fecalb)   }} </span>
                                        </h5>
                                        <h5 class="nombre_cortado" text-wrap >
                                            <span class="lote oscuro"><b><small>Ubicaci. : </small>{{ recepcion.ubicacion||''   }}</b></span>
                                            <span class="lote peque forzar_derecha">Importe: {{ $filters.Entero(recepcion.valor)  }} €</span>
                                        </h5>
                                    </ion-col>
            
                                </ion-row>
                            </ion-item>
                            <ion-item-options side="end">
                                <ion-item-option color="danger" @click="borrar_recepcion(recepcion)">
                                     <ion-icon slot="bottom" class="muy-grande" :icon="trashOutline"></ion-icon>
                                     <span class="boton-opciones">Borrar</span>
                                </ion-item-option>
                            </ion-item-options>
                        
                    </ion-item-sliding>


                    </ion-list> 

                    <ion-item v-else>
                        <ion-row no-padding no-margin  no-lines >
                                <ion-col class="centro">
                                    <ion-label >No hay recepciones en curso pendientes de entrada en Gestion 2000</ion-label>
                                </ion-col>
                        </ion-row>      
                    </ion-item>
                        
                </ion-row> 
        </ion-content>
    </ion-page>
</template>

<script>
    import CabeceraComponent from '@/components/CabeceraComponent'

    import { defineComponent } from 'vue';
    import { sortBy } from 'underscore';
    import { trashOutline, barcodeSharp , shuffle } from 'ionicons/icons';
    import { scanGenericStart, scanGenericStop  } from '@/utilities/scanner/scan-generic'
    import { delEntradaFromStorage } from '@/utilities/storage/storage-entradas'
    import { esSalida } from '@/utilities/global-constants';
    import { openAlertaV2 } from  '@/utilities/services/alert-utilities';
    import eventBus from "@/event-bus"; 
    export default defineComponent({
            name : "SeleccionRecepcionesPage",
            components: { CabeceraComponent } , 
            data() {
                return {
                    pdv : this.$route.meta.pdv, 
                    element_id : 'seleccion_recepciones', 
                    clave: undefined  ,  
                    showme : false, 
                    trashOutline,barcodeSharp, shuffle, 
                    datos: [], 
                    blinker : false 

                    }
            }, 
            async created() {
                this.pdv =  this.$route.meta.pdv 
            },
            async mounted() { 
                window.fake_console('seleccion ubicaciones: entro en mounted  ', this.pdv)
                await this.recuperarRecepciones()
                await this.escaner_generico()
            }, 
            async unmounted() {
                await scanGenericStop(this.element_id)
                this.blinker = false; 
            }, 
 
            methods: {     
                async borrar_recepcion(recepcion) {
                    const { codpro, codalb} = recepcion; 
                    const payload = { codpro, codalb};
                    payload.nuevo_estado = -1; 
                    const actualizacion = await this.$http.post(`/update_recepcion_status/${this.pdv}`, payload)
                    window.fake_console(actualizacion.data[0])
                    if(actualizacion.data[0].status === 0  ){
                        await openAlertaV2(
                            'Error actualizacion recepcion', 
                            `No hemos podido crear realizar la operacion, error:\n${actualizacion.data[0].alert} `
                            )     
                        }
                    else {
                        await openAlertaV2(
                            'Recepcion actualizada', 
                            `${actualizacion.data[0].alert} `   
                        )
                    }
                    await delEntradaFromStorage (this.pdv, codpro, codalb)
                this.showme = false;
                await this.recuperarRecepciones()
                await this.escaner_generico()


                }, 
                async recuperarRecepciones(){
                    try {                    
                        const recep = await this.$http.post(`/get_recepciones/${this.pdv}`)
                        window.fake_console(recep)
                        window.fake_console(recep.data)
                        window.fake_console(`/get_recepciones/${this.pdv}`)
                        const datos = recep.data 
                        if (datos.length>0) {
                            this.datos = sortBy(datos , x=> {return  x.created})
                        }
                        else { throw 'No hay recepciones'}
                    }
                    catch (ex) {
                        console.log(ex)
                        this.datos = []
                    }           
                    this.showme = true ;          
                }, 
                async irEntrada(recepcion){
                    const {codpro, codalb, tiptra } = recepcion;
                    this.$router.replace({'name' :'entrada', params : {codpro, codalb,tiptra }})
                }, 
                getColor(retraso) {
                   const value = ((Math.min(1, retraso/15))/2)+0.5
                    var hue = ((1 - value) * 120).toString(10);
                    return ["hsl(", hue, ",100%,40%)"].join("");
                }, 
                async lectorEventosGenericos() {
                    let self = this; 
                    eventBus().emitter.off(`lectura_generica:${this.element_id}`)
                    eventBus().emitter.once(`lectura_generica:${this.element_id}`,async (input_scaner)=>{
                       /** es de grupo  */
                       const esGrupo = esSalida.test(input_scaner);
                       /** es de mi pdv  */

                       const dat = input_scaner.match(esSalida).groups

                       if (esGrupo && dat.codigo === '9'+self.pdv && dat.tipmov == 'CV'){
                            self.$router.replace(`/recepcionar/${input_scaner.replace(/\?/g,'_')}`)
                       }
                       else {
                        await openAlertaV2('Albaran erroneo' , `
                            La lectura facilitada no corresponde con un albaran de grupo de tu punto de venta`
                            )
                        await self.lectorEventosGenericos()
                       }
                    })
                },
                async escaner_generico() {
                    window.fake_console('activado escaner')
                    /** Hacemos reset del scanner cada vez que entramos en el campo  */
                    await scanGenericStop(this.element_id)
                    this.blinker = true
                    await scanGenericStart(this.element_id, 'desde escaner_generico')
                    this.blinker = true
                    await this.lectorEventosGenericos()
                },
                async goCrossdocking() {
                    this.$router.push('/revisar-stock-crossdocking')
                }

            }, 
            computed :{
                largo: function () {
                    return Object.keys(this.datos).length
                    }
            }
            
 
    })
</script>
 

<style scoped  lang="scss">
    @import "@/theme/seleccion-pedido-styles.scss";
    span.boton-opciones {
        font-size: larger !important; 
        padding-bottom: 0.3rem !important; 
        font-weight: bold !important;
    }
    ion-badge {
        width: 100% !important; 
    }
    ion-row.altos {
        height: 3rem !important;
        
    }
    ion-text.en-botton {
        font-family: 'Roboto';
        font-size: 0.9rem !important; 
        color: var(--ion-color-blanco)  !important;
        text-transform: none !important;
        line-height: 0.4rem !important;
    }
    i.fas.blanca {
        font-size: 2rem !important; 
        color: var(--ion-color-blanco)  !important;
        line-height: 1rem !important; 
        }
    ion-row {
        max-width: 600px !important; 
        padding: 0.3rem 0.2rem !important; 
    }

    ion-item {
        --inner-padding-end : 0px !important; 
        --padding-end : 0px !important; 
    }
    ion-text.fake-titulo {
        color: var(--ion-color-primary) !important; 
    }
    span.oscuro {
        color: var(--ion-color-medium) !important; 
        padding-left: 0.3rem !important; 
    }
    span.primario {
        color: var(--ion-color-primary) !important; 
        padding-left: 0.3rem !important; 
    }
    span.peque {
        font-size: smaller !important; 
        padding-right: 0.3rem !important; 
    }
    i.fas.tiempo {
        color: red; 
        font-weight: 600 important; ;
        font-size: 1.8rem !important;
        padding-right: 0.4rem !important
    }

    ion-item.iniciado {
        border-right: 10px  solid !important;
        border-image: linear-gradient(to bottom, white, var(--ion-color-darkdanger), white) 2;
    }

    ion-icon.crossdock {
        font-size: xx-large !important; 
        font-weight: 1000 !important;

    }

</style>