import { Storage } from '@capacitor/storage';
const consola = function(){ void(0) }; //consola

const schema = 'ubiku.ges.digital'

        const saveEnvioToStorage = async function(pdv, codexp, datos) {
            try 
                {
                    const clave = `${schema}:envio:${pdv}:${codexp}`
                    await Storage.set({ key: clave, value : JSON.stringify (datos)});
                    consola(1)
                    return true 
                }        
            catch { return false }
            }
        /** ------------------------------------------------------------------------------------------ */

        const loadEnviofromStorage = async function(pdv,codexp) {
            try 
            {const clave = `${schema}:envio:${pdv}:${codexp}`
                const {value} = await Storage.get({ key: clave})
                return value == undefined ? undefined: JSON.parse(value )
            }
            catch {
                return undefined 
            }
        }
        /** ------------------------------------------------------------------------------------------ */

        const delEnvioFromStorage = async function(pdv, codexp) {
            try 
            {
                const clave = `${schema}:envio:${pdv}:${codexp}`
                await Storage.remove({ key: clave });
                return true    
            }
            catch {
                return false 
            }
        }

export { saveEnvioToStorage, loadEnviofromStorage , delEnvioFromStorage }

