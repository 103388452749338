<template>
    <!--
        <p v-if="datos_entrada" 
            v-html="JSON.stringify( datos_entrada).replace(/,/g,',<br/>')">
        </p>
    -->
    <ion-list>
        <ion-list-header lines ="inset">
            <ion-row>
                <ion-col size="48" class="titulin">
                    {{sku}}<br/>
                </ion-col>
                <ion-col size="48" class="unidades_pedido">
                    <ion-label class="unidades_pedido">
                        {{$filters.Entero(unidades_pedido)}} pendientes
                    </ion-label>
                </ion-col>
            </ion-row>
        </ion-list-header>

       <RecepcionInputComponent 
            titulo="Albaranadas"
            nombre="uds_alb"
            placeholder = "Uds. albaran"
            v-on:update:uds_alb="datos_entrada.uds_alb = $event"
            descripcion="Introduce las unidades del albaran "
            modo="decimal"
            decimales="2"
            copy_to="uds_rec"
            :org="org"
            />

         <ion-item class="sin-borde pure-form alerta"  mode="ios"
                v-if="datos_entrada.uds_alb && datos_entrada.uds_alb>unidades_pedido">
                    <ion-label class="alerta">
                            Cantidad mayor a pendiente {{$filters.Entero(uds_ped)}} ,<br/>
                            si es correcto marca para validar la linea<br/>
                            sino edita la cantidad 
                    </ion-label>
                    <ion-toggle class="alerta" color="darkdanger" slot="end" v-model="cantidad_albaran"></ion-toggle>
            </ion-item>



        <RecepcionInputComponent 
            titulo="Recibidas"
            nombre="uds_rec"
            modo="decimal"
            decimales="2"
            placeholder = "Uds. recepcion"
            v-on:update:uds_rec="datos_entrada.uds_rec = $event"
            v-on:request:from_child:uds_rec="copiaUds($event)"
            copy_from="uds_alb"
            descripcion="Introduce las unidades recibidas "
            />
        <ion-item class="sin-borde pure-form alerta"  mode="ios"
            v-if="datos_entrada.uds_rec!==undefined && datos_entrada.uds_rec!=(datos_entrada.uds_alb||datos_entrada.uds_rec)">
                <ion-label class="alerta">
                        Cantidad recibida diferente a albaranada,<br/>
                        si es correcto acepta en el boton adjunto y<br/>
                        marca el tipo de incidencia a generar 
                </ion-label>
                <ion-toggle class="alerta" color="darkdanger" slot="end" v-model="cantidad_recibida"></ion-toggle>
        </ion-item>

    <AccionIncidenciaEntradaComponent :uds_alb="datos_entrada.uds_alb" :uds_rec="datos_entrada.uds_rec"
        :key="`idx:${datos_entrada.uds_alb}.${datos_entrada.uds_rec}`"
        v-if="cantidad_recibida"
        v-on:update:radio-button-accion="resuelveAccion($event)"
        />


    </ion-list>
           <ion-toolbar >
                <ion-buttons slot="start" class="completo"  >    
                    <ion-button class ="completo"  expand ="block" fill="solid" 
                        color="darkdanger" size="normal" @click="CreaLineaEntrada(undefined)"
                        >   
                        Descartar
                </ion-button>
                </ion-buttons>
                <ion-buttons slot="end" class="completo"  >    
                    <ion-button class ="completo"  expand ="block" fill="solid" 
                        id="recepcion_boton_creacion_field"
                        color="primary" size="normal" @click="CreaLineaEntrada(datos_entrada)"
                        :disabled = "incompleto"
                        > 
                        Crear entrada
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>



</template>

<script>
    import { defineComponent } from 'vue';
    import RecepcionInputComponent  from '@/pages/recepcionar/components/RecepcionInputComponent.vue' ;
    import AccionIncidenciaEntradaComponent  from '@/pages/recepcionar/components/AccionIncidenciaEntradaComponent.vue' ;
    import eventBus from "@/event-bus"; 
    export default defineComponent({
        name : "NuevaEntradaComponent",
        components: { RecepcionInputComponent , AccionIncidenciaEntradaComponent  } , 
        props : ['codpro', 'sku', 'uds_ped', 'albaran', 'recepcion', 'origen'] ,
        data() { 
                return { 
                    element_id: 'nueva-entrada-recepcion',
                    pdv: undefined,
                    datos_entrada : {
                        uds_alb: undefined, 
                        uds_rec: undefined
                    }, 
                    cantidad_albaran: false, 
                    cantidad_recibida:  false, 
                    unidades_pedido: undefined,
                    org: undefined
                    }
            },
        watch: {
            'datos_entrada.uds_alb'() { 
                const {uds_alb, uds_rec} = this.datos_entrada 
                this.cantidad_albaran= false; 
                this.datos_entrada.accion= uds_alb == uds_rec
            },
            'datos_entrada.uds_rec'() { 
                const {uds_alb, uds_rec} = this.datos_entrada 
                this.cantidad_recibida = false; 
                this.datos_entrada.accion= uds_alb == uds_rec
            }
        },
        computed :{
            incompleto: function () {
                const dat = this.datos_entrada 
                const valores = Object.values(dat);    
                /** validaciones de datos  */
                const control_albaran = this.cantidad_albaran||this.datos_entrada.uds_alb<=this.unidades_pedido;
                const control_recibidas = this.cantidad_recibida||this.datos_entrada.uds_rec ==this.datos_entrada.uds_alb
                valores.push(...[control_albaran,control_recibidas])
                return valores.some(x=>!x)
                }, 
            }, 
        async mounted() {

            if (this.albaran) { eventBus().emitter.emit('uds_alb:from_parent:update' , this.albaran.toString())}
            if (this.recepcion) { eventBus().emitter.emit('uds_rec:from_parent:update' , this.recepcion.toString())}
            window.fake_console( this.datos_entrada.uds_alb,  this.albaran, this.datos_entrada.uds_rec ,  this.recepcion)
            this.unidades_pedido = this.uds_ped||0
            this.org = this.$props.origen ?? 'pedido'
            },
        methods :{
            resuelveAccion(e){
                if(e=="I")  {
                    this.copiaUds({origen: 'uds_alb', destino: 'uds_rec' })
                }
                else {
                    this.datos_entrada.accion = e 
                }
            }, 
            copiaUds(e){
                window.fake_console(`${e.destino}:from_parent:update` , this.datos_entrada[e.origen])
                eventBus().emitter.emit(`${e.destino}:from_parent:update` , this.datos_entrada[e.origen])
            },  
            async CreaLineaEntrada (entrada) {
                eventBus().emitter.emit('creacion-entrada-nueva',entrada)
            }, 

        }


        
    })
</script>

<style scoped lang="scss">
    @import "@/theme/recepciones-styles.scss";
    @import "@/theme/alert-popover-styles.scss";
    @import "@/theme/footer-styles.scss";

</style>

