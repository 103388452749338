<template>
    <ion-row class="fondo  ion-margin" v-if="done && totals && totals !=0 ">
        <div class="medida" :style="`width:${progreso}% !important;`"> 
            <div class="valor">
                {{$filters.sIntPorcentaje(progreso)}} <br/>
            <span class="con-linea">hecho</span>
        </div>
        </div>
        <div class="done" :style="`width:${100-progreso}% !important`" >
            {{$filters.Entero(totals)}}<br/>
            <span class="con-linea">totales</span>
        </div>    
    </ion-row>
</template>

<script>
    import { defineComponent } from 'vue';
    export default defineComponent({
        name : "ProgressBarComponent",
 
        props : ['done','totals'] , 
        computed :{
            progreso: function () {
               return this.done>=this.totals ? 100 : parseInt(this.done*100/this.totals)
                }
            }
    })
</script>

<style scoped>
    ion-row.fondo {
        min-height: 3rem !important; 
        width : 98% !important;
        margin-right: 1% !important; 
        margin-left: 1% !important; 
        border: 1px solid var(--ion-color-light-shade) !important;
        border-radius: 0.2rem !important;
        background:  var(--ion-color-light-tint) !important;
        height: auto !important; 
    }
    div.medida {
        min-height: 3rem !important; 
        height: auto !important; 
        border-radius: 0.2rem !important;
        background: var(--ion-color-darksuccess-shade) !important;
         text-align:center;
    }
    div.valor {
        min-height: 3rem !important; 
        height: auto !important; 
        display:inline;
        font-size: 1.5rem !important;
        font-weight: bold !important;
        white-space: nowrap !important;
        padding: 0.6rem 0.4rem !important; 
        float: left !important;
        color: var(--ion-color-blanco) !important ;
        white-space: nowrap  !important; 
    }

    div.done {
        display:inline;
        direction: rtl !important;
        font-size: 1.5rem !important;
        font-weight: bold !important;
        color: var(--ion-color-primary) !important ;
        padding-top: 0.6rem !important; 
        padding-bottom: 0.6rem !important; 
        float:right !important;
        text-align: right !important;
        white-space: nowrap  !important; 
        overflow: visible !important;

    }
    span.con-linea:before { 
        content: ' '; display: block; 
    }    
    span.con-linea {
        font-size: small !important; 
    }

</style>