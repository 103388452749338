/**
NOTA: AÑADIR EL CHECK DE LAS UBICACIONES 

Pagina para ubicar articulos.
    Requiere que como primer paso leamos un codigo de barra de zona, hasta ese momento no aceptará ninguna
    lectura de productos 


 */

<template>
    <ion-page >
        <CabeceraComponentBack  
            :pdv = "pdv"
            :isIOS = "$route.meta.isIOS"
            titulo="Ubicar Articulos "
            color="primary"
        />
        <ion-content class="ion-padding" v-if="ubicacion" >   
                
                
                <ion-row>
                    <ion-col size ="12">
                        <ion-button class="alternativa impresion" fill="outline" expand="block" color="primary" @click="BusquedaManual()">
                            <i class="fas fa-search en-fab">
                                 <ion-text class="ion-text-wrap en-boton">
                                    Busqueda manual producto
                                 </ion-text>
                            </i> 
                        </ion-button>
                    </ion-col>
                    <ion-col size="24" >
                        <ion-button :id="element_id" expand="block" fill="outline" size="large"  class="trigger_scan" >
                            <ion-title >{{ubicacion}}
                                <br/> 
                                <ion-text class="ion-text-wrap">Zona seleccionada</ion-text>
                            </ion-title>
                        </ion-button>
                    </ion-col>
                    <ion-col size ="12">
                        <ion-button class="alternativa impresion" fill="outline" expand="block" color="primary" @click="Imprimir()" :disabled="datos.length==0">
                            <i class="fas fa-print en-boton">
                                 <ion-text class="ion-text-wrap en-boton">
                                    Imprimir etiquetas producto
                                 </ion-text>
                            </i> 
                        </ion-button>
                    </ion-col>
                </ion-row>

                <ion-row v-if="datos && datos.length>0">
                    
                    <LotesSeleccionados :datos="datos" :key="`datos:${datos.length}`"/>
                </ion-row>
        </ion-content>
        <ion-footer>
            <ion-toolbar color="default" >               
                <ion-buttons slot="end" class="completo"  >    
                    <ion-button class ="completo"  expand ="block" fill="solid" color="darksuccess" size="large" @click="UpdateGestion2000"> 
                        Grabar en <br/>Gestion 2000
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-footer>

    </ion-page>
</template>

<script>
    import CabeceraComponentBack from '@/components/CabeceraComponentBack';
    import {  modalController} from '@ionic/vue';
    import SeleccionLoteComponent from '@/components/SeleccionLoteComponent.vue'
    import LotesSeleccionados from '@/components/LotesSeleccionados.vue'
    import SeleccionProductoStockComponent from '@/components/search/seleccion-producto-stock-component.vue'
    import { reject, pluck, pick , uniq } from 'underscore';
    import { defineComponent } from 'vue';
    import { scanSkuStart, scanSkuStop } from '@/utilities/scanner/scan-sku'
    import { saveUbicacionStorage, loadUbicacionStorage ,delUbicacionStorage } from '@/utilities/storage/storage-ubicar'
    import { saveEanStorage, loadEanStorage, delEanStorage , listEanStorage , delEanAll} from '@/utilities/storage/storage-eans';
    import { openToast , openAlertaEsUbicacionPrincipal , openAlertaV2 } from  '@/utilities/services/alert-utilities';
    import {  isOnline}  from '@/utilities/global_functions'
    import eventBus from "@/event-bus"; 

 
    export default defineComponent({
            name : "UbicarArticulosPage",
            components: { CabeceraComponentBack ,  LotesSeleccionados } , 
            props:['ubi'],
            data() {
                return {
                    element_id : 'selector_articulos', 
                    datos: [], 
                    ubicacion: undefined, 
                    pdv: undefined
                }
            }, 
            async created() {
                this.pdv =  this.$route.meta.pdv 
                this.ubicacion = this.ubi !=undefined ? this.ubi : this.ubicacion 
                window.fake_console('seleccion ubicaciones: entro en created', this.$route.meta.pdv, this.ubicacion)
            },
            async mounted() { 
                window.fake_console(this.datos)
                window.fake_console('mounted', this.ubi, this.ubicacion)
                let self = this; 
                const stg = await loadUbicacionStorage(this.ubicacion, this.pdv)
                window.fake_console(this.ubicacion, stg)
                if (stg){ this.datos = stg}
                this.escanear()
                eventBus().emitter.on('deselect-sku', async (item)=> {
                    self.update_data(item, 'remove')
                    await saveUbicacionStorage(this.ubicacion, self.$pdv, self.datos)
                    if (item.ean_sin_resultados) {
                        await delEanStorage(self.$pdv, item.ean_sin_resultados)
                    }
                })                    
            },             
            async unmounted() {
                await scanSkuStop(this.element_id)
            }, 

            methods: {     
                async DelAll(){
                    await delUbicacionStorage(this.ubicacion, this.$pdv)
                    await delEanAll( this.$pdv)
                    this.datos = [];
                },
                async Imprimir(){
                    /**etiqueta/producto/${this.$pdv}?sku=${sku}&ubi=${ubi} */
                    let self = this
                    /** Si esta online dejamos imprimir etiquetas  */
                    if (isOnline) { 
                        await this.datos.reduce( async (memo, v)=> {
                            const m = await memo ;
                            window.fake_console(v)
                            const payload = pick (v ,[ 'sku','ubi','lote','refpro'] )
                            if (payload.lote && /^rollo\d/i.test(payload.lote)) {
                                    payload.unimed = (v.unimed||'').toLowerCase()
                                    payload.stoalm = v.stoalm
                                }
                            window.fake_console(`/etiqueta/producto/${self.$pdv}`, payload)
                            await self.$http.post(`/etiqueta/producto/${self.$pdv}`, payload)
                            return m 
                        }, [] )
                    }
                    /* Sino  lanzamos alerta*/
                    else {
                        await openAlertaV2('Sin Cobertura!!' , `
                            Ahora mismo no tienes cobertura. \n 
                            No tienes cobertura, acercate a una zona con cobertura para imprimir .  `
                        ) 
                            
                    }
                }, 

                async BusquedaManual(dato_entrada, ean_sin_resultados){
                    await scanSkuStop(this.element_id)
                    const self = this; 
                    const modal = await modalController.create({
                        component: SeleccionProductoStockComponent ,
                        cssClass: 'custom-recommendations-modal',
                        backdropDismiss: false, 
                        componentProps: {
                            origen : 'busqueda-manual-ubicar-articulo' , 
                            input_inicial_filtro : dato_entrada , 
                            ean_sin_resultados: ean_sin_resultados
                            },
                        })
                    modal.onDidDismiss().then(()=> {
                        window.fake_console('dismiss sin seleccion, seguimos con escaner activo')
                        eventBus().emitter.emit('busqueda-manual-ubicar-articulo',undefined)    
                        })                    
                    eventBus().emitter.once('busqueda-manual-ubicar-articulo',async (el)=>{
                        window.fake_console('Cierro el selector')
                        modal.dismiss();
                        window.miconsola(el)
                        if (el) 
                            {
                                 try
                                    {                                 
                                        await self.update_data(el, 'add') 
                                        if (el.ean_sin_resultados ){
                                            window.fake_console(self.$pdv, el.ean_sin_resultados,  el.sku)
                                            await saveEanStorage(self.$pdv, el.ean_sin_resultados,  el.sku)
                                            window.fake_console('GRABADO!!!')
                                        }
                                    }
                                catch (ere) {
                                    console.log(ere)
                                }
                               }
                        await scanSkuStart(self.element_id, 'Desde L168, emiter busqueda manual')
                        })
                        
                    modal.present()


                }, 

                async UpdateGestion2000(){
                    let self = this
                    window.fake_console(this.datos)
                    /** Si esta online dejamos actualizar en G2k  */
                    if (isOnline) { 
                        /** Grabamos las ubicaciones en Gestion  */
                        await this.datos.reduce( async (memo, v, i)=> {
                            
                            const m = await memo ;
                            window.fake_console(i, v)
                            const payload = pick (v ,[ 'sku','codsuc','ubi','principal','lote'] )
                            window.fake_console(payload)
                            const feedback = await  self.$http.post(`/actualizar_ubicaciones/${self.$pdv}`, payload)
                            window.fake_console(feedback)
                            if ( feedback.data.status == 1) {
                                await this.update_data(v,'remove')
                                m.push(v)
                            }
                            return m 
                        }, [] )

                        /** Si tenemos Eans pendiente tb. los grabamos en G2k */
                        const eans_pendientes = await listEanStorage(self.$pdv)
                        window.fake_console(eans_pendientes)

                        if (eans_pendientes && eans_pendientes.length >0 ) {
                            await eans_pendientes.reduce( async (memo, ean_nuevo) => {
                              const me = await memo; 
                              try 
                              {
                                const sku_ean  = await loadEanStorage(self.$pdv, ean_nuevo)
                                
                                window.fake_console(sku_ean)
                                if (sku_ean) {
                                    window.fake_console(sku_ean)
                                    const ean_update = await  self.$http.post(`/ean/${ean_nuevo}/${sku_ean}`)
                                    if (ean_update.data.status === 'ok') {
                                        await delEanStorage (self.$pdv, ean_nuevo)
                                        me.push(ean_nuevo)
                                    }
                                 } 
                              }
                              catch (eee) {
                                console.log('ERROR:', eee)
                              }
                              return me 
                            }, [])
                        }



                    }
                    /* Sino guardamos para más adelante */
                    else {
                        await openAlertaV2('Sin Cobertura!!' , `
                            Ahora mismo no tienes cobertura. \n 
                            No te preocupes, el trabajo queda grabado en tu movil y puedes actualizarlo en Gestion 2000  cuando estes en una zona con cobertura.\n
                            Te redirigimos a la pagina de selección de ubicaciones.  `) 
                        this.$router.replace('/ubicar')
                    }
                }, 

                async update_data(item, tipo ='add'){
                    const clau = `${item.sku}.${this.$pdv}.${item.codsuc}.${!item.lote ? 'N' :item.lote}`
                    const actuales = pluck(this.datos,'fila')
                    window.fake_console(actuales)
                    if (tipo == 'add' && actuales.indexOf(clau)==-1) {
                        /** Verificamos si existen ubicaciones  */
                        let hasUbi  
                        let nueva_ubicacion = { 'ubi': this.ubicacion , 'principal' :'1' }
                        try {
                                hasUbi = this.$ubicaciones_pdv[clau]
                                const previas = JSON.parse(hasUbi['ubi2'])
                                previas.unshift(this.ubicacion)
                                const unicos = uniq(previas)
                                window.fake_console(unicos)
                                if (unicos.length>1) {
                                    await scanSkuStop(this.element_id)
                                    /**si hay más de un elemento preguntamos, sino no . */
                                    const d = await openAlertaEsUbicacionPrincipal(unicos) 
                                    nueva_ubicacion['principal'] = d === true ? '1' : null
                                    window.fake_console(d, nueva_ubicacion)
                                    window.fake_console(Object.assign({'fila':clau}, item, nueva_ubicacion))
                                    window.fake_console('llego a la 164')   
                                    this.datos.unshift(Object.assign({'fila':clau}, item, nueva_ubicacion))
                                    await saveUbicacionStorage(this.ubicacion, this.$pdv, this.datos)      
                                    await scanSkuStart(this.element_id, 'Despues de una alerta ')
                                    
                                }
                            }
                        /** Si no existen asociamos a la ubicación principal  */
                        catch {
                                window.fake_console('entro en el error')
                                hasUbi = undefined
                                /*self.datos.push(Object.assign({'fila':clau}, item, nueva_ubicacion))   */
                                this.datos.unshift(Object.assign({'fila':clau}, item, nueva_ubicacion)) 
                                // Grabamos en disco 
                                await saveUbicacionStorage(this.ubicacion, this.$pdv, this.datos)    
                            }
                    }
                    else if (tipo == 'add' && actuales.indexOf(clau)!=-1)
                    {
                        openToast('Elemento existente', 'Esta referencia/lote/ubicación ya forma parte de la lista ', 1000)
                    }
                    else  if (tipo =='remove'){
                        const quedan = reject(this.datos , (e)=> e.fila ==  clau )
                        this.datos = quedan; 
                        await saveUbicacionStorage(this.ubicacion, this.$pdv, this.datos)  
                        if (this.datos.length == 0 ) {
                            await delUbicacionStorage(this.ubicacion, this.$pdv)
                        }                        
                    }
                    window.fake_console(tipo, clau)
                    return 
                }, 

                async lectorEventos() {
                    let self = this; 
                    eventBus().emitter.off(`resultados:${this.element_id}`)
                    eventBus().emitter.once(`resultados:${this.element_id}`,async (resultados)=>{
                        window.fake_console('recibo mensaje con resultados... ',resultados.estado == true , resultados, resultados.ean_buscado_en_google)
                        if (resultados.estado === true && resultados.lotes.length == 1 ) {
                            /* entramos aqui si el resutlado es único  */
                            await self.update_data(resultados.lotes[0], 'add')
                                if (resultados.ean_buscado_en_google ){
                                    window.fake_console(self.$pdv, resultados.ean_buscado_en_google,  resultados.lotes[0].sku)
                                    await saveEanStorage(self.$pdv, resultados.ean_buscado_en_google,  resultados.lotes[0].sku)
                                }                            
                        }
                        /**
                         * añado un bloque para controlar el caso en que quiero que busque la lectura 
                         */

                        else if (resultados.estado == true && resultados.accion == 'filtrar-pdv') {
                            await self.BusquedaManual(
                                    !resultados.texto  ? undefined : resultados.texto.toLowerCase(), 
                                    resultados.ean_sin_resultados)
                        }
                        /** PASAMOS AQUI CUANDO HAY VARIANTES DE LOTES */
                        else if (resultados.estado == true )
                        {
                            window.fake_console('en la apertura del modal de selecccion')
                            const modal = await modalController
                                .create({
                                    component: SeleccionLoteComponent ,
                                    cssClass: 'custom-recommendations-modal',
                                    componentProps: {datos : resultados },
                                    })
                            modal.onDidDismiss().then(()=> {
                                window.fake_console('dismiss sin seleccion, seguimos con escaner activo')
                                eventBus().emitter.emit('cerrar-selector',undefined)    
                                })
                            window.fake_console('aqui tengo ean??? ', resultados.ean_buscado_en_google)
                            eventBus().emitter.once('cerrar-selector',async (el)=>{
                                window.fake_console('Cierro el selector')
                                modal.dismiss();
                                if (el) {
                                    await self.update_data(el, 'add')
                                    if (resultados.ean_buscado_en_google ){
                                            window.fake_console(self.$pdv, resultados.ean_buscado_en_google,  el.sku)
                                            await saveEanStorage(self.$pdv, resultados.ean_buscado_en_google,  el.sku)
                                        }
                                    
                                    }
                                })
                            modal.present()
                        }
                        self.lectorEventos()
                    })
                },
                async escanear() {
                    await scanSkuStart(this.element_id, 'Desde escanear')
                    await this.lectorEventos()
                },
            }, 
            computed :{
                largo: function () {
                    return Object.keys(this.datos).length
                    }
            },
 
    })
</script>

<style scoped lang="scss">
  @import "@/theme/footer-styles.scss";
    ion-buttons.completo {
        width : 50% !important; 
    }
  ion-text.en-boton {
        font-family: 'Roboto';
        font-size: 0.9rem !important; 
        color: var(--ion-color-primary)  !important;
        text-transform: none !important;
        line-height: 0.4rem !important;
    }
    i.fas.en-boton {
        font-size: 3rem !important; 
        color: var(--ion-color-primary)  !important;
        line-height: 1rem !important; 
        }
    ion-title {
        position: relative;
    }
</style>
