import { alertController, toastController } from '@ionic/vue';
import {add2MostradorStorage } from '@/utilities/storage/storage-mostrador'
/*
    import { globalFilters } from '@/utilities/filters'
    const {Entero} = globalFilters;
*/

const consola = function(){ void(0) }; //consola

    const openToast= async function (head, msg, tiempo) {
        const toast = await toastController
            .create({
            header: head,
            message: msg,
            duration: tiempo ===undefined ? 3000 : tiempo,
            position: 'middle',
            buttons: [
                {
                side: 'end',
                text: 'Ok'
                }
            ]
            })
        await toast.present();
        };





   
    const openAlertaBinariaInput = async function(head, msg, bNo, bSi,mis_inputs,  callback) {
        const alerta = await alertController
            .create({
                header: head, 
                message: msg,
                mode : "md",
                inputs :mis_inputs, 
                buttons: [
                    { text: bNo===undefined ? 'Si' : bNo, 
                        handler: () => { window.fake_console('desestimamos'); callback(false, null) } },
                    { text: bSi===undefined ? 'Si' : bSi, 
                        handler: (data) => { window.fake_console('en el handler'); callback(true, data);} },
                ]
            })
        return alerta.present(); 
    }




    const openAlertaEsUbicacionPrincipal = async function (dx){
        window.fake_console(dx)
        const head = `Existen ubicaciones...`
        const msg = `Ya hay ubicaciones informadas.\n Es esta la nueva ubicación principal? `
        const alerta = await alertController
            .create({
                header: head, 
                message: msg,
                mode : "md",
                backdropDismiss: false, 
                buttons: [
                    { text:'NO' , role: 'cancel', 
                        handler: () => {window.miconsola('desestimamos'); } },
                    { text: 'SI', role : 'confirm', 
                        handler: () => { window.miconsola('en el handler'); } },
                ]
            })
        await alerta.present(); 
        const {role} = await alerta.onDidDismiss();
        window.fake_console({role})
        return role =='cancel' ? false : true
    }

/** 2013425486 */

    const openAlertaEligeAlbaranPTL = async function (in_data, callback){
        const mis_inputs = in_data.map( (v) => {
            const Etiqueta = `Albaran : ${v.codalb}  Proveedor:${v.nombre}`
                    return {'label' : Etiqueta, 'type' :'radio', 'value' : v}
        })
        const head = `Hay albaranes PTL...`
        const msg = `Nos constan albaranes PTL pendientes, si es alguno de los siguientes, seleccionalo para facilitarte el trabajo`
        const alerta = await alertController
            .create({
                header: head, 
                message: msg,
                mode : "md",
                cssClass: 'custom-ptl-alert',

                backdropDismiss: false, 
                inputs :mis_inputs, 
                buttons: [
                    {
                      text: 'Cancelar',
                      role: 'cancel',
                      handler: () => {
                        consola('Alert canceled');
                      },
                    },
                    {
                      text: 'OK',   
                      role: 'confirm',
                      handler: ( ) => {
                        consola('Alert confirmed');
                      },
                    },
                  ]
            })
        await alerta.present();
        
        const {data, role} = await alerta.onDidDismiss();
        consola(data)
        if (role=='confirm'){
            callback(data)
            
        }
        else {
            callback(undefined)
        }
        await alerta.dismiss()
    }

    const openAlertaUnidadesCarrito = async function (cliente, pdv, sku_data){
        const {sku, dispo,  unimed, stoalm, hay_stock , uniser =1} = sku_data
        let disponible = Math.min(dispo,stoalm) ;
        const head = `Albaran Mostrador`
        
        
        const msg1 = `
            <b>${uniser !=1 ? '<h4>Se vende en  multiplos de '+uniser.toLocaleString('de-DE', {minimumFractionDigits: 0, maximumFractionDigits: 0}) :''}</b></h4>    
            Introduce las unidades (${unimed}) necesarias.<br/>
            Las que excedan el disponible<b> (${disponible.toLocaleString('de-DE', {minimumFractionDigits: 0, maximumFractionDigits: 0})}) </b>las incorporaremos como una linea adicional para que puedas pedirlas al fabricante u otro pdv. `
        const msg2 = `
        <b>${uniser !=1 ? '<h4>Se vende en  multiplos de '+uniser.toLocaleString('de-DE', {minimumFractionDigits: 0, maximumFractionDigits: 0}) :''}</b></h4>
        Introduce las unidades (${unimed}) necesarias.<br/>
            Las incorporaremos como una linea para pedir al fabricante u otro pdv. `

        const alerta = await alertController
            .create({
                header: head, 
                subHeader: `${sku}`, 
                message: hay_stock ? msg1 : msg2 ,
                mode : "md",
                cssClass: 'custom-ptl-alert',
                backdropDismiss: false, 
                inputs :[

                    {
                        value : "Unidades Albaran", 
                        disabled: true,
                        cssClass : 'etiqueta lx'
                    },
                    {
                        name: 'uds_alb',
                        type: 'number',
                        id:'uds_albaranar', 
                        cssClass : 'grande prioritaria', 
                        tabindex :0 , 
                        min: 0,
                        max: Math.min(dispo,stoalm),
                    },
                    {
                        value : '', 
                        id : 'control_error_uniser', 
                        disabled: true,
                        cssClass : 'etiqueta-error lx'
                    },

                ], 
                buttons: [
                    {
                      text: 'Cancelar',
                      role: 'cancel',
                      handler: () => {
                        consola('Alert canceled');
                      },
                    },
                    {
                      text: 'OK',   
                      role: 'confirm',
                      handler: (data ) => {
                        const valor = parseFloat(data.uds_alb);
                        window.fake_console(valor, uniser,valor%uniser  )
                        if ( valor%uniser === 0 ) {
                          return true 
                        }
                        else {
                          const ux = uniser.toLocaleString('de-DE', {minimumFractionDigits: 0, maximumFractionDigits: 0});
                          control_error.value =  `Unidades deben ser multiplo de ${ux}`
                          foco.value = ''; 
                          return false 
                        }
                      },
                    },
                  ]
            })
        await alerta.present(); 
        let foco = document.getElementById('uds_albaranar')       
        foco.focus()
        let control_error = document.getElementById('control_error_uniser')       
        const {data, role} = await alerta.onDidDismiss();

        if (role=='confirm'){ 
            const valor = parseFloat(data.values.uds_alb);
            window.fake_console({sku, dispo,  unimed, stoalm, hay_stock } )
            if (hay_stock===false || (hay_stock == true &&  valor <= disponible )) {
                sku_data.uds_alb = valor;
                sku_data.tipo = hay_stock === true ? 'stock' : 'pedido';
                window.fake_console('L265 :',sku_data)
                await add2MostradorStorage(cliente, pdv, sku_data)    
            }
            else {
                const en_stock = Object.assign({uds_alb : disponible, tipo:'stock'}, sku_data)
                const a_pedir = Object.assign({uds_alb : valor-disponible, tipo:'pedido'}, sku_data)
                window.fake_console('L271 : ', en_stock)
                window.fake_console('L272 : ', a_pedir)
                await add2MostradorStorage(cliente, pdv, en_stock)
                await add2MostradorStorage(cliente, pdv, a_pedir)
            }            
            return true  
        }
        else { return undefined }
    }



    const openAlertaSiNo = async function (cliente, head, msg){
            const alerta = await alertController
            .create({
                header: head, 
                subHeader: `Cliente : ${cliente}`, 
                message: msg,
                mode : "md",
                cssClass: 'custom-ptl-alert',
                backdropDismiss: false, 
                buttons: [
                    {
                      text: 'CANCELAR',
                      role: 'cancel'
                    },
                    {
                      text: 'SI',   
                      role: 'confirm'
                    },
                  ]
            })
        await alerta.present(); 
        const {role} = await alerta.onDidDismiss();
        if (role=='confirm'){  return true   }
        else { return undefined }
    }



    const OpenAlertaImpresoras = async function (impresoras, actual){
        const mis_inputs = impresoras.map( (v) => {
            return {'label' : v.v, 'type' :'radio', 'value' : v.k , 'checked' : v.k==actual}
        })
        const head = `Cambiar impresora`
        const msg = `Hay varias impresoras en el pdv, elige la que te convenga`
        const alerta = await alertController
            .create({
                header: head, 
                message: msg,
                mode : "md",
                cssClass: 'custom-ptl-alert',

                backdropDismiss: false, 
                inputs :mis_inputs, 
                buttons: [
                    {
                      text: 'Cancelar',
                      role: 'cancel',
                      handler: () => {
                        consola('Alert canceled');
                      },
                    },
                    {
                      text: 'OK',   
                      role: 'confirm',
                      handler: ( ) => {
                        consola('Alert confirmed');
                      },
                    },
                  ]
            })
        await alerta.present();
        
        const {data, role} = await alerta.onDidDismiss();
        consola(data)
        if (role=='confirm'){
            return data 
            
        }
        else {
            return false
        }
        
    }



    const openAlertaV2  = async function(head, msg) {
        const alerta = await alertController
            .create({
                header: head, 
                message: msg,
                mode : "md",
                cssClass: 'alerta-estandar',
                backdropDismiss: false, 
                buttons: [
                    {
                    text: 'Si',
                    handler: () => { 
                        window.fake_console('en el handler')
                    },
                    },
                ]
            })
        await alerta.present();      
        const {data} = await alerta.onDidDismiss();
        consola(data)
        return 
    }

    const openAlertaBinaria = async function(head, msg, bNo, bSi) {
        const alerta = await alertController
            .create({
                header: head, 
                message: msg,
                mode : "md",
                backdropDismiss: false, 
                buttons: [
                    { 
                        text: bNo===undefined ? 'No' : bNo, 
                        role: 'cancel' 
                    },
                    { 
                        text: bSi===undefined ? 'Si' : bSi, 
                        role: 'confirm',
                     },
                ]
            })
        await alerta.present(); 
        const { role } = await alerta.onDidDismiss();
        if (role=='confirm'){return true  } 
        else { return false }
    }




    





export { openToast ,  openAlertaBinaria, 
        openAlertaBinariaInput , 
        openAlertaEligeAlbaranPTL , openAlertaUnidadesCarrito , 
        openAlertaSiNo , OpenAlertaImpresoras, 
        /** Revisadas */
        openAlertaV2,openAlertaEsUbicacionPrincipal, 

    }

