<template>
    <ion-page :key="qt">
        <ion-header>
            <ion-toolbar   color="primary" class="ion-justify-content-start ion-align-items-start"> 
                <ion-title>
                    <ion-row  class="ion-justify-content-start ion-align-items-start">
                        <ion-col size="40"  class="ion-float-left izquierda" 
                            :style="isIOS ? 'padding-left: 4rem !important;': ''">
                            Recepcionar<br/>Seleccionar proveedor ({{pdv}})
                        </ion-col>
                    </ion-row>                
                </ion-title>
                <ion-buttons slot="end" >
                    <ion-button size="default" color="light"  @click="ElementoStockSeleccionado(undefined)">
                        <i class="fa-sharp fa-solid fa-circle-left muy-grande" color="light" ></i>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
            <ion-toolbar color="primary"  class="top">
                    <ion-searchbar  v-model="search_manually"
                        id="busqueda_filtrada_proveedor"
                        ref="search_busqueda_filtrada_proveedor"
                        :debounce="debounce_milis" 
                        @ionClear="onCancel($event)"
                        autocomplete="off" autocorrect="off" 
                        autocapitalize="off" spellcheck="false"
                        show-clear-button="always"
                        placeholder="Filtrar pedidos proveedor" 
                        inputmode ="search"
                        type="search"
                        @ionChange="filtroInputSearch"
                        @ionBlur="getFoco($event, false)"
                        @ionFocus="getFoco($event,true)"
                        slot="start"
                        mode = "md">
                    </ion-searchbar>
                </ion-toolbar>


        </ion-header>

        <ion-content>
            
            <ion-row  class="spin ion-justify-content-center "  v-if="!showme">
                <ion-col  v-if="!showme" class="spin ion-align-self-center" size="48" > 
                    <ion-spinner  v-if="!showme"></ion-spinner>            
                </ion-col>
            </ion-row>
                 
                 
            <ion-row v-if="showme">
                <ion-row class="ion-no-padding"
                     v-if="showme && pedidos_filtrados.length >0 && (!search_manually|| search_manually.length==0)">
                    <ion-col size="44" text-right>
                        <p color="info">
                            <span style="font-size:larger"><b>{{datos_tot }} pedidos con lineas pendientes, </b>
                            filtra para seleccionar, se muestran los {{Math.min(limite, pedidos_filtrados.length)}} primeros
                            </span> 
                        </p>            
                    </ion-col>
                </ion-row>
                <ion-row class="ion-no-padding" v-else-if="showme && pedidos_filtrados.length >0 && search_manually && search_manually.length>0">
                    <ion-col size="44"  text-right>
                        <p color="info">
                            Tu busqueda "{{search_manually}}" ha generado <span style="font-size:larger"><b>{{datos_tot }} resultados </b></span>
                            <br/>
                            Solo se muestran los {{Math.min(limite, pedidos_filtrados.length)}} primeros
                        </p>            
                    </ion-col>
                </ion-row>

                <!-- BUSQUEDA SIN RESULTADOS-->
                <ion-row class="ion-padding ion-margin" v-else>
                    <ion-title class="ion-padding-top ion-margin-top texto-mas-grande" >Busqueda sin resultados </ion-title>
                        <div class="ion-padding-top ion-margin-top ion-text-justify texto-mas-grande">
                            <ion-text >
                            No hemos encontrado ningun resultado para  tu busqueda "<strong><i>'{{search_manually}}'</i></strong>"
                            entre los pedidos pendientes del pdv. <br/><br/>

                            Puedes editar manualmente la busqueda, o escanear para seguir buscando. 
                            <br/>
                            </ion-text>
                        </div>
                </ion-row>
                
                <ion-list v-if="showme && pedidos_filtrados.length >0 " class="ion-no-padding">       
                    <ion-item 
                        v-for="(proveedor,index) in pedidos_filtrados"
                        v-bind:key="`item-${index}`"
                        class="ion-no-padding ion-no-margin">        
                        <ion-row >    

                            <ion-col size-offset="1" size="47"  class="ion-no-margin  ion-no-padding">
                                <h3 @click = "ElementoStockSeleccionado(proveedor)" class="nombre_cortado" text-wrap>
                                    {{String(proveedor.codpro).padStart(5,'0')}} - {{proveedor.nombre}}
                                </h3>
                            </ion-col>
                            <ion-col size-offset="1" size="47"  class="ion-no-margin  ion-no-padding">
                                <h5 @click = "ElementoStockSeleccionado(proveedor)" class="full-h5"> 
                                    <span class="lote" >Pdv : {{ proveedor.codemp }} / Suc: {{proveedor.codsuc}}</span>
                                    <span class="lote forzar_derecha">Fecha: {{ $filters.num2Date(proveedor.fecped)   }}</span>
                                    
                                </h5>
                                <h5 @click = "ElementoStockSeleccionado(proveedor)" class="nombre_cortado" text-wrap >
                                    <span class="lote"><b>Pedido : {{ proveedor.numped  }}</b> 
                                            ( {{$filters.linesTag(proveedor.lins)}} )
                                    </span> 
                                    <span class="lote forzar_derecha">Importe: {{ $filters.Entero(proveedor.importe)  }} €</span>
                                </h5>
                            </ion-col>
    
                        </ion-row>
                    </ion-item>
                </ion-list>
            </ion-row>
        </ion-content>
    </ion-page>

</template>
    

<script>
    import { defineComponent } from 'vue';
    import { closeCircleSharp , homeSharp,  printSharp, thumbsDownSharp} from 'ionicons/icons';
    import { RegexBobinasPdv , transformSchneiderBrand, eans_articulo} from '@/utilities/scanner/scan-sku'
    import { useRoute } from 'vue-router';
    import {  partition, every, pluck } from 'underscore';
    import {scanGenericStart, scanGenericStop  } from '@/utilities/scanner/scan-generic'
    import eventBus from "@/event-bus"; 

    export default defineComponent({
            name : "SeleccionPedidosRecepcionComponent",
            data() { 
                return { 
                    element_id: 'seleccion-proveedor-recepcion',
                    pdv: undefined,
                    isIOS: undefined, 
                    search_manually : undefined, 
                    closeCircleSharp, homeSharp,  printSharp , thumbsDownSharp, 
                    limite:  100 , 
                    datos_tot: undefined , 
                    pedidos_todos : undefined, 
                    pedidos_filtrados: undefined , 
                    showme :false, 
                    ruta: undefined, 
                    qt :false , 
                    input_activo: false,
                    debounce_milis: 300 
                    }
            },
            props : ['codpro', 'pedidos_input'] , 
            created() {
                this.pdv =  this.$route.meta.pdv 
                this.isIOS = this.$route.meta.isIOS 
            },
            watch: {
                '$route.query.q'() { this.onCancel();}
                
            },
            updated() {
                this.pdv =  this.$route.meta.pdv 
            },
            async mounted() {
                this.pdv =  this.$route.meta.pdv 
                const route = useRoute();
                this.ruta = route.fullPath
                this.qt = route.query.q
                await this.fetchData()
                this.onCancel()
                await this.escaner_generico()
                
            },
            async unmounted() {
                await scanGenericStop(this.element_id)
            }, 
            methods: {
                async fetchData () { 
                    window.fake_console(this.pedidos_input)
                    let lista_pedidos_input = pluck(this.pedidos_input ,'numped')
                    const peds = !this.codpro  
                        ? await this.$http.post(`/get_purchase_orders/${this.pdv}`)
                        : await this.$http.post(`/get_purchase_orders_by_supplier/${this.pdv}/${this.codpro}`)
                    const pedidos = peds.data.reduce((m,v) => {
                        let tags = v['marcas'].concat(...v['sku']);
                        tags.push(...[v.codemp,v.codsuc,v.codpro, v.nombre,v.numped, v.pedcli ])
                        const eans = v['sku'].map(s => eans_articulo[s]||'')
                        tags.push(...eans)
                        v['tags'] = tags.join(' ').toLocaleLowerCase() 
                        m.push(v);
                        return m                        
                    },[])
                    window.fake_console(this.pedidos_input && this.pedidos_input.length >0, this.pedidos_input)
                    this.pedidos_todos = pedidos.filter(z=> lista_pedidos_input.indexOf(z.numped)==-1)
                    return 
                }, 
                getFoco (ev, si) {
                    window.fake_console('evento escuchado')
                    if(si===true) {
                        this.input_activo = true; 
                        window.fake_console('activado')
                    }
                    else {
                        this.input_activo=false; 
                        window.fake_console('desactivado')
                    }
                },
                goBack() {
                    this.$router.go(-1)
                },


                async lectorEventosGenericos() {
                    let self = this; 
                    eventBus().emitter.off(`lectura_generica:${this.element_id}`)
                    eventBus().emitter.once(`lectura_generica:${this.element_id}`,async (input_scaner)=>{
                       self.input_activo = false;
                       let buscar = input_scaner
                       /** miramos si el termino tiene includo un codigo de bobina , en cuyo caso lo eliminamos */
                       if ( RegexBobinasPdv.test(input_scaner) && input_scaner.replace(RegexBobinasPdv,'').length > 2)
                            { 
                                buscar = input_scaner.replace(RegexBobinasPdv,'')
                                window.fake_console(input_scaner, buscar)
                            }
                       self.search_manually = buscar
                       self.filtrar('Via lector Generico' , 'exacta') 
                       self.lectorEventosGenericos()
                    })
                },
                async escaner_generico() {
                    await scanGenericStart(this.element_id, 'desde escaner_generico')
                    await this.lectorEventosGenericos()
                },
                async filtroInputSearch() {
                    window.fake_console(this.input_activo)
                    if (this.input_activo) {
                        this.filtrar('Caja de busqueda via filtroInputSearch')
                    }
                }, 
                onCancel(){
                    this.showme = false
                    this.pdv =  this.$route.meta.pdv 
                    window.fake_console('en el cancel ')
                    this.search_manually = undefined; 
                    const d = this.pedidos_todos
                    this.datos_tot = d.length||0 
                    window.fake_console(this.datos_tot,d.slice(0,5))
                    this.pedidos_filtrados = d.slice(0,this.limite)
                    this.showme = true 
                    },

                async filtrar (comentario) {
                    window.fake_console('COMENTARIO :', comentario, this.input_activo)
                    window.fake_console(this.search_manually)
                    const terminos = transformSchneiderBrand(this.search_manually)
                    if (terminos === false ) {
                        this.pedidos_filtrados= this.pedidos_todos
                        }
                    else {

                        const primarySearch = new RegExp(`(${terminos.toLowerCase().replace(/ +$/,'').replace(/ +/g,'.*')})`, 'i')
                        const secondarySearch = terminos.toLowerCase().replace(/ +$/,'').split(' ').map(v => new RegExp(`${v}`,'i'))
                        const [primario,resto] = partition(this.pedidos_todos, v=>primarySearch.test(v.tags.toLocaleLowerCase()))
                        //en segundo lugar desordenados 
                        const secundario =  resto.filter(v=>every(secondarySearch, t => t.test(v.tags.toLowerCase())))                    
                        this.pedidos_filtrados= primario.concat(secundario)||[]
                    }
                    
                    this.datos_tot = this.pedidos_filtrados.length 
                    this.pedidos_filtrados = this.pedidos_filtrados.slice(0,this.limite)
                    this.showme = true
                    
                }, 
                async ElementoStockSeleccionado(item) {
                    let tipo = this.codpro ? 'pedido-adicional' : 'proveedor-recepcion'
                    window.fake_console(item)
                    if (item)
                    {
                        const proveedor = item.codpro 
                        const numero_pedidos_proveedor = this.pedidos_todos.filter(x=>x.codpro==proveedor).length-1
                        item.numero_pedidos_proveedor = numero_pedidos_proveedor
                        eventBus().emitter.emit(tipo, item)
                    }
                    else {
                        eventBus().emitter.emit(tipo, undefined)
                    }
                 }

            },    
        })
</script>

<style scoped lang="scss">
    @import "@/theme/footer-styles.scss";
    @import "@/theme/seleccion-pedido-styles.scss";
</style>