/**
    Seleccion expecidiones 
    Listado de las envios generadas y aun no expedidas a cliente 
 */

<template>
    <ion-page  >
        <ion-header>
            <ion-toolbar   color="dark" class="ion-justify-content-start ion-align-items-start"> 
                <ion-buttons slot="start" >
                    <ion-button size="default" color="light"  routerLink="/expediciones" routerDirection="none">
                        <i class="fa-sharp fa-solid fa-circle-left muy-grande" color="light" ></i>
                    </ion-button>
                </ion-buttons>
                <ion-title>
                    <ion-row  class="ion-justify-content-start ion-align-items-start">
                        <ion-col size="40"  class="ion-float-left izquierda" 
                            :style="isIOS ? 'padding-left: 4rem !important;': ''">
                            Cargar Camion {{pdv}} {{mis_pdvs}} ({{vehiculo_actual}})
                        </ion-col>
                    </ion-row>                
                </ion-title>
                <i class="fas fa-solid fa-person-shelter fa-fade en-header" v-if="EnPdv===true"></i>
                <i class="fas fa-solid fa-house-person-leave fa-fade en-header" v-else></i>

            </ion-toolbar>
        </ion-header>


        <ion-content class="ion-padding" key="`content:${qt}`"> 

            <ion-fab vertical="top" horizontal="end" slot="fixed" >
                    <ion-fab-button  :key="`scan-activo:${blinker}`"
                        :class="blinker ? 'activo' : 'inactivo'">
                        <i class="fas fa-solid fa-barcode-read muy-grande" :class="blinker ? 'blink' : 'not-blink'" :key="`ion-scan:${blinker}`"  ></i>
                    </ion-fab-button>
            </ion-fab>
            <ion-fab vertical="bottom" horizontal="end" edge="true" slot="fixed" >
                    <ion-fab-button  :color="lecturas_pendientes == 0 ? 'darksuccess' : 'darkdanger' " @click="finCarga()">
                        <i class="fas fa-sharp fa-dolly  crossdock blanco"/>
                    </ion-fab-button>
                    
            </ion-fab>


            <ion-row >
                <ion-col size="48">
                    <ion-button expand="block" fill="outline" size="large"  class="trigger_scan" >
                            <ion-title> 
                            <ion-text class="ion-text-wrap estrecho">
                                Escanea un envio para añadirlo al camion.<br/>
                                Debes escanear todos los bultos para poder confirmar la carga. 
                            </ion-text>
                        </ion-title> 
                    </ion-button>
                </ion-col>                   
            </ion-row>


            <ion-row >
                <ion-list v-if="showme  && datos.length>0" class="ion-no-padding" 
                    ref="ion_item_options_slider"
                    >       
                    <ion-list-header class="head"  style="width:100% !important;">
                        <ion-row>
                            <ion-col size="48">
                                <ion-text class="fake-titulo"> Nueva carga ({{vehiculo_actual}})</ion-text>
                            </ion-col>  
                        </ion-row>
                    </ion-list-header >
                        <ion-item-sliding  v-for="(envio,index) in datos" v-bind:key="`data-item-${index}`"  >
                                <ion-item  class="ion-no-padding ion-no-margin"
                                    :class="envio.situa==1 ? 'iniciado' : ''">        
                                    <ion-row  @click="irEnvio(envio)">    
                                        <ion-col size-offset="1" size="36"  class="ion-no-margin  ion-no-padding">
                                            <h3 class="nombre_cortado" text-wrap>
                                                {{String(envio.codemp)}}.{{String(envio.codcli)}} - {{envio.nomcli}}
                                            </h3>
                                        </ion-col>
                                        <ion-col size="12" class="ion-no-margin  ion-no-padding">
                                            <i v-for="(blt, index) in sortBy(envio.bultos_checked, (v,k)=> -k)" v-bind:key="`blt-${index}`" 
                                                :class="`fas fa-${blt==true ? 'square-xmark' : 'square-check'}  ${blt==true ? 'verde' :'rojo'} checks `
                                            "/>
                                        </ion-col>
                                        
                                        <ion-col size-offset="1" size="48"  class="ion-no-margin  ion-no-padding">
                                            <h5 class="full-h5 intermedio"> 
                                                <span class="lote oscuro"><b><small>Nomenv : </small>
                                                    {{ envio.nomenv  }}
                                                    </b> 
                                                </span> 
                                                <span class="lote peque forzar_derecha">Importe: {{ $filters.Entero(envio.valor)  }} €</span>
                                            </h5>
                                            <h5 class="nombre_cortado" text-wrap >
                                                <span class="lote oscuro">
                                                    <small>
                                                        <b>{{ envio.cpenv||''}} - {{envio.pobenv}}</b>
                                                    </small>
                                                </span> 
                                                <span class="lote peque forzar_derecha" >Fecha: {{ $filters.num2Date(envio.fecenv)   }} </span>
                                            </h5>
                                        </ion-col>

                                        <ion-col size-offset="1" :size="envio.ubicacion ? 33 :48 "  class="ion-no-margin  ion-no-padding">
                                            <h5 class="full-h5 intermedio" text-wrap >
                                                <span class="lote oscuro"><b><small>Albaran. : </small>{{envio.albaranes}}</b></span>
                                            </h5>
                                        </ion-col>

                                        <ion-col  size="15"  class="ion-no-margin  ion-no-padding" v-if="envio.ubicacion">
                                                <h3 class="full-h5 sin-margen" >
                                                <span class="lote forzar_derecha primario">
                                                    <b>{{envio.ubicacion}}</b>
                                                </span>
                                            </h3>
                                            
                                        </ion-col>
                
                                    </ion-row>
                                </ion-item>

                            <ion-item-options side="end">            
                                <ion-item-option color="danger" @click="eliminar_carga(envio)" v-if="envio.situa==0">
                                        <i class="far fa-sharp fa-trash-can muy-grande" slot="bottom"></i> 
                                    <span class="boton-opciones">Borrar</span>
                                </ion-item-option>
                            </ion-item-options>
                        </ion-item-sliding>
                    </ion-list> 
                
                    <ion-list v-else  class="ion-no-padding" 
                        ref="ion_item_options_slider"
                        >       
                        <ion-list-header class="head"  style="width:100% !important;">
                            <ion-row>
                                <ion-col size="48">
                                    <ion-text class="fake-titulo"> Nueva carga vehiculo {{vehiculo_actual}}</ion-text>
                                </ion-col>  
                            </ion-row>
                        </ion-list-header>
                        <ion-item>
                            <ion-row no-padding no-margin  no-lines >
                                    <ion-col class="centro">
                                        <ion-text >No hay envios nuevos que añadir a la carga del vehiculo</ion-text>
                                    </ion-col>
                            </ion-row>      
                        </ion-item>
                    </ion-list>                  
                </ion-row> 
        </ion-content>

        <ion-tab-bar slot="bottom" color="dark" disabled>

            <ion-tab-button  disabled >
                <i class="fak fa-regular-truck-circle-question tiempo"></i>
                <ion-label>Cambiar<br/>Cambion</ion-label>
            </ion-tab-button>

            <ion-tab-button  disabled >
                <i class="fas fa-sharp fa-truck-ramp-box tiempo"/>
                <ion-label>Carga<br/>Actual</ion-label>
            </ion-tab-button>
            
            <ion-tab-button disabled>
                <i class="fas fa-route tiempo"/>
                <ion-label>Reparto</ion-label>
            </ion-tab-button>
            
            <ion-tab-button disabled>
                <i class="fak fa-sharp-regular-house-circle-check tiempo"/>
                <ion-label>Entregado</ion-label>
            </ion-tab-button>
            
            <ion-tab-button  selected>
                    <ion-label class="fab-in-tab">Fin Carga<br/>Camion</ion-label>
            </ion-tab-button>


        </ion-tab-bar>
        
        
    </ion-page>
</template>

<script>
    import { defineComponent } from 'vue';
    import { scanGenericStart, scanGenericStop  } from '@/utilities/scanner/scan-generic'
    import { esEnvio  } from '@/utilities/global-constants';
    import { openAlertaV2 , openAlertaBinaria } from  '@/utilities/services/alert-utilities';
    import { loadStorage } from '@/utilities/storage/storage-utilities-main'
    import { sortBy  } from 'underscore';
    import { ubicaciones_entrega } from '@/utilities/global-constants';
    import { pdvDistance}  from '@/utilities/location'
    import { useRoute } from 'vue-router';
    import eventBus from "@/event-bus"; 

    export default defineComponent({
            name : "ExpedicionesCargarCamionPage", 
            props : ['envio_inicial'],
            data() {
                return {
                    ubicaciones_entrega, sortBy, 
                    pdv : this.$route.meta.pdv, 
                    isIOS: undefined, 
                    cargando: false, 
                    nompos : this.$route.meta.nompos, 
                    element_id : 'seleccion_expediciones_carga', 
                    clave: undefined  ,  
                    showme : false, 
                    datos: [], 
                    lecturas_pendientes : 0, 
                    vehiculo_actual : undefined, 
                    blinker : false , 
                    qt: false , 
                    pdv_geoloc : this.$route.meta.pdv_geoloc , 
                    ubiku_rol: this.$route.meta.ubiku_rol, 
                    mis_pdvs: this.$route.meta.mis_pdvs, 
                    EnPdv : undefined

                }
            }, 
            async created() {
                this.pdv =  this.$route.meta.pdv ;
                this.nompos =  this.$route.meta.nompos ;
                this.pdv_geoloc =  this.$route.meta.pdv_geoloc 
                this.ubiku_rol =  this.$route.meta.ubiku_rol 
                this.mis_pdvs = this.$route.meta.mis_pdvs
                this.isIOS = this.$route.meta.isIOS 
                window.fake_console(this.mis_pdvs)
            },
            async mounted() { 
                this.$NoBackNavigator()
                const route = useRoute();
                this.qt = route.query.q||Date.now()
                this.EnPdv = await pdvDistance(this.$geofence, this.pdv_geoloc, true ) === true||undefined 
                this.vehiculo_actual = await loadStorage('vehiculo')
                await this.verificacion_vehiculo ()
                await this.escaner_generico()
                if (this.envio_inicial){
                    eventBus().emitter.emit(`lectura_generica:${this.element_id}`, this.envio_inicial)
                }
            }, 
            async unmounted() {
                await this.pararScanner()
            },  
            methods: {     

                async verificacion_vehiculo (){

                    /** CONTROL VEHICULO ACTUAL SELECCIONADO */
                    if (!this.vehiculo_actual) {
                        await openAlertaV2('Camion no seleccionado' , `No hay un vehiculo seleccionado. Te redirigimos a la pagina principal para que lo elijas.`)
                        this.$router.replace('/expediciones')
                    }
                    /** CONTROL POSICIÓN GEO EN PDV?  */
                    const enPdv = await pdvDistance(this.$geofence, this.pdv_geoloc, true )||undefined 
                    if (!enPdv && this.ubiku_rol< 5) {
                        await openAlertaV2('Operación no permitida' , `Parece que no estas en el punto de venta.<br/>La carga solo puede realizarse cuando estas en el pdv.`)
                        this.$router.replace('/expediciones')
                    }
                    else if (!enPdv) {
                        /* Cambio de enfoque en función del perfil que tenga el usuario */
                        const confirmacion = await openAlertaBinaria('Nota ' , `Estas fuera de tu pdv, seguro que necesitas cargar envios??<br/>
                            Si es así confimar. En caso contrario cancela`, 'Cancelar','Confirmar')
                        if (confirmacion !== true){
                            this.$router.replace('/expediciones')
                        }
                    }
                    /** CONTROL DEL ESTADO DEL CAMION  */
                    const status_camion = await this.$http.post(`/status_transport/${this.pdv}`, {vehiculo : this.vehiculo_actual})
                    const dat = status_camion.data
                    const { estado , email, nomusu } = dat
                    /** Lo tiene bloqueado un chofer */
                    if ((estado||0) === 1 && (email||this.$current_user) != this.$current_user){
                        await openAlertaV2('Camion Bloqueado' , `El vehiculo ${this.vehiculo_actual} esta bloqueado por ${nomusu||this.$current_user_name}.<br/> 
                            No es posible cargar el vehiculo`)
                        this.$router.replace('/expediciones')
                    }
                    /** Lo tiene bloqueado el chofer actual, puede descartar o seguir y cierra la ruta  */
                    if (estado === 1 && email == this.$current_user ){
                        const camion_con_reparto =  await openAlertaBinaria('Camion con reparto pendiente', 
                                `El camión tiene repartos repartos pendientes, esta bloqueado por tí mismo. <br>
                                Puedes cancelar la operación o seguir. Si sigues, los repartos pasarán a constar como carga del camion. 
                                `, 'Cancelar', 'Seguir')
                        window.fake_console(camion_con_reparto)    
                        if (camion_con_reparto ) {
                            await this.cerrarRepartos()
                            this.refrescar()

                        }
                        else  {
                            this.$router.replace('/expediciones')
                        }   
                    }



                },
                async cerrarRepartos(){
                    try {
                            const payload = {   vehiculo : this.vehiculo_actual };
                            const actualizacion = await this.$http.post(`/unset_expedicion/${this.pdv}`, payload)
                            window.fake_console(actualizacion.data)
                            if(actualizacion.data.status === 0  ){
                                await openAlertaV2(
                                    'Error en el cierre del reparto ', 
                                    `No hemos podido realizar la operacion, error:\n${actualizacion.data.alert} `
                                )
                                }
                            else {
                                const desbloqueo_camion = await this.desbloquearCamion()
                                if (desbloqueo_camion) {
                                    await openAlertaV2(
                                        'Error en el desbloqueo del camion ', 
                                        `Hemos anulado el reparto pero ha habido un error al desbloquear el camion `
                                    )
                                }
                                await openAlertaV2(
                                    `Reparto ${this.vehiculo_actual} cerrado`, 
                                    `${actualizacion.data.alert}`
                                )
                            }
                    }
                    catch (ex) {
                        window.fake_console(ex)
                    }
                },
                async desbloquearCamion( ){
                        try {
                            const payload = {vehiculo : this.vehiculo_actual, nomusu: this.$current_user_name }
                            await this.$http.post(`/unlock_transport/${this.pdv}`, payload)
                            return undefined 
                        }
                        catch (ex) {
                            return ex 
                        }

                }, 


                async finCarga() {
                    if (this.datos.length == 0 ) {
                        this.$router.replace('/expediciones')
                    }
                    else if (this.lecturas_pendientes !=0){
                        await openAlertaV2('Envios incompletos' , `Hay envios en los que no has escaneado todos los bultos. \n\n Para poder finalizar debes escanearlos todos o eliminar el envio de la carga`)
                        return 
                    }
                    else {
                        try  { 
                            const no_procesados = await this.datos.reduce(async (memo,vx)=> {
                                    const mx = await memo 
                                    const payload = {codexp: vx.ms_uuid , vehiculo: this.vehiculo_actual,nuevo_estado : 1 }
                                    const respuesta = await  this.$http.post(`/update_envio_carga/${this.$pdv}`, { ...payload })    
                                        if (respuesta.data[0].status !==1){ mx.push(vx) }
                                        return mx 
                                }, [])
                                this.datos = no_procesados; 
                                if (this.datos.length == 0){
                                    await openAlertaV2('Carga realizada' , `Todos los envios han sido traspasados al camion, te redirigimos a expediciones`)
                                    this.$router.replace('/expediciones')
                                }
                                else {
                                    await openAlertaV2('Carga con errores realizada' , `Algunos envios no han podido cargarse, revisalos por favor`)
                                    this.refrescar()

                                }
                            }
                        catch (ex) {
                            window.fake_console(ex)
                            window.miconsola('Error : ', ex)
                        } 
                    }
                }, 
                async pararScanner () {
                    await scanGenericStop(this.element_id)
                    this.blinker = false;
                }, 
                async refrescar(){
                    this.showme = false; 
                    this.showme = true; 
                }, 
                async recuperar_envio(params){
                    const {ms_uuid, bultos, bl} = params
                    try {             
                        const recep = await this.$http.post(`/check_envio/${this.pdv}`, {ms_uuid})
                        window.fake_console(recep.data)
                        if (!recep.data || recep.data.length ==0) {
                            throw `No hemos podido encontrar el envio ${ms_uuid}`
                        }
                        const envi = recep.data[0]
                        if (envi.envio_tipo!='REPARTO') {
                            throw `El envio ${ms_uuid} no corresponde a un reparto, consta como "${this.ubicaciones_entrega[envi['envio_tipo']]}"`
                        }
                        if (envi.situa!=0) {
                            throw `El envio ${ms_uuid} parece que ya ha sido expedido o entregado anteriormente`
                        }

                        envi.bultos_checked = [...Array(envi.bultos).keys()].reduce((m,y)=> {
                            m[y+1]= y+1==bl; 
                            return m 
                        }, {})

                        if (bultos != envi.bultos) {
                            await openAlertaV2('Envio inconsistente' , `El número de bultos no coincide con el de la creación del envio para el envio : ${ms_uuid}`)
                            return 
                        }
                        this.datos.unshift(envi)                        
                    }
                    catch (ex) {
                        console.log(ex)
                        await openAlertaV2('Envio erroneo' , ex)
                    }     
                    return       
                }, 

                async eliminar_carga(envio) {
                    const { ms_uuid} = envio; 
                    this.datos = this.datos.filter(x => x.ms_uuid !=ms_uuid)
                    await this.escaner_generico()
                }, 
                
                async lectorEventosGenericos() {
                    let self = this; 
                    const d = new Date();
                    d.setDate(d.getDate() - 90);
                    const corte = parseInt(d.toISOString().replace(/-/g,'').slice(0,8))
                    eventBus().emitter.off(`lectura_generica:${this.element_id}`)
                    eventBus().emitter.once(`lectura_generica:${this.element_id}`,async (input_scaner_in)=>{
                       /** es de mi pdv  */

                        try {
                            const {ms_uuid, bultos, bl } = JSON.parse(input_scaner_in)
                            window.fake_console({ms_uuid, bultos, bl })
                            const dat = ms_uuid.match(esEnvio).groups
                            void ({dat, bultos, bl})
                        }
                        catch {
                            await openAlertaV2('Envio erroneo' , `
                                    La lectura no corresponde a un envio.<br/>
                                    Recuerda que en esta pantalla cargamos el camión, para ello es necesario tener envios y sus etiquetas.`
                                )
                                await self.escaner_generico()
                                return 
                        }   

                       const {ms_uuid, bultos, bl } = JSON.parse(input_scaner_in)
                       const dat = ms_uuid.match(esEnvio).groups

                       /*antes 
                       if (dat.codemp === self.pdv && dat.fecenv >= corte){
                        */
                       if (dat.fecenv >= corte){
                            if ( dat.codemp !== self.pdv && (self.mis_pdvs!='000' && self.mis_pdvs.indexOf(dat.codemp) === -1 ) ) {
                                const confirmacion = await openAlertaBinaria('Nota ' , `Este envio no es de tu pdv.<br/>
                                    Seguro que quieres cargarlo??`, 'Cancelar','Confirmar')
                                if ( confirmacion !== true ){
                                    this.$router.replace('/expediciones')
                                }
                            }
                            const existe = this.datos.reduce((m,x,i)=> { m = x.ms_uuid== ms_uuid ? i : (undefined||m) ; return m  } ,undefined)
                            if (existe != undefined){
                                this.datos[existe].bultos_checked[bl]= true
                            }
                            else {
                                await this.recuperar_envio({ms_uuid, bultos, bl})
                            }
                            this.lecturas_pendientes = this.datos.reduce( (m,v)=> { 
                                const falsos = Object.values(v.bultos_checked).filter(y=>y===false).length
                                return m+=falsos
                            },0)
                        }
                       else {
                        await self.pararScanner()
                        await openAlertaV2('Envio erroneo' , `
                            La lectura facilitada no corresponde a un envio expedible`
                            )
                       }
                      await self.escaner_generico()
                    })
                },
                async escaner_generico() {
                    window.fake_console('activado escaner')
                    /** Hacemos reset del scanner cada vez que entramos en el campo  */
                    await scanGenericStop(this.element_id)
                    this.blinker = true
                    await scanGenericStart(this.element_id, 'desde escaner_generico')
                    this.blinker = true
                    this.refrescar()
                    await this.lectorEventosGenericos()
                },
  


            }, 
            computed :{
                largo: function () {
                    return this.datos.length
                    }
            }
            
 
    })
</script>
 

<style scoped  lang="scss">
    @import "@/theme/seleccion-pedido-styles.scss";

    ion-label.fab-in-tab {
        padding-top:1.5rem !important;
        font-weight: 900 !important;
    }
    ion-tab-bar {
        height: 5rem !important; 
    }
    ion-segment {
        width: 100% !important; 
    }
    ion-segment-button.todas {
        width: 20% ª!important; 
    }
    span.boton-opciones {
        font-size: 1.05rem !important; 
        padding-bottom: 0.3rem !important; 
        font-weight: bold !important;
        text-align: center !important;
    }
    ion-row {
        max-width: 600px !important; 
        padding: 0.3rem 0.2rem !important; 
    }
    ion-item {
        --inner-padding-end : 0px !important; 
        --padding-end : 0px !important; 
    }
    ion-text.fake-titulo {
        color: var(--ion-color-dark) !important; 
        text-align: center !important;
    }
    span.oscuro {
        color: var(--ion-color-medium) !important; 
        padding-left: 0.3rem !important; 
    }
    span.primario {
        color: var(--ion-color-primary) !important; 
        padding-left: 0.3rem !important; 
    }
    span.peque {
        font-size: smaller !important; 
        padding-right: 0.3rem !important; 
    }
    i.fas.tiempo , i.fak.tiempo , i.far.tiempo {
        color: dark; 
        font-weight: 600 important; ;
        font-size: 1.8rem !important;
        padding-right: 0.4rem !important
    }

    ion-item.iniciado {
        border-right: 10px  solid !important;
        border-image: linear-gradient(to bottom, white, var(--ion-color-darkdanger), white) 2;
    }
    .sin-margen {
        margin-top: 0px !important; 
    }

    i.crossdock {
        position:absolute;
        top:50%;
        left:50%;
    }
    
    i.blanco {
        color: var(--ion-color-blanco) !important;
        font-size: xx-large !important; 
        font-weight: 300 !important;
        transform:translate(-50%,-50%)

    }

    i.fas.checks{
        display: inline-flex !important; 
        font-size: 1.5rem !important; 
        padding: 0.15rem !important;
        float: right !important;
    }

</style>