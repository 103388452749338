
<template>
    <ion-page >
        <CabeceraComponentBack  
            :pdv = "pdv"
            :isIOS = "$route.meta.isIOS"
            titulo="Preparar Pedido Mostrador"
            color="primary"
            :previo="`pedidos-cliente/${cliente}`"

        />
        <ion-content class="ion-padding" v-if="cliente" >                   

            <ion-row >
                    <ion-col size="10">
                        <ion-button class="alternativa impresion" fill="solid" expand="block" color="primary" @click="void(0)">
                            <i class="fas fa-refresh blanca">
                                <ion-text class="ion-text-wrap en-botton">
                                    Refrescar
                                 </ion-text>
                            </i> 
                        </ion-button>       
                    </ion-col>
                    <ion-col size="38">
                        <ion-button expand="block" fill="outline" size="large"  class="trigger_scan" >
                                <ion-title>Preparación Pedido
                                <br/> 
                                <ion-text class="ion-text-wrap estrecho">
                                    <b>{{pedido}}</b>
                                </ion-text>
                            </ion-title>
                        </ion-button>
                    </ion-col>

                </ion-row>


                <ion-row >
                    <ion-list  class="ion-no-padding"  v-if="showme && datos && datos.length>0" >       
                        <ion-list-header class="head"  style="width:100% !important;">
                            <ion-row>
                                <ion-col size="48">
                                    <ion-text class="fake-titulo"> Lineas pendientes pedido  </ion-text>
                                </ion-col>
                            </ion-row>
                        </ion-list-header>

                    <!--selector de zonas --> 
                    <ion-row >
                        <ion-col size="12" class="ion-no-padding ion-no-margin"  :key="`zn-${zona}`"> 
                            <ion-button :color="zona!='todas' ? 'primary' : 'darkdanger'" size="small"  class="zonas"  @click="setZona('todas')">
                                Todas
                            </ion-button>
                        </ion-col>
                        <ion-col  size="12" 
                            v-for="zon in zonas"  :key="zon[0]"
                            class="ion-no-padding ion-no-margin">  
                            <ion-button :color="zona==zon[0] ? 'darkdanger' : 'secondary'" fill="solid" size="small" :value="zon[0]"  class="zonas" @click="setZona(zon[0])">
                            {{zon[0]=='undefined' ? 'NOUBI' : zon[0]}} ({{zon[1]}})
                            </ion-button>
                        </ion-col>

                    </ion-row>  
                        <ion-item v-for="(product,index) in filtrados"
                            v-bind:key="`item-${index}`"
                            class="ion-no-padding ion-no-margin">        
                            <ion-row >    
                                    <ion-col size-offset="1" size="47"  class="ion-no-margin  ion-no-padding">
                                        <h3 @click="IrADetalleProducto(product)" > {{ $filters.showSku(product.sku)   }}
                                            <span class="lote forzar_derecha" :class="product.hay_stock =='total' ? 'verde' : product.hay_stock =='parcial' ? 'naranja' : 'rojo'">
                                                Listo: {{ $filters.Initcap(product.hay_stock||'')   }}
                                            </span>
                                        </h3>
                                        <h4 @click = "IrADetalleProducto(product)" class="nombre_cortado" text-wrap>
                                            {{product.desart}}
                                        </h4>
                                    </ion-col>

                                    <!-- RESULTADOS DESDE EL STOCK -->
                                    <ion-col size-offset="1" size="30"  class="ion-no-margin  ion-no-padding">
                                        <h5 @click="IrADetalleProducto(product)" class="full-h5"> 
                                            <span class="lote" >Suc : {{ product.codsuc   }}</span>
                                            <span class="lote forzar_derecha" v-if="product.ubi">Ubic : {{ product.ubi   }}</span>
                                            <span class="lote forzar_derecha rojo" v-else>No Ubicado</span>
                                        </h5>
                                        <h5>
                                            <span class="lote primario" >Dispo<sup>*</sup> : {{ $filters.Entero(product.dispo+product.unipen)}}</span>
                                        </h5>
                                    </ion-col>
                                    <ion-col size="11"  class="ion-no-margin  ion-no-padding ion-text-end">
                                        <ion-text  @click = "IrADetalleProducto(product)" class="stock" text-wrap>
                                            <span class="stock"><b>{{$filters.Entero(product.unipen)}}</b></span>
                                        </ion-text>
                                        <ion-text @click = "IrADetalleProducto(product)" class="mini-lista" text-wrap>
                                        {{product.done}} {{product.unimed}}
                                        </ion-text>
                                    </ion-col>
                                    <ion-col size="6"  class="ion-no-margin  ion-no-padding ion-text-end  ion-align-items-baseline" @click="checkLinea(product)">
                                            <ion-icon :icon="product.done ? checkbox : squareOutline" 
                                                class="muy-grande carro"
                                                :color="product.done ? 'darksuccess' : 'secondary'"> </ion-icon>
                                    </ion-col>
                        
                                </ion-row>
                            </ion-item>
                    </ion-list>


                    <ion-item v-else>
                        <ion-row no-padding no-margin  no-lines >
                                <ion-col class="centro">
                                    <ion-label >No hay articulos pendientes en el pedido </ion-label>
                                </ion-col>
                        </ion-row>      
                    </ion-item>

                </ion-row>
        </ion-content>
     
     

     
     

    </ion-page>
</template>

<script>
    import CabeceraComponentBack from '@/components/CabeceraComponentBack';
    import { defineComponent } from 'vue';
    import { compact, chain } from 'underscore';
    import { loadMostradorStorage ,delMostradorStorage , saveMostradorStorage, FlagPedidoMostradorStorage  } from '@/utilities/storage/storage-mostrador'
    import { checkbox, squareOutline} from 'ionicons/icons';

    export default defineComponent({
            name : "PrepararPedidoMostradorPage",
            components: { CabeceraComponentBack  } , 
            props:['cliente','origen', 'pedido'],
            data() {
                return {
                    checkbox, squareOutline, 
                    element_id : 'preparar_pedidos_mostrador', 
                    datos: [], 
                    filtrados : undefined , 
                    pdv: undefined, 
                    nomcli: undefined, 
                    zonas_preparacion : undefined,
                    zona: 'todas',  
                    showme : false  
                }
            }, 
            async created() {
                this.pdv =  this.$route.meta.pdv 
                this.nomcli = this.$route.meta.nombre_cliente
                window.fake_console('listado de mostrador: entro en created', this.$route.meta.pdv, this.cliente)
            },
            async mounted() { 
                window.fake_console(this.datos)
                window.fake_console('mounted', this.cliente)
                await this.inicializar()
            },      

            methods: {     
                async recuperar_bbdd() {
                        const ruta_pedido= this.pedido.replace(/\./g,'/')
                        const pedido_bbdd = await this.$http.post(`/get_detalle_pedido_cliente_pendiente/${ruta_pedido}`)
                        const dt = pedido_bbdd.data
                        const dat = dt.reduce( (m,x) => {
                            const {codemp, codsuc, numped, numlin } = x ;
                            const clave = `${codemp}.${codsuc}.${numped}.${numlin}`;
                            x.fila = clave; 
                            x.done = false;
                            m.push(x)
                            return m
                            }, [] ); 
                        this.zonas = chain(dat).map( x => x.ubi ? x.ubi.slice(0,3)  : undefined ).countBy(x =>x  ).map((v,k) => [k,v]).sortBy(x=>-x[0]).value()
                        return (dat && dat.length>0) ? dat : undefined 
                }, 
                async inicializar(){
                    let lista_previa = []; 
                    const stgd = await loadMostradorStorage(this.pedido,  this.pdv)
                    const stg = compact(stgd||[])
                    /** situacion pedidos en memoria  */
                    const antiguos = stg.reduce((m,v)=> {
                        const {fila, done } = v 
                        m[fila] = done ;
                        return m 
                    }, {})
                    /** 
                     * Si venimos del listado de pedidos, actualizamos fusionando ambos 
                     * 
                     * */
                    if (this.origen == 'pedidos_mostrador') { 
                        const prelim_data = await this.recuperar_bbdd() 
                        lista_previa = prelim_data.map( x=>{
                            const fl = x.fila; 
                            x['done']    = antiguos[fl]||false; 
                            return x 
                        } )
                    }
                    /** Sino asociamos lo que tenemos en la memoria , y si no existiese recuperamos de base de datos  */
                    else {
                        lista_previa = stg
                    }
                    await saveMostradorStorage(this.pedido, this.$pdv, lista_previa)
                    this.datos = lista_previa 
                    this.filtrar()
                },
                async checkLinea(item) {
                    this.showme = false;
                    item.done = !item.done ; 
                    const nueva = await FlagPedidoMostradorStorage (this.pedido, this.pdv, item)
                    this.datos = nueva; 
                    await this.filtrar()
                    this.showme = true; 
                },
                async setZona(zn) {
                    this.zona = zn; 
                    this.filtrar()
                }, 
                async filtrar() {

                    this.showme = false; 
                    if (this.zona == 'todas') {
                        this.filtrados = this.datos 
                    }
                    else if (this.zona =='undefined' || !this.zona )
                    {
                        this.filtrados = this.datos.filter (x => !x.ubi || x.ubi == null  )
                    }
                    else 
                    {
                        this.filtrados = this.datos.filter(x => (x.ubi||'').startsWith(this.zona))
                    } 
                    this.filtrados.sort( function(a,b) {
                        const a1 = a.done==true ? 'Z' : 'A';
                        const b1 = b.done==true ? 'Z' : 'A';
                        const a2 = (a.ubi||'NOUBI').toString()
                        const b2 = (b.ubi||'NOUBI').toString()
                        /** primero ordenamos por pendientes / hecho, después por zona ascendente */
                        return a1.localeCompare(b1)||a2.localeCompare(b2)
                    })   
                    this.showme = true;                 

                },


                async IrADetalleProducto(item) {
                    window.fake_console(this.$router)
                    window.fake_console(item)
                    this.$router.replace({'name' :'detalle_producto', params : { sku: item.sku , origen: `/preparar-pedido-mostrador/${this.cliente}/${this.pedido}`}})    
                        window.fake_console(item)
                 },
               



                async DelAll(){
                    await delMostradorStorage(this.pedido, this.$pdv)
                    this.datos = [];
                },


                async back2CustomerList() {
                    this.$router.replace({'name' : 'pedidos_cliente'  , params : {'cliente': this.cliente,  'origen':'lista-clientes' }})
                }
            }
 
    })
</script>

<style scoped  lang="scss">
    @import "@/theme/seleccion-pedido-styles.scss";
    @import "@/theme/botones-etiqueta.scss";
    span.boton-opciones {
        font-size: larger !important; 
        padding-bottom: 0.3rem !important; 
        font-weight: bold !important;
    }
    ion-badge {
        width: 100% !important; 
    }
    ion-row.altos {
        height: 3rem !important;
        
    }
    ion-text.en-botton {
        font-family: 'Roboto';
        font-size: 0.9rem !important; 
        color: var(--ion-color-blanco)  !important;
        text-transform: none !important;
        line-height: 0.4rem !important;
    }
    i.fas.blanca {
        font-size: 2rem !important; 
        color: var(--ion-color-blanco)  !important;
        line-height: 1rem !important; 
        }
    ion-row {
        max-width: 600px !important; 
        padding: 0.3rem 0.2rem !important; 
    }

    ion-item {
        --inner-padding-end : 0px !important; 
        --padding-end : 0px !important; 
    }
    ion-text.fake-titulo {
        color: var(--ion-color-primary) !important; 
    }
    span.oscuro {
        color: var(--ion-color-medium) !important; 
        padding-left: 0.3rem !important; 
    }
    span.primario {
        color: var(--ion-color-primary) !important; 
    }
    span.peque {
        font-size: smaller !important; 
        padding-right: 0.3rem !important; 
    }
    i.fas.tiempo {
        color: red; 
        font-weight: 600 important; ;
        font-size: 1.8rem !important;
        padding-right: 0.4rem !important
    }

    ion-item.iniciado {
        border-right: 10px  solid !important;
        border-image: linear-gradient(to bottom, white, var(--ion-color-darkdanger), white) 2;
    }

    ion-icon.crossdock {
        font-size: xx-large !important; 
        font-weight: 1000 !important;

    }
    .margen-down {
        margin-bottom: 0.3rem !important; 
    }

    ion-button.zonas {
        width: 100% !important;
        margin: 0.1rem !important; 
        padding: 0.2rem !important; 
        
    }

</style>