<template>
    <ion-page :key="qt">
        <ion-header>
            <ion-toolbar   color="primary" class="ion-justify-content-start ion-align-items-start"> 
                <ion-title>
                    <ion-row  class="ion-justify-content-start ion-align-items-start">
                        <ion-col size="40"  class="ion-float-left izquierda" 
                            :style="isIOS ? 'padding-left: 4rem !important;': ''">
                            Revisar Crossdocking ({{pdv}})
                        </ion-col>
                    </ion-row>                
                </ion-title>
                <ion-buttons slot="end" >
                    <ion-button size="default" color="light"  routerLink="/recepciones" routerDirection="none">
                        <i class="fa-sharp fa-solid fa-circle-left muy-grande" color="light" ></i>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content>
            
            <ion-row  class="spin ion-justify-content-center "  v-if="!showme">
                <ion-col  v-if="!showme" class="spin ion-align-self-center" size="48" > 
                    <ion-spinner  v-if="!showme"></ion-spinner>            
                </ion-col>
            </ion-row>
                 
                 
            <ion-row v-if="showme && datos_tot">
                <div class="resumen" v-if="resumen_entrada">
                        <ion-row  class=" cross-docking resumen-header " >
                            <ion-col class="resumen-header superior centro ion-align-self-end" size="30">Lineas entrada</ion-col>
                            <ion-col class="resumen-header borde-izquierdo superior centro ion-align-self-end" size="18">Valor</ion-col>
                            <ion-col class="resumen-header  centro ion-align-self-end" size="7">Stock</ion-col>
                            <ion-col class="resumen-header  centro ion-align-self-end" size="7">Cross</ion-col>
                            <ion-col class="resumen-header  centro ion-align-self-end" size="8">Mixtas</ion-col>
                            <ion-col class="resumen-header  centro ion-align-self-end" size="8">Total</ion-col>
                            <ion-col class="resumen-header borde-izquierdo centro  ion-align-self-center" size="9">Stock</ion-col>
                            <ion-col class="resumen-header  centro resalta ion-align-self-center" size="9">Cliente</ion-col>
                        </ion-row>
                        <ion-row  class=" cross-docking resumen-detalle " >
                            <ion-col class="resumen-detalle centro" size="7">{{$filters.Entero(resumen_entrada.linstock)}}</ion-col>
                            <ion-col class="resumen-detalle centro" size="7">{{$filters.Entero(resumen_entrada.lincross)}}</ion-col>
                            <ion-col class="resumen-detalle centro" size="7">{{$filters.Entero(resumen_entrada.linmix)}}</ion-col>
                            <ion-col class="resumen-detalle centro" size="9">{{$filters.Entero(resumen_entrada.lineas)}}</ion-col>
                            <ion-col class="resumen-detalle borde-izquierdo centro" size="9">
                                {{$filters.sIntPorcentaje(resumen_entrada.stock*100/resumen_entrada.valor)}}
                            </ion-col>
                            <ion-col class="resumen-detalle resalta centro" size="9">
                                {{$filters.sIntPorcentaje(resumen_entrada.commited*100/resumen_entrada.valor)}}
                            </ion-col>
                        </ion-row>

                </div>

                   <!--  {{datos_tot}}-->
                <ion-text class="ion-text-wrap aviso-crossdocking" v-if="hayCrossDocking">
                    Hay materiales pendientes de entrega a cliente. <br/>
                    Marca los que quieras separar en las zonas de Cross-docking propuestas 
                </ion-text>
                <ion-list v-for="(tipo) in Object.keys(datos_tot)" lines="none" :key="`caso:${tipo}`">
                    <ion-list-header>
                        {{tipo == 'especifico' ? 'Pedidos especificos' : 'Pedidos genericos'}} 
                    </ion-list-header>
                    <ion-list 
                        v-for="(pedido,index) in datos_tot[tipo]" v-bind:key="`pedido-${tipo}-${index}-${pedido.numped}`"
                        lines="none">
                        <ion-item  lines="none" >
                            <ion-label class="titulo-cross-docking">
                                {{pedido.cliente}} - {{pedido.nomenv}}<br/>
                                <span class="izquierda cross-docking">
                                    <small>{{pedido.locenv}}</small>
                                </span>    
                            </ion-label>                            
                            
                            <ion-toggle  color="primary" slot="end" @ionChange="generarRegistrosCrossDocking()" v-model="pedido.xdocking" ></ion-toggle>
                        </ion-item>

                        
                        <div class="pedidos">
                            <ion-row  class=" cross-docking pedidos-header " >
                                <ion-col class="pedidos-header" size="12">Pedido</ion-col>
                                <ion-col class="pedidos-header forzar_derecha" size="12">Total</ion-col>
                                <ion-col class="pedidos-header forzar_derecha" size="8">Ent.Tot</ion-col>
                                <ion-col class="pedidos-header forzar_derecha" size="8">Pdte.</ion-col>
                                <ion-col class="pedidos-header forzar_derecha" size="8">Entrada</ion-col>
                            </ion-row>

                            <ion-row  class=" cross-docking pedidos-detalle" >
                                <ion-col class="pedidos-detalle" size="12">      
                                    <span class="izquierda ">{{pedido.numped}} 
                                        <span style="color:var(--ion-color-primary) !important; ">({{pedido.enttot}})</span>
                                    </span>
                                </ion-col>
                                <ion-col class="pedidos-detalle" size="12">
                                    <span class="forzar_derecha ">{{$filters.Moneda(pedido.impbru_ped)}}</span>
                                </ion-col>
                                <ion-col class="pedidos-detalle " size="8">
                                    <span class="forzar_derecha enttot" style="color:var(--ion-color-primary) !important; ">
                                        {{pedido.enttot == 'S' ? 'Si' : 'No'}}
                                    </span>
                                </ion-col>
                                <ion-col class="pedidos-detalle " size="8">
                                    <span class="forzar_derecha ">{{$filters.sIntPorcentaje(pedido.imppen_ped*100/pedido.impbru_ped)}}</span>
                                </ion-col>
                                <ion-col class="pedidos-detalle " size="8">
                                    <span class="forzar_derecha ">{{$filters.sIntPorcentaje(pedido.impbru_ent*100/pedido.impbru_ped)}}</span>
                                </ion-col>
                            </ion-row>
                        </div>
                        
                        <ion-row class="cross-docking ">
                            <ion-col size="48" v-if="pedido.xdocking" class="ion-align-self-end">
                                <ion-label class="alerta-sobrantes" v-if="pedido.sobrante_total && pedido.sobrante_total!=0" >OJO, hay sobrantes</ion-label>
                                <span class="forzar_derecha cross-docking-ubi">{{asignarUbicacionCrossDocking(pedido.numped)}}</span>
                            </ion-col>
                        </ion-row>
                        <div class="destacar" v-if="pedido.xdocking && pedido.sobrante_total && pedido.sobrante_total!=0" >
                            <ion-row class="cross-docking detalle-header" >
                                        <ion-col class="detalle-header" size="16">Sku</ion-col>
                                        <ion-col class="detalle-header" size="14">Lote</ion-col>
                                        <ion-col class="detalle-header" size="10">Recib.</ion-col>
                                        <ion-col class="detalle-header" size="8">Sobra</ion-col>

                                    <div class="full" v-for="(sk, index) in filter(pedido.detalle, (sk)=>sk.sobrante && sk.sobrante!=0)" :key="`lote-sk-${sk.sku}-${index}`">
                                        <ion-row class=" cross-docking detalle-detail" v-for="(lt) in filter(sk.lotes, (v)=>v.sobrante && v.sobrante!=0)" :key="`lote-${lt}`" >

                                            <ion-col class="detalle-detail izquierda" size="16">{{sk.sku}}</ion-col>
                                            <ion-col class="detalle-detail izquierda" size="14">{{lt.numlot}} </ion-col>
                                            <ion-col class="detalle-detail derecha" size="10">{{$filters.sDecimal(lt.uds_rec)}}</ion-col>
                                            <ion-col class="detalle-detail derecha" size="8">{{$filters.sDecimal(lt.sobrante)}}</ion-col>
                                        </ion-row>
                                    </div>
                            </ion-row>
                        </div>
                    
                    </ion-list>
                </ion-list>




                

            </ion-row>
        </ion-content>


        <ion-footer >
            <ion-toolbar color="primary" >    
                <ion-buttons slot="end" class="completo" color="primary" >    
                    <ion-button class ="completo"  expand ="block" fill="outline" 
                        id="crear_entrada"
                        size="normal" @click="RevisarEntrada()"
                        
                        > 
                        <b>Revisar Entrada</b>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-footer>

    </ion-page>

</template>
    

<script>
    import { defineComponent } from 'vue';
    import {   addCircleSharp} from 'ionicons/icons';
    import { useRoute } from 'vue-router';
    import { filter, extend, values, compact,  chain , keys, omit } from 'underscore';
    import { getDetallePedidosSku } from '@/pages/recepcionar/funciones/ChequearCrossDocking'
    import { loadStorage } from '@/utilities/storage/storage-utilities-main'
    import { loadEntradafromStorage , saveEntradaToStorage  } from '@/utilities/storage/storage-entradas'
     
    import { DetallesReferencia} from '@/utilities/scanner/scan-sku';
    import { convertSku }  from '@/utilities/global_functions'

    export default defineComponent({
            name : "RevisarCrossdockingClientesPage",
            data() { 
                return { 
                    filter, element_id: 'revisar-cross-docking-cliente',
                    pdv: undefined,
                    isIOS: undefined, 
                     addCircleSharp, 
                    datos_tot: undefined , 
                    showme :false, 
                    ruta: undefined, 
                    qt :false , 
                    debounce_milis: 300   , 
                    zonas_cd : undefined , 
                    crossdocking_data: [], 
                    recepcion_data : undefined , 
                    entrada_g2k: [] , 
                    cd_per_sku:{}, 
                    lineas_cd : undefined, 
                    crossdocking : undefined 
                    }
            },
            props : ['codpro', 'codalb'] , 
            created() {
                this.pdv =  this.$route.meta.pdv 
                this.isIOS = this.$route.meta.isIOS 
            },
            updated() {
                this.pdv =  this.$route.meta.pdv 
            },
            computed :{
                hayCrossDocking: function(){
                    return keys(this.datos_tot).length>0 
                }, 
                resumen_entrada: function () {
                    return this.entrada_g2k.reduce((m,x)=>{
                        const sk = `${x.codmar}.${x.artpro}`
                        m['lineas']= (m['lineas']||0)+1
                        m['linstock'] = (m['linstock']||0)+((this.cd_per_sku[sk]||0)== 0 ? 1 : 0 ) 
                        m['lincross'] = (m['lincross']||0)+((this.cd_per_sku[sk]||0)== x['udsrec'] ? 1 : 0 ) 
                        m['linmix'] = m['lineas']-m['linstock']-m['lincross'] 
                        m['valor'] = (m['valor']||0)+(x['vstock']||0)
                        m['commited'] = (m['commited']||0)+((this.cd_per_sku[sk]||0)*(x.pmp_ges||0))
                        m['stock'] = (m['valor']||0)-(m['commited']||0)
                        return m 
                    }, {})
                    }
            }, 
            async mounted() {
                this.pdv =  this.$route.meta.pdv;               
                const route = useRoute();
                this.ruta = route.fullPath
                this.qt = route.query.q
                await this.fetchData()
                const all_recepcion_data = await loadEntradafromStorage(this.pdv, this.codpro, this.codalb, 'recepcion')
                this.recepcion_data = all_recepcion_data.filter(x => x.recepcionable === true)
                await this.prepararDatosEntrada()
                await this.generarRegistrosCrossDocking()
                window.fake_console(this.recepcion_data)
                this.showme = true

            },
            methods: {
                async fetchData () { 
                    const datos_backlog = await getDetallePedidosSku(this.pdv, this.codpro, this.codalb, this.$http)
                    window.fake_console(datos_backlog)
                    this.datos_tot = datos_backlog
                    window.fake_console(this.datos_tot)
                    window.fake_console(this.datos_tot)
                    const zonasCD= await loadStorage('zonas_crossdocking');
                    window.fake_console(zonasCD)
                    this.zonas_cd = zonasCD === undefined ? 1 : zonasCD;
                    window.fake_console(this.zonas_cd)
                    return 
                }, 
                
                goBack() {
                    this.$router.go(-1) 
                },

                asignarUbicacionCrossDocking (numped) {
                    window.fake_console(numped, this.zonas_cd, (numped%this.zonas_cd))
                    const box = ('0'+(numped%this.zonas_cd)).slice(-2)
                    return `CRO${box} ${numped}`
                },

                prepararDatosEntrada(){
                    /*** notas  FALTA
                     * la fecha de entrada 
                     * ubicacion 
                     */
                    let dat = this.recepcion_data
                    const maindata = {codemp : this.pdv, codpro: this.codpro, codalb: this.codalb }
                    this.entrada_g2k = dat.map( (v, i ) => {
                        const {codsuc, sku, desart, geslot, numlot, numped, pedlin , uds_alb, uds_ped, uds_rec , accion, pmp_ges } = v 
                        const { codmar , artpro } = convertSku(sku)
                        const uu = DetallesReferencia(sku).filter(x=>x.lote === null ).reduce((mu,vu)=> {
                            if (vu.ubi) { mu[codsuc] = vu.ubi }
                            return mu ;
                        }, {})
                        window.fake_console(uu)
                        const posibles = compact([uu[codsuc], uu['00'], ...values(uu)])
                        window.fake_console(posibles)
                        const linea = extend (
                            {codsuc, codmar , artpro, desart, geslot, numlot : numlot||null , 
                                numped : geslot==='E' && pedlin=== -99 ? null : numped , 
                                pedlin : geslot==='E' && pedlin=== -99 ? null : pedlin  , 
                                unidad : parseInt(uds_alb*100)/100||0, uniped: parseInt(uds_ped*100)/100||0, udsrec: parseInt(uds_rec*100)/100||0 , 
                                accion: accion === true ? null : accion||null , 
                                numlin : i+1 , ubicacion : posibles[0]||null , unircl : accion === null ? 0 : accion === true ? 0 :  uds_alb-uds_rec, 
                                pmp_ges, 
                                vstock: (pmp_ges||0)*(uds_alb||0)
                            }, maindata, 
                            )
                        return linea
                        })
                    const precios_entrada = this.entrada_g2k.reduce( (my,vy)=>{
                        window.fake_console(my,vy)
                        return vy 
                    }  , {})
                    window.fake_console(precios_entrada)

                }, 
                generarRegistrosCrossDocking(){
                    
                    const maindata = {codemp : this.pdv, codpro: this.codpro, codalb: this.codalb }
                    window.fake_console(this.datos_tot)
                    this.crossdocking_data= chain(this.datos_tot).values().flatten().filter(x=>x.xdocking === true).value()

                    window.fake_console(this.crossdocking_data)


                    const lineas_cd =  chain(this.crossdocking_data).pluck('lineas').flatten().value()

                    let detalle_lotes = chain(this.crossdocking_data).pluck('detalle').flatten().compact()
                        .reduce((mv, gv)=> {mv[gv['sku']]= gv['lotes']; return mv}, {})
                        .value()
                    
                    /**FALTARÍA EL CARTERIANO DE  LOTES Y BOBINAS  */
                    //window.fake_console(detalle_lotes)
                    this.crossdocking = lineas_cd.reduce((mf,vf)=> {
                        window.fake_console(vf)
                        const sk =   `${vf.codmar}.${vf.artpro}`
                        const {numped, numlin, codsuc, codmar, artpro, asignadas} = vf
                        let lin = extend({numpedc : numped, numlinc : numlin, codmar, artpro,codsuc,  unidad : asignadas }, maindata, {numlot : null },  )
                        window.fake_console(lin)
                        const lotes = detalle_lotes[sk]
                            if (lotes && lotes.length>0) {
                                lotes.forEach(l => {
                                    l.unidad = l.uds_rec
                                    mf.push( extend( {},lin , omit(l,'uds_rec'), {'ubicacion': this.asignarUbicacionCrossDocking(lin.numpedc)} ))
                                    const bobs = this.entrada_g2k.filter(x=>x.numlot == l.numlot && sk !=`${x.codmar}.${x.artpro}` )
                                    if (bobs.length >0)
                                        {                                    
                                        const {codmar, artpro, numlot, unidad} = bobs[0]
                                        mf.push(extend({},lin, {codmar, artpro, numlot, unidad, 'ubicacion': this.asignarUbicacionCrossDocking(lin.numpedc)}))
                                        }                                    
                                    window.fake_console(bobs)
                                    })
                                }
                            else { mf.push(extend(lin, {'ubicacion': this.asignarUbicacionCrossDocking(lin.numpedc)})) }
                            return mf 
                        },[])

                    this.cd_per_sku = this.crossdocking .reduce((m,v)=> {
                        const sku  = `${v.codmar}.${v.artpro}`  
                        m[sku] = (m[sku]||0)+v['unidad']
                        return m 
                    }, {})
                    window.fake_console(this.cd_per_sku)
                    window.fake_console(this.entrada_g2k)
                    window.fake_console(this.crossdocking)
                }, 
            async RevisarEntrada(){
                try {
                    const {codpro, pdv, codalb} = this;
                    window.fake_console({codpro, pdv, codalb})
                    await saveEntradaToStorage ( pdv,  codpro,  codalb, 'entrada', this.entrada_g2k);
                    await saveEntradaToStorage ( pdv,  codpro,  codalb, 'crossdocking', this.crossdocking);
                    this.$router.replace({'name' :'revisar_entrada', params : {  codpro, codalb }})
                }
                catch (e) {
                    console.log(e)

                }


            }


                

            },    
        })
</script>

<style scoped lang="scss">
    @import "@/theme/footer-styles.scss";
    @import "@/theme/seleccion-producto-stock.scss";
    @import "@/theme/revisar-crossdocking-cliente.scss";


    
</style>