<template>
    <ion-page>
        <CabeceraComponentBack 
            :pdv = "$route.meta.pdv"
            :isIOS = "$route.meta.isIOS"
            titulo="Asignar delegados"
            color="primary"
        />
        <ion-header>
            <ion-toolbar color="primary" style="padding-top:8px;" class="top">
                <ion-searchbar  v-model="search_manually"
                    @ionChange="filtrar_elementos"
                    autocomplete="off" autocorrect="off" 
                    autocapitalize="off" spellcheck="false"
                    show-clear-button="always"
                    :placeholder="'Filtrar'" 
                    slot="start"
                    mode = "md">
                </ion-searchbar>       
            </ion-toolbar>  

        </ion-header>

        <ion-content class="ion-padding" key="`content:${qt}`"> 

            <ion-row  class="spin ion-justify-content-center "  v-if="!showme">
                <ion-col  v-if="!showme" class="spin ion-align-self-center" size="48" > 
                    <ion-spinner  v-if="!showme"></ion-spinner>            
                </ion-col>
            </ion-row>

           <ion-row v-else>     
                <ion-list  v-for="(v, index) in datos" :key="`ele:${index}`">   
                    <ion-item v-if="v.visible ===true">
                        <ion-label > 
                                {{v.nombre}}<br/>
                                <small>{{v.posicion}}</small>
                        </ion-label>
                        <ion-checkbox slot="end" v-model="v.isDelegate" @ionChange="changeDelegate(v)">
                        </ion-checkbox>
                    </ion-item>
                </ion-list>
                
           </ion-row>

        </ion-content>

 

    </ion-page>
</template>

<script>

    
    import CabeceraComponentBack from '@/components/CabeceraComponentBack'
    import { defineComponent } from 'vue';
    import { isOnline }  from '@/utilities/global_functions';
    import { useRoute } from 'vue-router';
    import { every, sortBy } from 'underscore';
    import {  openAlertaV2 } from  '@/utilities/services/alert-utilities';


    export default defineComponent({
            name : "CambiarDelegadosPage",
            components: {  CabeceraComponentBack},
            async created() {
                this.pdv =  this.$route.meta.pdv ;
                this.nompos =  this.$route.meta.nompos ;
                this.pdv_geoloc =  this.$route.meta.pdv_geoloc ;
                this.ubiku_rol = this.$route.meta.ubiku_rol 

            },
            data() { return {  
                isOnline, 
                datos : [], 
                delegados : [],
                search_manually: undefined , 
                showme: false , 
             }},
            async mounted() { 
                window.fake_console('seleccion ubicaciones: entro en mounted  ', this.pdv)
                const route = useRoute();
                this.qt = route.query.q||Date.now()
                const chk = await  this.$http.post(`/check/${this.pdv}`)
                if (chk.data.ubiku_rol !== 10 ) {
                    await openAlertaV2('Operacion no permitida', `
                            Tu perfil no permite acceder a esta operación `) 
                    this.$router.replace('/login')
                }

                await this.recuperar_delegados() 
            }, 
            methods: {     
                async recuperar_delegados() {
                    try {
                        /** recuperaos los vehiculos y vemos  */
                        const res = await this.$http.post(`/get_transportes_pdv/${this.pdv}`) 
                        const {delegados, personas }= res.data
                        this.delegados = delegados
                        const datos = personas.map( (v)=> { 
                            v.isDelegate = delegados.indexOf(v.email) !=-1;
                            v.visible = true ; return v 
                            }).filter( x =>x.email != this.$current_user)
                        this.datos = sortBy(datos, v =>{return  v.email })
                        this.showme = true ; 
                        this.qt = new Date()
                    }
                    catch(ex){
                        console.log(ex)
                        alert('ERROR : '+JSON.stringify(ex))
                    }
                }, 

                async changeDelegate(v){
                    this.showme = false; 
                    if (v.isDelegate=== true) {
                        this.delegados.push(v.email)
                    }
                    else {
                        this.delegados = this.delegados.filter(x => x!=v.email)
                    }
                    window.fake_console(this.delegados)
                    try
                        {
                            const feedback = await this.$http.post(`/update_transportes_pdv/${this.pdv}`,{ delegados : this.delegados })
                            if (feedback.data.status == 1) {
                                await openAlertaV2('Delegados actualizados', `
                                        Has actualizado los recursos de transporte del pdv `) 
                                await this.recuperar_delegados()
                                }
                            else { throw 'feedback negativo' }
                        }                        
                        catch(ex) {
                            console.log('error')
                            console.log(ex)
                            window.fake_console('ERROR ', ex)
                        }


                }, 
                
                
                async filtrar_elementos () {
                    this.showme = false; 
                    const terminos = this.search_manually
                    if (!terminos || terminos === false ) {
                        this.datos.forEach( v => v.visible = true)
                    }
                    else {
                        const primarySearch = new RegExp(`(${terminos.toLowerCase().replace(/ +$/,'').replace(/ +/g,'.*')})`, 'i')
                        const secondarySearch = terminos.toLowerCase().replace(/ +$/,'').split(' ').map(v => new RegExp(`${v}`,'i'))
                        this.datos.forEach( v => {
                            v.visible = false; 
                            if ( primarySearch.test(JSON.stringify(v).toLocaleLowerCase()) ||every(secondarySearch, t => t.test(JSON.stringify(v).toLowerCase()))) {
                                v.visible = true
                            }
                        })
                    }
                    this.showme = true
                },    

                
            }
})
</script>
<style scoped >
    ion-content {
        max-width: 700px !important;
    }
    ion-title {
        padding-left: 4px !important; 
        padding-right: 4px !important; 
        position:relative

    }
    ion-list, ion-item {
        width: 100% !important;
    }
        



</style>