<template>
    <ion-page>
        
        <ion-header>
            <ion-toolbar  color="primary" class="ion-justify-content-start ion-align-items-start"> 
                <ion-title>
                    <ion-row  class="ion-justify-content-start ion-align-items-start">
                        <ion-col size="40"  class="ion-float-left izquierda"                     
                            :style="isIOS ? 'padding-left: 4rem !important;': ''">
                            Seleccion pedido cliente 
                        </ion-col>
                    </ion-row>                
                </ion-title>
                <ion-buttons slot="end" >
                    <ion-button size="default" color="light"  @click="back2CustomerList()">
                        <ion-icon :icon="closeCircle" color="light" class="muy-grande"> </ion-icon>
                    </ion-button>
                </ion-buttons>

            </ion-toolbar>
        </ion-header>

        <ion-header>
            <ion-toolbar color="primary" style="padding-top:8px;" class="top">
                <ion-searchbar  v-model="search_manually"
                    @ionChange="filtrar_elementos"
                    autocomplete="off" autocorrect="off" 
                    autocapitalize="off" spellcheck="false"
                    show-clear-button="always"
                    :placeholder="'Buscar pedido'" 
                    slot="start"
                    mode = "md">
                </ion-searchbar>       
            </ion-toolbar>

        </ion-header>

        <ion-content class="ion-padding">
            <ion-row>
                <ion-note lines="none" text-right v-if="filtrados && lista_pedidos">
                      <i><span>{{ filtrados.length }}</span> de <span>{{ lista_pedidos.length }}</span> pedidos de {{nomcli}}</i>
                </ion-note>


                <ion-list v-if="showme && lista_pedidos && lista_pedidos.length>0" class="no-padding">      
                  <ion-list-header class="head"  style="width:100% !important;"  color="">
                        <ion-row>
                            <ion-col size="48">
                                <ion-text class="fake-titulo"> Pedidos pendientes del cliente </ion-text>
                                <ion-text class="fake-titulo"> {{cliente}} {{nomcli}} </ion-text>

                            </ion-col>
                        </ion-row>
                    </ion-list-header>

                      <ion-item   class="ion-no-padding ion-no-margin"
                        v-for="(pedido,index) in filtrados"
                        :key="`ped-${index}`" @click="forward(pedido)"      >
                        <ion-row >    
                            <ion-col size-offset="1" size="47"  class="ion-no-margin  ion-no-padding">
                                <h3 > 
                                    Pedido: {{ pedido.numped  }}
                                    <span class="forzar_derecha primario" v-if="pedido.pedcli">Pedcli : {{ pedido.pedcli   }}</span>
                                </h3>
                                <h4 class="nombre_cortado" text-wrap v-if="pedido.codobr">
                                    Obra: {{pedido.codobr}}
                                </h4>
                            </ion-col>

                            <!-- RESULTADOS DESDE EL STOCK -->
                            <ion-col size-offset="1" size="35"  class="ion-no-margin  ion-no-padding" >
                              <h5 class="full-h5"> 
                                  <span class="lote"  >Fecha: {{ $filters.num2Date(pedido.fem)   }}</span>
                              </h5>
                            </ion-col>
                            <ion-col size="12"  class="ion-no-margin  ion-no-padding ion-text-end" >
                                <ion-text class="stock" text-wrap>
                                    <span class="stock">
                                      <b>{{$filters.Entero(pedido.val_pen)}}</b>
                                      <sup>{{$filters.DecimalPart(pedido.val_pen)}}</sup>
                                    </span>
                                </ion-text>
                            </ion-col>
                        </ion-row>
                      </ion-item>
                </ion-list>

                <ion-list v-else class="no-padding">      
                    <ion-list-header class="head"  style="width:100% !important;"  color="">
                          <ion-row>
                              <ion-col size="48">
                                  <ion-text class="fake-titulo"> Pedidos pendientes del cliente </ion-text>
                              </ion-col>
                          </ion-row>
                      </ion-list-header>

                  <ion-item lines="none" >
                      <ion-row class="ion-padding ion-margin  no-lines" >
                              <ion-col class="centro">
                                  <ion-label >No hay pedidos pendientes del cliente </ion-label>
                              </ion-col>
                      </ion-row>      
                  </ion-item>
                </ion-list>

            </ion-row>

        </ion-content>
    </ion-page>
</template>


<script>

    
    import { defineComponent } from 'vue';
    import {closeCircle} from "ionicons/icons";
    import { partition, every, sortBy} from 'underscore';

    export default defineComponent({
        name: 'CustomerOrdersListPage',
        props:['cliente', 'origen'],
        data() {
            return {
                closeCircle,
                pdv: undefined, 
                nomcli: undefined, 
                showme :false,                 
                filtrados: undefined, 
                search_manually: undefined ,
                datos: [], 
                lista_pedidos : [], 
                limite: 25
            }
        },
        created() {
                this.pdv =  this.$route.meta.pdv ;
                this.nomcli = this.$route.meta.nombre_cliente;
                this.isIOS = this.$route.meta.isIOS ;
                window.fake_console('seleccion ubicaciones: entro en created', this.$route.meta.pdv)
        },
        async mounted (){
            this.pdv =  this.$route.meta.pdv ;
            this.isIOS = this.$route.meta.isIOS ;    
            window.fake_console('mounted' , this.$route)
            await this.fetchCustomerOrders()
            
            
        },
        updated (){
            window.fake_console('updated' , this.$route)
            this.search_manually = false; 
            this.filtrar_elementos()
            
            
        },
        methods : {
            async fetchCustomerOrders (){
                try 
                {
                    window.fake_console(this.pdv)
                    const [pdv, codcli] = this.cliente.split('.')
                    const dt =  await this.$http.get(`/get_pedidos_cliente/${pdv}/${codcli}`)
                    this.lista_pedidos = dt.data
                    await this.filtrar_elementos()
                }
                catch(ex) {
                    return null 
                }
            },
            async filtrar_elementos () {
                this.showme = false; 
                let dat 
                const terminos = this.search_manually
                if (!terminos || terminos === false ) {
                    dat = this.lista_pedidos.slice(0,this.limite)
                }
                else {
                    const primarySearch = new RegExp(`(${terminos.toLowerCase().replace(/ +$/,'').replace(/ +/g,'.*')})`, 'i')
                    const secondarySearch = terminos.toLowerCase().replace(/ +$/,'').split(' ').map(v => new RegExp(`${v}`,'i'))
                    const [primario,resto] = partition(this.lista_pedidos, v=>primarySearch.test(JSON.stringify(v).toLocaleLowerCase()))
                    const secundario =  resto.filter(v=>every(secondarySearch, t => t.test(JSON.stringify(v).toLowerCase())))                    
                    dat = primario.concat(secundario)||[]
                }
                this.filtrados = sortBy(dat, z=> -parseInt(z.fem)||0).slice(0,this.limite )
                this.showme = true
            }, 
            async back2CustomerList() {
                this.$router.replace({'name' : 'clientes_preparacion_pedidos'  , params : {'cliente': this.cliente, 'origen':'home' }})
            }, 
            async forward(pedido){
                window.fake_console(pedido);
                const {codemp, codsuc, numped } = pedido ;
                const ped_key = `${codemp}.${codsuc}.${numped}`
                this.$router.replace({'name':'preparar_pedido_cliente', params: {'origen': 'pedidos_mostrador' , 'cliente': this.cliente, 'pedido':ped_key }})
            }

        }

    });
</script>




 
<style scoped lang="scss">

    @import "@/theme/seleccion-pedido-styles.scss";

    sup {
      padding-left: 0.3rem !important; 
    }

    span.boton-opciones {
        font-size: larger !important; 
        padding-bottom: 0.3rem !important; 
        font-weight: bold !important;
    }
    ion-badge {
        width: 100% !important; 
    }
    ion-row.altos {
        height: 3rem !important;
        
    }
    ion-text.en-botton {
        font-family: 'Roboto';
        font-size: 0.9rem !important; 
        color: var(--ion-color-blanco)  !important;
        text-transform: none !important;
        line-height: 0.4rem !important;
    }
    i.fas.blanca {
        font-size: 2rem !important; 
        color: var(--ion-color-blanco)  !important;
        line-height: 1rem !important; 
        }
    ion-row {
        max-width: 600px !important; 
        padding: 0.3rem 0.2rem !important; 
    }

    ion-item {
        --inner-padding-end : 0px !important; 
        --padding-end : 0px !important; 
    }
    ion-text.fake-titulo {
        color: var(--ion-color-primary) !important; 
    }
    span.oscuro {
        color: var(--ion-color-medium) !important; 
        padding-left: 0.3rem !important; 
    }
    span.primario {
        color: var(--ion-color-primary) !important; 
        padding-left: 0.3rem !important; 
    }
    span.peque {
        font-size: smaller !important; 
        padding-right: 0.3rem !important; 
    }
    i.fas.tiempo {
        color: red; 
        font-weight: 600 important; ;
        font-size: 1.8rem !important;
        padding-right: 0.4rem !important
    }

    ion-item.iniciado {
        border-right: 10px  solid !important;
        border-image: linear-gradient(to bottom, white, var(--ion-color-darkdanger), white) 2;
    }

    ion-icon.crossdock {
        font-size: xx-large !important; 
        font-weight: 1000 !important;

    }
    .margen-down {
        margin-bottom: 0.3rem !important; 
    }
   .primario {
    color: var(--ion-color-primary) !important; 
   }
</style>