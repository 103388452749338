<template>
    <ion-page :key="qt">
        <ion-header>
            <ion-toolbar   color="primary" class="ion-justify-content-start ion-align-items-start"> 
                <ion-title>
                    <ion-row  class="ion-justify-content-start ion-align-items-start">
                        <ion-col size="40"  class="ion-float-left izquierda" 
                            :style="isIOS ? 'padding-left: 4rem !important;': ''">
                            Revisar Stock Crossdocking ({{pdv}})
                        </ion-col>
                    </ion-row>                
                </ion-title>
                <ion-buttons slot="end" >
                    <ion-button size="default" color="light"  @click="goBack">
                        <i class="fa-sharp fa-solid fa-circle-left muy-grande" color="light" ></i>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
            <ion-toolbar color="primary" style="padding-top:2px;" class="top">
                    <ion-searchbar  v-model="search_manually"
                        id="busqueda_filtrada_crossdocking"
                        ref="search_busqueda_filtrada_crossdocking"
                        :debounce="debounce_milis" 
                        @ionClear="filtrar"
                        autocomplete="off" autocorrect="off" 
                        autocapitalize="off" spellcheck="false"
                        show-clear-button="always"
                        placeholder="Filtrar items en crossdocking" 
                        inputmode ="search"
                        type="search"
                        @ionChange="filtroInputSearch"
                        @ionBlur="getFoco($event, false)"
                        @ionFocus="getFoco($event,true)"
                        slot="start"
                        mode = "md">
                    </ion-searchbar>

                </ion-toolbar>

        </ion-header>

        <ion-content>
            
           <ion-row  class="spin ion-justify-content-center "  v-if="!showme">
                <ion-col  v-if="!showme" class="spin ion-align-self-center" size="48" > 
                    <ion-spinner  v-if="!showme"></ion-spinner>            
                </ion-col>
            </ion-row>
                 
                 
            <ion-row v-if="showme">
                <ion-row class="ion-no-padding"
                     v-if="showme && lista_articulos.length >0 && (!search_manually|| search_manually.length==0)">
                    <ion-col size="44" text-right>
                        <p color="info">
                            <span style="font-size:larger"><b>{{datos_tot }} articulos en stock, </b>
                            filtra para seleccionar, se muestran los {{Math.min(limite, lista_articulos.length)}} primeros
                            </span> 
                        </p>            
                    </ion-col>
                </ion-row>
                <ion-row class="ion-no-padding" v-else-if="showme && lista_articulos.length >0 && search_manually && search_manually.length>0">
                    <ion-col size="44"  text-right>
                        <p color="info">
                            Tu busqueda "{{search_manually}}" ha generado <span style="font-size:larger"><b>{{datos_tot }} resultados </b></span>
                            <br/>
                            Solo se muestran los {{Math.min(limite, lista_articulos.length)}} primeros
                        </p>            
                    </ion-col>
                </ion-row>

                <!-- BUSQUEDA SIN RESULTADOS-->
                <ion-row class="ion-padding ion-margin" v-else>
                    <ion-title class="ion-padding-top ion-margin-top texto-mas-grande" >Busqueda sin resultados </ion-title>
                        <div class="ion-padding-top ion-margin-top ion-text-justify texto-mas-grande">
                            <ion-text >
                            No hemos encontrado ningun resultado para  tu busqueda "<strong><i>'{{search_manually}}'</i></strong>"
                            entre los articulos del pdv. <br/><br/>

                            Puedes editar manualmente la busqueda, o escanear para seguir buscando. 
                            <br/>
                            </ion-text>
                        </div>
                </ion-row>
                


                <!-- class="ion-no-padding ion-no-margin">         -->
                <ion-list v-if="showme && lista_articulos.length >0 " class="ion-no-padding">       
                    <ion-item 
                        v-for="(product,index) in lista_articulos"
                        v-bind:key="`item-${index}`"
                        class="ion-no-padding ion-no-margin">        
                        <ion-row :class="product.resaltado ? 'resaltado' : ''">    

                            <ion-col size-offset="1" size="47"  class="ion-no-margin  ion-no-padding">
                                <h3 @click = "void(0)" > {{ $filters.showSku(product.sku) ||(product.codmar+' '+product.artpro)   }}</h3>
                                <h5 @click = "void(0)" class="nombre_cortado " text-wrap>
                                   {{product.shortDescription||product.desart}}
                                </h5>
                            </ion-col>
                            <ion-col size-offset="1" size="47"  class="ion-no-margin  ion-no-padding">
                                <h4 @click = "void(0)" class="full-h5"> 
                                    <span class="lote" >{{product.nomcli }} ({{product.cliente}})</span>
                                </h4>
                            </ion-col>


                            <ion-col size-offset="1" size="36"  class="ion-no-margin  ion-no-padding">
                                


                                
                                
                                <h5 @click = "void(0)" class="full-h5"> 

                                <ion-button  size="small" class="boton-impresion-etiqueta" color="darksuccess" @click="imprimir_etiqueta(product.ubicac)" >
                                    <ion-icon slot="start" :icon="printSharp" class="impresion"></ion-icon>
                                    <span class="boton-impresion-etiqueta ">{{product.ubicac}}</span>
                                </ion-button>
                                    <span :class="product.estado =='C' ? 'alerta' : 'lote' " class="negrita grande" v-if="product.ubicac"> ({{product.estado}})</span>
                                    <span class="lote  rojo" v-else>No Ubicado</span>
                                </h5>
                                <h5 @click = "void(0)" class="nombre_cortado" text-wrap v-if="product.numlot!=null">
                                    <b><span class="lote">Lote : {{ product.numlot  }}</span></b>
                                </h5>
                            </ion-col>
                            <ion-col size="11"  class="ion-no-margin  ion-no-padding ion-text-end">
                                <ion-text  @click = "void(0)" class="stock" text-wrap>
                                    <span class="stock"><b>{{$filters.sDecimal(product.uds)}}</b></span>
                                </ion-text>
                                <ion-text @click = "void(0)" class="mini-lista" text-wrap>
                                   {{product.unimed}}
                                </ion-text>
                            </ion-col>
    
                        </ion-row>
                    </ion-item>
                </ion-list>
            </ion-row>
        </ion-content>

    </ion-page>

</template>
    

<script>
    import { defineComponent } from 'vue';
    import { closeCircleSharp , homeSharp,  printSharp, thumbsDownSharp} from 'ionicons/icons';
    import { RegexBobinasPdv , transformSchneiderBrand , DatosCatalogoSku} from '@/utilities/scanner/scan-sku'
    import { useRoute } from 'vue-router';
    import {partition, every , extend} from 'underscore';
    import {scanGenericStart, scanGenericStop  } from '@/utilities/scanner/scan-generic'
    import eventBus from "@/event-bus"; 

    export default defineComponent({
            name : "RevisionStockCrossDockingComponent",
            data() { 
                return { 
                    element_id: 'revision-stock-crossdocking',
                    pdv: undefined,
                    isIOS: undefined, 
                    search_manually : undefined, 
                    closeCircleSharp, homeSharp,  printSharp , thumbsDownSharp, 
                    limite:  100 , 
                    datos_tot: undefined , 
                    lista_articulos: undefined , 
                    articulos_crossdocking: undefined, 
                    showme :false, 
                    ruta: undefined, 
                    qt :false , 
                    input_activo: false,
                    debounce_milis: 300
                    
                    }
            },
            props : [  'input_inicial_filtro'] , 
            created() {
                this.pdv =  this.$route.meta.pdv 
                this.isIOS = this.$route.meta.isIOS 
            },
            watch: {
                '$route.query.q'() { this.fetchData();}, 
            },
            updated() {
                this.pdv =  this.$route.meta.pdv 
            },
            async mounted() {
                this.pdv =  this.$route.meta.pdv 
                const route = useRoute();
                this.ruta = route.fullPath
                this.qt = route.query.q
                this.fetchData()
                if (this.input_inicial_filtro !=  undefined ) {
                    this.search_manually = this.input_inicial_filtro+'' 
                    await this.filtrar(' Desde mounted')
                }
                await this.escaner_generico()
            },
            async unmounted() {
                await scanGenericStop(this.element_id)
            }, 
            methods: {
                getFoco (ev, si) {
                    window.fake_console('evento escuchado')
                    if(si===true) {
                        this.input_activo = true; 
                        window.fake_console('activado')
                    }
                    else {
                        this.input_activo=false; 
                        window.fake_console('desactivado')
                    }
                },
                goBack() {
                    this.$router.go(-1)
                },

                async lectorEventosGenericos() {
                    let self = this; 
                    eventBus().emitter.off(`lectura_generica:${this.element_id}`)
                    eventBus().emitter.once(`lectura_generica:${this.element_id}`,async (input_scaner)=>{
                       self.input_activo = false;
                       let buscar = input_scaner
                       /** miramos si el termino tiene includo un codigo de bobina , en cuyo caso lo eliminamos */
                       if ( RegexBobinasPdv.test(input_scaner) && input_scaner.replace(RegexBobinasPdv,'').length > 2)
                            { 
                                buscar = input_scaner.replace(RegexBobinasPdv,'')
                                window.fake_console(input_scaner, buscar)
                            }
                       self.search_manually = buscar
                       self.filtrar('Via lector Generico' , 'exacta') 
                       self.lectorEventosGenericos()
                    })
                },
                async escaner_generico() {
                    await scanGenericStart(this.element_id, 'desde escaner_generico')
                    await this.lectorEventosGenericos()
                },
                async filtroInputSearch() {
                    window.fake_console(this.input_activo)
                    if (this.input_activo) {
                        this.filtrar('Caja de busqueda via filtroInputSearch')
                    }
                }, 
                async fetchData(){
                    this.showme = false
                    this.pdv =  this.$route.meta.pdv 
                    window.fake_console('en el cancel ')
                    this.search_manually = undefined; 
                    const cd = await this.$http.post(`/get_crossdocking/${this.pdv}`)
                    const d = cd.data.map( x => {
                        const s = DatosCatalogoSku(x.codmar, x.artpro )
                        x.resaltado = x.estado =='C'
                        return extend(x, s )
                    }) 
                    window.fake_console(d)                    
                    this.datos_tot = d.length||0 
                    window.fake_console(this.datos_tot,d.slice(0,5))
                    this.articulos_crossdocking = d 
                    this.lista_articulos = d.slice(0,this.limite)
                    this.showme = true 
                    },
                async filtrar (comentario) {
                    window.fake_console('COMENTARIO :', comentario, this.input_activo)
                    window.fake_console(this.search_manually)
                    const terminos = transformSchneiderBrand(this.search_manually)
                    if (terminos === false ) {
                        this.lista_articulos= this.articulos_crossdocking
                        }
                    else {
                        const primarySearch = new RegExp(`(${terminos.toLowerCase().replace(/ +$/,'').replace(/ +/g,'.*')})`, 'i')
                        const secondarySearch = terminos.toLowerCase().replace(/ +$/,'').split(' ').map(v => new RegExp(`${v}`,'i'))
                        const [primario,resto] = partition(this.articulos_crossdocking, v=>primarySearch.test(JSON.stringify(v).toLocaleLowerCase()))
                        //en segundo lugar desordenados 
                        const secundario =  resto.filter(v=>every(secondarySearch, t => t.test(JSON.stringify(v).toLocaleLowerCase())))                    
                        this.lista_articulos= primario.concat(secundario)||[]
                    }
                    
                    this.datos_tot = this.lista_articulos.length 
                    this.lista_articulos = this.lista_articulos.slice(0,this.limite)
                    this.showme = true
                    
                },
                
                async imprimir_etiqueta(ubi) {
                    try  { 
                        await  this.$http.post(`/etiqueta/ubicacion/${this.$pdv}`, {ubi:ubi})
                        }
                    catch (ex) {
                        console.log(ex)
                        window.miconsola('Error : ', ubi)
                    }
                } , 

            },    
        })
</script>

<style scoped lang="scss">
    @import "@/theme/footer-styles.scss";
    @import "@/theme/seleccion-producto-stock.scss";
    .lote {
            color: var(--ion-color-dark) !important; 
        }   
    span.boton-impresion-etiqueta {
        color: var(--ion-color-white) !important; 
        font-size: 1.1rem !important; 
        padding: 0.3rem 0.8rem !important; 
    }
    ion-button.boton-impresion-etiqueta{
        width: 65% !important; 
    }
 
    ion-icon.impresion  {
        color: var(--ion-color-white) !important; 
    }
    span.grande {
        font-size: larger !important; 
        padding: 1rem !important; 
        height: 2rem !important; 
        position: relative !important; 
        bottom: 0px !important;
        left: 0px !important;  

        /*padding:  0.9rem 0.8rem !important; */
    }

</style>